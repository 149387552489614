import React, { Component } from 'react';

import { themeit } from 'react-themeit';

const themeOptions = {
  base: cb => cb(require('./styles.less')),
  themes: {
    glossary: cb => cb(require('./themes/glossary.less'))
  }
};

class PopUp extends Component {
  render() {
    const { word, description, example, onClick, styles, style } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.popUp} style={style}>
          <div
            role="button"
            tabIndex={0}
            onClick={onClick}
            className={styles.closeButton}
          />
          <h1>
            {word}
          </h1>
          <p>
            {description}
          </p>
          <br />
          <p>
            <b>Beispiele:</b>
            <br />
            {example}
          </p>
        </div>
      </div>
    );
  }
}

export default themeit(themeOptions)(PopUp);
