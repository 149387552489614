import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'empathicConvincing', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.empathicConvincing === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['food', 'empathicConvincing'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Auf empathische Art und Weise überzeugen',
      text: 'Sind Sie gut im Überzeugen? Wenn Sie empathisch sind, haben Sie bessere Chancen. Probieren Sie es aus! Mit welcher Antwort können Sie Ihre Patienten am besten motivieren?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title:
            'Mit welcher Antwort können Sie Ihre Patienten am besten motivieren, wenn…',
          question: 'Ihr Patient keinen Hunger hat.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              data: 'Haben Sie denn gar nichts gegessen? Sie sollten schon etwas zu sich nehmen.'
            },
            {
              id: 2,
              data: 'Ach, kommen Sie schon. Sie müssen jetzt etwas essen.'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'Das ist aber schade, ich könnte<br>Ihnen doch eine Kleinigkeit bringen, vielleicht einen Joghurt?'
            }
          ]
        },
        {
          title:
            'Mit welcher Antwort können Sie Ihre Patienten am besten motivieren, wenn…',
          question: 'Ihrer Patientin das Essen nicht schmeckt.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              data: 'Probieren Sie doch wenigstens mal. Ein bisschen was geht doch immer.'
            },
            {
              id: 2,
              data: 'Aber Sie haben doch vier Gerichte zur Auswahl. Eins muss Ihnen doch schmecken.'
            },
            {
              id: 3,
              meta: { correct: 1 },
              data: 'Darf ich Ihnen was anderes holen? Worauf hätten Sie denn Lust?'
            }
          ]
        },
        {
          title:
            'Mit welcher Antwort können Sie Ihre Patienten am besten motivieren, wenn…',
          question: 'eine Patientin gerne ihre Ruhe hätte.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Ah, verstehe. Dann komme ich später nochmal mit der Speisekarte vorbei.'
            },
            {
              id: 2,
              data: 'So geht es aber nicht! Dann müssen wir dem Arzt Bescheid geben.'
            },
            {
              id: 3,
              data: 'Sie sind aber nicht kooperativ! So kommen wir nicht weiter.'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
