import React, { Component } from 'react';
import { themeit } from 'react-themeit';

const themeOptions = {
  base: cb => cb(require('./styles.less')),
  themes: {
    back: cb => cb(require('./themes/back.less')),
    next: cb => cb(require('./themes/next.less')),
    invert: cb => cb(require('./themes/invert.less')),
    noFixed: cb => cb(require('./themes/noFixed.less'))
  }
};

class Button extends Component {
  render() {
    const { onClick, styles, children, className, style } = this.props;
    return (
      <div
        className={`${styles.buttonWrapper} ${className}`}
        style={style}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        {children || <i className={styles.buttonArrow} />}
      </div>
    );
  }
}

export default themeit(themeOptions)(Button);
