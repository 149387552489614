import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

import { CustomMultipleChoice } from '../../../components/customMultipleChoice';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'coffeemaschineExercise5', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.coffeemaschineExercise5 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['coffee', 'coffeemaschineExercise5'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Nachdrücklicher sprechen',
      text: 'Um Aussagen zu verstärken, wird häufig der Modalpartikel "doch" benutzt.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          type: 'CLOZE',
          title: (
            <span>
              Entscheiden Sie, an welcher Stelle <i>doch</i> passt.
            </span>
          ),
          text: `Beispiel:<br>
                 <i>Das habe ich dem Patienten <b>doch</b> schon erklärt.</i><br><br>
                 Das {} gehört {} zu {} meinem Job.`,
          items: [
            [
              { data: '_', meta: { correct: 1 }, id: 'a' },
              { data: 'doch', id: 'b' }
            ],
            [
              { data: '_', id: 'a' },
              { data: 'doch', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: '_', meta: { correct: 1 }, id: 'a' },
              { data: 'doch', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u16/u16_01.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: (
            <span>
              Entscheiden Sie, an welcher Stelle <i>doch</i> passt.
            </span>
          ),
          text: `Beispiel:<br>
                 <i>Das habe ich dem Patienten <b>doch</b> schon erklärt.</i><br><br>
                 Das {} schaffe {} ich {} auch allein.`,
          items: [
            [
              { data: '_', meta: { correct: 1 }, id: 'a' },
              { data: 'doch', id: 'b' }
            ],
            [
              { data: '_', meta: { correct: 1 }, id: 'a' },
              { data: 'doch', id: 'b' }
            ],
            [
              { data: '_', id: 'a' },
              { data: 'doch', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u16/u16_02.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: (
            <span>
              Entscheiden Sie, an welcher Stelle <i>doch</i> passt.
            </span>
          ),
          text: `Beispiel:<br>
                 <i>Das habe ich dem Patienten <b>doch</b> schon erklärt.</i><br><br>
                 Das {} ist {} kein {} Problem.`,
          items: [
            [
              { data: '_', meta: { correct: 1 }, id: 'a' },
              { data: 'doch', id: 'b' }
            ],
            [
              { data: '_', id: 'a' },
              { data: 'doch', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: '_', meta: { correct: 1 }, id: 'a' },
              { data: 'doch', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u16/u16_03.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
