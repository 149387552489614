import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'closeWindow', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'closeWindow', 'overlay'],
      false
    )
  );

  store.dispatch(stepsActions.set(['step1', 'choices', 'windowClosed'], true));

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'entities', 'window', 'overlay'],
      false
    )
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Ich mach\' mal wieder das Fenster zu, es ist schon ein bisschen frisch hier. Sind Sie einverstanden?',
      audio: '017-Fenster_schliessen_ID_1.mp3'
    },
    1: {
      from: 'ai',
      text: 'Ja gut, ist mir recht.',
      audio: '017-Fenster_schliessen_ID_2.mp3'
    },
    2: {
      from: 'player',
      text: 'Hoffentlich wird\'s bald wärmer draußen. Das wäre doch schön, oder?',
      audio: '017-Fenster_schliessen_ID_3.mp3'
    },
    3: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
