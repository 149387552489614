import React, { Component } from 'react';

import styles from './styles.less';

class Notification extends Component {
  render() {
    const { children, onClick } = this.props;
    return (
      <div className={styles.notificationContainer}>
        <div className={styles.notification}>
          <div
            role="button"
            tabIndex={0}
            onClick={onClick}
            className={styles.closeButton}
          />
          <span>
            {children}
          </span>
        </div>
      </div>
    );
  }
}

export default Notification;
