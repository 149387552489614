import React from 'react';

export const Communication = ({ size, className, fill }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    style={{ width: size, height: size }}
  >
    <title>EinTagDeutsch_Icons-Übungstypen</title>
    <g id="Layer_2" data-name="Layer 2">
      <path
        d="M23.41,23.6H45.62A2.38,2.38,0,0,1,48,26V36.22a2.38,2.38,0,0,1-2.38,2.38H42.27V42.2a.26.26,0,0,1-.17.25l-.09,0a.26.26,0,0,1-.2-.09L38.46,38.6H23.41A2.38,2.38,0,0,1,21,36.22V26A2.38,2.38,0,0,1,23.41,23.6Z"
        fill="#afd0ca"
      />
      <circle cx="28.38" cy="31.6" r="1.88" fill="#fff" />
      <circle cx="34.97" cy="31.6" r="1.88" fill="#fff" />
      <circle cx="41.56" cy="31.6" r="1.88" fill="#fff" />
      <path
        d="M19.14,26a4.27,4.27,0,0,1,4.27-4.27H43.64V12.48A3.68,3.68,0,0,0,40,8.81H5.68A3.68,3.68,0,0,0,2,12.48V32.07a3.68,3.68,0,0,0,3.68,3.68h5.16V41.3a.41.41,0,0,0,.26.38l.14,0a.41.41,0,0,0,.31-.14l5.17-5.82h2.42Z"
        fill="#79ada3"
      />
    </g>
  </svg>;
