import React from 'react';
import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { CustomMultipleChoice } from '../../../components/customMultipleChoice';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'checkWoundExercise3', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.checkWoundExercise3 === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['checkWound', 'checkWoundExercise3'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: () => <span>Schmerz&shy;beschreibungen verstehen</span>,
      text:
                'Wie können sich Schmerzen anfühlen? Es hämmert, sticht, brennt oder pocht. Hören Sie genau hin.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: (
            <span>
              <CustomMultipleChoice
                key={2}
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u7/u7_1.mp3')
                      },
                      'Hören Sie.'
                    ]
                  }
                ]}
              />
            </span>
          ),
          question: 'Was passt? Wählen Sie aus.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u7/haemmernd.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u7/pochend.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u7/brennend.jpg')
            },
            {
              id: 4,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u7/stechend.jpg')
            }
          ]
        },
        {
          title: (
            <span>
              <CustomMultipleChoice
                key={3}
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u7/u7_3.mp3')
                      },
                      'Hören Sie.'
                    ]
                  }
                ]}
              />
            </span>
          ),
          question: 'Was passt? Wählen Sie aus.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u7/brennend.jpg')
            },
            {
              id: 2,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u7/haemmernd.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u7/schneidend.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u7/stechend.jpg')
            }
          ]
        },
        {
          title: (
            <span>
              <CustomMultipleChoice
                key={4}
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u7/u7_2.mp3')
                      },
                      'Hören Sie.'
                    ]
                  }
                ]}
              />
            </span>
          ),
          question: 'Was passt? Wählen Sie aus.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u7/pochend.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u7/brennend.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u7/haemmernd.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u7/schneidend.jpg')
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
