import { createReducer, createActionTypes } from '../lib/redux';

const defaultState = {
  currentInfo: ''
};

const types = createActionTypes(['SET_CURRENT_INFO'], '');

const actions = {
  setCurrentInfo: value => ({
    type: types.SET_CURRENT_INFO,
    value
  })
};

const reducer = createReducer(defaultState, {
  [types.SET_CURRENT_INFO]: (state = defaultState, { value }) => ({
    ...state,
    currentInfo: value
  })
});

export { actions, reducer };
