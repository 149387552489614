import { combineReducers } from 'redux';

import { reducer as appReducer } from './app';
import { reducer as userReducer } from './user';
import { reducer as corridorReducer } from './corridor';
import { reducer as stepReducer } from './steps';
import { reducer as infoboxReducer } from './infobox';
import { reducer as glossaryReducer } from './glossary';
import { reducer as rewardSystemReducer } from './rewardSystem';
import { reducer as exerciseSetReducer } from './exerciseSet';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  corridor: corridorReducer,
  steps: stepReducer,
  infobox: infoboxReducer,
  glossary: glossaryReducer,
  rewardSystem: rewardSystemReducer,
  exerciseSet: exerciseSetReducer
});

export default rootReducer;
