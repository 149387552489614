import React, { Component } from 'react';
import { themeit } from 'react-themeit';

const themeOptions = {
  base: cb => cb(require('./styles.less')),
  themes: {
    green: cb => cb(require('./themes/green.less')),
    right: cb => cb(require('./themes/right.less')),
    narrator: cb => cb(require('./themes/narrator.less'))
  }
};

class Message extends Component {
  render() {
    const { message, messageHeadline, avatar, isThought, styles } = this.props;

    if (message && (message.length > 0 || typeof message === 'function')) {
      return (
        <div className={`${styles.message} ${isThought ? styles.thought : ''}`}>
          {messageHeadline && messageHeadline.length > 0
            ? <p className={styles.headline}>
              {messageHeadline}
            </p>
            : ''}
          <p>
            {typeof message === 'function' ? message() : message}
          </p>
          <div
            className={styles.avatar}
            style={{ backgroundImage: `url(${avatar})` }}
          />
        </div>
      );
    }

    return null;
  }
}

Message.displayName = 'Message';

export default themeit(themeOptions)(Message);
