import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { Speaker } from '../../../static/icons/Speaker';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'headboard', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.headboard === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['activitySecondPart', 'headboard'])
    );
  }
}

const style = { display: 'flex', alignItems: 'center' };

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Satzmelodie',
      text:
        'Der Ton macht die Musik: Nicht nur "was" Sie sagen, sondern "wie" Sie es sagen, ist wichtig für die Beziehung zu den Patienten. Achten Sie bei dieser Übung auf die Satzmelodie. Korrigieren Sie sich selbst!',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u12/u12_1.mp3')
                },
                'Guten Tag, Frau Keller, wie geht es Ihnen heute? Ich könnte Sie in den Aufenthaltsraum begleiten. Hätten Sie Lust?'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u12/u12_2.mp3')
                },
                'Frau Keller, ich hätte da eine Idee. Ich helfe Ihnen und wir machen das einfach zusammen. Was halten Sie davon?'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u12/u12_3.mp3')
                },
                'Na gut, ausnahmsweise mache ich das heute für Sie. Aber Sie versprechen mir, dass Sie morgen mitmachen, ja?'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u12/u12_4.mp3')
                },
                'Es wäre doch schön, wenn Sie sich die Zeitschriften selbst holen könnten. Was meinen Sie?'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u12/u12_5.mp3')
                },
                'Sie werden sehen: Wenn Sie regelmäßig Ihre Übungen machen, bekommen Sie mit der Zeit auch mehr Sicherheit.'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
