import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as appActions } from '../../reducers/app';
import { actions as infoboxActions } from '../../reducers/infobox';
import { Button } from '../../components/button';

import ENTRIES from '../../config/infos';

import styles from './styles.less';

const mapStateToProps = ({ infobox }) => ({
  unlockedEntries: infobox.unlockedEntries
});

const mapDispatchToProps = {
  setPage: appActions.setPage,
  setSeenEntries: infoboxActions.setSeenEntries
};

class InfoBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entryID: null
    };
  }

  render() {
    return (
      <div id="infoBox" className={styles.infoBox}>
        {this.renderList()}
        {this.renderEntry()}
      </div>
    );
  }

  componentDidMount() {
    const { setSeenEntries, unlockedEntries } = this.props;
    setSeenEntries(unlockedEntries.length);
  }

  componentDidUpdate() {
    const infoBox = document.getElementById('infoBox');
    infoBox.scrollTop = 0;
  }

  renderList = () => {
    const { entryID } = this.state;
    if (entryID === null) {
      return [
        <div key="list" className={styles.list}>
          <h1>Infobox</h1>

          {this.renderListItems()}
        </div>,
        <Button key="button" theme="back" onClick={this.changePage} />
      ];
    }
    return null;
  };

  renderListItems = () => {
    const { unlockedEntries } = this.props;
    if (unlockedEntries.length > 0) {
      return unlockedEntries.map(entry => {
        let categoryHeading = '';
        if (ENTRIES[entry].categoryHeading) {
          categoryHeading = (
            <div
              key={ENTRIES[entry].categoryHeading}
              className={`${styles.listItem} ${styles.heading}`}
            >
              <strong>
                {ENTRIES[entry].categoryHeading}
              </strong>
            </div>
          );
        }
        return [
          categoryHeading,
          <div
            role="button"
            tabIndex={0}
            key={entry}
            className={styles.listItem}
            onClick={() => {
              this.openEntry(entry);
            }}
          >
            {ENTRIES[entry].heading}
          </div>
        ];
      });
    }
    return (
      <span className={styles.message}>
        Du hast noch keine Einträge freigeschalten.
      </span>
    );
  };

  renderEntry = () => {
    const { entryID } = this.state;
    if (entryID !== null) {
      return [
        <div key="entry" className={styles.entry}>
          <h2>
            {ENTRIES[entryID].heading}
          </h2>
          {ENTRIES[entryID].content}
        </div>,
        <Button key="button" theme="back" onClick={this.closeEntry} />
      ];
    }
    return null;
  };

  openEntry = id => {
    this.setState({ entryID: id });
  };

  closeEntry = () => {
    this.setState({ entryID: null });
  };

  changePage = () => {
    const { setPage } = this.props;
    setPage('room');
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoBox);
