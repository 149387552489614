import React, { Component } from 'react';

import { Speaker } from '../../static/icons/Speaker';

import styles from './styles.less';

class Audio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPlaying: false
    };
  }

  render() {
    const { src } = this.props;
    return (
      <div className={styles.audioWrapper}>
        {this.renderPlayButton()}
        <audio
          ref={audio => {
            this.audio = audio;
          }}
          className={styles.audio}
          src={src}
          onPause={this.onPause}
          onEnded={this.onEnded}
        />
      </div>
    );
  }

  handleClick = () => {
    const { isPlaying } = this.state;

    if (isPlaying) {
      this.audio.pause();
      this.setState({ isPlaying: false });
    } else {
      const audioElements = document.getElementsByTagName('audio');

      for (let i = 0; i < audioElements.length; i++) {
        const element = audioElements[i];
        
        if (element !== this.audio) {
          element.pause();
          element.currentTime = 0;
        }
      }

      this.audio.play();
      this.setState({ isPlaying: true });
    }
  };

  onPause = () => {
    this.setState({ isPlaying: false });
  };

  onEnded = () => {
    this.setState({ isPlaying: false });
  };

  renderPlayButton = () => {   
    const { isPlaying } = this.state;
    
    return (
      <div
        className={`${styles.audioPlay} ${isPlaying ? styles.playing : ''}`}
        role="button"
        tabIndex={0}
        onClick={this.handleClick}
      >
        <Speaker size="100%" className="speaker" fill="#717171" />
      </div>
    );
  };
}

export default Audio;
