import { createReducer, createActionTypes } from '../lib/redux';

const defaultState = {
  sex: false,
  name: false
};

const types = createActionTypes(['SET_SEX', 'SET_NAME'], '');

const actions = {
  setSex: sex => ({ type: types.SET_SEX, sex }),
  setName: name => ({ type: types.SET_NAME, name })
};

const reducer = createReducer(defaultState, {
  [types.SET_SEX]: (state = defaultState, { sex }) => ({
    ...state,
    sex
  }),
  [types.SET_NAME]: (state = defaultState, { name }) => ({
    ...state,
    name
  })
});

export { actions, reducer };
