import React, { Component } from 'react';
import styles from '../../styles/slide.less';

class Slide extends Component {
  render() {
    const { heading, paragraph } = this.props;
    return (
      <div className={`${styles.slide} ${styles.welcome}`}>
        <h2>
          {heading}
        </h2>
        <p>
          {paragraph}
        </p>
        {this.renderComponent()}
      </div>
    );
  }

  renderComponent = () => {
    const { content } = this.props;
    if (content) {
      return content;
    }
    return null;
  };
}

export default Slide;
