import React from 'react';
import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise1', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.adviceExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['advice', 'adviceExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Wörter mit Dienst-',
      text: 'Wie viele Wörter kennen Sie schon mit Dienst-?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          type: 'CLOZE',
          title: 'Lesen Sie den Dialog.',
          text: `<strong>Schreiben Sie das Wort.</strong> <br><br>
                 Komm, wir gehen heute Abend noch ins Kino.<br>
                 Tut mir leid, ich möchte früh ins Bett gehen. Ich habe morgen {}. (FRENDISTÜH)<br><br>
                 `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'Frühdienst' }, id: 'a' }]
          ],
          feedback: (
            <span>
              Komm, wir gehen heute Abend noch ins Kino.<br />
              Tut mir leid, ich möchte früh ins Bett gehen. Ich habe morgen{' '}
              <strong>Frühdienst</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Lesen Sie den Dialog.',
          text: `<strong>Schreiben Sie das Wort.</strong> <br><br>
                 Um 10 Uhr haben wir {} (DINGREENBESTSPCHU).<br>
                 Dann kannst du mir nochmal in Ruhe erzählen, wo das Problem liegt.<br>
                 In Ordnung.
                 `,
          items: [
            [
              {
                type: 'INPUT',
                meta: { correct: 'Dienstbesprechung' },
                id: 'b'
              }
            ]
          ],
          feedback: (
            <span>
              Um 10 Uhr haben wir <strong>Dienstbesprechung</strong>.<br />
              Dann kannst du mir nochmal in Ruhe erzählen, wo das Problem liegt.
              <br />
              In Ordnung.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Lesen Sie den Dialog.',
          text: `<strong>Schreiben Sie das Wort.</strong> <br><br>
                 Frau Temme hat uns selbst gebackenen Kuchen mitgebracht. Wir treffen uns nachher im {}. (EDMERINZISTM)<br><br>
                `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'Dienstzimmer' }, id: 'a' }]
          ],
          feedback: (
            <span>
              Frau Temme hat uns selbst gebackenen Kuchen mitgebracht. Wir
              treffen uns nachher im <strong>Dienstzimmer</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Lesen Sie den Dialog.',
          text: `<strong>Schreiben Sie das Wort.</strong> <br><br>
          Ich könnte das ja nicht, ich wäre ständig müde, glaube ich. <br>
          Ach, daran gewöhnt man sich. Mit {} (CHNATENDIST) kann man jedenfalls ganz gut Geld verdienen.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'Nachtdienst' }, id: 'b' }]
          ],
          feedback: (
            <span>
              Ich könnte das ja nicht, ich wäre ständig müde, glaube ich. <br />
              Ach, daran gewöhnt man sich. Mit <strong>Nachtdienst</strong> kann
              man jedenfalls ganz gut Geld verdienen.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Lesen Sie den Dialog.',
          text: `<strong>Schreiben Sie das Wort.</strong> <br><br>
                 Immer, wenn ich {} (ÄTNSDSPIET) habe, kann ich morgens einkaufen gehen.<br>
                 Ja klar, aber ein bisschen schade ist es auch. Dann ist der halbe Tag schon weg.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'Spätdienst' }, id: 'a' }]
          ],
          feedback: (
            <span>
              Immer, wenn ich <strong>Spätdienst</strong> habe, kann ich morgens
              einkaufen gehen.<br />
              Ja klar, aber ein bisschen schade ist es auch. Dann ist der halbe
              Tag schon weg.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Lesen Sie den Dialog.',
          text: `<strong>Schreiben Sie das Wort.</strong> <br><br>
                Du bist ja immer noch da.<br>
                Ich warte auf meine Kollegin. Wir müssen ja noch die {} (EDINÜSTBEABRGE) machen.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'Dienstübergabe' }, id: 'b' }]
          ],
          feedback: (
            <span>
              Du bist ja immer noch da.<br />
              Ich warte auf meine Kollegin. Wir müssen ja noch die{' '}
              <strong>Dienstübergabe</strong> machen.
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
