import React from 'react';
import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise2', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.adviceExercise2 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['advice', 'adviceExercise2'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Wortbildung Adjektive',
      text: 'Adjektive auf -bar zeigen, was möglich ist.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: 'Beispiel: <br /> <i>Man kann die Krankheit heilen. Sie ist <strong>heilbar.</strong></i> <br><br>Man kann den Patienten mobilisieren. Er ist {}.',
          items: [
            [{ type: 'INPUT', meta: { correct: 'mobilisierbar' }, id: 'a' }]
          ],
          feedback: (
            <span>
              Man kann den Patienten mobilisieren. Er ist{' '}
              <strong>mobilisierbar</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: 'Beispiel: <br /> <i>Man kann die Krankheit heilen. Sie ist <strong>heilbar.</strong></i> <br><br>Man kann die Patientin ansprechen. Sie ist {}.',
          items: [
            [{ type: 'INPUT', meta: { correct: 'ansprechbar' }, id: 'a' }]
          ],
          feedback: (
            <span>
              Man kann die Patientin ansprechen. Sie ist{' '}
              <strong>ansprechbar</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: 'Beispiel: <br /> <i>Man kann die Krankheit heilen. Sie ist <strong>heilbar.</strong></i> <br><br>Man darf den Patienten nicht mit Problemen belasten. Er ist nicht {}.',
          items: [[{ type: 'INPUT', meta: { correct: 'belastbar' }, id: 'a' }]],
          feedback: (
            <span>
              Man darf den Patienten nicht mit Problemen belasten. Er ist nicht{' '}
              <strong>belastbar</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: 'Beispiel: <br /> <i>Man kann die Krankheit heilen. Sie ist <strong>heilbar.</strong></i> <br><br>Einen Dekubitus kann man behandeln. Er ist {}.',
          items: [
            [{ type: 'INPUT', meta: { correct: 'behandelbar' }, id: 'a' }]
          ],
          feedback: (
            <span>
              Einen Dekubitus kann man behandeln. Er ist{' '}
              <strong>behandelbar</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: 'Beispiel: <br /> <i>Man kann die Krankheit heilen. Sie ist <strong>heilbar.</strong></i> <br><br>Welche Ressourcen kann man erkennen? Welche Ressourcen sind {}?',
          items: [[{ type: 'INPUT', meta: { correct: 'erkennbar' }, id: 'a' }]],
          feedback: (
            <span>
              Welche Ressourcen kann man erkennen? Welche Ressourcen sind{' '}
              <strong>erkennbar</strong>?
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: 'Beispiel: <br /> <i>Man kann die Krankheit heilen. Sie ist <strong>heilbar.</strong></i> <br><br>Man kann die Hausärztin schlecht erreichen. Sie ist schlecht {}.',
          items: [
            [{ type: 'INPUT', meta: { correct: 'erreichbar' }, id: 'a' }]
          ],
          feedback: (
            <span>
              Man kann die Hausärztin schlecht erreichen. Sie ist schlecht{' '}
              <strong>erreichbar</strong>.
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
