import React, { Component } from 'react';

import { Button } from '../button';

import styles from './styles.less';

class InfoScreen extends Component {
  render() {
    const { subline, headline, text, duration, onClick } = this.props;
    return (
      <div className={styles.container}>
        <span className={styles.subline}>
          {subline}
        </span>
        <h1 className={styles.headline}>
          {headline}
        </h1>
        <p className={styles.text}>
          {text}
        </p>
        <span className={styles.duration}>
          {duration}
        </span>
        <Button theme="next invert" onClick={onClick} />
      </div>
    );
  }
}

export default InfoScreen;
