import React, { Component } from 'react';
import { MultipleChoice } from '@ovos-media/widget-multiple-choice';

const customThemes = {
  images: require('./themes/images.less'),
  multiple: require('./themes/multiple.less')
};

class CustomMultipleChoice extends Component {
  render() {
    const {
      answers,
      question,
      multiple,
      showResult,
      onChange,
      customTheme
    } = this.props;

    let styleFiles;

    if (customTheme) {
      styleFiles = cb =>
        cb(require('./styles.less'), customThemes[customTheme]);
    } else if (multiple) {
      styleFiles = cb => cb(require('./styles.less'), customThemes.multiple);
    } else {
      styleFiles = cb => cb(require('./styles.less'));
    }

    return (
      <MultipleChoice
        {...this.props}
        question={question}
        answers={answers}
        multiple={multiple}
        showResult={showResult}
        onChange={onChange}
        addStyleFiles={styleFiles}
      />
    );
  }
}

export default CustomMultipleChoice;
