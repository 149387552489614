import React, { Component } from 'react';
import ReactSwipe from 'react-swipe';

import { NavDots } from '../../../../components/navDots';
import { Button } from '../../../../components/button';
import Slide from './Slide';

import { slides } from './slides';

import styles from '../../styles/tutorial.less';

class Tutorial extends Component {
  constructor(props) {
    super(props);

    this.slides = [];
    this.lastSlide = slides.length - 1;
    this.lastIndex = 0;

    const startSlide = 0;

    this.swipeOptions = {
      continuous: false,
      transitionEnd: this.onSwiped,
      startSlide
    };

    this.state = {
      currentSlide: startSlide,
      nextSlide: startSlide + 1,
      prevSlide: startSlide - 1
    };
  }

  onSwiped = index => {
    if (this.lastIndex !== index) {
      this.setCurrentSlide(index);
      this.lastIndex = index;
    } else if (this.lastIndex === this.lastSlide) {
      const tutorialFinished = true;
      this.props.tutorialStatus(tutorialFinished);
    }
  };

  render() {
    const { difference } = this.props;
    const { currentSlide } = this.state;

    this.slides = slides.map(slide =>
      <Slide
        heading={slide.heading}
        paragraph={slide.paragraph}
        content={slide.content}
      />
    );

    return (
      <div className={styles.container}>
        <div
          className={styles.slider}
          style={{
            left: `calc(${difference / 2}px + 2.5%)`,
            right: `calc(${difference / 2}px + 2.5%)`
          }}
        >
          <ReactSwipe
            swipeOptions={this.swipeOptions}
            ref={s => {
              this.$swipe = s;
            }}
            className={styles.swipeContainer}
          >
            {this.slides.map((slide, index) =>
              <div key={index} className={styles.swipeableSlide}>
                {slide}
              </div>
            )}
          </ReactSwipe>
          <NavDots
            current={currentSlide}
            amount={this.slides.length}
            onChange={this.setCurrentSlide}
            className={styles.navDots}
            theme="small"
          />
        </div>
        {this.renderBackButton()}
        <Button
          onClick={this.onClickNext}
          theme="next"
          style={{
            right: `calc(${difference / 2}px + 2.5% - 20px)`
          }}
        />
      </div>
    );
  }

  renderBackButton = () => {
    const { difference } = this.props;
    const { currentSlide } = this.state;

    if (currentSlide > 0) {
      return <Button
        onClick={this.onClickPrev}
        theme="back"
        style={{
          left: `calc(${difference / 2}px + 2.5% - 20px)`
        }}
      />;
    }
    return null;
  };

  onClickNext = () => {
    const { currentSlide, nextSlide } = this.state;
    const { tutorialStatus } = this.props;
    if (currentSlide < this.slides.length - 1) {
      this.setCurrentSlide(nextSlide);
    } else {
      const tutorialFinished = true;
      tutorialStatus(tutorialFinished);
    }
  };

  onClickPrev = () => {
    const { currentSlide, prevSlide } = this.state;

    if (currentSlide > 0) {
      this.setCurrentSlide(prevSlide);
    }
  };

  setCurrentSlide = index => {
    this.setState({
      currentSlide: index,
      nextSlide: index + 1,
      prevSlide: index - 1
    });
    this.$swipe.swipe.slide(index);
  };
}

export default Tutorial;
