import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'dietaryAssistantExercise4', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.dietaryAssistantExercise4 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise([
        'dietaryAssistant',
        'dietaryAssistantExercise4'
      ])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Grammatik',
      headline: 'Immer eine gute Alternative ',
      text: 'Wie kann man es noch sagen? Üben Sie hier Formen mit sich lassen und -bar.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'grammar',
      questions: [
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Sätze um.',
          text: `
            Beispiel:<br>
            <i>Ich <strong>kann</strong> das Labor telefonisch nicht <strong>erreichen</strong>.</i><br>
            lassen + Infinitiv: <i>Das Labor <strong>lässt sich</strong> telefonisch nicht <strong>erreichen</strong>.</i><br>
            sein + Adjektiv auf -bar: <i>Das Labor <strong>ist</strong> telefonisch nicht <strong>erreichbar</strong>.</i><br><br>
            Wir können für Frau Werner einen Platz in der Tagespflege reservieren.<br><br>
            Ein Platz in der Tagespflege {} {} für Frau Werner {}.<br>
            Ein Platz in der Tagespflege ist für Frau Werner {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'lässt' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'sich' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'reservieren' }, id: 'c' }],
            [{ type: 'INPUT', meta: { correct: 'reservierbar' }, id: 'd' }]
          ],
          feedback: (
            <span>
              Ein Platz in der Tagespflege <strong>lässt</strong>{' '}
              <strong>sich</strong> für Frau Werner <strong>reservieren</strong>.<br />
              Ein Platz in der Tagespflege ist für Frau Werner{' '}
              <strong>reservierbar</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Sätze um.',
          text: `
            Beispiel:<br>
            <i>Ich <strong>kann</strong> das Labor telefonisch nicht <strong>erreichen</strong>.</i><br>
            lassen + Infinitiv: <i>Das Labor <strong>lässt sich</strong> telefonisch nicht <strong>erreichen</strong>.</i><br>
            sein + Adjektiv auf -bar: <i>Das Labor <strong>ist</strong> telefonisch nicht <strong>erreichbar</strong>.</i><br><br>
            Der Ernährungsplan kann an die Blutzuckerwerte angepasst werden.<br><br>
            Der Ernährungsplan {} {} an die Blutzuckerwerte {}.<br>
            Der Ernährungsplan {} an die Blutzuckerwerte {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'lässt' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'sich' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'anpassen' }, id: 'c' }],
            [{ type: 'INPUT', meta: { correct: 'ist' }, id: 'd' }],
            [{ type: 'INPUT', meta: { correct: 'anpassbar' }, id: 'e' }]
          ],
          feedback: (
            <span>
              Der Ernährungsplan <strong>lässt</strong> <strong>sich</strong> an
              die Blutzuckerwerte <strong>anpassen</strong>.<br />
              Der Ernährungsplan <strong>ist</strong> an die Blutzuckerwerte{' '}
              <strong>anpassbar</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Sätze um.',
          text: `
            Beispiel:<br>
            <i>Ich <strong>kann</strong> das Labor telefonisch nicht <strong>erreichen</strong>.</i><br>
            lassen + Infinitiv: <i>Das Labor <strong>lässt sich</strong> telefonisch nicht <strong>erreichen</strong>.</i><br>
            sein + Adjektiv auf -bar: <i>Das Labor <strong>ist</strong> telefonisch nicht <strong>erreichbar</strong>.</i><br><br>
            Den Pflegeverlauf kann man am Computer dokumentieren.<br><br>
            Der Pflegeverlauf {} {} am Computer {}.<br>
            Der Pflegeverlauf {} am Computer {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'lässt' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'sich' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'dokumentieren' }, id: 'c' }],
            [{ type: 'INPUT', meta: { correct: 'ist' }, id: 'd' }],
            [{ type: 'INPUT', meta: { correct: 'dokumentierbar' }, id: 'e' }]
          ],
          feedback: (
            <span>
              Der Pflegeverlauf <strong>lässt</strong> <strong>sich</strong> am
              Computer <strong>dokumentieren</strong>.<br />
              Der Pflegeverlauf <strong>ist</strong> am Computer{' '}
              <strong>dokumentierbar</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Sätze um.',
          text: `
            Beispiel:<br>
            <i>Ich <strong>kann</strong> das Labor telefonisch nicht <strong>erreichen</strong>.</i><br>
            lassen + Infinitiv: <i>Das Labor <strong>lässt sich</strong> telefonisch nicht <strong>erreichen</strong>.</i><br>
            sein + Adjektiv auf -bar: <i>Das Labor <strong>ist</strong> telefonisch nicht <strong>erreichbar</strong>.</i><br><br>
            Einen Rollator kann man schnell bestellen.<br><br>
            Ein Rollator {} {} schnell {}.<br>
            Ein Rollator {} schnell {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'lässt' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'sich' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'bestellen' }, id: 'c' }],
            [{ type: 'INPUT', meta: { correct: 'ist' }, id: 'd' }],
            [{ type: 'INPUT', meta: { correct: 'bestellbar' }, id: 'e' }]
          ],
          feedback: (
            <span>
              Ein Rollator <strong>lässt</strong> <strong>sich</strong> schnell{' '}
              <strong>bestellen</strong>.<br />
              Ein Rollator <strong>ist</strong> schnell{' '}
              <strong>bestellbar</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Sätze um.',
          text: `
            Beispiel:<br>
            <i>Ich <strong>kann</strong> das Labor telefonisch nicht <strong>erreichen</strong>.</i><br>
            lassen + Infinitiv: <i>Das Labor <strong>lässt sich</strong> telefonisch nicht <strong>erreichen</strong>.</i><br>
            sein + Adjektiv auf -bar: <i>Das Labor <strong>ist</strong> telefonisch nicht <strong>erreichbar</strong>.</i><br><br>
            Die Atmung kann man am Monitor überwachen.<br><br>
            Die Atmung {} {} am Monitor {}.<br>
            Die Atmung {} am Monitor {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'lässt' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'sich' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'überwachen' }, id: 'c' }],
            [{ type: 'INPUT', meta: { correct: 'ist' }, id: 'd' }],
            [{ type: 'INPUT', meta: { correct: 'überwachbar' }, id: 'e' }]
          ],
          feedback: (
            <span>
              Die Atmung <strong>lässt</strong> <strong>sich</strong> am Monitor{' '}
              <strong>überwachen</strong>.<br />
              Die Atmung <strong>ist</strong> am Monitor{' '}
              <strong>überwachbar</strong>.
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
