import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { actions as rewardSystemActions } from '../../reducers/rewardSystem';

import { InfoScreen } from '../infoScreen';
import { QuestionSet } from '../questionSet';
import styles from './styles.less';

const mapDispatchToProps = {
  resetCurrentPoints: rewardSystemActions.resetCurrentPoints
};

class Exercise extends Component {
  constructor(props) {
    super(props);
    const { flow } = props;
    this.state = {
      messages: [],
      currentIndex: parseInt(Object.keys(flow)[0], 10)
    };
  }

  render() {
    const { flow, resetCurrentPoints } = this.props;
    const { currentIndex } = this.state;

    const currentSnippet = get(flow, currentIndex);
    let component;

    const headline =
      typeof currentSnippet.headline === 'function'
        ? currentSnippet.headline()
        : currentSnippet.headline;

    switch (currentSnippet.type) {
      case 'info': {
        resetCurrentPoints();
        const onClick = () => {
          if (currentSnippet.goTo) {
            const nextIndex = currentSnippet.goTo
              .toString()
              .replace('.', '.flow.');
            this.setState({
              currentIndex: nextIndex
            });
          }
        };
        component = (
          <InfoScreen
            subline={currentSnippet.subline}
            headline={headline}
            text={currentSnippet.text}
            duration={currentSnippet.duration}
            onClick={onClick}
          />
        );
        break;
      }
      default:
        component = (
          <QuestionSet
            category={currentSnippet.category}
            questions={currentSnippet.questions}
          />
        );
    }
    return (
      <div className={styles.exercise}>
        {component}
      </div>
    );
  }
}

Exercise.propTypes = {
  flow: PropTypes.object.isRequired
};

Exercise.defaultProps = {
  flow: {}
};

export default connect(undefined, mapDispatchToProps)(Exercise);
