import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'activityExercise1', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'activityExercise2', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'activityExercise2'));
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.activityExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['activity', 'activityExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Gegenstände im Krankenzimmer',
      text:
        'Für Ihre tägliche Arbeit ist es wichtig, die Bezeichnungen zu kennen. Testen Sie Ihr Wissen.',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'das Krankenbett',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u9/krankenbett.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/lifter.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/nachttisch.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'der Lifter',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u9/nachttisch.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/bettdecke.jpg')
            },
            {
              id: 3,
              meta: { correct: 3 },
              type: 'image',
              src: require('../../../static/images/u9/lifter.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'der Nachttisch',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u9/knopf.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/kopfteil.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            },
            {
              id: 4,
              meta: { correct: 4 },
              type: 'image',
              src: require('../../../static/images/u9/nachttisch.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'die Schublade',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u9/schublade.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/lifter.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/patientenruf.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'der Patientenruf',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u9/krankenbett.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/bettkante.jpg')
            },
            {
              id: 3,
              meta: { correct: 3 },
              type: 'image',
              src: require('../../../static/images/u9/patientenruf.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/nachttisch.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'die Bettdecke',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u9/krankenbett.jpg')
            },
            {
              id: 2,
              meta: { correct: 2 },
              type: 'image',
              src: require('../../../static/images/u9/bettdecke.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/knopf.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'das Kopfteil',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u9/kopfteil.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/vorhang.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/nachttisch.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'die Bettkante',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/schublade.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/staender.jpg')
            },
            {
              id: 4,
              meta: { correct: 4 },
              type: 'image',
              src: require('../../../static/images/u9/bettkante.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'der Knopf',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u9/knopf.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/staender.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/nachttisch.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'der Ständer',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u9/lifter.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u9/schublade.jpg')
            },
            {
              id: 3,
              meta: { correct: 3 },
              type: 'image',
              src: require('../../../static/images/u9/staender.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/patientenruf.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'der Griff',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u9/krankenbett.jpg')
            },
            {
              id: 2,
              meta: { correct: 2 },
              type: 'image',
              src: require('../../../static/images/u9/griff.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/staender.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/nachttisch.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig? Wählen Sie aus.',
          question: 'der Vorhang',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u9/krankenbett.jpg')
            },
            {
              id: 2,
              meta: { correct: 2 },
              type: 'image',
              src: require('../../../static/images/u9/vorhang.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u9/staender.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u9/schublade.jpg')
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
