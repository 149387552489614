import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'exercises', 'handingOverExercise4', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'exercises', 'handingOverExercise5', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'handingOverExercise5'));
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.handingOverExercise4 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Tätigkeiten beschreiben',
      text:
        'Was machen die Ärzte oder das Pflegepersonal? Kennen Sie die richtigen Nomen-Verb-Verbindungen?',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Welches Verb passt zum Nomen? Wählen Sie aus.',
          question: 'die Lunge …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'machen'
            },
            {
              id: 2,
              data: 'verabreichen'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'abhören'
            },
            {
              id: 4,
              data: 'verordnen'
            }
          ]
        },
        {
          title: 'Welches Verb passt zum Nomen? Wählen Sie aus.',
          question: 'einen Wundabstrich …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'machen'
            },
            {
              id: 2,
              data: 'tasten'
            },
            {
              id: 3,
              data: 'messen'
            },
            {
              id: 4,
              data: 'abhören'
            }
          ]
        },
        {
          title: 'Welches Verb passt zum Nomen? Wählen Sie aus.',
          question: 'den Puls ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'machen'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'tasten'
            },
            {
              id: 3,
              data: 'verabreichen'
            },
            {
              id: 4,
              data: 'abhören'
            }
          ]
        },
        {
          title: 'Welches Verb passt zum Nomen? Wählen Sie aus.',
          question: 'den Blutdruck …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'messen'
            },
            {
              id: 2,
              data: 'verabreichen'
            },
            {
              id: 3,
              data: 'verordnen'
            },
            {
              id: 4,
              data: 'abhören'
            }
          ]
        },
        {
          title: 'Welches Verb passt zum Nomen? Wählen Sie aus.',
          question: 'ein Medikament …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'tasten'
            },
            {
              id: 2,
              data: 'messen'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'verabreichen'
            },
            {
              id: 4,
              data: 'abhören'
            }
          ]
        },
        {
          title: 'Welches Verb passt zum Nomen? Wählen Sie aus.',
          question: 'Krankengymnastik …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'tasten'
            },
            {
              id: 2,
              data: 'messen'
            },
            {
              id: 3,
              data: 'verabreichen'
            },
            {
              id: 4,
              meta: { correct: 4 },
              data: 'verordnen'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
