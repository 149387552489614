import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'exercises', 'doctorExercise3', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.doctorExercise3 === false) {
    store.dispatch(rewardSystemActions.increment('step3', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['doctor', 'doctorExercise3'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Pflegemaßnahmen dokumentieren',
      text:
        'Wie notieren Sie kurz und knapp, was Sie gemacht haben? In dieser Übung geht es um feste Wort-Verbindungen beim Dokumentieren.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Was passt?',
          question: 'Bett ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'bezogen'
            },
            {
              id: 2,
              data: 'hingewiesen'
            },
            {
              id: 3,
              data: 'verabreicht'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Blutdruck und Fieber ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'dokumentiert'
            },
            {
              id: 2,
              data: 'geröntgt'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'gemessen'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'rechtes Bein auf Kissen ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'durchgeführt'
            },
            {
              id: 2,
              data: 'bezogen'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'gelagert'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'auf Thromboserisiko ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'ausgefüllt'
            },
            {
              id: 2,
              data: 'gereinigt'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'hingewiesen'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Vitalzeichenkontrolle ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'durchgeführt'
            },
            {
              id: 2,
              data: 'gemessen'
            },
            {
              id: 3,
              data: 'verabreicht'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Antibiotikum ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'gemessen'
            },
            {
              id: 2,
              data: 'gereinigt'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'verabreicht'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Wunde ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'gereinigt'
            },
            {
              id: 2,
              data: 'gelagert'
            },
            {
              id: 3,
              data: 'ausgefüllt'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Wundstatus ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'hingewiesen'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'dokumentiert'
            },
            {
              id: 3,
              data: 'bezogen'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Sturzprotokoll ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'ausgefüllt'
            },
            {
              id: 2,
              data: 'verabreicht'
            },
            {
              id: 3,
              data: 'gemessen'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Schulter ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'bezogen'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'geröntgt'
            },
            {
              id: 3,
              data: 'dokumentiert'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
