import React from 'react';
import store from '../../../store';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';
import { actions as stepsActions } from '../../../reducers/steps';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'exercises', 'doctorExercise2', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.doctorExercise2 === false) {
    store.dispatch(rewardSystemActions.increment('step3', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['doctor', 'doctorExercise2'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Patientenaussagen im Pflegebericht',
      text:
        'Wie übertragen Sie das, was gesagt wurde, in die schriftliche Dokumentation? In dieser Übung trainieren Sie die Möglichkeit mit Infinitiv + zu. ',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: `Beispiel:<br>
                 <i>Herr T.: „Ich will nicht aufstehen.“<br>
                 Herr T. gab an, nicht <strong>aufstehen zu wollen</strong>.</i><br><br>
                 Frau S.: „Ich will meinen Mann nicht sehen.“<br>
                 Frau S. äußerte, ihren Mann nicht {} {} {}.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'sehen' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'zu' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'wollen' }, id: 'c' }]
          ],
          feedback: (
            <span>
              Frau S. äußerte, ihren Mann nicht <strong>sehen zu wollen</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: `Beispiel:<br>
                 <i>Herr T.: „Ich will nicht aufstehen.“<br>
                 Herr T. gab an, nicht <strong>aufstehen zu wollen</strong>.</i><br><br>
                 Herr. B.: „Ich brauche keine Medikamente.<br>
                 Herr B. gab an, keine Medikamente {} {}.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'zu' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'brauchen' }, id: 'b' }]
          ],
          feedback: (
            <span>
              Herr B. gab an, keine Medikamente <strong>zu brauchen</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: `Beispiel:<br>
                 <i>Herr T.: „Ich will nicht aufstehen.“<br>
                 Herr T. gab an, nicht <strong>aufstehen zu wollen</strong>.</i><br><br>
                 Frau F.: „Ich kann nicht allein auf die Toilette gehen.“<br>
                 Frau F. äußerte, nicht allein auf die Toilette {} {} {}.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'gehen' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'zu' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'können' }, id: 'c' }]
          ],
          feedback: (
            <span>
              Frau F. äußerte, nicht allein auf die Toilette{' '}
              <strong>gehen zu können</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie.',
          text: `Beispiel:<br>
                 <i>Herr T.: „Ich will nicht aufstehen.“<br>
                 Herr T. gab an, nicht <strong>aufstehen zu wollen</strong>.</i><br><br>
                 Herr P: „Ich vermisse meine Frau.“<br>
                 Herr P. gab an, seine Frau {} {}.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'zu' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'vermissen' }, id: 'b' }]
          ],
          feedback: (
            <span>
              Herr P. gab an, seine Frau
              <strong> zu vermissen</strong>.
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
