import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'exercises', 'recordsExercise1', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.recordsExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step3', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['records', 'recordsExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Pflegemaßnahmen beschreiben',
      text:
        'Welche Verben passen zu den Nomen und welche Kombinationen machen keinen Sinn?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Ein Verb passt nicht. Wählen Sie aus!',
          question: 'die Pflegemaßnahmen …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'besprechen'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'mobilisieren'
            },
            {
              id: 3,
              data: 'dokumentieren'
            },
            {
              id: 4,
              data: 'anpassen'
            }
          ]
        },
        {
          title: 'Ein Verb passt nicht. Wählen Sie aus!',
          question: 'den Wundstatus …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'beurteilen'
            },
            {
              id: 2,
              data: 'dokumentieren'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'alarmieren'
            },
            {
              id: 4,
              data: 'notieren'
            }
          ]
        },
        {
          title: 'Ein Verb passt nicht. Wählen Sie aus!',
          question: 'die Ernährungsberatung ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'machen'
            },
            {
              id: 2,
              data: 'durchführen'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'entscheiden'
            },
            {
              id: 4,
              data: 'veranlassen'
            }
          ]
        },
        {
          title: 'Ein Verb passt nicht. Wählen Sie aus!',
          question: 'ein Antibiotikum …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'abklären'
            },
            {
              id: 2,
              data: 'ansetzen'
            },
            {
              id: 3,
              data: 'verabreichen'
            },
            {
              id: 4,
              data: 'absetzen'
            }
          ]
        },
        {
          title: 'Ein Verb passt nicht. Wählen Sie aus!',
          question: 'die Wundmanagerin …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'benachrichtigen'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'durchführen'
            },
            {
              id: 3,
              data: 'anrufen'
            },
            {
              id: 4,
              data: 'informieren'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
