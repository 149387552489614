import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise3', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise4', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'adviceExercise4'));

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.adviceExercise3 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Hilfsmittel',
      text: 'Für Ihre tägliche Arbeit ist es wichtig, die Bezeichnungen zu kennen. Testen Sie Ihr Wissen.',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'threeChained'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Was ist richtig?',
          question: 'die Brille',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u21/beinprothese.jpg')
            },
            {
              id: 2,
              meta: { correct: 2 },
              type: 'image',
              src: require('../../../static/images/u21/brille.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u21/gehstock.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u21/kontaktlinse.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'der Rollator',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u21/rollator.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u21/gehstock.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u21/hoergeraet.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u21/kontaktlinse.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'die Kontaktlinsen',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u21/beinprothese.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u21/toilettenstuhl.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u21/rollstuhl.jpg')
            },
            {
              id: 4,
              meta: { correct: 4 },
              type: 'image',
              src: require('../../../static/images/u21/kontaktlinse.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'die Beinprothese',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u21/beinprothese.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u21/gehhilfe.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u21/gehstock.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u21/rollstuhl.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'das Hörgerät',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u21/gehhilfe.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u21/brille.jpg')
            },
            {
              id: 3,
              meta: { correct: 3 },
              type: 'image',
              src: require('../../../static/images/u21/hoergeraet.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u21/lupe.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'die Gehhilfe',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u21/beinprothese.jpg')
            },
            {
              id: 2,
              meta: { correct: 2 },
              type: 'image',
              src: require('../../../static/images/u21/gehhilfe.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u21/gehstock.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u21/rollstuhl.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'die Lupe',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u21/brille.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u21/hoergeraet.jpg')
            },
            {
              id: 3,
              meta: { correct: 3 },
              type: 'image',
              src: require('../../../static/images/u21/lupe.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u21/kontaktlinse.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'der Rollstuhl',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u21/beinprothese.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u21/gehhilfe.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u21/rollator.jpg')
            },
            {
              id: 4,
              meta: { correct: 4 },
              type: 'image',
              src: require('../../../static/images/u21/rollstuhl.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'der Gehstock',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              type: 'image',
              src: require('../../../static/images/u21/beinprothese.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u21/brille.jpg')
            },
            {
              id: 3,
              meta: { correct: 3 },
              type: 'image',
              src: require('../../../static/images/u21/gehstock.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u21/rollator.jpg')
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'der Toilettenstuhl',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          customTheme: 'images',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              type: 'image',
              src: require('../../../static/images/u21/toilettenstuhl.jpg')
            },
            {
              id: 2,
              type: 'image',
              src: require('../../../static/images/u21/hoergeraet.jpg')
            },
            {
              id: 3,
              type: 'image',
              src: require('../../../static/images/u21/lupe.jpg')
            },
            {
              id: 4,
              type: 'image',
              src: require('../../../static/images/u21/brille.jpg')
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
