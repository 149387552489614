import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'exercises', 'recordsExercise2', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'exercises', 'recordsExercise3', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'recordsExercise3'));
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.recordsExercise2 === false) {
    store.dispatch(rewardSystemActions.increment('step3', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['records', 'recordsExercise2'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Grammatik',
      headline: 'Patientenzustand beschreiben',
      text:
        'Wie kann man es anders sagen? Lesen Sie Beispiele für das Partizip I.',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'grammar',
      questions: [
        {
          title: 'Was passt?',
          question:
            'Der Zustand des Patienten ist so schlecht, dass man den Arzt alarmieren müsste. Sein Zustand ist ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'besorgniserregend'
            },
            {
              id: 2,
              data: 'zufriedenstellend'
            },
            {
              id: 3,
              data: 'ausreichend'
            },
            {
              id: 4,
              meta: { correct: 4 },
              data: 'alarmierend'
            }
          ]
        },
        {
          title: 'Was passt?',
          question:
            'Der Zustand der Patientin ist so schlecht, dass man sich Sorgen machen muss. Ihr Zustand ist ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'zufriedenstellend'
            },
            {
              id: 2,
              data: 'ablehnend'
            },
            {
              id: 3,
              data: 'alarmierend'
            },
            {
              id: 4,
              meta: { correct: 4 },
              data: 'besorgniserregend'
            }
          ]
        },
        {
          title: 'Was passt?',
          question:
            'Der Zustand des Patienten ist in Ordnung. Man kann zufrieden sein. Sein Zustand ist ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'zufriedenstellend'
            },
            {
              id: 2,
              data: 'ablehnend'
            },
            {
              id: 3,

              data: 'ausreichend'
            },
            {
              id: 4,
              data: 'besorgniserregend'
            }
          ]
        },
        {
          title: 'Was passt?',
          question:
            'Was der Patient gegessen hat, reicht nicht aus. Die Nahrungsaufnahme ist nicht ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'zufriedenstellend'
            },
            {
              id: 2,
              data: 'besorgniserregend'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'ausreichend'
            },
            {
              id: 4,
              data: 'ablehnend'
            }
          ]
        },
        {
          title: 'Was passt?',
          question:
            'Der Patient möchte sich nicht bewegen. Er reagiert ... auf den Vorschlag, ein paar Schritte zu gehen.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'zufriedenstellend'
            },
            {
              id: 2,
              data: 'besorgniserregend'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'ablehnend'
            },
            {
              id: 4,
              data: 'alarmierend'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
