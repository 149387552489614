import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as infoboxActions } from '../../../reducers/infobox';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'greeting', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'greeting', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'food', 'overlay'],
      true
    )
  );
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step1', 'choices', 'greetingFeedback'], 'init')
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question:
        'Wie kann ich den ersten Kontakt gestalten, sodass Herr Teller Vertrauen zu mir fasst?',
      answers: () => {
        const greetingFeedback = store.getState().steps.step1.choices
          .greetingFeedback;
        return [
          {
            id: 'a',
            goTo: '0-1-1',
            data: 'den Nachttisch aufräumen',
            onClick: () => {
              if (greetingFeedback === 'init') {
                store.dispatch(
                  stepsActions.set(
                    ['step1', 'choices', 'greetingFeedback'],
                    false
                  )
                );
              }
            },
            renderQuestion: false
          },
          {
            id: 'b',
            goTo: '0-2-1',
            data: 'nach dem Befinden fragen',
            onClick: () => {
              if (greetingFeedback === 'init') {
                store.dispatch(
                  stepsActions.set(
                    ['step1', 'choices', 'greetingFeedback'],
                    false
                  )
                );
              }
            },
            renderQuestion: false
          },
          {
            id: 'c',
            goTo: '1-2',
            data: 'sich vorstellen',
            onClick: () => {
              if (greetingFeedback === 'init') {
                store.dispatch(
                  stepsActions.set(
                    ['step1', 'choices', 'greetingFeedback'],
                    true
                  )
                );
              }
            },
            renderQuestion: false
          }
        ];
      }
    },
    '0-1-1': {
      from: 'ai',
      text: 'Was machen Sie denn da?',
      audio: '006-Wechseln (ohne Informieren)_ID_1.mp3',
      nextSnippet: '0-1-2'
    },
    '0-1-2': {
      from: 'ai',
      text: 'Wer sind Sie überhaupt?',
      audio: '011-Aufraeumen_oV_ID_2.mp3',
      nextSnippet: '0-1-3'
    },
    '0-1-3': {
      from: 'player',
      text: 'Oh, ich sollte mich erst einmal vorstellen.',
      audio: '011-Aufraeumen_oV_ID_3.mp3',
      type: 'thought',
      nextSnippet: '1-1'
    },

    '0-2-1': {
      from: 'player',
      text: 'Guten Morgen. Wie geht es Ihnen?',
      audio: '012-Erkundigen_oV_ID_1.mp3',
      nextSnippet: '0-2-2'
    },
    '0-2-2': {
      from: 'ai',
      text: 'Sie haben sich mir noch gar nicht vorgestellt.',
      audio: '012-Erkundigen_oV_ID_2.mp3',
      nextSnippet: '0-1-3'
    },
    '1-1': {
      type: 'choice',
      question: 'Wählen Sie eine der folgenden Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          audio: '012-Erkundigen_oV_ID_5.mp3',
          data: `Das tut mir leid, mein Name ist ${store.getState().user.name}.`
        },
        {
          id: 'b',
          goTo: '1-2-1',
          audio: '012-Erkundigen_oV_ID_6.mp3',
          onClick: () => {
            const greetingFeedback = store.getState().steps.step1.choices
              .greetingFeedback;
            if (greetingFeedback === true) {
              store.dispatch(
                stepsActions.set(
                  ['step1', 'choices', 'greetingFeedback'],
                  false
                )
              );
            }
          },
          data: `${store.getState().user.name}, mein Name. Und wie heißen Sie?`
        },
        {
          id: 'c',
          goTo: '1-3-1',
          audio: '012-Erkundigen_oV_ID_4.mp3',
          data: `Oh, bitte entschuldigen Sie. Ich bin erst seit zwei Wochen auf dieser Station. Mein Name ist ${store.getState()
            .user.name}`
        }
      ]
    },
    '1-2': {
      type: 'choice',
      question: 'Wählen Sie eine der folgenden Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          audio: '010-Begruessen_ID_2.mp3',
          data: `Guten Morgen, mein Name ist ${store.getState().user.name}.`
        },
        {
          id: 'b',
          goTo: '1-2-1',
          audio: '010-Begruessen_ID_3.mp3',
          onClick: () => {
            const greetingFeedback = store.getState().steps.step1.choices
              .greetingFeedback;
            if (greetingFeedback === true) {
              store.dispatch(
                stepsActions.set(
                  ['step1', 'choices', 'greetingFeedback'],
                  false
                )
              );
            }
          },
          data: `${store.getState().user.name}, mein Name. Und wie heißen Sie?`
        },
        {
          id: 'c',
          goTo: '1-3-1',
          audio: '010-Begruessen_ID_1.mp3',
          data: `Guten Morgen Herr Teller, wir kennen uns noch gar nicht. Ich bin erst seit zwei Wochen auf dieser Station. Mein Name ist ${store.getState()
            .user.name}.`
        }
      ]
    },
    '1-1-1': {
      from: 'ai',
      text: 'Morgen, Teller, aber das wissen Sie sicher schon.',
      audio: '010-Begruessen_ID_9.mp3',
      nextSnippet: '1-1-2'
    },
    '1-1-2': {
      from: 'player',
      text: () =>
        <span>
          Ja, Herr Teller, ich habe Ihre Akte gelesen. Ich bin heute in der{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('frühschicht'));
            }}
          >
            Frühschicht
          </span>{' '}
          für Sie{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('zuständig'));
            }}
          >
            zuständig
          </span>.
        </span>,
      audio: '010-Begruessen_ID_10.mp3',
      nextSnippet: '1-1-3'
    },
    '1-1-3': {
      from: 'ai',
      text: 'Aha, ja, dann kann es ja losgehen.',
      audio: '010-Begruessen_ID_7.mp3',
      nextSnippet: 2
    },
    '1-2-1': {
      from: 'ai',
      text: () =>
        <span>
          Das steht doch alles in meiner{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('akte'));
            }}
          >
            Akte
          </span>
          .
        </span>,
      audio: '010-Begruessen_ID_13.mp3',
      nextSnippet: '1-2-2'
    },
    '1-2-2': {
      from: 'player',
      text:
        'Da hat er recht. Vielleicht hätte ich ihn direkt mit seinem Namen ansprechen sollen!',
      audio: '011-Aufraeumen_oV_ID_17.mp3',
      type: 'thought',
      nextSnippet: '1-2-3'
    },
    '1-2-3': {
      from: 'player',
      text: () =>
        <span>
          Verzeihen Sie, natürlich habe ich Ihre Akte gelesen, aber ich wollte
          mich gerne persönlich bekannt machen. Ich bin heute in der{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('frühschicht'));
            }}
          >
            Frühschicht
          </span>{' '}
          für Sie{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('zuständig'));
            }}
          >
            zuständig
          </span>.
        </span>,
      audio: '011-Aufraeumen_oV_ID_18.mp3',
      nextSnippet: '1-2-4'
    },
    '1-2-4': {
      from: 'ai',
      text: `Ach so. Na dann, willkommen, ${store.getState().user.name}!`,
      audio: '010-Begruessen_ID_16.mp3',
      nextSnippet: '1-2-5'
    },
    '1-2-5': {
      from: 'ai',
      text: 'Dann kann es ja losgehen!',
      audio: '010-Begruessen_ID_17.mp3',
      nextSnippet: 2
    },
    '1-3-1': {
      from: 'ai',
      text: `Na dann, willkommen, ${store.getState().user.name}!`,
      audio: '010-Begruessen_ID_5.mp3',
      nextSnippet: '1-3-2'
    },
    '1-3-2': {
      from: 'player',
      text: () =>
        <span>
          Vielen Dank. Ich bin heute in der{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('frühschicht'));
            }}
          >
            Frühschicht
          </span>{' '}
          für Sie{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('zuständig'));
            }}
          >
            zuständig
          </span>.
        </span>,
      audio: '010-Begruessen_ID_6.mp3',
      nextSnippet: '1-2-5'
    },
    2: {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: 3,
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },
    3: {
      type: 'choice',
      question: 'Wählen Sie eine der folgenden Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '3-1-1',
          data: 'den Nachttisch aufräumen',
          renderQuestion: false
        },
        {
          id: 'b',
          goTo: 5,
          data: 'nach dem Befinden fragen',
          renderQuestion: false
        }
      ]
    },
    '3-1-1': {
      from: 'ai',
      text: 'Was machen Sie denn da?',
      audio: '006-Wechseln (ohne Informieren)_ID_1.mp3',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'player',
      text: 'Keine Sorge, ich wollte nur ein bisschen Platz schaffen.',
      audio: '014-Aufraeumen_mV_ID_2.mp3',
      nextSnippet: 4
    },
    4: {
      type: 'choice',
      question: 'Wählen Sie eine der folgenden Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '4-1-1',
          audio: '014-Aufraeumen_mV_ID_4.mp3',
          data: 'Wegen der Ordnung und aus Respekt vor Ihrem Zimmernachbarn.'
        },
        {
          id: 'b',
          goTo: '5',
          audio: '014-Aufraeumen_mV_ID_3.mp3',
          data: () =>
            <span>
              Aus{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('hygienisch'));
                }}
              >
                hygienischen
              </span>{' '}
              Gründen und wegen Ihrer Sicherheit.
            </span>
        }
      ]
    },
    '4-1-1': {
      from: 'player',
      text: () =>
        <span>
          Ach Quatsch. Hier geht es um die Patientensicherheit und{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('hygiene'));
            }}
          >
            Hygiene
          </span>
          .
        </span>,
      audio: '014-Aufraeumen_mV_ID_7.mp3',
      type: 'thought',
      nextSnippet: '4-1-2'
    },
    '4-1-2': {
      from: 'player',
      text: 'Erzählen Sie mir doch, wie es Ihnen geht! Wie fühlen Sie sich heute?',
      audio: '0013-Erkundigen_mV_ID_1.mp3',
      nextSnippet: '5-1-1'
    },
    5: {
      type: 'choice',
      question: 'Wählen Sie eine der folgenden Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '5-1-1',
          audio: '0013-Erkundigen_mV_ID_1.mp3',
          data:
            'Erzählen Sie mir doch, wie es Ihnen geht! Wie fühlen Sie sich heute?'
        },
        {
          id: 'b',
          goTo: '5-2-1',
          audio: '0013-Erkundigen_mV_ID_2.mp3',
          data: 'Na, wie geht\'s uns heute?'
        }
      ]
    },
    '5-1-1': {
      from: 'ai',
      text: 'Ehrlich gesagt nicht so besonders. Dieses Herumliegen geht mir schrecklich auf die Nerven.',
      audio: '0013-Erkundigen_mV_ID_4.mp3',
      nextSnippet: '5-1-2'
    },
    '5-1-2': {
      from: 'player',
      text: 'Das kann ich sehr gut verstehen. Sie sind wirklich schon sehr geduldig gewesen.',
      audio: '0013-Erkundigen_mV_ID_5.mp3',
      nextSnippet: '5-1-3'
    },
    '5-1-3': {
      from: 'ai',
      text: () =>
        <span>
          Ja, das finde ich auch. Aber meine Wunde{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('spannt'));
            }}
          >
            spannt
          </span>{' '}
          so komisch.
        </span>,
      audio: '0013-Erkundigen_mV_ID_6.mp3',
      nextSnippet: '5-1-4'
    },
    '5-1-4': {
      from: 'player',
      text: 'Die sehe ich mir jetzt gleich an.',
      audio: '0013-Erkundigen_mV_ID_7.mp3',
      nextSnippet: 'fin',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['kritik', 'aktivesZuhören']));
        }
      }
    },
    '5-2-1': {
      from: 'ai',
      text: 'Wie es Ihnen geht, weiß ich nicht. Mir nicht besonders gut. Ich liege hier schon so lange und möchte nach Hause.',
      audio: '0013-Erkundigen_mV_ID_9.mp3',
      nextSnippet: '5-1-2'
    },
    fin: {
      type: 'choice',
      answers: () => {
        const greetingFeedback = store.getState().steps.step1.choices
          .greetingFeedback;
        const feedback = greetingFeedback === true ? '6-1' : '6-2';

        return [
          {
            id: 'a',
            goTo: feedback,
            onClick: () => {
              resetChoices();
            },
            data: 'Weiter',
            renderQuestion: false
          }
        ];
      }
    },
    '6-1': {
      type: 'feedback',
      text: 'Wunderbar! Sie haben Torben Teller freundlich begrüßt.',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['kritik', 'aktivesZuhören'],
      correct: true
    },
    '6-2': {
      type: 'feedback',
      text: 'Sie haben heute sicher viel zu tun! Zeit für eine freundliche Begrüßung muss sein. Den Patienten mit Namen anzusprechen schafft Vertrauen.',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['kritik', 'aktivesZuhören'],
      correct: false
    }
  }
};
