import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as stepsActions } from '../../reducers/steps';

import { Pronunciation } from '../../static/icons/Pronunciation';
import { Grammar } from '../../static/icons/Grammar';
import { Vocabulary } from '../../static/icons/Vocabulary';
import { Communication } from '../../static/icons/Communication';

import EXERCISES from '../../config/exercises';

import styles from './styles.less';

const mapStateToProps = ({ steps, exerciseSet }) => ({
  currentStep: steps.currentStep,
  currentRoom: steps.currentRoom,
  currentExerciseSet: exerciseSet.currentExerciseSet,
  exerciseSet
});

const mapDispatchToProps = {
  setExercise: stepsActions.setExercise,
  set: stepsActions.set
};

class ExerciseOverview extends Component {
  render() {
    const {
      currentStep,
      currentRoom,
      currentExerciseSet,
      exerciseSet
    } = this.props;
    const exercisesData = EXERCISES[currentStep][currentRoom];
    const exercises = exerciseSet[currentExerciseSet];

    return Object.keys(exercises).map(key => {
      const exerciseDescription = exercisesData[key].flow[0];
      const category = exercisesData[key].flow[1].category;

      const headline =
        typeof exerciseDescription.headline !== 'function'
          ? exerciseDescription.headline
          : exerciseDescription.headline();

      const exerciseFinished = exerciseSet[currentExerciseSet][key]
        ? styles.finished
        : '';

      const chainedStyle = {
        oneChained: { style: styles.oneChained, amount: 1 } ,
        twoChained: { style: styles.twoChained, amount: 2 },
        threeChained: { style: styles.threeChained, amount: 3 }
      };

      const chainedExercises = exerciseDescription.chainedExercises
        ? chainedStyle[exerciseDescription.chainedExercises]
        : '';

      return (
        <span
          key={key}
          role="button"
          tabIndex={0}
          className={`${styles.exerciseTrigger} ${chainedExercises.style} ${exerciseFinished} `}
          onClick={() => {
            this.setExercise(key);
          }}
        >
          <span className={styles.exerciseIcon}>
            {chainedExercises.amount >= 1 ? <span className={styles.coin1} /> : null}
            {chainedExercises.amount >= 2 ? <span className={styles.coin2} /> : null}
            {chainedExercises.amount >= 3 ? <span className={styles.coin3} /> : null}
            {this.renderIcon(category)}
          </span>
          {headline}
        </span>
      );
    });
  }

  setExercise = exercise => {
    const { setExercise, set, currentStep, currentRoom } = this.props;
    setExercise(exercise);
    set([currentStep, currentRoom, 'exercises', exercise, 'overlay'], true);
  };

  renderIcon = category => {
    switch (category) {
      case 'pronunciation':
        return <Pronunciation size="35px" />;
      case 'grammar':
        return <Grammar size="35px" />;
      case 'vocabulary':
        return <Vocabulary size="35px" />;
      default:
        return <Communication size="35px" />;
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseOverview);
