export default {
  step1: {
    hospitalRoom: {
      calm: require('./hospitalRoom/calm').default,
      checkWoundExercise1: require('./hospitalRoom/checkWoundExercise1')
        .default,
      checkWoundExercise2: require('./hospitalRoom/checkWoundExercise2')
        .default,
      checkWoundExercise3: require('./hospitalRoom/checkWoundExercise3')
        .default,
      empathicConvincing: require('./hospitalRoom/empathicConvincing').default,
      disinfectionExercise1: require('./hospitalRoom/disinfectionExercise1')
        .default,
      disinfectionExercise2: require('./hospitalRoom/disinfectionExercise2')
        .default,
      woundAndCare: require('./hospitalRoom/woundAndCare').default,
      headboard: require('./hospitalRoom/headboard').default,
      trayExercise1: require('./hospitalRoom/trayExercise1').default,
      trayExercise2: require('./hospitalRoom/trayExercise2').default,
      trayExercise3: require('./hospitalRoom/trayExercise3').default,
      activityExercise1: require('./hospitalRoom/activityExercise1').default,
      activityExercise2: require('./hospitalRoom/activityExercise2').default,
      activityExercise3: require('./hospitalRoom/activityExercise3').default
    }
  },
  step2: {
    corridor: {
      mrsTellerExercise1: require('./corridor/mrsTellerExercise1.js').default,
      mrsTellerExercise2: require('./corridor/mrsTellerExercise2.js').default
    },
    office: {
      coffeemaschineExercise1: require('./office/coffeemaschineExercise1')
        .default,
      coffeemaschineExercise2: require('./office/coffeemaschineExercise2')
        .default,
      coffeemaschineExercise3: require('./office/coffeemaschineExercise3')
        .default,
      coffeemaschineExercise4: require('./office/coffeemaschineExercise4')
        .default,
      coffeemaschineExercise5: require('./office/coffeemaschineExercise5')
        .default,
      coffeemaschineExercise6: require('./office/coffeemaschineExercise6')
        .default,
      dietaryAssistantExercise1: require('./office/dietaryAssistantExercise1')
        .default,
      dietaryAssistantExercise2: require('./office/dietaryAssistantExercise2')
        .default,
      dietaryAssistantExercise3: require('./office/dietaryAssistantExercise3')
        .default,
      dietaryAssistantExercise4: require('./office/dietaryAssistantExercise4')
        .default,
      dietaryAssistantExercise5: require('./office/dietaryAssistantExercise5')
        .default,
      adviceExercise1: require('./office/adviceExercise1').default,
      adviceExercise2: require('./office/adviceExercise2').default,
      adviceExercise3: require('./office/adviceExercise3').default,
      adviceExercise4: require('./office/adviceExercise4').default,
      adviceExercise5: require('./office/adviceExercise5').default,
      adviceExercise6: require('./office/adviceExercise6').default,
      email: require('./office/email').default
    }
  },
  step3: {
    office: {
      doctorExercise1: require('./office/doctorExercise1').default,
      doctorExercise2: require('./office/doctorExercise2').default,
      doctorExercise3: require('./office/doctorExercise3').default,
      recordsExercise1: require('./office/recordsExercise1').default,
      recordsExercise2: require('./office/recordsExercise2').default,
      recordsExercise3: require('./office/recordsExercise3').default,
      recordsExercise4: require('./office/recordsExercise4').default
    }
  },
  step4: {
    corridor: {
      knockingExercise1: require('./corridor/knockingExercise1').default,
      knockingExercise2: require('./corridor/knockingExercise2').default
    },
    hospitalRoom: {
      accidentExercise1: require('./hospitalRoom/accidentExercise1').default,
      accidentExercise2: require('./hospitalRoom/accidentExercise2').default,
      accidentExercise3: require('./hospitalRoom/accidentExercise3').default
    },
    office: {
      handingOverExercise1: require('./office/handingOverExercise1').default,
      handingOverExercise2: require('./office/handingOverExercise2').default,
      handingOverExercise3: require('./office/handingOverExercise3').default,
      handingOverExercise4: require('./office/handingOverExercise4').default,
      handingOverExercise5: require('./office/handingOverExercise5').default
    }
  }
};
