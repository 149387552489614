import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { CustomMultipleChoice } from '../../../components/customMultipleChoice';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'dietaryAssistantExercise5', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.dietaryAssistantExercise5 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise([
        'dietaryAssistant',
        'dietaryAssistantExercise5'
      ])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Nachnamen verstehen',
      text: 'Ein Kollege spricht über Patienten. Wie schreibt man die Nachnamen?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <CustomMultipleChoice
              key="1"
              answers={[
                {
                  id: 1,
                  meta: { correct: 1 },
                  data: [
                    {
                      type: 'audio',
                      src: require('../../../static/audio/u25/u25_1.mp3')
                    },
                    'Hören Sie den Namen.'
                  ]
                }
              ]}
            />
          ),
          question:
            'Welche Schreibweisen sind richtig? Es gibt mehrere Lösungen. Wählen Sie aus.',
          correct: 'Gute Antwort, weiter so!',
          partiallyCorrect: 'Das stimmt noch nicht ganz.',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: true,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Reyser'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'Rayser'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'Raiser'
            },
            {
              id: 4,
              data: 'Reuser'
            },
            {
              id: 5,
              meta: { correct: 5 },
              data: 'Reiser'
            }
          ]
        },
        {
          title: (
            <CustomMultipleChoice
              key="2"
              answers={[
                {
                  id: 1,
                  meta: { correct: 1 },
                  data: [
                    {
                      type: 'audio',
                      src: require('../../../static/audio/u25/u25_2.mp3')
                    },
                    'Hören Sie den Namen.'
                  ]
                }
              ]}
            />
          ),
          question:
            'Welche Schreibweisen sind richtig? Es gibt mehrere Lösungen. Wählen Sie aus.',
          correct: 'Gute Antwort, weiter so!',
          partiallyCorrect: 'Das stimmt noch nicht ganz.',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: true,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Bölmann'
            },
            {
              id: 2,
              data: 'Büllmann'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'Böhlmann'
            },
            {
              id: 4,
              data: 'Böllmann'
            },
            {
              id: 5,
              data: 'Bühlmann'
            }
          ]
        },
        {
          title: (
            <CustomMultipleChoice
              key="3"
              answers={[
                {
                  id: 1,
                  meta: { correct: 1 },
                  data: [
                    {
                      type: 'audio',
                      src: require('../../../static/audio/u25/u25_3.mp3')
                    },
                    'Hören Sie den Namen.'
                  ]
                }
              ]}
            />
          ),
          question:
            'Welche Schreibweisen sind richtig? Es gibt mehrere Lösungen. Wählen Sie aus.',
          correct: 'Gute Antwort, weiter so!',
          partiallyCorrect: 'Das stimmt noch nicht ganz.',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: true,
          answers: [
            {
              id: 1,
              data: 'Meuer'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'Meyer'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'Maier'
            },
            {
              id: 4,
              meta: { correct: 4 },
              data: 'Meier'
            },
            {
              id: 5,
              data: 'Mäuer'
            }
          ]
        },
        {
          title: (
            <CustomMultipleChoice
              key="4"
              answers={[
                {
                  id: 1,
                  meta: { correct: 1 },
                  data: [
                    {
                      type: 'audio',
                      src: require('../../../static/audio/u25/u25_4.mp3')
                    },
                    'Hören Sie den Namen.'
                  ]
                }
              ]}
            />
          ),
          question:
            'Welche Schreibweisen sind richtig? Es gibt mehrere Lösungen. Wählen Sie aus.',
          correct: 'Gute Antwort, weiter so!',
          partiallyCorrect: 'Das stimmt noch nicht ganz.',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: true,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Heller'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'Häller'
            },
            {
              id: 3,
              data: 'Hähler'
            },
            {
              id: 4,
              data: 'Hehler'
            },
            {
              id: 5,
              data: 'Heler'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
