import React, { Component } from 'react';
import { connect } from 'react-redux';

import PageRouter from './PageRouter';
import { LoadingScreen } from './loadingScreen';

const mapStateToProps = ({ app }) => ({
  isAppReady: app.get('ready')
});

class Container extends Component {
  render() {
    const { isAppReady } = this.props;
    if (isAppReady) {
      return <PageRouter />;
    }
    return <LoadingScreen />;
  }
}

export default connect(mapStateToProps)(Container);
