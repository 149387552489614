import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VERSIONS } from './../../constants';

import { actions as appActions } from '../../reducers/app';
import { actions as infoboxActions } from '../../reducers/infobox';

import entries from '../../config/infos';

import { DefaultButton } from '../../components/';
import logo from '../../static/images/logo.png';
import logoApp from '../../static/images/logo-app.png';

import styles from './styles.less';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setPage: appActions.setPage,
  setVersion: appActions.setVersion,
  unlock: infoboxActions.unlock
};

class Home extends Component {
  render() {
    return (
      <div
        className={styles.container}
        style={{
          backgroundImage: `url('${require('../../static/images/home.jpg')}')`
        }}
      >
        <div className={styles.header}>
          <img
            className={`${styles.headerLogo} ${styles.appLogo}`}
            src={logoApp}
            alt="app logo"
          />
          <img
            className={styles.headerLogo}
            src={logo}
            alt="iq fachstelle logo"
          />
        </div>

        <div className={styles.homeDescription}>
          <h1 className={styles.homeDescriptionTitle}>
            Ein Tag Deutsch in der Pflege
          </h1>
          <p className={styles.homeDescriptionText}>
            Berufssprache spielerisch trainieren
          </p>
          {this.renderVersionSelection()}
        </div>
      </div>
    );
  }

  renderVersionSelection = () => {
    const { unlock } = this.props;

    return (
      <div className={styles.buttonWrapper}>
        <DefaultButton
          className={styles.button}
          onClick={() => {
            this.changePage(VERSIONS.TEACHER);
            unlock(Object.keys(entries));
          }}
          label={'Ich unterrichte Deutsch.'}
        />
        <DefaultButton
          className={styles.button}
          onClick={() => {
            this.changePage(VERSIONS.STUDENT);
            unlock(['info', 'bildnachweis', 'credits', 'impressum']);
          }}
          label={'Ich lerne Deutsch.'}
        />
      </div>
    );
  };

  changePage = version => {
    const { setPage, setVersion } = this.props;
    // if using the cordova container, deactivate sleepmode on IOS and Android
    if (window.plugins) {
      window.plugins.insomnia.keepAwake();
    }
    setPage('introduction');
    setVersion(version);
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
