import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'coffeemaschineExercise4', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'coffeemaschineExercise6', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(['currentExercise'], 'coffeemaschineExercise6')
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.coffeemaschineExercise4 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['coffee', 'coffeemaschineExercise4'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Vorschläge machen',
      text: 'Einen Kaffee anbieten? Dafür gibt es viele Möglichkeiten. Probieren Sie es aus!',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Was passt?',
          question: 'Wie wär‘s mit ...',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              data: 'dass wir erstmal einen Kaffee trinken.'
            },
            {
              id: 2,
              data: 'einen Kaffee zu trinken?'
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: 'einem Kaffee?'
            },
            {
              id: 4,
              data: 'einen Kaffee trinken!'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Lass uns doch ...',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              data: 'dass wir erstmal einen Kaffee trinken.'
            },
            {
              id: 2,
              data: 'einen Kaffee zu trinken?'
            },
            {
              id: 3,
              data: 'einem Kaffee?'
            },
            {
              id: 4,
              meta: { correct: 1 },
              data: 'einen Kaffee trinken!'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Ich schlage vor, ...',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'dass wir erstmal einen Kaffee trinken.'
            },
            {
              id: 2,
              data: 'einen Kaffee zu trinken?'
            },
            {
              id: 3,
              data: 'einem Kaffee?'
            },
            {
              id: 4,
              data: 'einen Kaffee trinken!'
            }
          ]
        },
        {
          title: 'Was passt?',
          question: 'Hättest du Lust, ...',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              data: 'dass wir erstmal einen Kaffee trinken.'
            },
            {
              id: 2,
              meta: { correct: 1 },
              data: 'einen Kaffee zu trinken?'
            },
            {
              id: 3,
              data: 'einem Kaffee?'
            },
            {
              id: 4,
              data: 'einen Kaffee trinken!'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
