import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'exercises', 'mrsTellerExercise1', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'exercises', 'mrsTellerExercise2', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'mrsTellerExercise2'));
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.mrsTellerExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Hinweise zur Ernährung',
      text:
        'Ernährungsempfehlungen sind auch für die Angehörigen ein Thema. Trainieren Sie in dieser Übung die passenden Verben!',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Welches Verb passt?',
          question: 'sich ausgewogen und salzarm',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'ernähren'
            },
            {
              id: 2,
              data: 'essen'
            }
          ]
        },
        {
          title: 'Welches Verb passt?',
          question: 'nur leicht verdauliche Kost',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'ernähren'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'essen'
            }
          ]
        },
        {
          title: 'Welches Verb passt?',
          question: 'auf Weißmehl und Süßigkeiten',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'nehmen'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'verzichten'
            }
          ]
        },
        {
          title: 'Welches Verb passt?',
          question: 'mehrere Mahlzeiten zu sich',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'essen'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'nehmen'
            }
          ]
        },
        {
          title: 'Welches Verb passt?',
          question: 'den Ernährungsplan',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'einhalten'
            },
            {
              id: 2,
              data: 'verzichten'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
