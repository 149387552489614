import React, { Component } from 'react';
import { Provider } from 'react-redux';

import store from '../store';

import ErrorBoundary from './ErrorBoundary';
import Container from './Container';
import MatomoProvider from './MatomoProvider';

import '../styles/styles.less';

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <MatomoProvider>
            <Container />
          </MatomoProvider>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
