import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'dialogues', 'firstThoughts', 'overlay'],
      false
    )
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Vieles läuft anders, als ich das gelernt habe. Oft fühle ich mich noch nicht sicher. Hoffentlich wird das bald besser.',
      audio: '201-Monolog_ID_1.mp3',
      type: 'thought'
    },
    1: {
      from: 'player',
      text: 'Ein Gespräch mit einer Kollegin wäre jetzt genau das Richtige.',
      audio: '201-Monolog_ID_2.mp3',
      type: 'thought'
    },
    2: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
