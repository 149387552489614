import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as appActions } from '../../reducers/app';
import { actions as stepsActions } from '../../reducers/steps';

import { InfoScreen } from '../../components/infoScreen';
import { content } from './content';

const mapStateToProps = ({ steps }) => ({
  currentStep: steps.currentStep
});

const mapDispatchToProps = {
  setPage: appActions.setPage,
  setRoom: stepsActions.setRoom,
  set: stepsActions.set
};

class Steps extends Component {
  render() {
    const { currentStep } = this.props;
    // replace all leading non-digits with nothing
    const stepIndex = currentStep.replace(/^\D+/g, '');
    return (
      <InfoScreen
        subline={`SCHRITT ${stepIndex} VON 4`}
        headline={content[currentStep].heading}
        text={
          typeof content[currentStep].paragraph === 'function'
            ? content[currentStep].paragraph()
            : content[currentStep].paragraph
        }
        duration={`DAUER: ${content[currentStep].duration}`}
        onClick={this.changePage}
      />
    );
  }

  changePage = () => {
    const { setPage, setRoom, currentStep, set } = this.props;
    setPage('room');
    if (currentStep === 'step4') {
      setRoom('office');
      set(['currentDialogue'], 'canMiloHelpMe');
    } else {
      setRoom('corridor');
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
