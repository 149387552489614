import React, { Component } from 'react';

import styles from './styles.less';

class Score extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  componentDidMount() {
    this.counter = setInterval(() => {
      const { count } = this.state;
      const { currentPoints } = this.props;
      if (count < currentPoints) {
        this.setState({ count: count + 1 });
      }
    }, 25);
  }

  componentWillUnmount() {
    clearInterval(this.counter);
  }

  render() {
    const { image, text, maxPoints, currentPoints, onClick } = this.props;
    const { count } = this.state;
    const percentage = currentPoints / maxPoints;
    const feedback =
      percentage >= 0.6
        ? 'Super, das haben Sie sehr gut gemacht!'
        : 'Das können Sie sicher noch besser!';
    return (
      <div className={styles.container}>
        <p className={styles.stepsCounter}>
          {text}
        </p>
        <div className={styles.badgeContainer}>
          <img alt="badge" className={styles.badge} src={image} />
        </div>
        <div>
          <p className={styles.points}>
            <span>{count}</span>/{maxPoints} Punkte
          </p>
          <p
            className={`${styles.feedback} ${currentPoints === count
              ? styles.show
              : ''} `}
          >
            {feedback}
          </p>
        </div>
        <div
          role="button"
          tabIndex={0}
          className={styles.nextButton}
          onClick={onClick}
        >
          <span>Weiter</span>
        </div>
      </div>
    );
  }
}

export default Score;
