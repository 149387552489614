import React, { Component } from 'react';

import styles from './styles.less';

class InputText extends Component {
  render() {
    return (
      <div className={styles.inputWrapper}>
        <label className={styles.inputLabel} htmlFor="name">
          <span className={styles.inputLabelText}>Ihr Name</span>
          <input
            maxLength="15"
            className={styles.inputField}
            onBlur={inputValue => {
              this.props.onBlur(inputValue.target.value);
            }}
            type="text"
            name="name"
            defaultValue={this.props.inputValue ? this.props.inputValue : ''}
          />
        </label>
      </div>
    );
  }
}

export default InputText;
