import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { VelocityTransitionGroup } from 'velocity-react';

import { actions as rewardSystemActions } from '../../reducers/rewardSystem';
import PageTransitions from '../../lib/pageTransitions';
import { Progress } from '../progress';
import { CustomMultipleChoice } from '../customMultipleChoice';
import { CustomCloze } from '../customCloze';
import { Pronunciation } from '../../static/icons/Pronunciation';
import { Grammar } from '../../static/icons/Grammar';
import { Vocabulary } from '../../static/icons/Vocabulary';
import { Communication } from '../../static/icons/Communication';

import TYPE from './constants';

import styles from './styles.less';

const mapStateToProps = ({ steps }) => ({
  currentStep: steps.currentStep
});

const mapDispatchToProps = {
  increment: rewardSystemActions.increment
};

class QuestionSet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      total: props.questions.length,
      answer: null,
      answers: {},
      disableNextButton: true,
      showResult: false,
      feedback: '',
      status: ''
    };
  }
  render() {
    const { questions } = this.props;
    const { progress, total } = this.state;
    const { title } = questions[progress] || {};
    return (
      <div className={styles.questionSet}>
        <Progress theme="bar" current={progress + 1} total={total} />
        <div className={styles.scrollContainer}>
          <VelocityTransitionGroup
            component="div"
            className={styles.transitionContainer}
            enter={{ animation: PageTransitions.slideInFromRight }}
            leave={{ animation: PageTransitions.slideOutToLeft }}
          >
            <div className={styles.questionWrapper}>
              <h4>
                {title}
              </h4>
              {this.renderExercise()}
            </div>
          </VelocityTransitionGroup>
        </div>
        <div className={styles.footer}>
          {this.renderCategoryIcon()}
          <div className={styles.counter}>
            Aufgabe {progress + 1}/{total}
          </div>
          {this.renderCheckAnswerButton()}
        </div>
      </div>
    );
  }

  renderExercise = () => {
    const { questions } = this.props;
    const { progress, showResult } = this.state;

    const question = questions[progress];

    let component;

    if (typeof question === 'undefined') {
      return 'No question available...';
    }

    switch (question.type) {
      case TYPE.CLOZE: {
        component = [
          <CustomCloze
            key={`cloze${progress}`}
            content={typeof question.text === 'function' ? question.text() : question.text} // hack to make email work
            items={question.items}
            multiple={false}
            showResult={showResult}
            onChange={this.handleChange}
          />,
          <div
            key="feedback"
            className={`${styles.feedback} ${styles[this.state.status] || ''}`}
          >
            {typeof this.state.feedback === 'function' ? this.state.feedback() : this.state.feedback  /* hack to make email work */ }
          </div>
        ];
        break;
      }
      case TYPE.MULTIPLE_CHOICE:
      // eslint-disable-next-line
      default:
        const { answers, customTheme } = question;
        const isMultiple = answers.reduce((acc, answer) => {
          if (answer.meta && answer.meta.correct) {
            return acc + 1;
          }

          return acc;
        }, -1);

        component = [
          <CustomMultipleChoice
            key={progress}
            question={question.question}
            answers={answers}
            multiple={!!isMultiple}
            showResult={showResult}
            onChange={this.handleChange}
            customTheme={customTheme}
          />,
          <div
            key="feedback"
            className={`${styles.feedback} ${styles[this.state.status] || ''}`}
          >
            {this.state.feedback}
          </div>
        ];
        break;
    }

    return component;
  };

  handleChange = (index, answer) => {
    const { questions } = this.props;
    const { progress, showResult } = this.state;
    const question = questions[progress];

    // short route to prevent changing answers
    // after "Prüfen" button was clicked
    if (showResult) return;

    let newState;

    switch (question.type) {
      case TYPE.CLOZE:
        newState = {
          answers: {
            ...this.state.answers,
            [index]: answer
          }
        };

        newState.disableNextButton = !(
          Object.keys(newState.answers).length >= question.items.length
        );
        break;
      case TYPE.MULTIPLE_CHOICE:
      default:
        newState = {
          answer: index,
          disableNextButton: !index.length > 0
        };
        break;
    }

    this.setState(newState);
  };

  renderCategoryIcon = () => {
    const { category } = this.props;

    switch (category) {
      case 'pronunciation':
        return <Pronunciation size="50px" />;
      case 'grammar':
        return <Grammar size="50px" />;
      case 'vocabulary':
        return <Vocabulary size="50px" />;
      default:
        return <Communication size="50px" />;
    }
  };

  renderCheckAnswerButton = () => {
    let onClick;
    let text;

    const { disableNextButton } = this.state;
    const { showResult } = this.state;

    const { questions } = this.props;
    const { progress } = this.state;
    const question = questions[progress];

    if (showResult || question.type === 'listen') {
      text = 'Weiter';
      if (!disableNextButton) {
        onClick = this.nextQuestion;
      }
    } else {
      onClick = this.checkAnswer;
      text = 'Antworten';
    }

    return (
      <div
        role="button"
        tabIndex={0}
        onClick={onClick}
        className={`${styles.next} ${disableNextButton ? styles.disabled : ''}`}
      >
        {text}
      </div>
    );
  };

  nextQuestion = () => {
    const { progress } = this.state;
    const { questions } = this.props;
    const nextProgress = progress + 1;

    if (nextProgress > questions.length - 1) {
      questions[progress].after();
    } else {
      this.setState({
        progress: nextProgress,
        answer: null,
        showResult: false,
        disableNextButton: true,
        feedback: '',
        status: ''
      });
    }
  };

  checkAnswer = () => {
    const { increment } = this.props;
    const { disableNextButton } = this.state;
    if (!disableNextButton) {
      const { questions } = this.props;
      const { progress, answer, answers } = this.state;
      const question = questions[progress] || {};
      switch (question.type) {
        case TYPE.CLOZE: {
          let correctItems = 0;
          const answersCount = Object.keys(answers).length;
          Object.keys(answers).forEach(answerIndex => {
            const answerID = answers[answerIndex][0];
            let answerItem = question.items[answerIndex].find(
              a => a.id === answerID
            );

            if (typeof answerItem === 'undefined') {
              answerItem = question.items[answerIndex][0];
            }
            if (
              answerItem.type === 'INPUT' &&
              answerItem.meta.correct === answers[answerIndex]
            ) {
              correctItems++;
            }

            if (
              answerItem.type !== 'INPUT' &&
              answerItem.meta &&
              answerItem.meta.correct
            ) {
              correctItems++;
            }

            if (correctItems === answersCount) {
              increment('currentExercisePoints', 1);
            }
          });
          this.setState({
            feedback: question.feedback,
            answers: ''
          });
          break;
        }
        case TYPE.MULTIPLE_CHOICE:
        // eslint-disable-next-line
        default:
          let correctChosenAnswers = 0;
          let incorrectChosenAnswers = 0;

          let amountOfCorrectAnswers = 1;

          for (let i = 0; i < answer.length; i++) {
            const chosenAnswer = question.answers.find(a => a.id === answer[i]);
            if (chosenAnswer.meta) {
              if (chosenAnswer.meta.correct) {
                correctChosenAnswers++;
              }
            } else {
              incorrectChosenAnswers++;
            }
          }

          if (question.multiple) {
            amountOfCorrectAnswers = 0;
            for (let i = 0; i < question.answers.length; i++) {
              if (question.answers[i].meta) {
                if (question.answers[i].meta.correct) {
                  amountOfCorrectAnswers++;
                }
              }
            }
          }

          if (
            correctChosenAnswers === amountOfCorrectAnswers &&
            incorrectChosenAnswers === 0
          ) {
            increment('currentExercisePoints', 1);
            this.setState({ feedback: question.correct, status: 'correct' });
          } else if (correctChosenAnswers > 0 && incorrectChosenAnswers >= 0) {
            this.setState({
              feedback: question.partiallyCorrect,
              status: 'partiallyCorrect'
            });
          } else {
            this.setState({
              feedback: question.incorrect,
              status: 'incorrect'
            });
          }
          break;
      }
      this.setState({
        showResult: true
      });
    }
  };
}

QuestionSet.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};

QuestionSet.defaultProps = {
  questions: []
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSet);
