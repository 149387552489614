import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'dietaryAssistantExercise2', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'dietaryAssistantExercise3', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(['currentExercise'], 'dietaryAssistantExercise3')
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.dietaryAssistantExercise2 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise([
        'dietaryAssistant',
        'dietaryAssistantExercise2'
      ])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Grammatik',
      headline: 'Was muss noch getan werden?',
      text: 'Aufgaben, die gemacht werden müssen, werden häufig über Passiv mit Modalverben kommuniziert.',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'grammar',
      questions: [
        {
          type: 'CLOZE',
          title: 'Formulieren Sie den Satz um.',
          text: `
          Beispiel:<br>
          <i>Ich muss die Chirurgie noch informieren.<br>
          Die Chirurgie <b>muss</b> noch <b>informiert werden</b></i>.<br><br>
          Ich muss nach der OP einen Blasenkatheter einsetzen.<br>
          Ein Blasenkatheter {} nach der OP {} {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'muss' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'eingesetzt' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'werden' }, id: 'c' }]
          ],
          feedback: (
            <span>
              Ich muss nach der OP einen Blasenkatheter einsetzen.<br />
              Ein Blasenkatheter <strong>muss</strong> nach der OP{' '}
              <strong>eingesetzt</strong> <strong>werden</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie den Satz um.',
          text: `
          Beispiel:<br>
          <i>Ich muss die Chirurgie noch informieren.<br>
          Die Chirurgie <b>muss</b> noch <b>informiert werden</b></i>.<br><br>
          Wir müssen ein Trinkprotokoll für Frau Simon erstellen. <br>
          Ein Trinkprotokoll für Frau Simon {} {} {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'muss' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'erstellt' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'werden' }, id: 'c' }]
          ],
          feedback: (
            <span>
              Wir müssen ein Trinkprotokoll für Frau Simon erstellen. <br />
              Ein Trinkprotokoll für Frau Simon <strong>muss</strong>{' '}
              <strong>erstellt</strong>
              <strong> werden</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie den Satz um.',
          text: `
          Beispiel:<br>
          <i>Ich muss die Chirurgie noch informieren.<br>
          Die Chirurgie <b>muss</b> noch <b>informiert werden</b></i>.<br><br>
          Ich muss Herrn Humpe heute die Haare waschen. <br>
          Die Haare von Herrn Humpe {} {} {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'müssen' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'gewaschen' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'werden' }, id: 'c' }]
          ],
          feedback: (
            <span>
              Ich muss Herrn Humpe heute die Haare waschen. <br />
              Die Haare von Herrn Humpe <strong>müssen</strong>{' '}
              <strong>gewaschen</strong> <strong>werden</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie den Satz um.',
          text: `
          Beispiel:<br>
          <i>Ich muss die Chirurgie noch informieren.<br>
          Die Chirurgie <b>muss</b> noch <b>informiert werden</b></i>.<br><br>
          Wir müssen beim Legen von Venenkathetern Schutzhandschuhe tragen. <br>
          Beim Legen von Venenkathetern {} Schutzhandschuhe {} {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'müssen' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'getragen' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'werden' }, id: 'c' }]
          ],
          feedback: (
            <span>
              Wir müssen beim Legen von Venenkathetern Schutzhandschuhe tragen.{' '}
              <br />
              Beim Legen von Venenkathetern <strong>müssen</strong>{' '}
              Schutzhandschuhe
              <strong> getragen</strong> <strong>werden</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie den Satz um.',
          text: `
          Beispiel:<br>
          <i>Ich muss die Chirurgie noch informieren.<br>
          Die Chirurgie <b>muss</b> noch <b>informiert werden</b></i>.<br><br>
          Ich muss bei Frau Meyer einen sterilen Verband anlegen.<br>
          Bei Frau Meyer {} ein steriler Verband {} {}.
          `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'muss' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'angelegt' }, id: 'b' }],
            [{ type: 'INPUT', meta: { correct: 'werden' }, id: 'c' }]
          ],
          feedback: (
            <span>
              Ich muss bei Frau Meyer einen sterilen Verband anlegen <br />
              Bei Frau Meyer <strong>muss</strong> ein steriler Verband{' '}
              <strong>angelegt</strong>
              <strong> werden</strong>.
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
