import React, { Component } from 'react';

import male from '../../static/images/male.png';
import female from '../../static/images/female.png';

import styles from './styles.less';

class Portrait extends Component {
  render() {
    const { data, selected } = this.props;
    return (
      <div
        className={`${styles.portraitStatus} ${selected
          ? styles.selected
          : ''}`}
        role="button"
        tabIndex={0}
        onClick={this.handleSelect}
      >
        <div className={styles.portraitCircle}>
          <img
            src={data === 'female' ? female : male}
            className={styles.portrait}
            alt={'Portrait'}
          />
        </div>
      </div>
    );
  }

  handleSelect = () => {
    const { onClick, data } = this.props;
    onClick(data);
  };
}

export default Portrait;
