import React, { Component } from 'react';

import InfoBox from '../infoBox';
import { NewEntryIcon } from '../../static/icons/NewEntryIcon';

import styles from './styles.less';

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInfo: null
    };
  }

  render() {
    const { text, correct, onClick, resetDialogue, endScreen } = this.props;
    const feedbackText = typeof text === 'function' ? text() : text;

    const restartButtonLabel = endScreen ? 'Spiel neu starten' : 'Dialog wiederholen';

    const nextButtonLabel = endScreen ? 'Empfehlen Sie die App weiter!' : 'Weiter';

    return (
      <div className={styles.container}>
        <div className={styles.descriptionWrapper}>
          <div
            className={`${styles.icon} ${correct
              ? styles.iconCorrect
              : styles.iconWrong}`}
          />
          <p className={styles.description}>
            {feedbackText}
          </p>
          {this.renderInfoLinks()}
        </div>
        <div
          role="button"
          tabIndex={0}
          className={`${styles.button} ${styles.spacing}`}
          onClick={resetDialogue}
        >
          <span>
            {restartButtonLabel}
          </span>
        </div>
        <div role="button" tabIndex={0} className={`${styles.button} ${this.checkIfDisabled() ? '' : styles.disabled}`} onClick={onClick}>
          <span>
            {nextButtonLabel}
          </span>
        </div>
        {this.renderInfoBox()}
      </div>
    );
  }

  checkIfDisabled = () => {
    const { correct, onClick } = this.props;

    if (correct === false && onClick === undefined) {
      return false;
    } else {
      return true;
    }
  }

  renderInfoLinks = () => {
    const { unlockedEntries, infoBoxEntries } = this.props;

    if (unlockedEntries) {
      const count = unlockedEntries.length;
      return (
        <div className={styles.infoContainer}>
          <NewEntryIcon size={100} />
          <span className={styles.infoUnlocked} style={count > 1 ? { marginBottom: '10px' } : {}}>
            {`Sie haben ${
              count > 1
                ? `${count} Einträge`
                : `${count} Eintrag`
            } in ihrer Infobox: `}
          </span>
          {
            unlockedEntries.map(entry =>
              <a
                key={entry}
                onClick={this.showInfoBox.bind(this, entry)}
                className={styles.infoLink}
              >
                {`"${infoBoxEntries[entry].heading}"`}
              </a>
            )
          }
        </div >
      );
    }
    return null;
  };

  showInfoBox = entry => {
    this.setState({ currentInfo: entry });
  };

  closeInfoBox = () => {
    this.setState({ currentInfo: null });
  };

  renderInfoBox = () => {
    const { infoBoxEntries } = this.props;
    const { currentInfo } = this.state;

    if (currentInfo) {
      return (
        <InfoBox
          onClick={this.closeInfoBox}
          heading={infoBoxEntries[currentInfo].heading}
          content={infoBoxEntries[currentInfo].content}
        />
      );
    }
    return null;
  };
}

export default Feedback;
