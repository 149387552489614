import React from 'react';

export default {
  abklären: {
    word: 'ab|klären + Akk',
    description: 'etwas besprechen',
    example: 'Wir müssen die nächsten Schritte mit dem Arzt abklären.'
  },
  abstützen: {
    word: 'ab|stützen (sich)',
    description: 'Hilfe für das Aufstehen nutzen',
    example: 'Stützen Sie sich ruhig auf mich ab!'
  },
  akte: {
    word: 'Akte, die (-en)',
    description: 'die Krankenakte, die Patientenakte, die Dokumentationsmappe',
    example: 'Schreiben Sie das bitte in die Patientenakte!'
  },
  alarmieren: {
    word: 'alarmieren + Akk (hat alarmiert)',
    description: 'beunruhigen',
    example:
      'Die Patientin isst immer weniger und wird immer dünner. Das alarmiert mich.'
  },
  amputation: {
    word: 'Amputation, die (-en)',
    description: 'Operation, bei der ein Körperteil abgetrennt wird',
    example:
      'Nach der Amputation spürt der Patient sehr häufig Phantomschmerzen.'
  },
  angehörige: {
    word: 'Angehörige/r',
    description: 'Familie: Lebenspartner, Kinder, Mutter, Vater etc.',
    example: 'Könnten Sie bitte die Angehörigen informieren?'
  },
  anliegen: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Anliegen, das (-)',
    description: 'die Motivation, der Grund',
    example: 'Worum geht es denn? Welches Anliegen haben Sie?'
  },
  anordnen: {
    word: 'an|ordnen + Akk',
    description: 'bestimmen',
    example: 'Dr. Petersen hat vier Mal Krankengymnastik angeordnet.'
  },
  anpassen: {
    word: 'an|passen (an)',
    description: 'adaptieren, verändern, eine bessere Lösung finden',
    example:
      'Der Zustand des Patienten hat sich verändert, deshalb haben wir die Pflegemaßnahmen angepasst.'
  },
  ansetzen: {
    word: 'an|setzen + Akk',
    description: 'mit einem Medikament beginnen; Gegenteil: absetzen',
    example:
      'Das Antibiotikum haben wir am 01.08. angesetzt. Nach sieben Tagen, am 07.08., kann es abgesetzt werden.'
  },
  antibiotikum: {
    word: 'Antibiotikum, das (-a)',
    description: 'Wirkstoff gegen Krankheitserreger',
    example: 'Sie müssen das Antibiotikum täglich nach dem Essen einnehmen.'
  },
  appetitlosigkeit: {
    word: () => <span>Appetit&shy;losigkeit, die</span>,
    description: 'Das Gefühl, nicht essen zu wollen',
    example:
      'Die Patientin leidet unter Appetitlosigkeit: Sie hat keinen Hunger und möchte nichts essen.'
  },
  aufenthaltsraum: {
    word: () => <span>Aufenthalts&shy;raum, der (-räume)</span>,
    description: 'ein Zimmer als Treffpunkt für alle Patienten auf der Station',
    example:
      'Ihr Vater ist im Aufenthaltsraum. Er sieht ein bisschen fern und liest Zeitschriften.'
  },
  auffällig: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'auffällig',
    description: 'anders als normal, abweichend',
    example: 'Die Farbe des Urins ist sehr dunkel. Das ist auffällig.'
  },
  aufklären: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'auf|klären + Akk',
    description: 'informieren',
    example:
      'Dr. Schreiber wird Sie morgen in der Visite darüber aufklären, wie es genau weitergeht.'
  },
  aufnahmegespräch: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>Aufnahme&shy;gespräch, das (-e)</span>,
    description: 'das Anamnesegespräch, das Erstgespräch',
    example: 'Herr Erich sagte mir im Aufnahmegespräch, dass er nur für\'s Lesen eine Brille brauche.'
  },
  aufsetzen: {
    word: 'auf|setzen (sich)',
    description: 'vom Liegen ins Sitzen kommen',
    example: 'Können Sie sich alleine aufsetzen oder brauchen Sie Hilfe?'
  },
  austauschen: {
    word: 'aus|tauschen (sich)',
    description: 'mit anderen Personen über ein Thema sprechen',
    example:
      'Wir tauschen uns regelmäßig im Team über mögliche Pflegemaßnahmen aus.'
  },

  ballaststoff: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Ballaststoff, der (-e)',
    description:
      'Kohlenhydrate, die aus pflanzlichen Stoffen ( Gemüse, Hülsenfrüchte) kommen und unverdaulich sind',
    example:
      'Wenn Sie selten Verdauung haben, helfen Ihnen Ballaststoffe. Mögen Sie mit einem Müsli anfangen?'
  },
  befinden: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Befinden, das (-)',
    description: 'Gesundheitszustand, psychische Verfassung',
    example: 'Wie ist Ihr Befinden? = Wie geht es Ihnen?'
  },
  belasten: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'belasten + Akk',
    description: 'etwas macht Sorgen',
    example:
      'Unklare Prognosen und viele Untersuchungen belasten die Patienten sehr.'
  },
  belastung: {
    word: 'Belastung, die (-en)',
    description: 'ein Problem, eine Sorge, etwas Negatives',
    example:
      'Die Perspektive, mit nur einem Bein zu leben, ist eine Belastung für den Patienten.'
  },
  besorgniserregend: {
    word: () => <span>besorgnis&shy;erregend</span>,
    description: 'macht Sorgen, ist beängstigend, alarmierend',
    example: 'Der Zustand des Patienten ist besorgniserregend.'
  },
  bettkante: {
    word: 'Bettkante, die (-n)',
    description: 'der Rand des Bettes',
    example:
      'Können Sie sich bitte auf die Bettkante setzen und die Füße fest auf den Boden stellen?'
  },
  beunruhigen: {
    word: 'beunruhigen + Akk',
    description: 'Sorgen machen',
    example: 'Die schlechten Blutwerte des Patienten beunruhigen mich.'
  },
  blass: {
    word: 'blass',
    description: 'hell oder weiß, hier: die Gesichtsfarbe',
    example: 'Sie sehen heute etwas blass aus, geht es Ihnen nicht gut?'
  },
  blutdruck: {
    word: 'Blutdruck, der (-e)',
    description:
      'ein hoher/niedriger Blutdruck, ein normaler Blutdruck liegt bei 120/80',
    example:
      'Bitte legen Sie Ihren Arm frei, ich möchte Ihren Blutdruck messen.'
  },
  blutwert: {
    word: 'Blutwert, der (-e)',
    description: 'Ergebnis einer Blutanalyse',
    example: 'Alles in Ordnung. Die Blutwerte sind unauffällig.'
  },
  blutzucker: {
    word: 'Blutzucker, der (-)',
    description: 'der Zuckeranteil im Blut, die Höhe des Glucoseanteils',
    example:
      'Ihr Mann ist Diabetiker und muss seinen Blutzucker regelmäßig messen.'
  },
  blutzuckerspiegel: {
    word: () => <span>Blutzucker&shy;spiegel, der</span>,
    description: 'der Wert des Blutzuckers',
    example:
      'Frau Kantler, ich zeige Ihnen die Tabelle für den Blutzuckerspiegel bei Menschen ohne Diabetes.'
  },
  brennen: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'brennen',
    description: '(brannte, hat gebrannt) ein Gefühl wie Feuer, schmerzhaft',
    example:
      'Es tut mir leid. Ich weiß: Das Desinfizieren offener Wunden brennt sehr.'
  },

  chirurgie: {
    word: 'Chirurgie, die',
    description:
      'Teilgebiet der Medizin, Abteilung im Krankenhaus, hier wird operiert',
    example:
      'Herr Meister war nach der OP vier Tage auf der Chirurgie. Jetzt kommt er auf die Geriatrie.'
  },
  compliant: {
    word: 'compliant',
    description: 'kooperativ, mitarbeitend',
    example:
      'Die Patientin macht die Übungen gut von allein mit. Sie ist compliant.'
  },

  deprimiert: {
    word: 'deprimiert',
    description: 'unglücklich, traurig, hoffnungslos',
    example:
      'Renate Schenk wirkt deprimiert. Ich denke, weil sie niemals Besuch bekommt.'
  },
  desinfektion: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Desinfektion, die (-en)',
    description: 'eine Hygienemaßnahme zur Keimfreiheit',
    example:
      'Die Geräte müssen absolut steril sein. Die  Desinfektion nach dem Gebrauch ist deshalb notwendig.'
  },
  desinfizieren: {
    word: () => <span>desinfi&shy;zieren + Akk</span>,
    description: 'steril machen',
    example: 'Vergiss nicht, dir die Hände  zu desinfizieren.'
  },
  desinfizierer: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>Desinfi&shy;zierer, der (-)</span>,
    description: 'Behälter mit Desinfektionsmittel',
    example:
      'Hier im Eingangsbereich der Station befindet sich der Desinfizierer, da können Sie sich die Hände desinfizieren.'
  },
  desinteressiert: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>desinter&shy;essiert</span>,
    description: 'ohne Interesse, unbeteiligt, gleichgültig',
    example: 'Der Patient hat psychische Probleme und wirkt desinteressiert.'
  },
  diabetes: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Diabetes, der',
    description: 'Zuckerkrankheit',
    example: 'Patienten mit Diabetes haben zu hohe Blutzuckerwerte.'
  },
  diabetiker: {
    word: 'Diabetiker/in',
    description: 'jemand, der Diabetes hat',
    example:
      'Als Diabetiker sollte Ihr Mann seine Blutzuckerwerte immer unter Kontrolle haben.'
  },
  diagnose: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Diagnose, die (-n)',
    description: 'der Krankheitsbefund',
    example:
      'Welche Diagnose hat Frau Hirsch nun erhalten? Hat sie Morbus Crohn?'
  },
  diätassistent: {
    word: () => <span>Diät&shy;assistent/in</span>,
    description: 'ein Experte/eine Expertin für die richtige Ernährung',
    example:
      'Die Diätassistentin weiß, was Sie essen dürfen. Sie wird für Sie einen Plan erstellen.'
  },
  diätassistenz: {
    word: () => <span>Diät&shy;assistenz, die (-en)</span>,
    description: 'das Fachgebiet der Ernährungsberatung',
    example:
      'Kannst du bitte bei der Diätassistenz anrufen und nach dem Ernährungsplan fragen?'
  },
  diätkost: {
    word: 'Diätkost, die',
    description: 'spezielles Essen, z.B. kalorienarme Nahrung',
    example: 'Sie bekommen Diätkost, weil Sie ja lactosefrei essen sollen.'
  },
  diätplan: {
    word: 'Diätplan, der (-pläne)',
    description: 'Ernährungsplan, was und wie viel gegessen werden soll',
    example:
      'Es ist wirklich wichtig für Sie, sich an Ihren Diätplan zu halten.'
  },
  dienstschluss: {
    word: () => <span>Dienst&shy;schluss, der</span>,
    description: 'das Ende der Arbeit',
    example: 'Um wie viel Uhr hast du Dienstschluss?'
  },
  dienstzimmer: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>Dienst&shy;zimmer, das (-)</span>,
    description: 'Raum für das Personal im Krankenhaus',
    example: 'Das besprechen wir am besten morgen im Dienstzimmer.'
  },
  dokumentation: {
    word: () => <span>Dokumen&shy;tation, die (-en)</span>,
    description: 'Informationen werden gesammelt und aufgeschrieben',
    example:
      'Die  Pflegedokumentation machen wir hier immmer am Computer und wir speichern sie hier.'
  },
  dokumentieren: {
    word: () => <span>dokument&shy;ieren + Akk</span>,
    description: 'Informationen an einem Ort aufschreiben',
    example:
      'Im Pflegeteam dokumentieren wir alle Vorfälle während unserer Schicht.'
  },
  dosis: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Dosis (Insulindosis), die (Dosen)',
    description: 'die Menge',
    example: 'Wie hoch ist die Dosis? Wie viel Insulin bekommt der Patient?'
  },
  drücken: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'drücken',
    description: '(mit Kraft) berühren',
    example: 'Drücken Sie den Knopf, wenn Sie etwas brauchen.'
  },
  dumpf: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'dumpf',
    description: 'ein unklarer, unbestimmter, gedämpfter Schmerz',
    example:
      'Nein, die Schmerzen in meinem Arm sind nicht scharf, sie sind dumpf.'
  },
  duzen: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'duzen + Akk',
    description: '"du" zueinander sagen, die informelle Anrede verwenden',
    example: 'Also, wir duzen uns hier auf der Station B. Ich bin Karla.'
  },

  einfühlsam: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'einfühlsam',
    description: 'empathisch handeln',
    example:
      'Als Pflegekraft ist es wichtig, einfühlsam mit Patienten und Angehörigen zu sprechen.'
  },
  eingeschränkt: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>einge&shy;schränkt</span>,
    description: 'weniger Möglichkeiten haben, etwas nicht 100% tun können',
    example:
      'Der Patient ist bewegungseingeschränkt und benötigt Hilfe beim Gang zur Toilette.'
  },
  einmalhandschuh: {
    word: () => <span>Einmal&shy;handschuh, der (-e)</span>,
    description:
      'Wegwerfhandschuhe, Handschuhe aus Latex zum einmaligen Gebrauch',
    example: 'Wir tragen zum Schutz vor Infektionen immer Einmalhandschuhe.'
  },
  eintragen: {
    word: 'ein|tragen + Akk ',
    description:
      '(trug ein, hat eingetragen) hier: eine Information  notieren oder aufschreiben',
    example: 'Bitte tragen Sie die Werte in die Krankenakte ein.'
  },
  einwirken: {
    word: 'ein|wirken',
    description: 'in die Haut gehen',
    example: 'Bitte warten Sie noch, die Salbe muss fünf Minuten einwirken.'
  },
  eiweiß: {
    word: 'Eiweiß, das (-e)',
    description: 'das Protein',
    example:
      'Essen Sie viele Eier und viele Milchprodukte - die enthalten viel Eiweiß. Das ist gut für die Wundheilung.'
  },
  empathisch: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'empathisch',
    description: 'einfühlsam sprechen/sein',
    example: 'Es ist wichtig, empathisch mit besorgten Angehörigen umzugehen.'
  },
  entzünden: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'entzünden (sich)',
    description: 'sich infizieren, durch eine Infektion',
    example:
      'Ich werde Ihre Wunde jetzt desinfizieren, damit sie sich nicht entzündet.'
  },
  entzündet: {
    word: 'entzündet',
    description: 'infiziert sein',
    example: 'Die Wunde ist entzündet und muss dringend behandelt werden.'
  },
  entzündung: {
    word: 'Entzündung, die (-en)',
    description: 'die Infektion',
    example: 'Die Entzündung ist durch das Antibiotikum zurückgegangen.'
  },
  entzündungswert: {
    word: () => <span>Entzün&shy;dungswert, der (-e)</span>,
    description: 'Infektionszeichen, Entzündungsparameter',
    example:
      'Die Entzündungswerte im Blut sind hoch. Sie weisen auf eine Infektion hin.'
  },
  erfreulich: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'erfreulich',
    description: 'angenehm, positiv',
    example: 'Der Patientin geht es viel besser. Das ist wirklich erfreulich.'
  },
  erhöht: {
    word: 'erhöht',
    description: 'höher als normal',
    example: 'Der CRP-Wert ist erhöht: 200 statt 50.'
  },
  ernähren: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'ernähren (sich)',
    description: 'essen und trinken (z.B.  fett, gesund, kalorienarm)',
    example: 'Achten Sie darauf, was Sie essen und ernähren Sie sich gesund.'
  },
  ernährungsberatung: {
    word: () => <span>Ernährungs&shy;beratung, die (-en)</span>,
    description: 'eine Empfehlung für individuelle Ernährung',
    example: 'Die Ernährungsberatung hilft Ihnen, Ihre Ernährung zu verändern.'
  },
  ernährungsplan: {
    word: () => <span>Ernährungs&shy;plan, der (-pläne)</span>,
    description: 'ein Plan für eine individuelle Ernährung',
    example:
      'Der Ernährungsplan schlägt eine kalorienreiche Kost vor, damit Sie schnell zunehmen.'
  },

  fachlich: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'fachlich',
    description: 'das Arbeitsgebiet betreffend',
    example: 'Hättest du kurz Zeit - ich brauche einen fachlichen Rat.'
  },
  feierabend: {
    word: 'Feierabend, der (-e) ',
    description: 'das Ende des Arbeitstages',
    example: 'Schönen Feierabend!'
  },
  fetthaltig: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'fetthaltig',
    description: 'ein Nahrungsmittel enthält Fett',
    example:
      'Es wäre gut, wenn Sie keine fetthaltigen Speisen essen würden, zum Beispiel keine Wurst.'
  },
  fortschritt: {
    word: 'Fortschritt, der (-e)',
    description: 'eine Verbesserung des Zustandes, eine Progression',
    example: 'Super, Sie haben ja schon gute Fortschritte gemacht.'
  },
  frühschicht: {
    word: 'Frühschicht, die (-en)',
    description: 'Arbeitszeit am Vormittag, oftmals 6:00-14:00 Uhr',
    example:
      'Ich bin morgen Nachmittag nicht auf Station, denn ich habe Frühschicht.'
  },

  gehhilfe: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Gehhilfe, die (-n)',
    description: 'ein Gehstock oder ein Rollator, der beim Laufen hilft',
    example:
      'Die Patientin hat Schwierigkeiten beim Gehen und braucht eine Gehhilfe.'
  },
  gerötet: {
    word: 'gerötet',
    description: 'rot oder rötlich gefärbt',
    example: 'Die Wunde ist gerötet. Sie könnte entzündet sein.'
  },
  gesäß: {
    word: 'Gesäß, das (-e)',
    description: 'Bezeichnung für den Po',
    example:
      'Könnten Sie Ihr Gesäß kurz anheben, bitte? Dann kann ich das Laken glatt streichen.'
  },
  greifen: {
    word: 'greifen + Akk',
    description: 'einen Gegenstand mit der Hand und den Fingern umschließen',
    example: 'Können Sie den Stock greifen?'
  },

  hauptmahlzeit: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>Haupt&shy;mahlzeit, die (en)</span>,
    description: 'die größte Mahlzeit, in Deutschland meistens zur Mittagszeit',
    example:
      'Nehmen Sie einmal am Tag eine Hauptmahlzeit mit viel Gemüse zu sich.'
  },
  hämmern: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'hämmern',
    description: 'fest schlagen oder klopfen',
    example:
      'Wenn ich Kopfschmerzen habe, hämmert es in meinem Kopf. So als würde ein Hammer schlagen.'
  },
  heilen: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'heilen',
    description: 'gesund werden, jemanden gesund machen',
    example: 'Ich bin mir sicher, dass Ihre Wunde gut heilen wird.'
  },
  herausforderung: {
    word: () => <span>Heraus&shy;forderung, die (-en)</span>,
    description: 'eine Schwierigkeit, eine Hürde',
    example:
      'In einer Fremdsprache zu arbeiten, ist bestimmt eine Herausforderung!'
  },
  hindeuten: {
    word: 'hin|deuten (auf)',
    description: 'etwas anzeigen, auf etwas schließen lassen',
    example: 'Auf welche Krankheit deuten die Symptome hin?'
  },
  hygiene: {
    word: 'Hygiene, die (-n)',
    description: 'Sauberkeit, Keimfreiheit',
    example:
      'Wir müssen hier sehr auf die Hygiene achten und z.B. immer die Hände desinfizieren.'
  },
  hygienisch: {
    word: 'hygienisch',
    description: 'sauber, steril, antiseptisch',
    example:
      'Aus hygienischen Gründen tragen Sie bitte einen Patientenkittel auf der Intensivstation.'
  },

  insulin: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Insulin, das (-e)',
    description:
      'Insulinhormon, das im Pankreas gebildet wird, senkt den Blutzuckerspiegel',
    example: 'Wie viel Insulin haben Sie heute gespritzt?'
  },
  insulineinstellung: {
    word: () => <span>Insulin&shy;einstellung, die (en)</span>,
    description: 'die Menge des Insulins',
    example:
      'Denken Sie daran, dass gesundes Essen gut für Ihre Insulineinstellung ist.'
  },

  jucken: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'jucken',
    description: 'eine unangenehme Empfindung der Haut, die zum Kratzen anregt',
    example:
      'Können Sie mir etwas gegen das Ekzem geben? Meine Haut juckt stark.'
  },

  kalorie: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Kalorie, die (-n)',
    description: 'Maßeinheit für den Nährwert von Nahrung, auch: Joule',
    example: 'Achten Sie darauf, wie viele Kalorien Ihre Mahlzeit hat.'
  },
  kalorienarm: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'kalorienarm',
    description: 'mit wenig Kalorien',
    example:
      'Sie sollten kalorienarmes Essen wählen, Sie wollen ja abnehmen, Herr Reinhardt.'
  },
  keim: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Keim, der (-e)',
    description: 'Krankheitserreger, pathogener Mikroorganismus',
    example:
      'Achten Sie darauf, dass keine Keime in die Wunde kommen. Sie könnte sich sonst entzünden.'
  },
  kohlenhydrate: {
    word: 'Kohlenhydrat, das (-e)',
    description:
      'meist im Plural: Kohlenhydrate, Bestandteil aus der Nahrung, der viel Energie liefert',
    example:
      'Essen Sie am besten Nudeln oder Kartoffeln, die enthalten viele Kohlenhydrate.'
  },
  konsultieren: {
    word: () => <span>konsul&shy;tieren + Akk</span>,
    description: 'um Rat fragen, nachfragen',
    example: 'Ich werde morgen die Wundmanagerin konsultieren.'
  },
  kooperativ: {
    word: 'kooperativ',
    description: 'Personen arbeiten gut zusammen',
    example: 'Frau Janke ist wirklich kooperativ. Sie macht toll mit.'
  },
  kopfteil: {
    word: 'Kopfteil, das (-e)',
    description: 'Oberes Teil des Bettes, am Kopfende',
    example: 'Zum Essen stelle ich Ihr Kopfteil hoch.'
  },
  kost: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Kost, die',
    description: 'das Essen, die zubereitete Nahrung',
    example: 'Bekommt der Patient Diätkost oder Vollkost?'
  },
  krankenakte: {
    word: 'Krankenakte, die (-n)',
    description: 'die Patientenakte, die Dokumentationsmappe',
    example: 'Schreiben Sie die Werte in die Krankenakte.'
  },
  krankengymnastin: {
    word: () => <span>Kranken&shy;gymnast/in</span>,
    description: 'der/die Physiotherapeut/in',
    example: 'Später kommt unser Krankengymnast und macht Übungen mit Ihnen.'
  },
  krankengymnastik: {
    word: () => <span>Kranken&shy;gymnastik, die</span>,
    description: 'Bewegungsprogramm zur Kräftigung nach Operationen',
    example:
      'Der Patient muss regelmäßig Krankengymnastik machen, um mobiler zu werden.'
  },
  krankheitsbild: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>Krank&shy;heitsbild, das (-er)</span>,
    description: 'alle charakteristischen Merkmale oder Krankheitszeichen',
    example: 'Welche typischen Symptome hat das Krankheitsbild?'
  },
  kreislauf: {
    word: 'Kreislauf, der (-läufe)',
    description: 'die Zirkulation',
    example:
      'Die Patientin ist über 80 Jahre alt und hat Schwierigkeiten mit dem Kreislauf.'
  },
  kribbelt: {
    word: 'kribbeln',
    description: 'prickeln, das Gefühl von Ameisen',
    example: 'Ich habe mein Bein lange nicht bewegt und jetzt kribbelt es.'
  },

  lagere: {
    word: 'lagern + Akk',
    description: 'positionieren, legen',
    example:
      'Erika Körner hat jetzt einen Dekubitus. Lass sie uns anders lagern.'
  },
  lotion: {
    word: 'Lotion, die (-en)',
    description: 'eine recht flüssige Creme für die Haut',
    example: 'Ich trage Ihnen jetzt noch etwas Lotion auf Ihren Arm auf.'
  },
  lüften: {
    word: 'lüften',
    description: 'ein Fenster öffnen und frische Luft hereinlassen',
    example: 'Die Luft im Raum ist nicht mehr so frisch - soll ich mal lüften?'
  },
  mineralienreich: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>minerali&shy;enreich</span>,
    description: 'reich an Mineralstoffen, auch mineralstoffhaltig',
    example:
      'Essen Sie zum Beispiel Linsen oder Aprikosen, das sind mineralienreiche Nahrungsmittel.'
  },
  mineralstoff: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Mineralstoff, der (-e)',
    description: 'z.B. Calcium, Kalium, Magnesium',
    example:
      'Zu Patienten: "Zur gesunden Ernährung braucht Ihr Körper Mineralstoffe, also zum Beispiel Calcium und Magnesium."'
  },
  mobilisation: {
    word: 'Mobilisation, die (-en)',
    description: 'eigenständige Bewegung',
    example:
      'Die Mobilisation des Patienten ist sehr wichtig, er muss sich wieder selbstständig bewegen können.'
  },
  mobilisieren: {
    word: 'mobilisieren +  Akk',
    description: 'beweglich machen, zur Bewegung anregen',
    example:
      'Der Patient soll sein Bett verlassen. Wir werden ihn schrittweise mobilisieren.'
  },

  nachttisch: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Nachttisch, der (-e)',
    description: 'der Tisch neben dem Bett',
    example:
      'Ich lege Ihnen die Tabletten auf Ihren Nachttisch, neben die Lampe.'
  },
  namensschild: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Namensschild, das (-er)',
    description: 'das Schild mit dem Namen',
    example:
      'Hast du schon ein Namensschild von der Personalabteilung bekommen?'
  },
  nässen: {
    word: 'nässen',
    description: 'Flüssigkeit absondern',
    example: 'Die Wunde müsste trocken sein, aber sie nässt noch.'
  },
  nehmen: {
    word: 'nehmen (zu sich) + Akk',
    description: 'etwas essen und trinken',
    example:
      'Haben Sie heute schon etwas zu sich genommen? Zum Beispiel eine Scheibe Brot oder einen Tee?'
  },
  notfall: {
    word: 'Notfall, der (-fälle)',
    description:
      'Eine Patientin ist so krank oder verletzt, dass das Ärzte- und Pflegetam sofort reagieren muss.',
    example:
      'Ein Notfall auf der 2b! Herr Lebernz hat viel Blut erbrochen. Bitte kommen Sie sofort, Dr. Jannasch.'
  },

  panisch: {
    word: 'panisch',
    description: 'mit großer Angst, kopflos, phobisch',
    example: 'Der Patient hat panische Angst vor der Operation.'
  },
  papierkram: {
    word: 'Papierkram, der',
    description:
      'umgangsprachlich für das Ausfüllen von Formularen, Tabellen, Verwaltungsarbeit',
    example: 'Hast du den Papierkram schon erledigt?'
  },
  peinlich: {
    word: 'peinlich',
    description: 'unangenehm, das Schamgefühl betreffend',
    example:
      'Ich helfe Ihnen gerne beim Duschen. Das muss Ihnen nicht peinlich sein.'
  },
  pflegemaßnahme: {
    word: () => <span>Pflege&shy;maßnahme, die (-n)</span>,
    description:
      'eine pflegerische Handlung, mit der ein Pflegeziel erreicht werden soll.',
    example:
      'Wir besprechen die Pflegemaßnahmen im Team: Was machen wir wann, wie, wie oft, womit.'
  },
  phantomschmerz: {
    word: () => <span>Phantom&shy;schmerz, der (-en)</span>,
    description:
      'Schmerzen, die nach einer Amputation empfunden werden, obwohl das Körperteil entfernt wurde.',
    example:
      'Sie haben Schmerzen im Unterschenkel, Herr Teller? So einen Phantomschmerz fühlt die Hälfte der Patienten nach einer Amputation.'
  },
  pochend: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'pochend',
    description: 'pulsierend, klopfend',
    example: 'Frau Weikamp klagt über pochende Schmerzen im Oberbauch.'
  },
  postoperativ: {
    word: () => <span>postope&shy;rativ</span>,
    description: 'nach der Operation',
    example: 'Am ersten Tag postoperativ schlief Herr Merse von 17:00-5:00 Uhr.'
  },
  psyche: {
    word: 'Psyche, die (-n)',
    description: 'die mentale Verfassung',
    example: 'Die Psyche spielt bei der Genesung eine wichtige Rolle.'
  },
  psychisch: {
    word: 'psychisch',
    description: 'mental, geistig',
    example:
      'In welcher psychischen Verfassung ist der Herr Bremer? Weint er noch so viel wie gestern.'
  },
  pulsieren: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'pulsieren',
    description: 'rhythmisch schlagen, klopfen, dem Pulsschlag entsprechend',
    example: 'In meinem Kopf pulsiert das Blut. Das ist sehr unangenehm.'
  },

  reichhaltig: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>reich&shy;haltig</span>,
    description: 'üppig, gehaltvoll, kalorienreich',
    example:
      'Eva muss mindestens fünf Kilo zunehmen. Sie sollte reichhaltiges Essen zu sich nehmen.'
  },
  resigniert: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'resigniert',
    description: 'hoffnungslos, deprimiert, pessimistisch',
    example:
      'Die Patientin wirkt resigniert und glaubt nicht mehr an eine Heilung.'
  },
  ressource: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Ressource, die (-n)',
    description: 'Fähigkeiten und Kräfte, die die Genesung unterstützen',
    example:
      'Die Patientin isst regelmäßig und hat eine positive Grundeinstellung. Das sind ihre Ressourcen.'
  },
  rollstuhl: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Rollstuhl, der (-stühle)',
    description: 'ein Hilfsmittel zur Fortbewegung und Mobilisierung',
    example: 'Wir mobilisieren den Patienten in den Rollstuhl.'
  },

  rutschen: {
    word: 'rutschen',
    description: 'aus dem Bett rutschen: langsam aus dem Bett fallen',
    example: 'Er ist heute Morgen aus dem Bett gerutscht.'
  },

  schicht: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Schicht, die (-en)',
    description:
      'definierter Arbeitszeitraum im Krankenhaus: die Frühschicht, die Spätschicht, die Nachtschicht',
    example:
      'Wir arbeiten wie die meisten stationären Pflegekräfte im Drei-Schichtdienst: In der Früh-, Spät-, und Nachtschicht.'
  },
  schlapp: {
    word: 'schlapp',
    description: 'müde, energielos, kraftlos',
    example:
      'Die Patientin fühlt sich heute schlapp und möchte ihr Bett nicht verlassen.'
  },
  schmerzmittel: {
    word: () => <span>Schmerz&shy;mittel, das (-)</span>,
    description: 'Analgetikum (lat.), Medikamente gegen Schmerzen',
    example: 'Sie haben Schmerzen, richtig? Möchten Sie ein Schmerzmittel?'
  },
  siezen: {
    word: 'siezen + Akk',
    description: 'jemanden mit "Sie" ansprechen (formell)',
    example:
      'Hier bei uns siezen wir erwachsene Patienten und Angehörige. Also: "Sie, Frau Kleimann" und nicht "Du, Petra".'
  },
  sobald: {
    word: 'sobald',
    description: 'in dem Moment, wenn',
    example: 'Bitte informieren Sie mich, sobald der Patient wach ist.'
  },
  sorge: {
    word: 'Sorge, die (-n) / sich Sorgen machen um',
    description: 'Angst haben um, sich ängstigen um',
    example:
      'Ich kann verstehen, dass Sie sich große Sorgen um ihre Großmutter machen. Das müssen Sie nicht, denn die Temperatur ist jetzt auf 16°C. Also kein Problem.'
  },
  spannt: {
    word: 'spannen',
    description: 'sich unlocker und gedehnt anfühlen, ziehen',
    example: 'Die Haut um die Wunde ist sehr straff und spannt.'
  },
  spätschicht: {
    word: 'Spätschicht, die (-en)',
    description: 'Arbeitszeit am Nachmittag, oftmals 13:00 -19:00 Uhr',
    example: 'Super. Ich kann morgen ausschlafen, ich habe Spätschicht.'
  },
  spülung: {
    word: 'Spülung, die (-en)',
    description: 'Reinigung mit Kochsalzlösung',
    example:
      'Hast du die Wunde von Herrn Teller schon gereinigt und eine Spülung durchgeführt?'
  },
  station: {
    word: 'Station, die (-en)',
    description:
      'eine Sektion, Fachabteilung eines Krankenhauses z.B. Urologie, Innere Medizin, Geriatrie, Gynäkologie',
    example: 'Auf welcher Station arbeitest du?'
  },
  stationsarzt: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>Stations&shy;arzt / Stations&shy;ärztin</span>,
    description: 'der Arzt, der auf der Station die Patienten betreut',
    example:
      'Dr. Steidtmann arbeitet als Stationsärztin in der Pädiatrie. Sie betreut nur Kinder.'
  },
  stationsleitung: {
    word: () => <span>Stations&shy;leitung, die (-en)</span>,
    description: 'Fachkraft, die die Verantwortung auf der Station trägt',
    example:
      'Die Stationsleitung achtet darauf, dass die Hygienevorschriften eingehalten werden.'
  },
  stechen: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'stechen',
    description:
      'eine Tätigkeit, die eine Nadel beim Spritzen tut (stach, hat gestochen)',
    example: 'Es kann sein, dass es jetzt einmal kurz sticht.'
  },
  stechend: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'stechend',
    description: 'stark, scharf, schneidend',
    example: 'Ich habe einen stechenden Schmerz im rechten Ohr.'
  },
  stickig: {
    word: 'stickig',
    description: 'nicht frisch, verbrauchte Luft, wenig Sauerstoff',
    example:
      'Ein wenig frische Luft im Dienstzimmer wäre jetzt nicht schlecht. Es ist wirklich stickig. Ich öffne kurz das Fenster, ok?'
  },
  stumpf: {
    word: 'Stumpf, der (Stümpfe)',
    description:
      'der verbleibende Rest eines amputierten Körperteils: Oberarm, Oberschenkel',
    example: 'Der Stumpf hat eine große Wunde. Er muss gut versorgt werden.'
  },
  sturz: {
    word: 'Sturz, der (Stürze)',
    description: 'das Fallen zu Boden',
    example:
      'Bei dem Sturz im Treppenhaus hatte sich der Patient den Arm gebrochen.'
  },
  stürzen: {
    word: 'stürzen',
    description: '(hin-)fallen',
    example:
      'Die Patientin war ausgerutscht und gestürzt. Sie lag auf dem Boden.'
  },
  stützen: {
    word: 'stützen (sich) auf + Akk',
    description: 'Halt suchen, bekommen',
    example:
      'Stützen Sie sich auf den Rollator, wenn Ihnen schwindelig werden sollte.'
  },

  tapfer: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'tapfer',
    description:
      'trotz Schmerzen oder einer schwierigen Situation nicht klagen, nicht wehleidig sein',
    example: 'Sie beschweren sich gar nicht. Das finde ich tapfer! Super.'
  },

  umgangston: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Umgangston, der',
    description: 'die Art des Sprechens, ein guter oder schlechter Umgangston',
    example: 'Der Umgangston in unserem Team ist freundlich und kollegial.'
  },
  unauffällig: {
    word: 'unauffällig',
    description: 'ohne Befund, nicht pathologisch, normal, problemlos',
    example: 'Die Blutwerte der Patienten sind unauffällig.'
  },
  unerträglich: {
    word: 'unerträglich',
    description: 'nicht aushaltbar, zu stark, heftig',
    example:
      'Kann ich bitte ein Schmerzmittel bekommen? Die Schmerzen sind unerträglich.'
  },
  unterschenkel: {
    word: 'Unterschenkel, der (-)',
    description: 'Teil des Beins zwischen Knie und Fuß',
    example:
      'Ah, sein Bein ist verletzt. Hat er sich denn den Unter- oder den Oberschenkel gebrochen?'
  },
  unterstützen: {
    word: 'unterstützen + Akk',
    description: 'helfen, bei der Therapie, Reha mitmachen',
    example:
      'Bitte unterstützen Sie uns bei der Mobilisierung Ihrer Großmutter.'
  },
  übergabe: {
    word: 'Übergabe, die (-n)',
    description:
      'beim Wechsel der Schicht einen Austausch von Informationen mit Kollegen machen',
    example:
      'Hast du jetzt Zeit für die Übergabe? Es ist in der Nachtschicht viel passiert und ich möchte dir alle wichtigen Informationen weitergeben.'
  },

  verband: {
    word: 'Verband, der (-bände)',
    description: 'ein langes Stück Stoff für den Schutz der Wunde',
    example: 'Ihr Verband wird täglich gewechselt.'
  },
  verbandswechsel: {
    // NOTE: (DEN) not linked in the dialogues
    word: () => <span>Verbands&shy;wechsel, der (-)</span>,
    description: 'Das Austauschen eines Verbandes durch einen anderen',
    example: 'Der regelmäßige Verbandswechsel fördert den Heilungsprozess.'
  },
  verbinden: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'verbinden + Akk',
    description: '(verband, hat verbunden haben) etwas umwickeln zum Schutz',
    example: 'Ich verbinde nun Ihre Wunde.'
  },
  verheilen: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'verheilen',
    description:
      '(verheilte - verheilt sein) nicht mehr pathologisch, krank sondern gesund',
    example: 'Ihre Narbe ist aber schon gut verheilt, sehr schön!'
  },
  versorgen: {
    word: 'versorgen',
    description: 'sich um eine Wunde kümmern: desinfizieren, verbinden',
    example: 'Ich möchte jetzt Ihre Wunde versorgen.'
  },
  visite: {
    word: 'Visite, die (-n)',
    description:
      'ärztliche Visite: Ärztinnen/Ärzte und Pflegepersonal besuchen einmal täglich die Patientinnen und Patienten und besprechen den Genesungsprozess',
    example:
      'Der Arzt ist noch nicht von der Visite zurück. Die Visite dauert auf unserer Station meistens etwa eine Stunde.'
  },
  vitaminreich: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'vitaminreich',
    description: 'ein Nahrungsmittel enthält viele Vitamine',
    example: 'Der Obstsalat ist vitaminreich und lecker. Probieren Sie mal!'
  },
  vorschriftsmäßig: {
    word: () => <span>vorschrifts&shy;mäßig</span>,
    description: 'nach den Regeln',
    example: 'Ich habe die Wundheilung vorschriftsmäßig dokumentiert.'
  },
  vorsichtig: {
    word: 'vorsichtig',
    description: 'langsam, behutsam, nicht schnell',
    example: 'Nicht so schnell, bitte. Richten Sie sich vorsichtig auf.'
  },
  vorwurf: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Vorwurf, der (würfe)',
    description:
      'mitteilen, dass jemand etwas falsch/nicht richtig gemacht hat',
    example:
      'Mach der Patientin keine Vorwürfe, sondern erklär ihr nochmal, dass Sie die Tabletten regelmäßig nehmen muss.'
  },

  wechslen: {
    word: 'wechseln',
    description: 'tauschen, ersetzen',
    example:
      'Wir wechseln Ihren Verband regelmäßig, damit die Wunde besser heilt.'
  },
  wohl: {
    word: 'Wohl, das',
    description: 'gutes körperliches, seelisches Befinden',
    example:
      'Das Wohl der Patientinnen und Patienten steht selbstverständlich an erster Stelle.'
  },
  wund: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'wund',
    description:
      'verletzte Haut, entzündet, oftmals rot, oftmals durch Reibung',
    example:
      'Der Patient soll regelmäßig umgelagert werden, damit er sich nicht wund liegt.'
  },
  wundabstrich: {
    word: 'Wundabstrich, der (-e)',
    description:
      'Ein Wundabstrich dient zur Identifikation von Krankheitserregern, die eine Infektion verursachen könnten. Er wird mit dem Tupfer aus der Tiefe oder dem Rand der Wunde entnommen.',
    example:
      'Wir nehmen am besten jetzt einen Wundabstrich, um mögliche Erreger einer Infektion zu identifizieren.'
  },
  wunde: {
    word: 'Wunde, die (-en)',
    description: '(lat. die Läsion) ein verletztes Stück Haut',
    example:
      'Der Patient hatte sich verbrannt und hat daher eine Wunde an der Hand.'
  },
  wundheilung: {
    word: 'Wundheilung, die (-en)',
    description: 'Verbesserung des Wundzustandes',
    example: 'Sehr schön, die Wundheilung macht gute Fortschritte.'
  },
  wundrand: {
    word: 'Wundrand, der (-ränder)',
    description: 'nicht in der Mitte sondern am äußeren Teil der Wunde',
    example: 'Der Wundrand ist noch etwas gerötet.'
  },
  wundstatus: {
    word: 'Wundstatus, der ',
    description: 'der Zustand der Wunde,  die Wundheilungsphase',
    example: 'Der Wundstatus könnte besser sein, ich informiere den Arzt.'
  },
  wundverband: {
    // NOTE: (DEN) not linked in the dialogues
    word: 'Wundverband, die (-bände)',
    description: 'ein Schutz für die Wunde durch Abdecken',
    example:
      'Durch den Wundverband ist Ihre Wunde schön geschützt. Sie wird dann auch weniger weh tun, Frau Haber.'
  },

  zufriedenstellend: {
    word: () => <span>zufrieden&shy;stellend</span>,
    description: 'gut genug, okay',
    example: 'Die gesundheitliche Entwicklung ist zufriedenstellend.'
  },
  zuständig: {
    word: 'zuständig (für)',
    description: 'verantwortlich für etwas oder jemanden',
    example:
      'Der Krankengymnast ist zuständig für Ihre Beweglichkeit und wird Sie mit Übungen unterstützen.'
  },
  zweifeln: {
    word: 'zweifeln (an) ',
    description: 'etwas nicht glauben, kritisch sein',
    example: 'Sind Sie sicher? Ich nicht, ich zweifle an dieser Diagnose.'
  }
};
