import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { CustomMultipleChoice } from '../../../components/customMultipleChoice';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise5', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise6', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'adviceExercise6'));

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.adviceExercise3 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['advice', 'adviceExercise3'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Hilfsmittel anbieten',
      text: 'Jetzt sind Sie gefragt. Bei welcher Einschränkung wird was gebraucht?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          type: 'CLOZE',
          title: 'Wählen Sie aus.',
          text: `<strong>Hören Sie dann den Dialog zur Kontrolle</strong>.<br><br>
                 <i>Kann ich Ihnen helfen?</i><br>
                 Ja bitte, ich möchte etwas essen.<br>
                 <i>Soll ich Ihnen</i> {} <i>bringen?</i><br>
                 Danke, das ist nett.
                 `,
          items: [
            [
              { data: 'das Hörgerät', id: 'a' },
              { data: 'die Zahnprothese', meta: { correct: 1 }, id: 'b' },
              { data: 'die Lupe', id: 'c' }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u21/u21a-c_f2.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },

        {
          type: 'CLOZE',
          title: 'Wählen Sie aus.',
          text: `<strong>Hören Sie dann den Dialog zur Kontrolle.</strong><br><br>
                 <i>Kann ich Ihnen helfen?</i><br>
                 Ja bitte, meine Tochter kommt zu Besuch und ich bin so schwerhörig.<br>
                 <i>Soll ich Ihnen</i> {} <i>bringen?</i><br>
                 Danke, das ist nett.
                 `,
          items: [
            [
              { data: 'das Hörgerät', meta: { correct: 1 }, id: 'a' },
              { data: 'den Rollator', id: 'b' },
              { data: 'die Zahnprothese', id: 'c' }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u21/u21a-c_f3.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },

        {
          type: 'CLOZE',
          title: 'Wählen Sie aus.',
          text: `<strong>Hören Sie dann den Dialog zur Kontrolle.</strong><br><br>
                 <i>Kann ich Ihnen helfen?</i><br>
                 Ja bitte, mein Mann kommt und möchte mich in den Garten fahren.<br>
                 <i>Soll ich Ihnen</i> {} <i>bringen?</i><br>
                 Danke, das ist nett.
                 `,
          items: [
            [
              { data: 'die Brille', id: 'a' },
              { data: 'den Rollstuhl', meta: { correct: 1 }, id: 'b' },
              { data: 'den Toilettenstuhl', id: 'c' }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u21/u21a-c_f4.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },

        {
          type: 'CLOZE',
          title: 'Wählen Sie aus.',
          text: `<strong>Hören Sie dann den Dialog zur Kontrolle.</strong><br><br>
                 <i>Kann ich Ihnen helfen?</i><br>
                 Ja bitte, ich kann so schlecht laufen.<br>
                 <i>Soll ich Ihnen</i> {} <i>bringen?</i><br>
                 Danke, das ist nett.
                 `,
          items: [
            [
              { data: 'den Toilettenstuhl', id: 'a' },
              { data: 'die Kontaktlinsen', id: 'b' },
              { data: 'die Gehhilfe', meta: { correct: 1 }, id: 'c' }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u21/u21a-c_f5.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },

        {
          type: 'CLOZE',
          title: 'Wählen Sie aus.',
          text: `<strong>Hören Sie dann den Dialog zur Kontrolle.</strong><br><br>
                 <i>Kann ich Ihnen helfen?</i><br>
                 Ja bitte, ich muss auf die Toilette.<br>
                 <i>Soll ich Ihnen</i> {} <i>bringen?</i><br>
                 Danke, das ist nett.
                 `,
          items: [
            [
              { data: 'den Toilettenstuhl', meta: { correct: 1 }, id: 'a' },
              { data: 'die Lupe', id: 'b' },
              { data: 'die Gehhilfe', id: 'c' }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u21/u21a-c_f6.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
