import React, { Component } from 'react';

import styles from './styles.less';

class InfoBox extends Component {
  render() {
    const { heading, content, onClick } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.infoBox}>
          <div role="button" tabIndex={0} className={styles.closeButton} onClick={onClick} />
          <h1>
            {heading}
          </h1>
          <div className={styles.content}>
            {content}
          </div>
        </div>
      </div>
    );
  }
}

export default InfoBox;
