import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Howl } from 'howler';
import Animation from 'ainojs-animation';
import Easing from 'ainojs-easing';

import store from '../../store';
import { actions as glossaryActions } from '../../reducers/glossary';
import { actions as appActions } from '../../reducers/app';

import { Choice } from '../../components/choice';
import { Message } from '../../components/message';
import { Feedback } from '../../components/feedback';
import { Score } from '../../components/score';
import { PopUp } from '../../components/popUp';

import GLOSSARY from '../../config/glossary';
import INFO_BOX_ENTRIES from '../../config/infos';

import styles from './styles.less';

const mapStateToProps = ({ user, glossary, rewardSystem, steps }) => ({
  user,
  currentStep: steps.currentStep,
  currentInfo: glossary.currentInfo,
  rewardSystem
});

const mapDispatchToProps = {
  setCurrentInfo: glossaryActions.setCurrentInfo,
  setPage: appActions.setPage
};

class Dialogue extends Component {
  constructor(props) {
    super(props);

    const { flow, currentInfo } = props;

    this.state = {
      messages: [],
      messagesLength: 0,
      currentIndex: parseInt(Object.keys(flow)[0], 10),
      child: null,
      currentInfo
    };

    this.animation = new Animation({
      duration: 250,
      easing: Easing('ease')
    });

    this.sound = null;

    this.animation.on('frame', this.onFrame);

    this.animation.init({
      scrollTop: 0
    });
  }

  onFrame = e => {
    if (this.messages) {
      this.messages.scrollTop = e.values.scrollTop;
    }
  };

  componentDidUpdate() {
    this.scrollTimeout = setTimeout(() => {
      this.animation.animateTo({
        scrollTop: this.messages.scrollHeight - this.messages.offsetHeight
      });
    }, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimeout);
    this.animation = null;
    this.messages = null;
    if (this.sound) {
      this.sound.pause();
      this.sound = null;
    }
  }

  componentDidMount() {
    const { flow } = this.props;
    const { currentIndex } = this.state;
    const currentSnippet = get(flow, currentIndex);
    this.pushAndProceed(currentSnippet, currentSnippet.nextSnippet);
  }

  resetDialogue = () => {
    const { setPage, flow } = this.props;
    const { child } = this.state;
    if (child.endScreen) {
      this.setState({ loading: true });
      window.localForage.clear().then(() => {
        setPage('home');
        window.location = window.initialHref;
      });
    } else {
      this.setState({
        messages: [],
        messagesLength: 0,
        currentIndex: 0,
        child: null,
        currentInfo: ''
      });
      const currentSnippet = get(flow, 0);
      this.pushAndProceed(currentSnippet, currentSnippet.nextSnippet);
    }
  };

  render() {
    const { flow, currentInfo, difference } = this.props;
    const { currentIndex, child } = this.state;
    const currentSnippet = get(flow, currentIndex);
    const messages = [...this.state.messages];

    let background = '';
    background = currentSnippet.background;
    
    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.dialogue}
        style={{
          left: `${difference / 2}px`,
          right: `${difference / 2}px`
        }}
        onClick={() => {
          if (currentInfo === '' && (!child || child.type === 'choice')) {
            this.pushAndProceed(currentSnippet, currentSnippet.nextSnippet);
          }
        }}
      >
        <div
          className={styles.messages}
          style={{ backgroundImage: `url(${background})` }}
          ref={m => {
            this.messages = m;
          }}
        >
          {messages.map(this.renderMessage)}
        </div>
        {this.renderChild()}
        {this.renderPopUp()}
      </div>
    );
  }

  renderPopUp = () => {
    const { currentInfo, difference } = this.props;
    if (currentInfo) {
      const word =
        typeof GLOSSARY[currentInfo].word === 'function'
          ? GLOSSARY[currentInfo].word()
          : GLOSSARY[currentInfo].word;
      const description = GLOSSARY[currentInfo].description;
      const example = GLOSSARY[currentInfo].example;

      return (
        <PopUp
          onClick={() => {
            this.closePopUp();
          }}
          style={{
            left: `calc(${difference / 2}px + 20px)`,
            right: `calc(${difference / 2}px + 20px)`
          }}
          key={currentInfo}
          word={word}
          description={description}
          example={example}
        />
      );
    }
    return null;
  };

  closePopUp = () => {
    const { setCurrentInfo } = this.props;
    this.setState({ currentInfo: '' });
    setCurrentInfo('');
  };

  renderChild = () => {
    const { child } = this.state;
    if (child) {
      if (child.type === 'score') {
        const { rewardSystem, currentStep } = this.props;
        return (
          <Score
            image={child.image()}
            text={child.text}
            maxPoints={rewardSystem.max[currentStep]}
            currentPoints={rewardSystem[currentStep]}
            onClick={child.onClick}
          />
        );
      }
      if (child.type === 'feedback') {
        return (
          <Feedback
            text={child.text}
            onClick={child.onClick}
            resetDialogue={this.resetDialogue}
            correct={child.correct}
            endScreen={child.endScreen}
            unlockedEntries={child.unlockedEntries}
            infoBoxEntries={INFO_BOX_ENTRIES}
          />
        );
      }
      if (child.type === 'choice') {
        return (
          <div className={styles.choiceContainer}>
            <Choice
              question={child.question || ''}
              answers={child.answers}
              styles={{
                question: {
                  display: child.question ? 'inherit' : 'none'
                }
              }}
            />
          </div>
        );
      }
    }
    return <span className={styles.touchHint}>Berühren um fortzufahren</span>;
  };

  renderMessage = (message, index) => {
    const { speakers } = this.props;
    const speaker = speakers[message.from];
    switch (message.type) {
      default:
        return (
          <Message
            key={index}
            theme={speaker.theme}
            message={message.text}
            audio={message.audio}
            avatar={speaker.avatar()}
            isThought={message.type === 'thought'}
          />
        );
    }
  };

  pushAndProceed = (currentSnippet, nextSnippetIndex = false) => {
    // TODO: (DEN) recode
    if (store.getState().glossary.currentInfo !== '') {
      return null;
    }

    this.setState({ child: null });

    if (currentSnippet.after) {
      setTimeout(currentSnippet.after.cb, currentSnippet.after.delay);
    }

    if (currentSnippet.audio) {
      const { user } = this.props;
      let speaker = 'ai';
      if (currentSnippet.from === 'player') {
        speaker = user.sex || 'female';
      }

      const path = require(`../../static/audio/dialogues/${speaker}/${currentSnippet.audio}`);
      if (this.sound) {
        this.sound.pause();
      }
      this.sound = new Howl({
        src: [path]
      });
      this.sound.play();
    }
    if (currentSnippet.type === 'feedback') {
      this.setState({
        messages: [],
        currentIndex: 0,
        child: currentSnippet
      });
    } else if (currentSnippet.type === 'score') {
      this.setState({ child: currentSnippet });
    } else if (currentSnippet.type === 'choice') {
      const { flow } = this.props;
      let answers = currentSnippet.answers;
      if (typeof answers === 'function') answers = answers();

      answers = answers.map(answer => ({
        ...answer,
        onClick: () => {
          if (answer.goTo) {
            let nextIndex = answer.goTo.toString().replace('.', '.flow.');
            const nextSnippet = get(flow, nextIndex);

            if (nextSnippet.type === 'flow') {
              nextIndex = `${nextIndex}.flow.${Object.keys(
                nextSnippet.flow
              )[0]}`;
            }
            if (answer.renderQuestion === false) {
              this.setState({
                currentIndex: nextIndex
              });
              this.pushAndProceed(nextSnippet, nextSnippet.nextSnippet);
            } else {
              this.pushAndProceed(
                {
                  from: 'player',
                  text: answer.data,
                  audio: answer.audio
                },
                answer.goTo
              );
            }
          }

          if (typeof answer.onClick === 'function') {
            answer.onClick();
          }
        }
      }));
      this.setState({
        child: { ...currentSnippet, answers }
      });
    } else {
      const { currentIndex, messages } = this.state;
      const sections = currentIndex.toString().split('.');

      sections[sections.length - 1] =
        nextSnippetIndex || parseInt(sections[sections.length - 1], 10) + 1;
      this.setState({
        messages: currentSnippet ? [...messages, currentSnippet] : messages,
        currentIndex: sections.join('.')
      });
    }
  };
}

Dialogue.propTypes = {
  flow: PropTypes.object.isRequired
};

Dialogue.defaultProps = {
  flow: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Dialogue);
