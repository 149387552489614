import { Map } from 'immutable';

import { createReducer, createActionTypes } from '../lib/redux';
import DEFAULT_PAGE, { VERSIONS } from '../constants';

let CURRENT_PAGE = window.location.pathname.replace(/^\//, '');
CURRENT_PAGE = CURRENT_PAGE.replace('passage/demo/', '');

const defaultState = Map({
  page:
    CURRENT_PAGE.length > 2 && CURRENT_PAGE.indexOf('.') <= -1
      ? CURRENT_PAGE
      : DEFAULT_PAGE,
  ready: false,
  version: VERSIONS.TEACHER
});

const types = createActionTypes(['SET_PAGE', 'SET_READY', 'SET_VERSION'], '');

const actions = {
  setPage: (page, options = {}) => ({ type: types.SET_PAGE, page, options }),
  setReady: (ready = true) => ({ type: types.SET_READY, ready }),
  setVersion: version => ({ type: types.SET_VERSION, version })
};

const reducer = createReducer(defaultState, {
  [types.SET_PAGE]: (state, { page, options }) => {
    const currentPage = state.get('page');

    if (currentPage !== page) {
      if (!options.ignoreHistory)
        window.history.pushState(page, document.title, page);

      return state.merge({
        page
      });
    }

    return state;
  },
  [types.SET_READY]: (state, { ready }) => {
    if (ready !== state.get('ready')) {
      return state.merge({ ready });
    }

    return state;
  },
  [types.SET_VERSION]: (state = defaultState, { version }) => {
    if (version !== state.get('version')) {
      return state.merge({ version });
    }

    return state;
  }
});

export { reducer, actions };
