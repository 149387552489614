import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

import { Speaker } from '../../../static/icons/Speaker';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise6', 'overlay'],
      false
    )
  );
}

const style = { display: 'flex', alignItems: 'center' };

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Hilfsmittel anbieten',
      text: 'Im Gespräch mit dem Patienten.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f1.mp3')
                },
                'Kann ich Ihnen helfen?'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a1.mp3')
                },
                'Ja bitte, ich möchte etwas essen.'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f2.mp3')
                },
                'Soll ich Ihnen die Zahnprothese bringen?'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a4.mp3')
                },
                'Danke, das ist nett.'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f1.mp3')
                },
                'Kann ich Ihnen helfen?'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a3.mp3')
                },
                'Ja bitte, meine Tochter kommt zu Besuch und ich bin so schwerhörig.'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f3.mp3')
                },
                'Soll ich Ihnen das Hörgerät bringen?'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a4.mp3')
                },
                'Danke, das ist nett.'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f1.mp3')
                },
                'Kann ich Ihnen helfen?'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a5a.mp3')
                },
                'Ja bitte, mein Mann kommt und möchte mich in den Garten fahren.'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f4.mp3')
                },
                'Soll ich Ihnen den Rollstuhl bringen?'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a4.mp3')
                },
                'Danke, das ist nett.'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f1.mp3')
                },
                'Kann ich Ihnen helfen?'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a6.mp3')
                },
                'Ja bitte, ich kann so schlecht laufen.'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f5.mp3')
                },
                'Soll ich Ihnen die Gehhilfe bringen?'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a4.mp3')
                },
                'Danke, das ist nett.'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Sätze und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f1.mp3')
                },
                'Kann ich Ihnen helfen?'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a7.mp3')
                },
                'Ja bitte, ich muss auf die Toilette.'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_f6.mp3')
                },
                'Soll ich Ihnen den Toilettenstuhl bringen?'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_a4.mp3')
                },
                'Danke, das ist nett.'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
