import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { Speaker } from '../../../static/icons/Speaker';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'exercises', 'accidentExercise2', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.accidentExercise2 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['accident', 'accidentExercise2'])
    );
  }
}

const style = { display: 'flex', alignItems: 'center' };

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Fachbegriffe mit st, ür und rz',
      text:
        'Phrasen mit vielen Konsonantenpaaren sind oft eine Herausforderung. Trainieren Sie hier einige Kombinationen und korrigieren Sie sich selbst!',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Phrasen und sprechen Sie nach.',
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u39/u39_1.mp3')
                },
                'gestolpert und schlimm gestürzt'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u39/u39_2.mp3')
                },
                'ausgerutscht und schwer verletzt'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u39/u39_3.mp3')
                },
                'auf linkes Bein gestützt und Stumpf nicht belastet'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u39/u39_4.mp3')
                },
                'unterstützt und in den Rollstuhl gesetzt'
              ]
            },
            {
              id: 5,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u39/u39_5.mp3')
                },
                'Unterarmgehstützen als Hilfsmittel eingesetzt'
              ]
            },
            {
              id: 6,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u39/u39_6.mp3')
                },
                'Rettungsdienst gerufen und Notarzt informiert'
              ]
            },
            {
              id: 7,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u39/u39_7.mp3')
                },
                'Entzündungswerte gecheckt und Vitalzeichen kontrolliert'
              ]
            },
            {
              id: 8,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u39/u39_8.mp3')
                },
                'Sturzprotokoll ergänzt und Pflegebericht erstellt '
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
