import React from 'react';

export const Speaker = ({ size, className, fill }) =>
  <svg
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style={{ width: size, height: size }}
    className={className}
    fill={fill}
  >
    <path d="M53.5,14.8L27,36H12.8C10.7,36,9,37.7,9,39.8v23.4c0,2.1,1.7,3.8,3.8,3.8h18l22.7,18.1c1.4,1.1,3.5,0.1,3.5-1.7V16.5 C57,14.7,54.9,13.7,53.5,14.8z" />
    <g>
      <path d="M66.4,50c0,4.4-1.8,8.3-4.7,11.2l3.1,3.1c3.7-3.7,5.9-8.7,5.9-14.3s-2.3-10.6-5.9-14.3l-3.1,3.1 C64.6,41.7,66.4,45.6,66.4,50z" />
      <path d="M78.8,50c0,7.8-3.2,14.9-8.3,20l3.1,3.1c5.9-5.9,9.6-14.1,9.6-23.1s-3.7-17.2-9.6-23.1L70.5,30 C75.7,35.1,78.8,42.2,78.8,50z" />
      <path d="M90.6,50c0,11.1-4.5,21.1-11.8,28.3l3.1,3.1c8-8,13-19.2,13-31.4s-5-23.4-13-31.4l-3.1,3.1C86.2,28.9,90.6,38.9,90.6,50z" />
    </g>
  </svg>;
