import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { CustomMultipleChoice } from '../../../components/customMultipleChoice';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'coffeemaschineExercise2', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.coffeemaschineExercise2 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['coffee', 'coffeemaschineExercise2'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Höflich fragen',
      text: `Besonders freundlich kommunizieren Sie mit indirekten Fragesätzen.
             Statt „Wo finde ich die Augentropfen?“ würden Sie fragen: „Könnten Sie mir bitte sagen, wo ich die Augentropfen finde?“
             „War die Ehefrau heute schon zu Besuch“ wird zu: „Könnten Sie mir bitte sagen, ob die Ehefrau heute schon zu Besuch war?“.`,
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Frage um. Hören Sie dann zur Kontrolle.',
          text: `Beispiel:<br>
                 <i>War die Ehefrau heute schon zu Besuch?<br>
                 Könnten Sie mir bitte sagen, ob die Ehefrau heute schon zu Besuch war?</i><br><br>
                 Hat der Patient seine Medikamente schon genommen?<br>
                 Könnten Sie mir bitte sagen, ob der Patient seine Medikamente {}?`,
          items: [
            [
              {
                type: 'INPUT',
                meta: { correct: 'schon genommen hat' },
                id: 'a'
              }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u14/u14_1.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Frage um. Hören Sie dann zur Kontrolle.',
          text: `Beispiel:<br>
								 <i>Wo finde ich die Augentropfen?<br>
								 Könnten Sie mir bitte sagen, wo ich die Augentropfen finde?</i><br><br><br><br>
                 Wann wird der Patient operiert?<br />
                 Könnten Sie mir bitte sagen, wann der Patient {}?`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'operiert wird' }, id: 'a' }]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u14/u14_2.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Frage um. Hören Sie dann zur Kontrolle.',
          text: `Beispiel:<br>
								 <i>Wo finde ich die Augentropfen?<br>
								 Könnten Sie mir bitte sagen, wo ich die Augentropfen finde?</i><br><br>
                 Darf der Patient bestimmte Nahrungsmittel nicht essen?<br>
                 Könnten Sie mir bitte sagen, ob der Patient bestimmte Nahrungsmittel {}?`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'nicht essen darf' }, id: 'a' }]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u14/u14_3.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Formulieren Sie die Frage um. Hören Sie dann zur Kontrolle.',
          text: `Beispiel:<br>
					       <i>Wo finde ich die Augentropfen?<br>
								 Könnten Sie mir bitte sagen, wo ich die Augentropfen finde?</i><br><br>
                 Wie hoch war der Blutdruck von Frau Temme heute Morgen?<br>
                 Könnten Sie mir bitte sagen, wie hoch der Blutdruck von Frau Temme {}?`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'heute Morgen war' }, id: 'a' }]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u14/u14_4.mp3')
                      },
                      'Hören Sie sich die Lösung an.'
                    ]
                  }
                ]}
              />
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
