import React from 'react';

export const Grammar = ({ size, className, fill }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    style={{ width: size, height: size }}
  >
    <title>EinTagDeutsch_Icons-Übungstypen</title>
    <g id="Layer_2" data-name="Layer 2">
      <path
        d="M29.55,36.62H16.84l-1.77,6H3.65L17.27,6.38H29.47L43.09,42.6H31.37Zm-2.32-7.83-4-13-4,13Z"
        fill="#79ada3"
      />
      <path
        d="M39.5,32.67a8.6,8.6,0,1,1,8.6-8.6A8.61,8.61,0,0,1,39.5,32.67Z"
        fill="#afd0ca"
      />
      <path
        d="M39.5,16a8.1,8.1,0,1,1-8.1,8.1A8.1,8.1,0,0,1,39.5,16m0-1a9.1,9.1,0,1,0,9.1,9.1A9.11,9.11,0,0,0,39.5,15Z"
        fill="#fff"
      />
      <path
        d="M39,28.87l-4.18-3A1.07,1.07,0,0,1,36,24.14l2.49,1.78,3.9-5.13a1.07,1.07,0,1,1,1.71,1.3Z"
        fill="#fff"
      />
    </g>
  </svg>;
