import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as appActions } from '../../../reducers/app';
import { VERSIONS } from '../../../constants';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'farewell', 'overlay'],
      false
    )
  );
  
  if(store.getState().app.get('version') === VERSIONS.STUDENT) {
    store.dispatch(stepsActions.set(['currentStep'], 'step2'));
    store.dispatch(appActions.setPage('steps'));
    store.dispatch(stepsActions.set(['currentRoom'], 'office'));
  }
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Gut, Herr Teller, ich schaue dann später nochmal vorbei. Und Ihre Frau kommt ja sicher gleich.',
      audio: '018-Verlassen_ID_1.mp3',
      nextSnippet: '1'
    },
    1: {
      from: 'ai',
      text: 'Ja, ja. Und nach Feierabend auch noch ein Kollege.',
      audio: '018-Verlassen_ID_2.mp3',
      nextSnippet: '2'
    },
    2: {
      from: 'player',
      text: 'Na, Sie haben es aber gut. So viele Besucher! Was machen Sie eigentlich beruflich, Herr Teller?',
      audio: '018-Verlassen_ID_3.mp3',
      nextSnippet: '3'
    },
    3: {
      from: 'ai',
      text: 'Ich bin Mechatroniker.',
      audio: '018-Verlassen_ID_4.mp3',
      nextSnippet: '4'
    },
    4: {
      from: 'player',
      text: 'Ah, interessant. Und wo arbeiten Sie?',
      audio: '018-Verlassen_ID_5.mp3',
      nextSnippet: '5'
    },
    5: {
      from: 'ai',
      text: 'In der Automobilbranche.',
      audio: '018-Verlassen_ID_6.mp3',
      nextSnippet: '6'
    },
    6: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
