import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as infoboxActions } from '../../../reducers/infobox';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

import styles from '../styles.less';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'checkWound', 'overlay'],
      false
    )
  );
}

function finishDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'checkWound', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'checkWound', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'activity', 'overlay'],
      true
    )
  );

  store.dispatch(exerciseSetActions.setCurrentExerciseSet('checkWound'));
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step1', 'choices', 'informedFeedback'], 'init')
  );
  store.dispatch(
    stepsActions.set(['step1', 'choices', 'informedPatient'], false)
  );
  store.dispatch(stepsActions.set(['step1', 'choices', 'disinfected'], false));
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question: 'Jetzt sehe ich mir die Wunde mal an. Was mache ich zuerst?',
      answers: () => {
        const isDisinfected = store.getState().steps.step1.choices.disinfected;
        const isWindowClosed = store.getState().steps.step1.choices
          .windowClosed;
        const isPatientInformed = store.getState().steps.step1.choices
          .informedPatient;
        const informedFeedback = store.getState().steps.step1.choices
          .informedFeedback;

        let changeBandage = '0-1-1';
        if (isDisinfected) {
          if (!isWindowClosed) {
            changeBandage = '0-4-1';
          } else if (isPatientInformed) {
            changeBandage = 2;
          } else if (!isWindowClosed) {
            changeBandage = '0-4-1';
          } else {
            changeBandage = '0-3-1';
          }
        } else if (!isWindowClosed) changeBandage = '0-5-1';

        return [
          {
            id: 'a',
            goTo: changeBandage,
            data: 'den Verband wechseln',
            onClick: () => {
              if (informedFeedback === 'init') {
                store.dispatch(
                  stepsActions.set(
                    ['step1', 'choices', 'informedFeedback'],
                    false
                  )
                );
              }
            },
            renderQuestion: false
          },
          {
            id: 'b',
            goTo: '0-2-1',
            data: 'den Patienten informieren',
            renderQuestion: false,
            onClick: () => {
              if (informedFeedback === 'init') {
                store.dispatch(
                  stepsActions.set(
                    ['step1', 'choices', 'informedFeedback'],
                    true
                  )
                );
              }
              store.dispatch(
                stepsActions.set(['step1', 'choices', 'informedPatient'], true)
              );
            },
            className: isPatientInformed === true ? styles.disabled : ''
          },
          {
            id: 'c',
            onClick: () => {
              store.dispatch(
                stepsActions.set(
                  [
                    'step1',
                    'hospitalRoom',
                    'dialogues',
                    'closeWindow',
                    'overlay'
                  ],
                  true
                )
              );
              store.dispatch(
                stepsActions.set(['currentDialogue'], 'closeWindow')
              );
            },
            data: 'das Fenster schließen',
            renderQuestion: false,
            className: isWindowClosed ? styles.hideChoice : ''
          },
          {
            id: 'd',
            onClick: () => {
              exitDialogue();
              store.dispatch(
                stepsActions.set(['currentDialogue'], 'disinfection')
              );
              store.dispatch(
                stepsActions.set(
                  [
                    'step1',
                    'hospitalRoom',
                    'dialogues',
                    'disinfection',
                    'overlay'
                  ],
                  true
                )
              );
            },
            data: 'die Hände desinfizieren',
            renderQuestion: false,
            className: isDisinfected ? styles.disabled : ''
          }
        ];
      }
    },
    '0-1-1': {
      from: 'player',
      type: 'thought',
      text: 'Oh, ich muss mir ja noch die Hände desinfizieren. Das habe ich total vergessen!',
      audio: '004-Nicht-Desinfiziert_ID_1.mp3',
      nextSnippet: '0-1-2'
    },
    '0-1-2': {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: '0',
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },
    '0-2-1': {
      from: 'player',
      text: () =>
        <span>
          So, Herr Teller, ich löse jetzt den{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('verband'));
            }}
          >
            Verband
          </span>{' '}
          und dann{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('versorgen'));
            }}
          >
            versorge
          </span>{' '}
          ich in Ruhe Ihre Wunde.
        </span>,
      audio: '003-Informieren_ID_1.mp3',
      nextSnippet: '0-2-2'
    },
    '0-2-2': {
      from: 'ai',
      text: 'Alles klar, machen Sie nur.',
      audio: '003-Informieren_ID_2.mp3',
      nextSnippet: '0-1-2'
    },
    '0-3-1': {
      from: 'ai',
      text: 'Was machen Sie denn da?',
      audio: '006-Wechseln (ohne Informieren)_ID_1.mp3',
      nextSnippet: '0-3-2'
    },
    '0-3-2': {
      from: 'player',
      text: 'Stimmt, ich muss ihn erst mal informieren.',
      audio: '006-Wechseln (ohne Informieren)_ID_2.mp3',
      type: 'thought',
      nextSnippet: '0-3-3'
    },
    '0-3-3': {
      from: 'player',
      text: () =>
        <span>
          So, Herr Teller, ich löse jetzt den Verband und dann versorge ich in
          Ruhe Ihre{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wunde'));
            }}
          >
            Wunde
          </span>
          .
        </span>,
      audio: '003-Informieren_ID_1.mp3',
      nextSnippet: '0-3-4'
    },
    '0-3-4': {
      from: 'ai',
      text: 'Ach so, ja gut.',
      audio: '006-Wechseln (ohne Informieren)_ID_4.mp3',
      nextSnippet: '0-3-5'
    },
    '0-3-5': {
      from: 'player',
      text: 'Tut es weh, wenn ich den Verband abmache?',
      audio: '006-Wechseln (ohne Informieren)_ID_5.mp3',
      nextSnippet: '0-3-6'
    },
    '0-3-6': {
      from: 'ai',
      text: 'Ja, es ist unangenehm.',
      audio: '006-Wechseln (ohne Informieren)_ID_6.mp3',
      nextSnippet: '2'
    },
    '0-4-1': {
      from: 'player',
      text: () =>
        <span>
          Ach ja, bevor ich den Verband{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wechslen'));
            }}
          >
            wechsle
          </span>
          , muss ich unbedingt das Fenster schließen. Das habe ich total
          vergessen!
        </span>,
      audio: '020-Fenster-Nicht-Geschlossen_ID_1.mp3',
      type: 'thought',
      nextSnippet: '0-1-2'
    },
    '0-5-1': {
      from: 'player',
      text: () =>
        <span>
          Ach ja, bevor ich den Verband{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wechslen'));
            }}
          >
            wechsle
          </span>
          , muss ich unbedingt das Fenster schließen. Das habe ich total
          vergessen!
        </span>,
      audio: '021-Fenster-Desinfektion_ID_1.mp3',
      type: 'thought',
      nextSnippet: '0-5-2'
    },
    '0-5-2': {
      from: 'player',
      text: 'Und außerdem muss ich mir ja noch die Hände desinfizieren. Das habe ich auch total vergessen!',
      audio: '021-Fenster-Desinfektion_ID_2.mp3',
      type: 'thought',
      nextSnippet: '0-1-2'
    },
    2: {
      from: 'player',
      text: () =>
        <span>
          Die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wundheilung'));
            }}
          >
            Wundheilung
          </span>{' '}
          verläuft nicht optimal. Wie schaffe ich es, Herrn Teller darüber zu
          informieren, ohne ihm Angst zu machen?
        </span>,
      audio: '005-Wechseln_ID_1.mp3',
      type: 'thought'
    },
    3: {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '3-1-1',
          data:
            'Hm. Die Wundheilung könnte besser sein. Da sollten wir etwas unternehmen.',
          audio: '005-Wechseln_ID_3.mp3',
          renderQuestion: false
        },
        {
          id: 'b',
          goTo: '3-2-1',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step1', 'choices', 'informedFeedback'], false)
            );
          },
          data: 'Das sieht aber gar nicht gut aus.',
          audio: '005-Wechseln_ID_4.mp3'
        },
        {
          id: 'c',
          goTo: '3-3-1',
          data: () =>
            <span>
              Die{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('wundrand'));
                }}
              >
                Wundränder
              </span>{' '}
              sehen{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('entzündet'));
                }}
              >
                entzündet
              </span>{' '}
              aus. Wie fühlt sich das für Sie an?
            </span>,
          audio: '005-Wechseln_ID_2.mp3'
        }
      ]
    },
    '3-1-1': {
      from: 'player',
      text: 'Die Wundheilung könnte besser sein. Da sollten wir was unternehmen.',
      audio: '005-Wechseln_ID_3.mp3',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'ai',
      text: 'Ist das jetzt schlimm?',
      audio: '005-Wechseln_ID_11.mp3',
      nextSnippet: '3-1-3',
      type: 'thought'
    },
    '3-1-3': {
      from: 'ai',
      text: 'Was bedeutet das denn?',
      audio: '005-Wechseln_ID_7.mp3',
      nextSnippet: '3-1-4'
    },
    '3-1-4': {
      from: 'player',
      text: 'Dazu kann ich eigentlich gar nichts sagen.',
      audio: '005-Wechseln_ID_8.mp3',
      nextSnippet: '3-1-5'
    },
    '3-1-5': {
      from: 'player',
      text: () =>
        <span>
          Die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wundrand'));
            }}
          >
            Wundränder
          </span>{' '}
          sehen entzündet aus. Wie fühlt sich das für Sie an?
        </span>,
      audio: '005-Wechseln_ID_2.mp3',
      nextSnippet: '3-3-1'
    },
    '3-2-1': {
      from: 'ai',
      text: 'Oh je, auch das noch.',
      audio: '005-Wechseln_ID_6.mp3',
      nextSnippet: '3-1-3',
      type: 'thought'
    },
    '3-3-1': {
      from: 'ai',
      text: () =>
        <span>
          Na ja, es{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('spannt'));
            }}
          >
            spannt
          </span>{' '}
          immer noch und die Schmerzen hören einfach nicht auf.
        </span>,
      audio: '005-Wechseln_ID_16.mp3',
      nextSnippet: '3-3-2'
    },
    '3-3-2': {
      from: 'player',
      text: 'Auf der Schmerzskala: Wo sind Ihre Schmerzen gerade?',
      audio: '005-Wechseln_ID_17.mp3',
      nextSnippet: '3-3-3'
    },
    '3-3-3': {
      from: 'ai',
      text: () =>
        <span>
          Vorne am{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('stumpf'));
            }}
          >
            Stumpf
          </span>
          .
        </span>,
      audio: '005-Wechseln_ID_18.mp3',
      nextSnippet: '3-3-4'
    },
    '3-3-4': {
      from: 'player',
      text: () =>
        <span>
          Ich meinte, wie hoch schätzen Sie Ihre Schmerzen auf einer Skala von
          0-10 ein? 0 bedeutet keine Schmerzen und bei 10 sind die Schmerzen{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('unerträglich'));
            }}
          >
            unerträglich
          </span>
          .
        </span>,
      audio: '005-Wechseln_ID_19.mp3',
      nextSnippet: '3-3-5'
    },
    '3-3-5': {
      from: 'ai',
      text: () =>
        <span>
          Ach so, ich würde sagen 5. Und es{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('kribbelt'));
            }}
          >
            kribbelt
          </span>{' '}
          sehr stark. Außerdem ist der{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('phantomschmerz'));
            }}
          >
            Phantomschmerz
          </span>{' '}
          immer noch da.
        </span>,
      audio: '005-Wechseln_ID_20.mp3',
      nextSnippet: '3-3-6'
    },
    '3-3-6': {
      from: 'player',
      text: 'Ja, das ist bestimmt unangenehm. Es kann bei so einer Operation eine Weile dauern.',
      audio: '005-Wechseln_ID_21.mp3',
      nextSnippet: '3-3-7'
    },
    '3-3-7': {
      from: 'player',
      text: () =>
        <span>
          Ich reinige die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wunde'));
            }}
          >
            Wunde
          </span>{' '}
          jetzt und lege Ihnen dann einen frischen Verband an.
        </span>,
      audio: '005-Wechseln_ID_22.mp3',
      nextSnippet: '3-3-8'
    },
    '3-3-8': {
      from: 'player',
      text: () =>
        <span>
          Darf ich vorher noch ein paar Fotos für die Wunddoku&shy;mentation
          machen?
        </span>,
      audio: '005-Wechseln_ID_23.mp3',
      nextSnippet: '3-3-9'
    },
    '3-3-9': {
      from: 'ai',
      text: 'Ja, natürlich. Und wie geht’s dann mit mir weiter?',
      audio: '005-Wechseln_ID_24.mp3',
      nextSnippet: '4'
    },
    4: {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '4-1-1',
          data:
            'Ich spreche gleich mit der Wundmanagerin, meiner Kollegin mit zusätzlicher Wundqualifikation.',
          audio: '005-Wechseln_ID_26.mp3',
          renderQuestion: false
        },
        {
          id: 'b',
          goTo: '4-2-1',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step1', 'choices', 'informedFeedback'], false)
            );
          },
          data:
            'Ich werde die Wundmanagerin fragen, die kennt sich besser aus als ich.',
          audio: '005-Wechseln_ID_27.mp3'
        }
      ]
    },

    '4-1-1': {
      from: 'player',
      text: 'Ich spreche gleich mit der Wundmanagerin, meiner Kollegin mit zusätzlicher Wundqualifikation.',
      audio: '005-Wechseln_ID_26.mp3',
      nextSnippet: '4-1-2'
    },
    '4-1-2': {
      from: 'ai',
      text: 'Ah ja, gut. Das beruhigt mich.',
      audio: '005-Wechseln_ID_29.mp3',
      nextSnippet: '4-1-3'
    },
    '4-1-3': {
      from: 'player',
      text: 'Kann ich sonst noch etwas für Sie tun? Möchten Sie etwas gegen die Schmerzen?',
      audio: '005-Wechseln_ID_32.mp3',
      nextSnippet: '4-1-4'
    },
    '4-1-4': {
      from: 'ai',
      text: 'Ja, bitte.',
      audio: '005-Wechseln_ID_33.mp3',
      nextSnippet: '4-1-5'
    },
    '4-1-5': {
      from: 'player',
      text: () =>
        <span>
          In Ordnung. Dann schaue ich in Ihre{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('krankenakte'));
            }}
          >
            Krankenakte
          </span>
          , um zu sehen, welche{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('schmerzmittel'));
            }}
          >
            Schmerzmittel
          </span>{' '}
          angeordnet sind.
        </span>,
      audio: '005-Wechseln_ID_34.mp3',
      nextSnippet: '4-1-6'
    },
    '4-1-6': {
      from: 'player',
      text: 'Ich bringe sie Ihnen dann sofort. Danach werden Sie sich schnell besser fühlen.',
      audio: '005-Wechseln_ID_35.mp3',
      nextSnippet: '4-1-7'
    },
    '4-1-7': {
      from: 'player',
      text: () =>
        <span>
          Zusätzlich{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('lagere'));
            }}
          >
            lagere
          </span>{' '}
          ich Ihren{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('stumpf'));
            }}
          >
            Stumpf
          </span>{' '}
          auf einem kleinen Kissen.
        </span>,
      audio: '005-Wechseln_ID_36.mp3',
      nextSnippet: '4-1-8'
    },
    '4-1-8': {
      from: 'ai',
      text: 'Vielen Dank.',
      audio: '005-Wechseln_ID_37.mp3',
      nextSnippet: '4-1-9'
    },
    '4-1-9': {
      from: 'player',
      text: 'Gerne! Jetzt ruhen Sie sich erst mal ein bisschen aus.',
      audio: '005-Wechseln_ID_38.mp3',
      after: {
        cb: () => {
          store.dispatch(
            infoboxActions.unlock(['schmerzskala', 'kommunikation'])
          );
        }
      },
      nextSnippet: 'fin'
    },
    '4-2-1': {
      from: 'ai',
      text: 'Oh. Bin ich hier in guten Händen?',
      audio: '005-Wechseln_ID_31.mp3',
      type: 'thought',
      nextSnippet: '4-1-3'
    },
    fin: {
      type: 'choice',
      answers: () => {
        const informedFeedback = store.getState().steps.step1.choices
          .informedFeedback;
        const feedback = informedFeedback === true ? '5-1' : '5-2';
        return [
          {
            id: 'a',
            goTo: feedback,
            onClick: () => {
              resetChoices();
            },
            data: 'Weiter',
            renderQuestion: false
          }
        ];
      }
    },

    '5-1': {
      type: 'feedback',
      text:
        'Sehr gut! Sie haben Herrn Teller umfassend informiert und ihn nicht verunsichert.',
      onClick: () => {
        finishDialogue();
      },
      unlockedEntries: ['schmerzskala', 'kommunikation'],
      correct: true
    },
    '5-2': {
      type: 'feedback',
      text:
        'Denken Sie daran, Ihre Handlungen immer sprachlich so zu begleiten, dass Sie die Patienten nicht verunsichern.',
      onClick: () => {
        finishDialogue();
      },
      unlockedEntries: ['schmerzskala', 'kommunikation'],
      correct: false
    }
  }
};
