export default {
  step1: {
    hospitalRoom: {
      greeting: require('./hospitalRoom/greeting').default,
      disinfection: require('./hospitalRoom/disinfection').default,
      checkWound: require('./hospitalRoom/checkWound').default,
      openWindow: require('./hospitalRoom/openWindow').default,
      closeWindow: require('./hospitalRoom/closeWindow').default,
      family: require('./hospitalRoom/family').default,
      food: require('./hospitalRoom/food').default,
      activity: require('./hospitalRoom/activity').default,
      activitySecondPart: require('./hospitalRoom/activitySecondPart').default,
      farewell: require('./hospitalRoom/farewell').default,
      step1Score: require('./hospitalRoom/step1Score').default
    }
  },
  step2: {
    office: {
      firstThoughts: require('./office/firstThoughts').default,
      coffee: require('./office/coffee').default,
      advice: require('./office/advice').default,
      dietaryAssistant: require('./office/dietaryAssistant').default
    },
    corridor: {
      firstThoughtsCorridor: require('./corridor/firstThoughtsCorridor')
        .default,
      mrsTeller: require('./corridor/mrsTeller').default,
      step2Score: require('./corridor/step2Score').default
    }
  },
  step3: {
    office: {
      talkingWasNice: require('./office/talkingWasNice').default,
      doctor: require('./office/doctor').default,
      records: require('./office/records').default,
      step3Score: require('./office/step3Score').default
    }
  },
  step4: {
    hospitalRoom: {
      accident: require('./hospitalRoom/accident').default
    },
    office: {
      canMiloHelpMe: require('./office/canMiloHelpMe').default,
      askingMiloForHelp: require('./office/askingMiloForHelp').default,
      getHelp: require('./office/getHelp').default,
      handingOver: require('./office/handingOver').default,
      step4Score: require('./office/step4Score').default
    },
    corridor: {
      knocking: require('./corridor/knocking').default,
      goingHome: require('./corridor/goingHome').default,
      gameOver: require('./corridor/gameOver').default
    }
  }
};
