import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'disinfection', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'disinfection', 'overlay'],
      false
    )
  );

  store.dispatch(stepsActions.set(['step1', 'choices', 'disinfected'], true));
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: () =>
        <span>
          Die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('stationsleitung'));
            }}
          >
            Stationsleitung
          </span>{' '}
          ist da richtig streng: {store.getState().user.name}, vergiss nicht,
          dir zuerst die Hände zu{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('desinfizieren'));
            }}
          >
            desinfizieren
          </span>
          .
        </span>,
      audio: '002-Desinfizieren_ID_1.mp3',
      type: 'thought'
    },
    1: {
      from: 'player',
      text: () =>
        <span>
          Puh, die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('lotion'));
            }}
          >
            Lotion
          </span>{' '}
          muss mindestens 30 Sekunden{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('einwirken'));
            }}
          >
            einwirken
          </span>
          . Das finde ich ganz schön lang. Aber es muss ja sein!
        </span>,
      audio: '002-Desinfizieren_ID_2.mp3',
      type: 'thought'
    },
    2: {
      from: 'player',
      text: () =>
        <span>
          {store.getState().user.name}, und zieh dir unbedingt{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('einmalhandschuh'));
            }}
          >
            Einmalhandschuhe
          </span>{' '}
          an!
        </span>,
      audio: '002-Desinfizieren_ID_3.mp3',
      type: 'thought'
    },
    3: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
