import React from 'react';

export const InfoIcon = () =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 71 71"
  >
    <title>info</title>
    <path
      fill="#1E9EC1"
      d="M40.5996094,12.9790039 C41.9114649,12.9790039 43.017004,13.4339147 43.9162598,14.34375 C44.8155156,15.2535853 45.2651367,16.3538347 45.2651367,17.6445312 C45.2651367,18.9352278 44.8102259,20.0354772 43.9003906,20.9453125 C42.9905553,21.8551478 41.8903059,22.3100586 40.5996094,22.3100586 C39.3300718,22.3100586 38.2404017,21.8551478 37.3305664,20.9453125 C36.4207311,20.0354772 35.9658203,18.9352278 35.9658203,17.6445312 C35.9658203,16.3538347 36.4154415,15.2535853 37.3146973,14.34375 C38.2139531,13.4339147 39.3089128,12.9790039 40.5996094,12.9790039 Z M42.5673828,27.5786133 L36.0610352,50.1445312 C35.7013328,51.4352278 35.5214844,52.2815735 35.5214844,52.6835938 C35.5214844,52.9163423 35.6166983,53.1332184 35.8071289,53.3342285 C35.9975595,53.5352386 36.1985667,53.6357422 36.4101562,53.6357422 C36.7698586,53.6357422 37.1295555,53.4770524 37.4892578,53.159668 C38.441411,52.3767865 39.5839777,50.9591574 40.9169922,48.9067383 L41.9960938,49.5415039 C38.8010908,55.1063104 35.4051287,57.8886719 31.8081055,57.8886719 C30.4327731,57.8886719 29.3378133,57.5025266 28.5231934,56.7302246 C27.7085734,55.9579226 27.3012695,54.9793354 27.3012695,53.7944336 C27.3012695,53.0115521 27.481118,52.0170959 27.8408203,50.8110352 L32.2524414,35.6401367 C32.6756206,34.1801685 32.887207,33.0799191 32.887207,32.3393555 C32.887207,31.8738583 32.6861999,31.4612648 32.2841797,31.1015625 C31.8821594,30.7418602 31.3320347,30.5620117 30.6337891,30.5620117 C30.3164047,30.5620117 29.9355491,30.572591 29.4912109,30.59375 L29.9038086,29.3242188 L40.6630859,27.5786133 L42.5673828,27.5786133 Z"
    />
  </svg>;