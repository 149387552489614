import React from 'react';
import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'corridor', 'exercises', 'knockingExercise1', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.knockingExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['knocking', 'knockingExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Freundlicher fragen',
      text:
        'Mit dem Modalpartikel "denn" und einer angenehmen Satzmelodie können Sie folgende Fragen freundlicher machen.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title:
            'Entscheiden Sie, an welcher Stelle "denn" passt. Hören Sie dann zum Vergleich.',
          question: () =>
            <span>
              <small>
                Beispiel: <i>Wann möchten Sie <b>denn</b> gerne vorbeikommen?</i>
              </small>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u35/u35_1.mp3')
                },
                'Worum geht es?'
              ]
            }
          ]
        },
        {
          title:
            'Entscheiden Sie, an welcher Stelle "denn" passt. Hören Sie dann zum Vergleich.',
          question: () =>
            <span>
              <small>
                Beispiel: <i>Wann möchten Sie <b>denn</b> gerne vorbeikommen?</i>
              </small>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u35/u35_2.mp3')
                },
                'Was brauchen Sie?'
              ]
            }
          ]
        },
        {
          title:
            'Entscheiden Sie, an welcher Stelle "denn" passt. Hören Sie dann zum Vergleich.',
          question: () =>
            <span>
              <small>
                Beispiel: <i>Wann möchten Sie <b>denn</b> gerne vorbeikommen?</i>
              </small>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u35/u35_3.mp3')
                },
                'Wie kann ich Ihnen helfen?'
              ]
            }
          ]
        },
        {
          title:
            'Entscheiden Sie, an welcher Stelle "denn" passt. Hören Sie dann zum Vergleich.',
          question: () =>
            <span>
              <small>
                Beispiel: <i>Wann möchten Sie <b>denn</b> gerne vorbeikommen?</i>
              </small>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u35/u35_4.mp3')
                },
                'Was kann ich für Sie tun?'
              ]
            }
          ]
        },
        {
          title:
            'Entscheiden Sie, an welcher Stelle "denn" passt. Hören Sie dann zum Vergleich.',
          question: () =>
            <span>
              <small>
                Beispiel: <i>Wann möchten Sie <b>denn</b> gerne vorbeikommen?</i>
              </small>
            </span>,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u35/u35_5.mp3')
                },
                'Was ist passiert?'
              ]
            }
          ]
        },
        {
          title:
            'Entscheiden Sie, an welcher Stelle "denn" passt. Hören Sie dann zum Vergleich.',
          question: () =>
            <span>
              <small>
                Beispiel: <i>Wann möchten Sie <b>denn</b> gerne vorbeikommen?</i>
              </small>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u35/u35_6.mp3')
                },
                'Was ist hier los?'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
