import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function finishStep() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'dialogues', 'step2Score', 'overlay'],
      false
    )
  );

  store.dispatch(stepsActions.set(['stepFinished'], true));
  store.dispatch(stepsActions.set(['buttonHintStepFinished'], true));
}

export default {
  flow: {
    0: {
      type: 'score',
      image: () => require('../../../static/images/badges/informe.png'),
      text: 'Schritt 2 von 4',
      onClick: () => {
        finishStep();
      }
    }
  }
};
