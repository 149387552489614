import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'hotSpots', 'records', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(['step3', 'office', 'entities', 'milo', 'overlay'], true)
  );
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'entities', 'doctor', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'dialogues', 'records', 'overlay'],
      false
    )
  );
  store.dispatch(exerciseSetActions.setNextActivity('step3Score'));
  store.dispatch(exerciseSetActions.setCurrentExerciseSet('records'));
}

export default {
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/office/doctor-portrait.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: () =>
        <span>
          Irgendwie habe ich das Gefühl, dass der Arzt an meiner Kompetenz{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('zweifeln'));
            }}
          >
            zweifelt
          </span>
          . Dabei habe ich doch nichts falsch gemacht.
        </span>,
      audio: '209-Patientenakte_ID_1.mp3',
      type: 'thought'
    },
    1: {
      from: 'ai',
      text: () =>
        <span>
          Aha,{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('blutzuckerspiegel')
              );
            }}
          >
            Blutzucker
          </span>{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('unauffällig'));
            }}
          >
            unauffällig
          </span>. Wunde regelmäßig dokumentiert.
        </span>,
      audio: '209-Patientenakte_ID_2.mp3'
    },
    2: {
      from: 'ai',
      text: () =>
        <span>
          War mal besser, ist jetzt seit zwei Tagen sehr schlecht.
          Verbandswechsel täglich, Wundreinigung,{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('spülung'));
            }}
          >
            Spülung
          </span>{' '}
          NaCl auch ...
        </span>,
      audio: '209-Patientenakte_ID_3.mp3'
    },
    3: {
      from: 'ai',
      text: () =>
        <span>
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('mobilisation'));
            }}
          >
            Mobilisation
          </span>{' '}
          bisher nicht{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('zufriedenstellend')
              );
            }}
          >
            zufriedenstellend
          </span>. Nicht mal auf die Bettkante ...
        </span>,
      audio: '209-Patientenakte_ID_4.mp3'
    },
    4: {
      from: 'ai',
      text: () =>
        <span>
          Ernährungsprotokoll ist ja eher{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('besorgniserregend')
              );
            }}
          >
            besorgniserregend
          </span>
          . Nahrungsaufnahme nicht ausreichend ...
        </span>,
      audio: '209-Patientenakte_ID_5.mp3'
    },
    5: {
      from: 'ai',
      text: 'Na, dann ist das ja kein Wunder.',
      audio: '209-Patientenakte_ID_6.mp3'
    },
    6: {
      type: 'choice',
      question: 'Wie reagieren Sie?',
      answers: [
        {
          id: 'a',
          goTo: '7-1-1',
          data: 'Wie, kein Wunder?',
          audio: '209-Patientenakte_ID_9.mp3'
        },
        {
          id: 'b',
          goTo: '7-1-3',
          data: 'Was meinen Sie?',
          audio: '209-Patientenakte_ID_7.mp3'
        },
        {
          id: 'c',
          goTo: '7-3-1',
          data: 'So weit ist doch alles richtig, oder?',
          audio: '209-Patientenakte_ID_8.mp3'
        }
      ]
    },
    '7-1-1': {
      from: 'ai',
      text: 'Oh, gleich beleidigt!',
      audio: '209-Patientenakte_ID_15.mp3',
      type: 'thought',
      nextSnippet: '7-1-2'
    },
    '7-1-2': {
      from: 'ai',
      text: 'Ich kann es Ihnen gerne erklären.',
      audio: '209-Patientenakte_ID_16.mp3',
      nextSnippet: '7-1-3'
    },
    '7-1-3': {
      from: 'ai',
      text: () =>
        <span>
          Sie haben zwar alles{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('vorschriftsmäßig')
              );
            }}
          >
            vorschriftsmäßig
          </span>{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('dokumentieren'));
            }}
          >
            dokumentiert
          </span>, alle Kontrollen regelmäßig durchgeführt, aber die Wunde ist
          entzündet. Sie haben es nicht geschafft, Herrn Teller ausreichend zu{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('mobilisieren'));
            }}
          >
            mobilisieren
          </span>{' '}
          und er isst fast nichts. Das müsste Sie doch{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('alarmieren'));
            }}
          >
            alarmieren
          </span>
          .
        </span>,
      audio: '209-Patientenakte_ID_11.mp3',
      nextSnippet: '8'
    },
    '7-3-1': {
      from: 'ai',
      text: () =>
        <span>
          Das sehe ich ganz anders. Sie haben zwar alles{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('vorschriftsmäßig')
              );
            }}
          >
            vorschriftsmäßig
          </span>{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('dokumentieren'));
            }}
          >
            dokumentiert
          </span>, alle Kontrollen regelmäßig durchgeführt, aber die Wunde ist
          entzündet. Sie haben es nicht geschafft, Herrn Teller ausreichend zu{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('mobilisieren'));
            }}
          >
            mobilisieren
          </span>{' '}
          und er isst fast nichts. Das müsste Sie doch{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('alarmieren'));
            }}
          >
            alarmieren
          </span>
          .
        </span>,
      audio: '209-Patientenakte_ID_13.mp3',
      nextSnippet: '8'
    },
    8: {
      type: 'choice',
      question: 'Wie rechtfertigen Sie sich?',
      answers: [
        {
          id: 'a',
          goTo: '8-1-1',
          data: 'Wir sind alle sehr besorgt, wir geben uns viel Mühe, ihn zu mobilisieren. Er ist wenig kooperativ, weil er furchtbare Angst hat. Deshalb haben wir ja auch gemeinsam überlegt, wie es sinnvoll weitergehen kann.',
          audio: '209-Patientenakte_ID_18.mp3'
        },
        {
          id: 'b',
          goTo: '8-1-1',
          data: () =>
            <span>
              Ja, das tut es auch. Deswegen haben wir die Pflegemaßnahmen ja
              nochmal{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('anpassen'));
                }}
              >
                angepasst
              </span>
              .
            </span>,
          audio: '209-Patientenakte_ID_19.mp3'
        },
        {
          id: 'c',
          goTo: '8-3-1',
          data: 'Mich? Aber ich habe Herrn Teller doch erst heute kennengelernt. Ich kann doch gar nichts dafür. Das ist doch nicht mein Fehler.',
          audio: '209-Patientenakte_ID_20.mp3'
        }
      ]
    },
    '8-1-1': {
      from: 'player',
      text: () =>
        <span>
          Nachher kommen die Wundmanagerin und der Diätassistent. Sie können den{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wundstatus'));
            }}
          >
            Wundstatus
          </span>{' '}
          noch einmal beurteilen und die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('ernährungsberatung')
              );
            }}
          >
            Ernährungsberatung
          </span>{' '}
          übernehmen.
        </span>,
      audio: '209-Patientenakte_ID_22.mp3',
      nextSnippet: '8-1-2'
    },
    '8-1-2': {
      from: 'ai',
      text: 'Und was haben Sie noch geplant?',
      audio: '209-Patientenakte_ID_29.mp3',
      nextSnippet: '8-1-3'
    },
    '8-1-3': {
      from: 'player',
      text: () =>
        <span>
          Wir haben die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('krankengymnastik')
              );
            }}
          >
            Krankengymnastik
          </span>{' '}
          erhöht und Lisa wollte die Klinikpsychologin{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('konsultieren'));
            }}
          >
            konsultieren
          </span>{' '}
          und das mit Ihnen in der{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('visite'));
            }}
          >
            Visite
          </span>{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('abklären'));
            }}
          >
            abklären
          </span>
          .
        </span>,
      audio: '209-Patientenakte_ID_30.mp3',
      nextSnippet: '8-1-4'
    },
    '8-1-4': {
      from: 'ai',
      text: () =>
        <span>
          Das klingt gut. Hoffen wir, dass die Ergebnisse erfreulich sind. Eine
          Amputationswunde ist immer eine große{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('herausforderung'));
            }}
          >
            Herausforderung
          </span>
          .
        </span>,
      audio: '209-Patientenakte_ID_31.mp3',
      nextSnippet: '9'
    },
    '8-3-1': {
      from: 'ai',
      text: () =>
        <span>
          Hier geht es nicht darum, wer einen Fehler gemacht hat, sondern um das{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wohl'));
            }}
          >
            Wohl
          </span>{' '}
          des Patienten.
        </span>,
      audio: '209-Patientenakte_ID_26.mp3',
      nextSnippet: '8-3-2'
    },
    '8-3-2': {
      from: 'ai',
      text: 'Ich lese hier, Wundmanagerin und Diätassistent wurden kontaktiert.',
      audio: '209-Patientenakte_ID_27.mp3',
      nextSnippet: '8-3-3'
    },
    '8-3-3': {
      from: 'player',
      text: 'Ja, sie werden sich nochmal intensiv mit der Wundheilung und der Ernährungssituation von Herrn Teller beschäftigen.',
      audio: '209-Patientenakte_ID_28.mp3',
      nextSnippet: '8-1-2'
    },
    9: {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '9-1-1',
          data: `Das weiß ich doch. Das habe ich in meiner Ausbildung gelernt. Ich bin doch kein ${store.getState()
            .user.sex === 'male'
            ? 'Anfänger'
            : 'Anfängerin'}.`,
          audio: '209-Patientenakte_ID_34.mp3'
        },
        {
          id: 'b',
          goTo: '9-2-1',
          data: 'Wir tun unser Bestes.',
          audio: '209-Patientenakte_ID_32.mp3'
        },
        {
          id: 'c',
          goTo: '9-2-1',
          data: 'Ja, ich weiß.',
          audio: '209-Patientenakte_ID_33.mp3'
        }
      ]
    },
    '9-1-1': {
      from: 'ai',
      text: () =>
        <span>
          Wir gehen hier auf{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('station'));
            }}
          >
            Station
          </span>{' '}
          offen mit Kritik um, nehmen Sie es nicht so persönlich.
        </span>,
      audio: '209-Patientenakte_ID_42.mp3',
      nextSnippet: '9-1-2'
    },
    '9-1-2': {
      from: 'ai',
      text: () =>
        <span>
          Also, das{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('antibiotikum'));
            }}
          >
            Antibiotikum
          </span>{' '}
          haben wir angesetzt.
        </span>,
      audio: '209-Patientenakte_ID_43.mp3',
      nextSnippet: '9-2-3'
    },
    '9-2-1': {
      from: 'player',
      text: 'Wenn Ihnen noch etwas einfällt, dann nehmen wir das natürlich gerne auf.',
      audio: '209-Patientenakte_ID_36.mp3',
      nextSnippet: '9-2-2'
    },
    '9-2-2': {
      from: 'ai',
      text: () =>
        <span>
          Ja, also das{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('antibiotikum'));
            }}
          >
            Antibiotikum
          </span>{' '}
          haben wir{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('ansetzen'));
            }}
          >
            angesetzt
          </span>
          .
        </span>,
      audio: '209-Patientenakte_ID_37.mp3',
      nextSnippet: '9-2-3'
    },
    '9-2-3': {
      from: 'ai',
      text: () =>
        <span>
          Und ich möchte, dass beim nächsten Verbandswechsel auf jeden Fall ein{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('wundabstrich'));
            }}
          >
            Wundabstrich
          </span>{' '}
          gemacht wird. Die Wundmanagerin soll mich bitte nach ihrem Besuch
          anrufen.
        </span>,
      audio: '209-Patientenakte_ID_44.mp3',
      nextSnippet: '9-2-4'
    },
    '9-2-4': {
      from: 'player',
      text: () =>
        <span>
          Ja. Ich informiere auch Lisa,{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('sobald'));
            }}
          >
            sobald
          </span>{' '}
          sie wieder zurück ist.
        </span>,
      audio: '209-Patientenakte_ID_45.mp3',
      nextSnippet: '9-2-5'
    },
    '9-2-5': {
      from: 'ai',
      text: 'Danke. Wir sehen uns dann morgen wieder bei der Visite.',
      audio: '209-Patientenakte_ID_46.mp3',
      nextSnippet: 'fin'
    },
    fin: {
      type: 'feedback',
      text:
        'Nehmen Sie es nicht persönlich! Dass man sich mit Kritik auseinandersetzt, gehört dazu.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
