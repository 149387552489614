import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as glossaryActions } from '../../reducers/glossary';
import { actions as appActions } from '../../reducers/app';

import { PopUp } from '../../components/popUp';
import { Button } from '../../components/button';

import GLOSSARY from '../../config/glossary';

import styles from './styles.less';

const mapStateToProps = ({ glossary }) => ({
  currentInfo: glossary.currentInfo
});

const mapDispatchToProps = {
  setCurrentInfo: glossaryActions.setCurrentInfo,
  setPage: appActions.setPage
};

class Glossary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInfo: props.currentInfo
    };
  }

  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.title}>Glossar</h1>
        {this.renderGlossaryList()}
        {this.renderPopUp()}
        {this.renderBackButton()}
      </div>
    );
  }

  renderBackButton = () => {
    const { currentInfo } = this.props;
    return (
      <div>
        {!currentInfo ? <Button theme="back" onClick={this.changePage} /> : ''}
      </div>
    );
  };

  renderGlossaryList = () => {
    let letterGroup = '';
    let sectionHeader = '';

    const list = Object.keys(GLOSSARY).map(key => {
      const word =
        typeof GLOSSARY[key].word === 'function'
          ? GLOSSARY[key].word()
          : GLOSSARY[key].word;
      const firstLetter = key.charAt(0).toUpperCase();

      sectionHeader = '';
      if (letterGroup !== firstLetter) {
        sectionHeader = (
          <p className={styles.letterGroup}>
            {firstLetter}
          </p>
        );
      }
      letterGroup = firstLetter;

      return (
        <div key={key}>
          {sectionHeader}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              this.showInfo(key);
            }}
            className={styles.word}
          >
            {word}
          </span>
        </div>
      );
    });

    return (
      <div className={styles.content}>
        {list}
      </div>
    );
  };

  showInfo = currentInfo => {
    const { setCurrentInfo } = this.props;
    this.setState({ currentInfo });
    setCurrentInfo(currentInfo);
  };

  renderPopUp = () => {
    const { currentInfo } = this.props;

    if (currentInfo) {
      const word =
        typeof GLOSSARY[currentInfo].word === 'function'
          ? GLOSSARY[currentInfo].word()
          : GLOSSARY[currentInfo].word;
      const description = GLOSSARY[currentInfo].description;
      const example = GLOSSARY[currentInfo].example;

      return (
        <div>
          <PopUp
            theme="glossary"
            key={currentInfo}
            word={word}
            description={description}
            example={example}
          />
          <div className={styles.backButton}>
            <Button theme="back" onClick={this.closeEntry} />
          </div>
        </div>
      );
    }
    return null;
  };

  changePage = () => {
    const { setPage } = this.props;
    setPage('room');
  };

  closeEntry = () => {
    const { setCurrentInfo } = this.props;
    this.setState({ currentInfo: '' });
    setCurrentInfo('');
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Glossary);
