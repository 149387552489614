import { Component } from 'react';
import Bugsnag from 'bugsnag-js';

export default class ErrorBoundary extends Component {
  componentDidCatch(error, info) {
    Bugsnag.notifyException(error, { react: info });
  }
  render() {
    return this.props.children;
  }
}
