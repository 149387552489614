import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as infoboxActions } from '../../../reducers/infobox';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'hotSpots', 'doctor', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'dialogues', 'doctor', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'hotSpots', 'records', 'overlay'],
      true
    )
  );
  store.dispatch(exerciseSetActions.setCurrentExerciseSet('doctor'));
}

export default {
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/office/doctor-portrait.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question: 'Wie sprechen Sie den Stationsarzt an?',
      background: require('../../../static/images/office/doctor-detail.jpg'),
      answers: [
        {
          id: 'a',
          goTo: '0-1-1',
          data: 'Schönen guten Tag, Herr Doktor.',
          audio: '208-Arzt_ID_5.mp3'
        },
        {
          id: 'b',
          goTo: '0-2-1',
          data: 'Ah, Herr Dr. Thewes.',
          audio: '208-Arzt_ID_3.mp3'
        },
        {
          id: 'c',
          goTo: '0-3-1',
          data: 'Hallo Herr Thewes, schön, dass Sie hier sind.',
          audio: '208-Arzt_ID_4.mp3'
        }
      ]
    },
    '0-1-1': {
      from: 'ai',
      text: 'Herr Doktor und Frau Doktor. So hat man früher die Ärzte angesprochen. Bitte sagen Sie einfach Dr. Thewes zu mir.',
      audio: '208-Arzt_ID_12.mp3',
      nextSnippet: '0-1-2'
    },
    '0-1-2': {
      from: 'player',
      text: 'Gut zu wissen.',
      audio: '208-Arzt_ID_13.mp3',
      nextSnippet: '0-1-3'
    },
    '0-1-3': {
      from: 'ai',
      text: 'Ist Lisa da?',
      audio: '208-Arzt_ID_10.mp3',
      nextSnippet: '0-2-2'
    },
    '0-2-1': {
      from: 'ai',
      text: `Grüß Sie, ${store.getState().user.name}. Ist Lisa auch da?`,
      audio: '208-Arzt_ID_7.mp3',
      nextSnippet: '0-2-2'
    },
    '0-2-2': {
      from: 'player',
      text: 'Nein, sie hat gerade ein Treffen.',
      audio: '208-Arzt_ID_15.mp3',
      nextSnippet: '0-2-3'
    },
    '0-2-3': {
      from: 'ai',
      text: 'Ah, ok, dann setzen wir uns jetzt einfach zusammen.',
      audio: '208-Arzt_ID_16.mp3',
      nextSnippet: '1'
    },

    '0-3-1': {
      from: 'ai',
      text: 'Dr. Thewes.',
      audio: '208-Arzt_ID_9.mp3',
      nextSnippet: '0-1-3'
    },
    1: {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          data: 'Ja, gerne. Wollen Sie auch einen Kaffee? Der ist gerade frisch.',
          audio: '208-Arzt_ID_17.mp3'
        },
        {
          id: 'b',
          goTo: '1-2-1',
          data: 'Bitte setzen Sie sich doch auf meinen Platz. Ich schneide Ihnen ein Stück Torte ab. Sie möchten doch eins, oder? Kaffee bringe ich Ihnen auch gleich.',
          audio: '208-Arzt_ID_18.mp3'
        }
      ]
    },
    '1-1-1': {
      from: 'ai',
      text: 'Oh, das ist nett. Ja, ich nehme gerne eine Tasse.',
      audio: '208-Arzt_ID_20.mp3',
      nextSnippet: '1-2-2'
    },
    '1-2-1': {
      from: 'ai',
      text: 'Ach nein, das müssen Sie nicht. Ich hole mir den Kaffee selbst und hier ist ja auch noch ein Stuhl frei.',
      audio: '208-Arzt_ID_22.mp3',
      nextSnippet: '1-2-2'
    },
    '1-2-2': {
      from: 'ai',
      text: 'Wir müssen über Herrn Teller sprechen.',
      audio: '208-Arzt_ID_23.mp3',
      nextSnippet: '2'
    },
    2: {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '2-1-1',
          data: () =>
            <span>
              Ich habe mich gerade schon mit Lisa{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('austauschen'));
                }}
              >
                ausgetauscht
              </span>
              .
            </span>,
          audio: '208-Arzt_ID_26.mp3'
        },
        {
          id: 'b',
          goTo: '2-2-1',
          data: 'Ja, das können wir gerne machen.',
          audio: '208-Arzt_ID_25.mp3'
        },
        {
          id: 'c',
          goTo: '2-3-1',
          data: 'Wollen Sie nicht lieber auf Lisa warten?',
          audio: '208-Arzt_ID_27.mp3'
        }
      ]
    },
    '2-1-1': {
      from: 'ai',
      text: 'Aha! Es wäre schön, wenn Sie mich über das Ergebnis informieren würden.',
      audio: '208-Arzt_ID_36.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'player',
      text: () =>
        <span>
          Oh. Da war ich wohl nicht{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('kooperativ'));
            }}
          >
            kooperativ
          </span>{' '}
          genug.
        </span>,
      audio: '208-Arzt_ID_37.mp3',
      type: 'thought',
      nextSnippet: '2-1-3'
    },
    '2-1-3': {
      from: 'player',
      text: () =>
        <span>
          Wir haben uns Pflegemaßnahmen überlegt. Die wollte ich gerade{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('eintragen'));
            }}
          >
            eintragen
          </span>
          .
        </span>,
      audio: '208-Arzt_ID_41.mp3',
      nextSnippet: '2-1-4'
    },
    '2-1-4': {
      from: 'player',
      text: () =>
        <span>
          Wenn Sie mir noch zwei Minuten geben, dann ergänze ich die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('dokumentation'));
            }}
          >
            Dokumentation
          </span>{' '}
          und Sie können sich einen Überblick verschaffen.
        </span>,
      audio: '208-Arzt_ID_33.mp3',
      nextSnippet: '2-1-5'
    },
    '2-1-5': {
      from: 'ai',
      text: 'Kucken Sie mal, hier sind die Blutwerte, die können Sie dann auch gleich eintragen. Die Leukozyten und auch das CRP sind sehr hoch.',
      audio: '208-Arzt_ID_43.mp3',
      nextSnippet: '2-1-6'
    },
    '2-1-6': {
      from: 'ai',
      text: 'Er braucht dringend ein Antibiotikum. Über den Rest sprechen wir gleich.',
      audio: '208-Arzt_ID_44.mp3',
      nextSnippet: 'fin'
    },
    '2-2-1': {
      from: 'ai',
      text: () =>
        <span>
          Ich habe mir gerade die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('blutwert'));
            }}
          >
            Blutwerte
          </span>{' '}
          angesehen. Aktuell sind sie{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('erhöht'));
            }}
          >
            erhöht
          </span>, insbesondere die Leukozyten und auch das CRP. Das{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('beunruhigen'));
            }}
          >
            beunruhigt
          </span>{' '}
          mich, da müssen wir uns jetzt was überlegen.
        </span>,
      audio: '208-Arzt_ID_29.mp3',
      nextSnippet: '2-2-2'
    },
    '2-2-2': {
      from: 'player',
      text: 'Ja, die Wundheilung stagniert. Wie hoch sind denn die Werte?',
      audio: '208-Arzt_ID_30.mp3',
      nextSnippet: '2-2-3'
    },
    '2-2-3': {
      from: 'ai',
      text: () =>
        <span>
          Sie{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('hindeuten'));
            }}
          >
            deuten
          </span>{' '}
          auf eine{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('entzündung'));
            }}
          >
            Entzündung
          </span>{' '}
          hin. Er braucht dringend ein{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('antibiotikum'));
            }}
          >
            Antibiotikum
          </span>
          .
        </span>,
      audio: '208-Arzt_ID_31.mp3',
      nextSnippet: '2-2-4'
    },
    '2-2-4': {
      from: 'player',
      text: 'Lisa und ich haben uns gerade über die Pflegemaßnahmen unterhalten.',
      audio: '208-Arzt_ID_32.mp3',
      nextSnippet: '2-2-5'
    },
    '2-2-5': {
      from: 'player',
      text: 'Wenn Sie mir noch zwei Minuten geben, dann ergänze ich die Dokumentation und Sie können sich einen Überblick verschaffen.',
      audio: '208-Arzt_ID_33.mp3',
      nextSnippet: '2-2-6'
    },
    '2-2-6': {
      from: 'ai',
      text: 'Ja, gut. Machen Sie das. Ich probiere in der Zwischenzeit mal diese köstliche Torte.',
      audio: '208-Arzt_ID_34.mp3',
      after: {
        cb: () => {
          store.dispatch(
            infoboxActions.unlock(['pflegeplanung', 'krankenhausstruktur'])
          );
        }
      },
      nextSnippet: 'fin'
    },
    '2-3-1': {
      from: 'ai',
      text: 'Das kann leider nicht warten. Ich habe noch so viel Anderes zu tun.',
      audio: '208-Arzt_ID_39.mp3',
      nextSnippet: '2-3-2'
    },
    '2-3-2': {
      from: 'player',
      text: 'Ok, dann muss ich das jetzt einfach machen. Das schaffe ich schon.',
      audio: '208-Arzt_ID_40.mp3',
      type: 'thought',
      nextSnippet: '2-1-3'
    },
    fin: {
      type: 'feedback',
      text: 'Lassen Sie sich nicht verunsichern!',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['pflegeplanung', 'krankenhausstruktur'],
      correct: true
    }
  }
};
