import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'dialogues', 'talkingWasNice', 'overlay'],
      false
    )
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Das Gespräch mit meiner Kollegin hat mir gut getan. Ich habe das Gefühl, dass ich von Tag zu Tag sicherer werde, auch im Kontakt zu den Angehörigen. Ich bin auf dem richtigen Weg.',
      audio: '208-Arzt_ID_1.mp3',
      type: 'thought'
    },
    1: {
      from: 'player',
      text: 'Hoffentlich sieht das der Arzt genauso. Ach, da ist er ja. Das passt gut.',
      audio: '208-Arzt_ID_2.mp3',
      type: 'thought'
    },
    2: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
