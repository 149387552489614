import React, { Component } from 'react';
import { Howl } from 'howler';

import styles from './styles.less';
import { DefaultButton } from '..';

class Hotspot extends Component {
  constructor(props) {
    super(props);

    const { audio } = props;

    if (audio) {
      this.sound = new Howl({ src: [audio] });
    }
  }

  render() {
    const { className, style, scrollTarget } = this.props;
    return (
      <span
        className={`${styles.hotspot} ${className}`}
        role="button"
        tabIndex={0}
        style={style}
        data-scrolltarget={scrollTarget === true}
      >
        {this.renderHoverArea()}
        <span
          className={styles.inner}
          onClick={this.handleClick}
          role="button"
          tabIndex={-1}
        />
      </span>
    );
  }

  renderHoverArea = () => {
    const { exercises, handleExercise } = this.props;

    if (!exercises) {
      return null;
    }

    return (
      <div className={styles.hoverArea}>
        <DefaultButton
          label="Dialog mit Übungen"
          className={styles.entry}
          onClick={this.handleClick}
        />
        <DefaultButton
          key={exercises}
          label="Nur Übungen"
          className={styles.entry}
          onClick={handleExercise}
        />
      </div>
    );
  };

  handleClick = () => {
    const { handleHotSpot } = this.props;
    if (this.sound) {
      this.sound.play();
    }
    handleHotSpot();
  };
}

Hotspot.defaultProps = {
  className: ''
};

export default Hotspot;
