import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { createReducer, createActionTypes } from '../lib/redux';

const defaultState = {
  checkWound: {
    disinfectionExercise1: false,
    disinfectionExercise2: false,
    checkWoundExercise3: false,
    checkWoundExercise1: false
  },
  knocking: {
    knockingExercise1: false,
    knockingExercise2: false
  },
  mrsTeller: {
    mrsTellerExercise1: false
  },
  accident: {
    accidentExercise1: false,
    accidentExercise2: false,
    accidentExercise3: false
  },
  activity: {
    activityExercise1: false,
    activityExercise3: false
  },
  activitySecondPart: {
    headboard: false
  },
  food: {
    trayExercise1: false,
    trayExercise3: false,
    empathicConvincing: false
  },
  advice: {
    adviceExercise1: false,
    adviceExercise2: false,
    adviceExercise3: false
  },
  coffee: {
    coffeemaschineExercise1: false,
    coffeemaschineExercise2: false,
    coffeemaschineExercise3: false,
    coffeemaschineExercise5: false,
    coffeemaschineExercise4: false
  },
  dietaryAssistant: {
    dietaryAssistantExercise1: false,
    dietaryAssistantExercise2: false,
    dietaryAssistantExercise4: false,
    dietaryAssistantExercise5: false
  },
  doctor: {
    doctorExercise1: false,
    doctorExercise2: false,
    doctorExercise3: false
  },
  records: {
    recordsExercise1: false,
    recordsExercise2: false,
    recordsExercise4: false
  },
  handingOver: {
    handingOverExercise1: false,
    handingOverExercise3: false,
    handingOverExercise4: false
  },
  special: {
    email: false,
    calm: false,
    woundAndCare: false
  },
  nextActivity: '',
  currentExerciseSet: ''
};

const types = createActionTypes(
  ['SET_CURRENT_EXERCISE_SET', 'SET_NEXT_ACTIVITY', 'CLOSE_EXERCISE'],
  ''
);

const actions = {
  setCurrentExerciseSet: value => ({
    type: types.SET_CURRENT_EXERCISE_SET,
    value
  }),
  setNextActivity: value => ({
    type: types.SET_NEXT_ACTIVITY,
    value
  }),
  closeExercise: path => ({
    type: types.CLOSE_EXERCISE,
    path
  })
};

const reducer = createReducer(defaultState, {
  [types.SET_CURRENT_EXERCISE_SET]: (state = defaultState, { value }) => ({
    ...state,
    currentExerciseSet: value
  }),
  [types.SET_NEXT_ACTIVITY]: (state = defaultState, { value }) => ({
    ...state,
    nextActivity: value
  }),
  [types.CLOSE_EXERCISE]: (state = defaultState, { path }) =>
    set(cloneDeep(state), path, true)
});

export { actions, reducer };
