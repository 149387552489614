import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as infoboxActions } from '../../../reducers/infobox';

import styles from '../styles.less';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(['step2', 'office', 'hotSpots', 'advice', 'overlay'], true)
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'dialogues', 'coffee', 'overlay'],
      false
    )
  );
  store.dispatch(exerciseSetActions.setCurrentExerciseSet('coffee'));
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step2', 'choices', 'waitForColleague'], false)
  );
  store.dispatch(stepsActions.set(['step2', 'choices', 'leaveOffice'], false));
  store.dispatch(
    stepsActions.set(['step2', 'choices', 'coffeeFeedback'], true)
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/office/lisa-portrait.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question: 'Am Fenster steht Ihre Kollegin Lisa Blank. Was tun Sie?',
      answers: () => {
        const leaveOffice = store.getState().steps.step2.choices.leaveOffice;
        const waitForColleague = store.getState().steps.step2.choices
          .waitForColleague;
        return [
          {
            id: 'a',
            goTo: '0-1-1',
            data: 'ihr einen Kaffee anbieten',
            renderQuestion: false,
            onClick: () => {
              store.dispatch(
                stepsActions.set(
                  ['step2', 'office', 'entities', 'lisaBack', 'overlay'],
                  false
                )
              );
              store.dispatch(
                stepsActions.set(
                  ['step2', 'office', 'entities', 'lisaFront', 'overlay'],
                  true
                )
              );
              store.dispatch(
                stepsActions.set(
                  ['step2', 'office', 'hotSpots', 'coffee', 'overlay'],
                  false
                )
              );
            }
          },
          {
            id: 'b',
            goTo: '0-2-1',
            renderQuestion: false,
            data: 'abwarten, ob die Kollegin das Gespräch beginnt',
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step2', 'choices', 'waitForColleague'], true)
              );
            },
            className: waitForColleague ? styles.disabled : ''
          },
          {
            id: 'c',
            goTo: '0-3-1',
            renderQuestion: false,
            data: 'das Dienstzimmer verlassen',
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step2', 'choices', 'leaveOffice'], true)
              );
            },
            className: leaveOffice ? styles.disabled : ''
          }
        ];
      }
    },
    '0-1-1': {
      from: 'player',
      text: 'Hallo Lisa, möchtest du auch einen Kaffee?',
      audio: '204-Kaffee_ID_1.mp3',
      nextSnippet: '0-1-2'
    },
    '0-1-2': {
      from: 'ai',
      text: 'Ja, gerne.',
      audio: '204-Kaffee_ID_2.mp3',
      nextSnippet: '0-1-3'
    },
    '0-1-3': {
      from: 'ai',
      text: `Na, ${store.getState().user
        .name}, wie sieht es denn heute bei dir aus?`,
      audio: '204-Kaffee_ID_3.mp3',
      nextSnippet: '1-1'
    },

    '0-2-1': {
      from: 'player',
      text: 'Wahrscheinlich ist es besser, wenn ich das Gespräch beginne!',
      audio: '202-Abwarten_ID_1.mp3',
      type: 'thought',
      nextSnippet: '0-2-2'
    },
    '0-2-2': {
      from: 'player',
      text: 'Vielleicht hat sie ja Lust auf einen Kaffee.',
      audio: '202-Abwarten_ID_2.mp3',
      type: 'thought',
      nextSnippet: '0-3-4'
    },

    '0-3-1': {
      from: 'player',
      text: 'Warum denn?',
      audio: '203-Verlassen_ID_1.mp3',
      type: 'thought',
      nextSnippet: '0-3-2'
    },
    '0-3-2': {
      from: 'player',
      text:
        'Es wäre doch viel netter, mit meiner Kollegin ins Gespräch zu kommen.',
      audio: '203-Verlassen_ID_2.mp3',
      type: 'thought',
      nextSnippet: '0-3-3'
    },
    '0-3-3': {
      from: 'player',
      text: 'Wir könnten ja gemeinsam einen Kaffee trinken.',
      audio: '203-Verlassen_ID_3.mp3',
      type: 'thought',
      nextSnippet: '0-3-4'
    },
    '0-3-4': {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: '0',
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },

    '1-1': {
      type: 'choice',
      question: 'Wählen Sie eine der folgenden Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          data: 'Ah gut, danke.',
          audio: '204-Kaffee_ID_7.mp3'
        },
        {
          id: 'b',
          goTo: '1-1-1',
          data: 'Es geht.',
          audio: '204-Kaffee_ID_5.mp3'
        },
        {
          id: 'c',
          goTo: '1-3-1',
          data: 'Na ja, ich habe so viele private Probleme. Können wir kurz darüber sprechen?',
          audio: '204-Kaffee_ID_6.mp3'
        }
      ]
    },

    '1-1-1': {
      from: 'ai',
      text: 'Hast du dich denn hier bei uns schon eingelebt?',
      audio: '204-Kaffee_ID_8.mp3',
      nextSnippet: '1-3-5'
    },

    '1-3-1': {
      from: 'ai',
      text: 'Oh, dafür ist jetzt aber kein guter Moment.',
      audio: '204-Kaffee_ID_12.mp3',
      type: 'thought',
      nextSnippet: '1-3-2'
    },
    '1-3-2': {
      from: 'ai',
      text: 'Vielleicht können wir ja später darüber sprechen.',
      audio: '204-Kaffee_ID_13.mp3',
      nextSnippet: '1-3-3'
    },
    '1-3-3': {
      from: 'player',
      text: 'Ja, das wäre nett.',
      audio: '204-Kaffee_ID_14.mp3',
      nextSnippet: '1-3-4'
    },
    '1-3-4': {
      from: 'ai',
      text: 'Und hier auf der Arbeit? Hast du dich denn bei uns schon eingelebt?',
      audio: '204-Kaffee_ID_15.mp3',
      nextSnippet: '1-3-5'
    },
    '1-3-5': {
      from: 'player',
      text: `Ja, danke. Die Kollegen finde ich wirklich sehr nett und mit den Patienten komme ich auch gut klar.
Meine Aufgaben hier sind halt anders als in meinem Heimatland. Daran muss ich mich noch gewöhnen.`,
      audio: '204-Kaffee_ID_16.mp3',
      nextSnippet: '1-3-6'
    },
    '1-3-6': {
      from: 'player',
      text: 'Und mit der Sprache ist es noch ein bisschen schwierig. Ich verstehe nicht immer alles.',
      audio: '204-Kaffee_ID_17.mp3',
      nextSnippet: '1-3-7'
    },
    '1-3-7': {
      from: 'ai',
      text: 'Ja, ich weiß. Das ist bestimmt nicht leicht.',
      audio: '204-Kaffee_ID_18.mp3',
      nextSnippet: '1-3-8'
    },
    '1-3-8': {
      from: 'ai',
      text: `Aber ${store.getState().user
        .name}, bitte frag immer nach, wenn du etwas nicht verstehst.`,
      audio: '204-Kaffee_ID_19.mp3',
      nextSnippet: '2-1'
    },

    '2-1': {
      type: 'choice',
      question: 'Wählen Sie eine der folgenden Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '2-1-1',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step2', 'choices', 'coffeeFeedback'], false)
            );
          },
          data: () =>
            <span>
              Aber nein, das möchte ich nicht. Das ist mir{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('peinlich'));
                }}
              >
                peinlich
              </span>.
            </span>,
          audio: '204-Kaffee_ID_21.mp3'
        },
        {
          id: 'b',
          goTo: '2-2-1',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step2', 'choices', 'coffeeFeedback'], false)
            );
          },
          data: 'Fragen, wieso? Ich habe eine gute Ausbildung. Ich muss nicht fragen.',
          audio: '204-Kaffee_ID_22.mp3'
        },
        {
          id: 'c',
          goTo: '2-3-1',
          data: 'Stört das denn nicht?',
          audio: '204-Kaffee_ID_20.mp3'
        }
      ]
    },

    '2-1-1': {
      from: 'ai',
      text: `Aber ${store.getState().user
        .name}, das muss dir nicht peinlich sein. Nur so können wir Fehler vermeiden.`,
      audio: '204-Kaffee_ID_27.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'ai',
      text: 'Wir brauchen doch alle Informationen, um die Patienten gut versorgen zu können.',
      audio: '204-Kaffee_ID_25.mp3',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['smalltalk']));
        }
      },
      nextSnippet: 'fin'
    },

    '2-2-1': {
      from: 'ai',
      text: `Darum geht's doch gar nicht. Warum fühlt ${store.getState().user
        .sex === 'male'
        ? 'er'
        : 'sie'} sich denn so angegriffen?`,
      audio: `${store.getState().user.sex === 'male'
        ? '204-Kaffee_ID_30m.mp3'
        : '204-Kaffee_ID_30w.mp3'}`,
      type: 'thought',
      nextSnippet: '2-2-2'
    },
    '2-2-2': {
      from: 'ai',
      text: 'Ich weiß, dass du eine gute Ausbildung hast. Das ist gar keine Frage.',
      audio: '204-Kaffee_ID_31.mp3',
      nextSnippet: '2-2-3'
    },
    '2-2-3': {
      from: 'ai',
      text: 'Aber hier geht es um die Kommunikation und unsere Zusammenarbeit.',
      audio: '204-Kaffee_ID_32.mp3',
      nextSnippet: '2-2-4'
    },
    '2-2-4': {
      from: 'ai',
      text: 'Um die Patienten gut versorgen zu können, müssen wir uns gut austauschen.',
      audio: '204-Kaffee_ID_33.mp3',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['smalltalk']));
        }
      },
      nextSnippet: 'fin'
    },

    '2-3-1': {
      from: 'ai',
      text: 'Nein, überhaupt nicht. Es ist doch wichtig, dass es nicht zu Missverständnissen kommt.',
      audio: '204-Kaffee_ID_24.mp3',
      nextSnippet: '2-1-2'
    },

    fin: {
      type: 'choice',
      answers: () => {
        const coffeeFeedback = store.getState().steps.step2.choices
          .coffeeFeedback;
        const feedback = coffeeFeedback ? '3-1' : '3-2';

        return [
          {
            id: 'a',
            goTo: feedback,
            onClick: () => {
              resetChoices();
            },
            data: 'Weiter',
            renderQuestion: false
          }
        ];
      }
    },

    '3-1': {
      type: 'feedback',
      text:
        'Sehr gut! Suchen Sie weiterhin den Austausch mit den Kollegen, wenn Sie eine Frage haben.',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['smalltalk'],
      correct: true
    },
    '3-2': {
      type: 'feedback',
      text:
        'Nehmen Sie den Rat der Kollegin an: Fragen Sie lieber immer nach, wenn Sie etwas nicht verstanden haben!',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['smalltalk'],
      correct: false
    }
  }
};
