import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as appActions } from '../../reducers/app';
import { Button } from '../../components/button';
import { CharacterChoice } from '../../modules/introduction';
import { VERSIONS } from '../../constants';

import styles from './styles.less';

const mapStateToProps = ({ user, app }) => ({
  version: app.get('version'),
  user
});

const mapDispatchToProps = {
  setPage: appActions.setPage
};

class Introduction extends Component {
  render() {
    return (
      <div className={styles.content}>
        <CharacterChoice
          ref={component => {
            this.characterChoice = component;
          }}
        />
        <Button onClick={this.onClickNext} theme="next" />
      </div>
    );
  }

  onClickNext = () => {
    const { setPage, user, version } = this.props;

    if (user.sex && user.name) {
      if (version === VERSIONS.STUDENT) {
        setPage('steps');
      } else {
        setPage('room');
      }
    } else {
      this.characterChoice.wrappedInstance.setErrorMessage(
        'Bitte wählen Sie einen Charakter aus und geben Sie Ihren Namen ein!'
      );
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Introduction);
