import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Portrait } from '../../../components/portrait';
import { InputText } from '../../../components/inputText';

import { actions as userActions } from '../../../reducers/user';

import styles from '../styles/characterChoice.less';

const mapStateToProps = ({ user }) => ({
  user
});

const mapDispatchToProps = {
  setSex: userActions.setSex,
  setName: userActions.setName
};

class CharacterChoice extends Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      sex: user.sex,
      name: user.name,
      error: false
    };
  }

  render() {
    return (
      <div className={styles.container}>
        <h2 className={styles.welcomeTitle}>Wählen Sie einen Charakter</h2>
        <div className={styles.portraitWrapper}>
          <Portrait
            data="female"
            onClick={this.selectSex}
            selected={this.state.sex === 'female'}
          />
          <Portrait
            data="male"
            onClick={this.selectSex}
            selected={this.state.sex === 'male'}
          />
        </div>
        <div className={styles.nameInputWrapper}>
          <InputText onBlur={this.setName} inputValue={this.state.name} />
        </div>
        {this.renderErrorMessage()}
      </div>
    );
  }

  selectSex = sex => {
    const { setSex } = this.props;
    this.setState({ sex });
    setSex(sex);
  };

  setName = name => {
    const { setName } = this.props;
    this.setState({ name });
    setName(name);
  };

  setErrorMessage = error => {
    this.setState({ error });
  };

  renderErrorMessage = () => {
    const { error, sex, name } = this.state;
    if (error && (sex === false || name === false)) {
      return (
        <p className={styles.charachterSelectError}>
          {error}
        </p>
      );
    }
    return null;
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(CharacterChoice);
