import React from 'react';

import { Button } from '../../components';
import { GlossaryIcon } from '../../static/icons/GlossaryIcon';
import { InfoIcon } from '../../static/icons/InfoIcon';
import { DownloadIcon } from '../../static/icons/DownloadIcon';
import { InfoBoxIcon } from '../../static/icons/InfoBoxIcon';
import { Communication } from '../../static/icons/Communication';
import { Grammar } from '../../static/icons/Grammar';
import { Vocabulary } from '../../static/icons/Vocabulary';
import { Pronunciation } from '../../static/icons/Pronunciation';
import { RestartIcon } from '../../static/icons/RestartIcon';

import styles from './styles.less';

const entries = {
  info: {
    categoryHeading: 'Allgemeine Informationen',
    heading: 'Allgemeine Infos zum Spiel',
    content: [
      <div key="0" style={{ overflow: 'auto' }}>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>
                <img
                  alt="hotspot"
                  src={require('../../static/images/hotspot.jpg')}
                />
              </td>
              <td>Unter jedem Hotspot wartet ein Gespräch auf Sie.</td>
            </tr>
            <tr>
              <td>
                <GlossaryIcon />
              </td>
              <td>
                Im Wörterbuch können Sie die Bedeutung von unbekannten Wörtern
                nachlesen.
              </td>
            </tr>
            <tr>
              <td>
                <InfoBoxIcon />
              </td>
              <td>
                In der Infobox erfahren Sie Wissenswertes zur Arbeitswelt in
                Deutschland.
              </td>
            </tr>
            <tr>
              <td>
                <Communication />
              </td>
              <td>Übungen zu Kommunikation</td>
            </tr>
            <tr>
              <td>
                <Grammar />
              </td>
              <td>Übungen zu Grammatik</td>
            </tr>
            <tr>
              <td>
                <Vocabulary />
              </td>
              <td>Übungen zu Wortschatz</td>
            </tr>
            <tr>
              <td>
                <Pronunciation />
              </td>
              <td>Übungen zu Aussprache und Betonung</td>
            </tr>
            <tr>
              <td>
                <div className={styles.fakeButton}>
                  <RestartIcon />
                </div>
              </td>
              <td>
                Sie können das Spiel jederzeit neu starten. Ihre bisherigen
                Punkte werden dann gelöscht.
              </td>
            </tr>
          </tbody>
        </table>
      </div>,
      <p key="1">
        Wenn Sie bei den Übungen etwas schreiben, achten Sie bitte beim Tippen
        auf Groß- und Kleinschreibung.
      </p>,
      <p key="2">
        Das Spiel akzeptiert nur Wörter, die Sie selbst vollständig eingeben.
        Wenn Sie bei der Eingabe die Vorschläge von Ihrem Smartphone oder Tablet
        annehmen, wird das Wort automatisch als „falsch“ gewertet.
      </p>
    ]
  },
  zusatzmaterialien: {
    heading: 'Zusatzmaterialien für den Unterricht',
    content: 
    [
      <div key="0" style={{ overflow: 'auto' }}>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td className={styles.center}>
                <DownloadIcon />
              </td>
              <td>
                Neben den einzelnen Szenen können Sie jeweils separat die Handreichungen herunterladen, die sich inhaltich und sprachlich auf das Gespräch in der App beziehen.
              </td>
            </tr>
            <tr>
              <td>
                <Button
                  theme='noFixed'
                  className={`${styles.button}`}
                >
                  <InfoIcon />
                </Button>
              </td>
              <td>
                In der Gesamtdatei erhalten Sie einen Überblick über die Inhalte in der App, alle Handreichungen und eine Einführung in die Arbeit mit den Materialien im Unterricht.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ]
  },
  bildnachweis: {
    heading: 'Bildnachweis',
    content: [
      <strong key="0">Übung | Gegenstände im Krankenzimmer</strong>,
      <p key="1">
        Griff ©fotolia/OlegDoroshin, Knopf ©fotolia /Suguru, Krankenbett
        ©fotolia /GIBLEHO, Ständer ©fotolia/zinkevych, Vorhang
        ©fotolia(Ausschnitt) /peshkova Bettdecke, Bettkante, Kopfteil, Lifter,
        Nachttisch, Patientenruf, Schublade ©IQ Fachstelle Berufsbezogenes
        Deutsch
      </p>,
      <strong key="2">Übung | Schmerzbeschreibungen verstehen</strong>,
      <p key="3">©IQ Fachstelle Berufsbezogenes Deutsch</p>,
      <strong key="4">Übung | Hilfsmittel</strong>,
      <p key="5">
        Beinprothese ©fotolia/plpchirawong <br /> Brille ©fotolia/ akf <br />
        Gehhilfe ©fotolia/Dmitry Vereshchagin <br /> Gehstock ©fotolia/ Ljupco
        Smokovski <br /> Hörgerät ©fotolia/ Robert Przybysz <br /> Kontaktlinse
        ©fotolia/ Dirk Vonten <br /> Lupe ©fotolia/ taniho <br /> Rollator
        ©fotolia/Birgit Reitz-Hofmann <br /> Rollstuhl ©fotolia/ Peter Atkins
        <br /> Toilettenstuhl ©fotolia/ Ekkehard Stein
      </p>,
      <strong key="6">Infobox</strong>,
      <p key="7">Schmerzskala ©fotolia(Ausschnitt)/ lukpedclub</p>
    ]
  },
  credits: {
    heading: 'Credits',
    content: [
      <p key="0">
        <strong>Ein Tag Deutsch – in der Pflege </strong> ist eine
        Sprachlern-App der IQ Fachstelle Berufsbezogenes Deutsch. <br />
        <a href="http://www.deutsch-am-arbeitsplatz.de/">
          www.deutsch-am-arbeitsplatz.de
        </a>
      </p>,
      <strong key="2">Projektleitung und Konzept</strong>,
      <p key="3">Karin Ransberger</p>,
      <strong key="4">Didaktische Beratung</strong>,
      <p key="5">Birthe Scheffler</p>,
      <strong key="6">Autorinnen</strong>,
      <p key="7">
        Johanna Scheerer-Papp, Birthe Scheffler, Anja Schümann, Karin Ransberger
      </p>,
      <strong key="8">Redaktionelle Begleitung</strong>,
      <p key="9">Andrea Snippe</p>,
      <strong key="10">Fachliche Beratung im Bereich Pflege</strong>,
      <p key="11">
        Dorit Feldman, Dr. Sabine Kampf, Anja Müller, Julie Rothaug
      </p>,
      <p key="12">
        Die Dramaturgie des Lernspiels orientiert sich an den Szenarien für die
        Lernfortschrittsmessung von Wilhelmine Berg (VHS Braunschweig) und
        Gabriele Eilert-Ebke, die im Rahmen des IQ Landesnetzwerks Niedersachsen
        (IQ Förderprogramm) in Zusammenarbeit mit der Krankenpflegeschule des
        Klinikums Braunschweig entwickelt wurden. Alle Personen dieses Spieles
        sind frei erfunden. Etwaige Ähnlichkeiten zu lebenden oder verstorbenen
        Personen wären ungewollt und rein zufällig.
      </p>,
      <p key="13">
        Ein besonderer Dank gilt der IQ Arbeitsgruppe für Gesundheits- und
        Heilberufe, Silvia Brassel, Swantje Butenschön, Monika Siegert und der
        GINA-Klasse 20 von Wege in den Beruf, Laila Scheerer und der
        Geriatrischen Abteilung vom Evangelischen Krankenhaus Alsterdorf, den IQ
        Netzwerkpartnerinnen und -partnern und dem gesamten Team der IQ
        Fachstelle Berufsbezogenes Deutsch.
      </p>,
      <strong key="14">Audioproduktion</strong>,
      <p key="15">Simon Osterhold, S.I.M. Productions</p>,
      <strong key="16">Sprecherinnern und Sprecher</strong>,
      <p key="17">
        Tobias Baum, Arlett Drechsler, Tanja Frehse, Tommi Pieper, Angelika
        Utto, Walter von Hauff, Timo Wenzel
      </p>,
      <p key="18">
        <strong>Umsetzung - ovos media GmbH</strong>
        <br />
        <a href="https://www.ovos.at/">www.ovos.at</a>
      </p>,
      <br key="27" />,
      <strong key="21">Projektleitung</strong>,
      <p key="22">Hannes Amon, Rok Mele</p>,
      <strong key="19">Programmierung</strong>,
      <p key="20">Denis Lončarić, Florentin Luca Rieger, Benjamin Hofinger, Michael Mühlegger</p>,
      <strong key="23">QA</strong>,
      <p key="24">Mihaela Dorofteiu</p>,
      <strong key="25">Design</strong>,
      <p key="26">Julia Langer, Peter Matis</p>
    ]
  },
  impressum: {
    heading: 'Impressum',
    content: [
      <h3 key="0">Anbieter</h3>,
      <p key="8">
        <img
          alt="Logo: Fachstelle für Berfsbezogenes Deutsch"
          src={require('../../static/images/logo.png')}
        />
      </p>,
      <p key="3">
        <strong>
          Fachstelle Berufsbezogenes Deutsch im Förderprogramm „Integration
          durch Qualifizierung (IQ)“
        </strong>
        <br /> Nagelsweg 10 <br /> 20097 Hamburg <br />
        <a href="http://www.deutsch-am-arbeitsplatz.de/">
          www.deutsch-am-arbeitsplatz.de
        </a>
        <br /> Inhaltliche Verantwortung: Iris Beckmann-Schulz
      </p>,
      <p key="5">
        Das Förderprogramm „Integration durch Qualifizierung (IQ)“ zielt auf die
        nachhaltige Verbesserung der Arbeitsmarktintegration von Menschen mit
        Migrationshintergrund ab.
      </p>,
      <p key="7">
        Die IQ Fachstelle Berufsbezogenes Deutsch ist ein Projekt der passage
        gGmbH
        <br />
        Nagelsweg 14 <br /> 20097 Hamburg <br />
        <a href="https://passage.hamburg/">www.passage-hamburg.de</a>
        <br />
        Geschäftsführung: Gudrun Stefaniak <br /> Die passage gGmbH ist vom
        Finanzamt Mitte-Altstadt unter der Nr. 17/414/00878 als gemeinnützig
        anerkannt. <br /> Registernummer: HRB 67580, Amtsgericht Hamburg <br />
        Umsatzsteueridentifikationsnummer gem. §27 a Umsatzsteuergesetz: DE
        191440808
      </p>,
      <p key="9">
        Das Förderprogramm Integration durch Qualifizierung (IQ) wird durch das
        Bundesministerium für Arbeit und Soziales und den Europäischen
        Sozialfonds gefördert.
      </p>,
      <p key="10">
        <img src={require('../../static/images/logos/bmas.jpg')} alt="Logo Bundesministerium für Arbeit und Soziales" />
        <img src={require('../../static/images/logos/esf.jpg')} alt="Logo Europäischer Sozialfonds" />
        <img src={require('../../static/images/logos/eu-2014.jpg')} alt="Logo Europäische Union" />
        <img
          src={require('../../static/images/logos/zusammen-zukunft-gestalten.jpg')}
          alt="Logo Zusammen Zukunft gestalten"
        />
      </p>,
      <p key="11">In Kooperation mit:</p>,
      <p key="12">
        <img src={require('../../static/images/logos/bmas.jpg')} alt="Logo Bundesministerium für Arbeit und Soziales" />
        <img
          src={require('../../static/images/logos/bundesagentur-fuer-arbeit.jpg')}
          alt="Logo Bundesagentur für Arbeit"
        />
      </p>,
      <h3 key="13">Rechtliche Hinweise</h3>,
      <p key="15">
        Die Applikation wurde mit größtmöglicher Sorgfalt erstellt. Wir
        übernehmen jedoch keinerlei Gewähr für die Aktualität, Vollständigkeit
        oder Korrektheit der bereitgestellten Informationen. Daher erfolgt der
        Download, die Nutzung der Applikation und ihrer Inhalte auf eigene
        Verantwortung. Haftungsansprüche, welche sich auf Schäden materieller
        oder ideeller Art, die durch die Nutzung oder Nichtnutzung der
        dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
        unvollständiger Informationen verursacht wurden, sind grundsätzlich
        ausgeschlossen.{' '}
      </p>,
      <p key="17">
        Alle in dieser App enthaltenen Textbeiträge und Abbildungen sind
        urheberrechtlich geschützt. Das exklusive Nutzungsrecht liegt bei der
        Fachstelle Berufsbezogenes Deutsch im Förderprogramm „Integration durch
        Qualifizierung (IQ)“. Jede Veröffentlichung, Übernahme, Nutzung oder
        Vervielfältigung von Texten, Bildern oder anderen Daten bedarf der
        schriftlichen Zustimmung durch die Fachstelle Berufsbezogenes Deutsch im
        Förderprogramm „Integration durch Qualifizierung (IQ)“ oder der passage
        gGmbH.
      </p>,
      <p key="19">
        Ausdrücklich erwünscht und erlaubt ist die nicht-kommerzielle Nutzung in
        der allgemeinen und beruflichen Bildung, Weiterbildung,
        Integrationsförderung sowie anderen Unterrichts- und Lernsituationen.
        Insoweit wird ein einfaches Nutzungsrecht gewährt. Als Lernendes
        Instrument lebt die App von Ihren Rückmeldungen. Wir freuen uns auf den
        Austausch mit Ihnen unter{' '}
        <a href="https://www.ein-tag-deutsch.de/kontakt">
          www.ein-tag-deutsch.de/kontakt
        </a>
      </p>,
      <p key="20">
        © 2017 IQ Fachstelle Berufsbezogenes Deutsch, passage gGmbH
      </p>,
      <p key="21">
        Die Applikation erfasst keinerlei personenbezogenen Daten.
      </p>,
      <h3 key="22">Umsetzung</h3>,
      <p key="23">
        <strong>ovos media gmbh</strong> <br />Schottenfeldgasse 60/36-38 <br />1070
        Wien
        <br />Firmenbuchnummer: FN 252623 f <br />UID-Nummer: ATU61137308
      </p>,
      <p key="24">
        <strong>Kontakt</strong> <br />Schottenfeldgasse 60/36-38 <br />1070
        Wien
        <br />T: 01 8903389 <br />F: 01 890338920 <br /> M: office@ovos.at{' '}
        <br /> <a href="https://www.ovos.at">www.ovos.at</a>
      </p>
    ]
  },
  kritik: {
    categoryHeading: 'Infos zum Pflegealltag in Deutschland',
    heading: 'Mit Kritik umgehen',
    content: [
      <p key="0">
        In der täglichen Arbeit auf den Stationen kann es zu Missverständnissen
        und Konflikten mit Patienten und ihren Angehörigen kommen. In einem
        modernen Krankenhaus als Wirtschaftsunternehmen ist es wichtig, auf
        Kritik und Wünsche von Patienten und ihren Angehörigen einzugehen. Denn
        sie sind Kundinnen und Kunden und sollen zufrieden sein.
      </p>,
      <p key="1">
        Beschwerden der Patientinnen und Patienten sollten unbedingt ernst
        genommen werden, damit die Patienten nicht verärgert reagieren. Wünsche
        und Kritik von Patientinnen und Patienten können wichtige Informationen
        darüber liefern, wie der Heilungsprozess verläuft.
      </p>,
      <p key="2">
        Die Pflegefachkraft sollte aktiv auf die Menschen zugehen, die Wünsche
        und Kritik äußern, und das Gespräch suchen. Dadurch zeigt sie den
        Bedürfnissen der Patientinnen und Patienten gegenüber Respekt und
        Aufmerksamkeit. Es ist hilfreich, wichtige Wünsche und Anregungen zu
        dokumentieren.
      </p>,
      <p key="3">
        In manchen Krankenhäusern existiert ein Beschwerdemanagement: Es gibt
        einen formalen Ablauf für den Umgang mit Kritik. In einem
        Beschwerdemanagement-Training können Mitarbeiterinnen und Mitarbeiter
        lernen, wie sie mit der Unzufriedenheit und Kritik von Patientinnen und
        Patienten angemessen umgehen.
      </p>
    ]
  },
  aktivesZuhören: {
    heading: 'Aktives Zuhören',
    content: [
      <p key="0">
        Eine Pflegefachkraft muss über kommunikative Kompetenzen verfügen, damit
        sie erfolgreich Gespräche führen kann. Die Gespräche sind erfolgreich,
        wenn es gelingt, alle Informationen zur Situation der Patientin/des
        Patienten zu bekommen, die für eine gute Versorgung der Patientin/des
        Patienten wichtig sind. Das ist nicht immer einfach. Die Strategie{' '}
        <strong>Aktives Zuhören</strong> verbessert die Kommunikation mit
        Patientinnen und Patienten und deren Angehörigen.
      </p>,
      <p key="1">
        Die Pflegefachkraft konzentriert sich ganz auf das Gespräch und hört
        sehr aufmerksam zu. Sie versucht, die Perspektive der Patientin/des
        Patienten einzunehmen. Sie benutzt die folgenden Techniken:
      </p>,
      <ul key="2">
        <li>Die Pflegefachkraft hält während des Gesprächs Blickkontakt.</li>
        <li>
          Sie lässt ihre Gesprächspartnerin/ihren Gesprächspartner ausreden und
          unterbricht sie/ihn nicht.
        </li>
        <li>
          Sie bestätigt ihre Aufmerksamkeit immer wieder durch Kopfnicken und
          „mmh“ sagen.
        </li>
        <li>
          Sie fasst die Aussagen der Gesprächspartnerin/des Gesprächspartners
          noch einmal zusammen, um zu überprüfen, ob sie richtig verstanden hat
        </li>
        <li>
          Sie fragt nach, wenn etwas unklar ist oder besonders wichtig scheint.
        </li>
        <li>
          Sie „verbalisiert“: Sie spricht aus, was die Patientin/der Patient
          durch Mimik und Körpersprache ausdrückt und selbst nicht sagt.
        </li>
      </ul>,
      <p key="3">
        Die Techniken des aktiven Zuhörens machen es den Patienten leichter,
        über Sorgen und Ängste zu sprechen und sich im Gespräch zu öffnen.
        Schülerinnen und Schüler der Krankenpflege in Deutschland lernen diese
        Strategie während der Ausbildung.
      </p>
    ]
  },
  compliance: {
    heading: 'Compliance',
    content: [
      <p key="0">
        Die Begriffe Compliance und Adhärenz werden oft synonym gebraucht.
      </p>,
      <p key="1">
        Mit Compliance (engl.: „Einhalten, Befolgung“) beschreibt man die aktive
        Mithilfe der Patienten bei der Therapie. Dazu gehört, dass Patienten
        sich an die Anweisungen und Ratschläge des Fachpersonals (Ärzte und
        Pflegefachkräfte) halten, z.B. wenn sie eine bestimmte Diät einhalten
        oder körperliche Übungen machen sollen. Durch ihre Compliance übernehmen
        Patienten Verantwortung und tragen erheblich zum Erfolg einer Therapie
        bei.
      </p>,
      <p key="2">
        Für Patienten kann eine niedrige Compliance eine große Gefahr für die
        Gesundheit sein. Besonders bei langfristigen Maßnahmen (z.B. das
        Einhalten einer Diät bei Diabetes oder aktive Bewegungsübungen nach
        Fußamputation) kann es für chronisch kranke Patienten im Alltag
        schwierig sein, therapietreu zu bleiben und die pflegerischen und
        ärztlichen Hinweise immer umzusetzen. Diese Patienten brauchen viel
        Unterstützung von ihren Angehörigen.
      </p>,
      <p key="3">
        Es ist eine anspruchsvolle Aufgabe für Pflegefachkräfte, die
        Patientenedukation so zu gestalten, dass Patienten und Angehörige
        dauerhaft und aktiv an der Therapie mitarbeiten. Es gibt aber auch viele
        Faktoren, auf die weder das Fachpersonal noch die Patienten Einfluss
        haben und die den Therapieerfolg gefährden können (z.B. andere neue
        Krankheiten, Naturkatastrophen, Krankheit/Tod von Angehörigen, Flucht).
      </p>
    ]
  },
  schmerzskala: {
    heading: 'Schmerzskala',
    content: [
      <img key="0" src={require('../../static/images/schmerzskala.jpg')} alt="Schmerzskala"/>,
      <p key="1">
        Die bekannteste Skala zur Messung der Schmerzstärke ist die numerische
        Rangskala (NRS). Der Patient ordnet seine Schmerzen einer Zahl zwischen
        0 und 10 zu. 0 steht für „kein Schmerz“ und 10 für „stärkster
        vorstellbarer Schmerz“. Die numerische Rangskala gibt es in
        verschiedenen Versionen: auf Papier zum Eintragen für den Patienten, als
        Schmerzlineal (der Patient kann seinen Schmerz durch einen Schieber
        darstellen), oder als mündliche Abfrage. Das Pflegepersonal fragt dann:
        „Wie hoch schätzen Sie Ihre Schmerzen auf einer Skala von 0 -10 ein?“.
        Die numerische Rangskala ist unkompliziert und wird von den meisten
        Patienten ohne Probleme angenommen.
      </p>
    ]
  },
  kommunikation: {
    heading: 'Tipps zur Kommunikation',
    content: [
      <p key="0">
        Pflegebedürftige wissen oftmals nicht, welcher (Pflege-)Schritt folgt
        und ob für sie unangenehme Konsequenzen wie Schmerz, Warten,
        Unsicherheit erfolgen. Eine beruhigende Ausdrucksweise des
        Pflegepersonals kann helfen, unangenehme Gefühle zu verringern. Hier ein
        paar Tipps:
      </p>,
      <p key="1">Angst erzeugt man durch:</p>,
      <ul key="2">
        <li>Worte wie: „Achtung“ , „Stopp!“, „Problem“</li>
        <li>
          Modalverben, die Stress, Druck und Pflicht ausdrücken: „Wir müssen…“,
          „Sie sollen…“
        </li>
        <li>
          „Negative“ Wörter wie: schnell, dringend, sofort, schlimm,
          schrecklich, furchtbar, unfassbar
        </li>
        <li>Ausdrücke wie „Das geht so nicht.“, „Das sieht nicht gut aus.“</li>
      </ul>,
      <p key="3">Sie beruhigen durch:</p>,
      <ul key="4">
        <li>
          Worte, die positive Assoziationen erzeugen: „besser gehen“, „etwas in
          Ruhe machen“
        </li>
        <li>
          Persönliche Rückfragen am Ende des Satzes wie: „Ist das in Ordnung für
          Sie?“, „Einverstanden?“, „Was meinen Sie?“
        </li>
        <li>Darüber informieren, was als nächstes passiert.</li>
        <li>
          Positive Perspektiven bieten: „Das geht in Ordnung.“, „Das schaffen
          wir.“, „Seien Sie ganz beruhigt.“
        </li>
      </ul>
    ]
  },
  smalltalk: {
    heading: 'Small Talk am Arbeitsplatz',
    content: [
      <p key="0">
        Small Talk (engl.: „kleines Reden“) steht für ein Gespräch ohne viel
        Inhalt. Das Ziel ist Höflichkeit. Mit Small Talk an einem neuen
        Arbeitsort kann man mit den neuen Kollegen ins Gespräch kommen und den
        Kontakt aufbauen.
      </p>,
      <p key="2">
        Small Talk findet oft in Verbindung mit Begrüßung oder Verabschiedung
        statt. Wenn Kollegen sich im Dienstzimmer treffen, sprechen sie auf
        jeden Fall ein paar Worte miteinander. Auch wenn man gemeinsam Pause
        macht, ist Small Talk eine gute Möglichkeit, sich kennen zu lernen.
        Durch Small Talk kann man auch Neuigkeiten im Team und auf der Station
        erfahren. Man kann unangenehmes Schweigen überbrücken, wenn man mit
        fremden Personen etwas gemeinsam tun muss (z.B. in der Apotheke auf
        Medikamente warten).
      </p>,
      <p key="3">
        Die Themen Wetter, Beruf, Sport, Reisen, Filme & Theater sowie Familie
        (besonders Kinder) eignen sich gut für Small Talk. Unangemessene Themen
        sind Religion, Geld, Tod, Politik, Sex und persönliche Probleme.
      </p>,
      <p key="4">Was beim Small Talk nicht gut ist:</p>,
      <ul key="5">
        <li>schlecht über Dritte sprechen („lästern“) </li>
        <li>Pflegedienstleitung einer Klinik oder Abteilung</li>
        <li>zu neugierig fragen </li>
        <li>lange über sich selbst sprechen </li>
        <li>„jammern“ (sich über die eigenen Schwierigkeiten beklagen)</li>
      </ul>,
      <p key="6">
        Auf jeder Station gibt es{' '}
        <strong>unterschiedliche Gesprächskulturen</strong>. Auf manchen
        Stationen geht es recht familiär (informell) zu. Auf anderen Stationen
        eher formell. Beobachten Sie, wie Ihre Kolleginnen und Kollegen
        miteinander kommunizieren: Über welche Themen spricht man (nicht)?
      </p>
    ]
  },
  pflegefachkraft: {
    heading: 'Das Berufsbild der Pflegefachkraft',
    content: [
      <p key="0">
        In Deutschland macht eine Pflegefachkraft eine 3-jährige Ausbildung in
        der Gesundheits- und Krankenpflege. Sie pflegt, betreut und berät
        pflegebedürftige Menschen. Sie kann in Krankenhäusern (stationär), in
        Seniorenzentren (stationär) oder in der Ambulanten Pflege (auch:
        „Akutpflege“ oder „Häusliche Pflege“) tätig sein.
      </p>,
      <p key="1">
        Zu ihren Aufgaben gehören <strong>praktische</strong> Tätigkeiten
        (Grundpflege):
      </p>,
      <ul key="2">
        <li>Körperpflege wie Waschen, Duschen und Toilettengänge</li>
        <li>Hilfe beim Essen</li>
        <li>Wund- und Hautbehandlung, Verbandswechsel</li>
        <li>
          Vitalzeichenkontrolle: Messen von Blutdruck, Temperatur, Puls und
          Atmung
        </li>
        <li>Prophylaxen wie Dekubitus- und Sturzprophylaxe</li>
        <li>Sondierungen legen und Spülungen durchführen</li>
        <li>Überwachen von Geräten</li>
        <li>Verabreichen und Kontrolle von Arzneimitteln</li>
      </ul>,
      <p key="3">
        Sehr wichtig ist die sorgfältige Beobachtung der Pflegebedürftigen, die
        Identifizierung der Ressourcen und die Formulierung der Pflegemaßnahmen.
        Das Dokumentieren der Daten passiert manchmal handschriftlich, meistens
        aber am Computer.
      </p>,
      <p key="4">
        Insgesamt kann man die <strong>beruflichen Tätigkeiten</strong> von
        Pflegefachkräften folgendermaßen beschreiben:
      </p>,
      <ul key="5">
        <li>
          Betreuung von Pflegebedürftigen, Schwerstkranken und Begleitung
          Sterbender
        </li>
        <li>
          Beratung alter Menschen in ihren sozialen und persönlichen
          Angelegenheiten
        </li>
        <li>Mitmachen bei Diagnostik, Therapie und Rehabilitation</li>
      </ul>,
      <p key="6">
        Im Mittelpunkt der Pflegetätigkeit stehen die Pflegbedürftigen und ihre
        Genesung. Dabei sind die <strong>kommunikativen</strong> Kompetenzen
        enorm wichtig. Denn eine Pflegefachkraft ist Ansprechpartner für die
        Pflegebedürftigen und ihre Angehörigen. Sie hat ein offenes Ohr für die
        Sorgen der Menschen und informiert und berät auf empathische Art und
        Weise. Außerdem informiert sie über die nächsten pflegerischen Schritte.
        Sie bereitet z.B. die Patienten und ihre Angehörigen darauf vor,
        Pflegemaßnahmen zu Hause selbst durchzuführen.
      </p>,
      <p key="7">
        Eine ausgebildete Pflegefachkraft steht im Zentrum der Kommunikation
        zwischen Therapeuten, Ärzten, Angehörigen und Patienten. Bei ihr laufen
        alle Informationen zusammen und sie muss diese Informationen
        organisieren, weitergeben und dokumentieren.
      </p>
    ]
  },
  pflegedokumentation: {
    heading: 'Elemente der Pflegedokumentation',
    content: [
      <p key="0">
        Die Pflegedokumentation bildet die Basis für die Kommunikation zwischen
        dem Pflegepersonal, den Ärzten, den Patienten und anderen Professionen
        in Pflegeeinrichtungen. Zusätzlich verlangt der Medizinische Dienst der
        Krankenkassen (MDK) eine sorgfältige Pflegedokumentation in der
        stationären Pflege für die Abrechnung der Leistung. Dafür sind folgende
        Informationen notwendig:{' '}
        <strong>
          Stammdaten, Pflegedaten/ Informationssammlung, Pflegeplanung/
          Pflegeplan, Durchführungsnachweis, Pflegebericht, Überleitungsbogen
        </strong>
      </p>,
      <h3 key="1">Stammdaten</h3>,
      <p key="2">
        Die Stammdaten sind die persönlichen Daten der Patientin/des Patienten
        (Name, Geburtsdatum, Adresse, Telefonnummer, Hausarzt sowie Kontaktdaten
        von Angehörigen). Diese Daten werden meistens in eine Tabelle
        eingetragen.
      </p>,
      <h3 key="3">Pflegedaten / Informationssammlung</h3>,
      <p key="4">
        Zu den Pflegedaten gehören alle wichtigen Informationen über den Zustand
        der Patientin/des Patienten: Einweisungsdiagnose, Laborwerte,
        Allergien/Unverträglichkeiten, Ergebnisse des Anamnesegesprächs,
        Ergebnisse der körperlichen Untersuchung und Pflegediagnosen. Oft werden
        Stammdaten und Pflegedaten gemeinsam auf einem{' '}
        <strong>Stammblatt</strong> dokumentiert.
      </p>
    ]
  },
  patientenedukation: {
    heading: 'Patientenedukation',
    content: [
      <p key="0">
        Der Begriff Patientenedukation (lat.: Bildung/Ausbildung der Patienten)
        beschreibt die Information, Beratung und Schulung von Patienten und
        Angehörigen durch das Pflegepersonal. Zur Patientenedukation gehören
        psychologische und pädagogische Gespräche und Aktivitäten zwischen dem
        Pflegepersonal und den Patienten und ihren Angehörigen. Das
        Pflegepersonal informiert, berät und schult die Patienten, wie sie ihren
        Gesundheitszustand stabilisieren und verbessern können. Da Patienten in
        Deutschland einen relativ kurzen Klinikaufenthalt haben (1991: 14,0
        Tage; 2016: 7,4 Tage), spielt die Patientenedukation eine große Rolle
        für die Zeit nach dem Krankenhausaufenthalt. Wenn Patienten durch gute
        Information und Schulung ihren Gesundheitszustand auch zu Hause
        stabilisieren oder verbessern können, kommen sie nicht so schnell wieder
        ins Krankenhaus.
      </p>,
      <p key="1">
        Patientenedukation hat das Ziel, Patienten zur Selbstpflege zu
        motivieren, damit sie ihren Alltag so autonom wie möglich gestalten
        können. Die Idee ist, dass auch die Patienten und ihre Bezugspersonen
        einen Teil der Verantwortung für den Therapieerfolg übernehmen.
      </p>,
      <p key="2">
        Die Patientenedukation gehört zu den Aufgaben der Pflege im Rahmen der
        Gesundheitsförderung, die in Deutschland sehr wichtig ist. Die
        Patientenedukation ist Teil des Krankenpflegegesetzes. (KrPflG Abschnitt
        2,§3).
      </p>
    ]
  },
  pflegeplanung: {
    heading: 'Pflegeplanung und Durchführungsnachweis',
    content: [
      <h3 key="5">Pflegeplanung / Pflegeplan</h3>,
      <p key="6">
        Die Pflegeplanung zeigt die einzelnen Elemente der Pflege während des
        Krankenausaufenthalts der Patientin/des Patienten. Die Formulare können
        unterschiedlich aussehen, es gibt aber immer die drei Stufen (1)
        Ressourcen/Probleme, (2) Pflegeziele und (3) Maßnahmen. In (1) notieren
        die Pflegenden ein akutes Problem und die Kompetenz der Patienten, an
        der Lösung mitzuwirken (Ressource). In (2) beschreibt die
        Pflegefachkraft das Ziel, das erreicht werden soll. In (3) legt sie
        fest, was die Pflegenden konkret machen, damit das Ziel erreicht wird.
        Die Pflegenden dokumentieren ihre Eintragungen mit Datum und
        Kurzunterschrift.
        <br />
        <br />
        Beispiel:
      </p>,
      <div key="7" style={{ overflow: 'auto' }}>
        <table>
          <tbody>
            <tr>
              <th>Datum</th>
              <th>Hz.</th>
              <th>Nr.</th>
              <th>Ressourcen / Probleme</th>
              <th>Pflegeziele</th>
              <th>Maßnahmen</th>
            </tr>
            <tr>
              <td>02.09.</td>
              <td>Kl.</td>
              <td>1</td>
              <td>
                P: Frau M. kann nicht gut schlafen, weil es auf der Station
                nachts unruhig und laut ist. R: Frau M. kann ihren Wunsch/ ihr
                Problem ausdrücken.
              </td>
              <td>Frau M. hat ausreichend erholsamen Schlaf.</td>
              <td>
                Für mehr Ruhe auf Station und im Zimmer sorgen. Bei Bedarf
                Oropax anbieten.
              </td>
            </tr>
          </tbody>
        </table>
      </div>,
      <figcaption key="figure" style={{ fontSize: '12px' }}>
        Hz Handzeichen (Kurzunterschrift) der Pflegefachkraft, Nr Nummer, P
        Problem, R Ressource
      </figcaption>,
      <h3 key="8">Durchführungsnachweis</h3>,
      <p key="9">
        Der Durchführungsnachweis gibt eine Übersicht über alle
        Pflegeleistungen, die für die Patientin/den Patienten während
        ihres/seines Aufenthaltes gemacht werden (Messwerte, Beobachtungen,
        Untersuchungen, Medikationen, Therapien). Jeder Eintrag in den
        Durchführungsnachweis braucht Datum und Kurzunterschrift der
        Pflegefachkraft. Der Durchführungsnachweis ist wichtig für die
        Abrechnung der Krankenhäuser mit den Krankenkassen.
      </p>
    ]
  },
  krankenhausstruktur: {
    heading: 'Die deutsche Krankenhausstruktur',
    content: [
      <p key="0">
        In deutschen Krankenhäusern gibt es eine klare Hierarchie unter
        Ärztinnen und Ärzten. Das ärztliche Hierarchie-System sieht so aus:
      </p>,
      <ul key="2">
        <li>Chefärztin/ Chefarzt des Krankenhauses</li>
        <li>Oberärztin/ Oberarzt einer Klinik oder Abteilung</li>
        <li>Fachärztin/ Facharzt auf einer Station</li>
        <li>Assistenzärztin/ Assistenzarzt</li>
        <li>Gastärztin/ Gastarzt</li>
        <li>
          Medizinstudierende im praktischen Jahr (im letzten Jahr des Studiums)
        </li>
        <li>
          Medizinstudierende in der Famulatur (zwischen 1.und 2. Abschnitt der
          Ärztlichen Prüfung)
        </li>
      </ul>,
      <p key="3">
        Oft ist die Chefärztin/der Chefarzt des Krankenhauses auch
        Leiterin/Leiter des Krankenhauses als wirtschaftliche Einheit und trifft
        wirtschaftliche Entscheidungen. Krankenhäuser als Unternehmen haben
        Geschäftsführer und unterschiedliche Leitungsgremien (z.B. Kirchen,
        Universitäten oder Vertreter von Privatunternehmen).
      </p>,
      <p key="4">Die Hierarchie beim Pflegepersonal sieht so aus:</p>,
      <ul key="5">
        <li>Pflegedienstdirektion des Krankenhauses</li>
        <li>Pflegedienstleitung einer Klinik oder Abteilung</li>
        <li>Stationsleitung</li>
        <li>Pflegefachkraft</li>
        <li>Pflegeassistenz</li>
      </ul>,
      <p key="6">
        Die Pflegedienstdirektion leitet die Pflegeorganisation des ganzen
        Krankenhauses. Pflegedienstleitungen führen die Pflegekräfte einzelner
        Kliniken oder Abteilungen (z.B. der Geriatrie, die aus drei Stationen
        bestehen kann). Die Stationsleitungen leiten die einzelnen Stationen
        (z.B. die drei geriatrischen Stationen). Auf den Stationen arbeiten
        Pflegefachkräfte, die die volle Verantwortung für eine Station oder
        einen Teil der Station während ihres Dienstes übernehmen. In einigen
        Einrichtungen - meistens in Seniorenzentren - arbeiten auch
        Pflegeassistenten. Sie dürfen aber nur auf Anweisung der
        Pflegefachkräfte handeln und nur bestimmte pflegerische Tätigkeiten
        ausführen.
      </p>,
      <p key="7">
        Ärztinnen und Ärzte treffen medizinische Anordnungen, die die
        Pflegefachkräfte ausführen. Sie sind aber keine direkten Vorgesetzten
        der Pflegefachkräfte. Wenn es innerhalb des Pflegeteams zu
        Schwierigkeiten kommt, z.B. zu Pflegefehlern oder Konflikten im Team,
        dann ist die Stationsleitung zuständig. Die beiden Hierarchiesysteme
        existieren nebeneinander.
      </p>
    ]
  },
  pflegebericht: {
    heading: 'Pflegebericht und Überleitungsbogen',
    content: [
      <h3 key="10">Pflegebericht</h3>,
      <p key="11">
        Hier machen die Pflegefachkräfte wichtige Anmerkungen zu aktuellen
        Veränderungen im Befinden der Patienten. Das können neue Pflegeprobleme
        sein, die plötzlich auftreten, oder eine besondere Reaktion auf
        Pflegemaßnahmen. Auch andere Besonderheiten (z.B. Streit mit
        Angehörigen) oder Veränderungen der Stimmung (z.B. Traurigkeit) werden
        im Pflegebericht dokumentiert.
      </p>,
      <p key="12">Wenn Sie einen Pflegebericht schreiben, sollten Sie:</p>,
      <ul key="13">
        <li>so kurz und knapp wie möglich beschreiben </li>
        <li>
          Sätze nicht mit „Ich“ beginnen, sondern: Pat. oder Frau/Herr B. hat
          laut eigener Aussage starke Bauchkrämpfe.
        </li>
        <li>
          direkte Aussagen der Pflegebedürftigen in Anführungszeichen notieren
          („Ich habe höllische Schmerzen beim Wasserlassen.“) oder indirekt
          (Frau B./Pat. äußerte, dass sie höllische Schmerzen beim Wasserlassen
          habe.“)
        </li>
        <li>
          Situationen und Verhalten der Patienten objektiv beschreiben, bitte
          keine umgangssprachlichen Wörter wie „total“, „supi“ oder „Pat. hat
          genervt.“.
        </li>
        <li>
          deutlich, leserlich schreiben, so dass Kolleginnen und Kollegen den
          Bericht gut nachvollziehen können.
        </li>
      </ul>,
      <h3 key="14">Überleitungsbogen</h3>,
      <p key="15">
        Wenn die Patienten in eine andere ambulante oder stationäre Einrichtung
        wechseln, nehmen sie den Überleitungsbogen mit. Er enthält die
        Stammdaten und Informationen über den aktuellen Allgemeinzustand sowie
        die wichtigsten Pflegedaten. Diese Informationen helfen dem Pflegeteam
        der neuen Einrichtung, die Patientin/den Patienten gut zu versorgen.
      </p>
    ]
  },
  notfall: {
    heading: 'Verhalten im Notfall: Sturz im Krankenhaus',
    content: [
      <p key="0">
        Wenn eine Patientin/ein Patient im Krankenhaus stürzt, ist das richtige
        Verhalten der Pflegefachkräfte sehr wichtig. Es gibt folgende Regeln:
      </p>,
      <ol key="1">
        <li>
          Bleiben Sie ruhig, damit die Patientin/der Patient sich auch beruhigen
          kann.
        </li>
        <li>
          Überprüfen Sie, ob die Patientin/der Patient bei Bewusstsein ist.
        </li>
        <li>Fragen Sie nach Beschwerden und Schmerzen.</li>
        <li>
          Wenn die Patientin/der Patient nicht reagiert, kontrollieren Sie die
          Vitalfunktionen
          <ul>
            <li>B = Bewusstsein</li>
            <li>A= Atmung</li>
            <li>K= Kreislauf</li>
          </ul>
        </li>
        <li>
          Wenn die Patientin/der Patient bewusstlos ist, bringen Sie sie/ihn in
          die stabile Seitenlage und rufen den Arzt.
        </li>
        <li>
          Ist die Patientin/der Patient in gutem Zustand, erklären Sie ihr/ihm,
          wie sie/er sich über den <strong>Vierfüßlerstand</strong> aufrichten
          kann und helfen ihr/ihm dabei. Wenn Sie das allein nicht schaffen,
          klingeln Sie nach Hilfe. Lassen Sie die Patientin/den Patienten auf
          keinen Fall allein! Nachdem sich der Patient aufgerichtet hat, holen
          Sie auf jeden Fall den Arzt.
        </li>
        <li>
          Bei offenen Wunden oder Blutungen rufen Sie sofort den Arzt. Decken
          Sie offene Wunden steril ab, stillen Sie die Blutung.
        </li>
        <li>
          Wenn Sie glauben, dass die Patientin/der Patient eine Fraktur hat,
          rufen Sie sofort den Arzt. Bewegen Sie die Patientin/den Patienten
          nicht. Sorgen Sie für Wärme und Bequemlichkeit (z.B. Decke und
          Kopfkissen).
        </li>
      </ol>,
      <p key="2">
        Sie müssen jeden Sturz im Krankenhaus dokumentieren. Viele Krankenhäuser
        und Pflegeeinrichtungen haben dafür spezielle Formulare. Wichtig sind
        die beiden Fragen: Warum ist der Sturz passiert? Was kann man tun, damit
        das nicht noch einmal passiert?
      </p>
    ]
  },
};

export default entries;
