import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'exercises', 'mrsTellerExercise2', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.mrsTellerExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['mrsTeller', 'mrsTellerExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Empfehlungen zur Ernährung',
      text: 'Was raten Sie den Angehörigen dieser Patienten?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Was ist richtig?',
          question: 'Herr Tommes leidet unter starkem Bluthochdruck.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: `„Ihre Frau sollte gesund und abwechslungsreich essen. Aber eine Diät muss sie nicht machen.“
`
            },
            {
              id: 2,
              data: '„Ihre Frau sollte möglichst auf Weißmehl und Süßigkeiten verzichten.“'
            },
            {
              id: 3,
              meta: { correct: 1 },
              data: '„Für Ihren Mann ist es sehr wichtig, dass er sich salzarm ernährt.“'
            },
            {
              id: 4,
              data: '„Ihr Mann sollte lieber mehrere kleine Mahlzeiten am Tag zu sich nehmen als drei große.“'
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'Herr Weller ist Typ 1-Diabetiker.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: '„Für Ihren Mann ist es sehr wichtig, dass er sich salzarm ernährt.“'
            },
            {
              id: 2,
              data: '„Ihre Frau sollte möglichst auf Weißmehl und Süßigkeiten verzichten.“'
            },
            {
              id: 3,
              data: '„Ihre Frau sollte gesund und abwechslungsreich essen. Aber eine Diät muss sie nicht machen.“'
            },
            {
              id: 4,
              meta: { correct: 1 },
              data: '„Ihr Mann sollte lieber mehrere kleine Mahlzeiten am Tag zu sich nehmen als drei große.“'
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'Frau Lehmann hat Diabetes 2.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: '„Ihre Frau sollte möglichst auf Weißmehl und Süßigkeiten verzichten.“'
            },
            {
              id: 2,
              data: '„Ihr Mann sollte lieber mehrere kleine Mahlzeiten am Tag zu sich nehmen als drei große.“'
            },
            {
              id: 3,
              data: '„Ihre Frau sollte gesund und abwechslungsreich essen. Aber eine Diät muss sie nicht machen.“'
            },
            {
              id: 4,
              data: '„Für Ihren Mann ist es sehr wichtig, dass er sich salzarm ernährt.“'
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'Herr Meinert leidet unter starkem Übergewicht.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: '„Ihr Mann sollte Diät halten und sich möglichst kalorienarm ernähren.“'
            },
            {
              id: 2,
              data: '„Ihre Frau sollte möglichst auf Weißmehl und Süßigkeiten verzichten.“'
            },
            {
              id: 3,
              data: '„Ihr Mann sollte lieber mehrere kleine Mahlzeiten am Tag zu sich nehmen als drei große.“'
            },
            {
              id: 4,
              data: '„Ihre Frau sollte gesund und abwechslungsreich essen. Aber eine Diät muss sie nicht machen.“'
            }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'Frau Alvez-Romero hat entbunden und stillt ihr Baby.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: '„Für Ihren Mann ist es sehr wichtig, dass er sich salzarm ernährt.“'
            },
            {
              id: 2,
              data: '„Ihre Frau sollte möglichst auf Weißmehl und Süßigkeiten verzichten.“'
            },
            {
              id: 3,
              meta: { correct: 1 },
              data: '„Ihre Frau sollte gesund und abwechslungsreich essen. Aber eine Diät muss sie nicht machen.“'
            },
            {
              id: 4,
              data: '„Ihr Mann sollte lieber mehrere kleine Mahlzeiten am Tag zu sich nehmen als drei große.“'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
