import React from 'react';

export const ScrollIndicator = ({ size, className, fill }) =>
  <svg
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style={{ width: size, height: size }}
    className={className}
    fill={fill}
  >
    <path d="M74.3,64.1c-0.3-0.2-5.7-2.8-12.9-4.6V38.8c7.2-1.8,12.6-4.5,12.9-4.6c0.6-0.3,0.9-1.1,0.6-1.7c-0.3-0.6-1.1-0.9-1.7-0.6
    	c-0.1,0-5.1,2.5-11.8,4.3v-4.4c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v5C55.8,37.5,52.4,38,49,38c-2.9,0-5.9-0.4-8.7-0.9v-5.3
    	c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v4.7c-7.4-1.8-13-4.6-13.1-4.6c0,0,0,0,0,0c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.2,0-0.2,0
    	c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.1,0.1-0.2,0.2
    	c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0,0,0,0,0v32.2c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2
    	c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0.1,0c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0,0.1,0
    	c0.1,0.1,0.3,0.1,0.4,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.4,0,0.5-0.1c0,0,0,0,0,0c0.1,0,5.7-2.8,13.1-4.6
    	v3.5c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2v-4c2.8-0.5,5.7-0.9,8.7-0.9c3.4,0,6.8,0.5,9.9,1.2v3.8c0,0.7,0.6,1.2,1.2,1.2
    	s1.2-0.6,1.2-1.2V62c6.7,1.8,11.8,4.3,11.8,4.3c0.6,0.3,1.4,0.1,1.7-0.6C75.2,65.2,74.9,64.4,74.3,64.1z M25.5,63.3V34.9
    	c2.4,1,6.9,2.9,12.4,4.1v20.1C32.4,60.4,27.8,62.2,25.5,63.3z M49,57.8c-3,0-5.9,0.3-8.7,0.9V39.6c2.8,0.5,5.7,0.9,8.7,0.9
    	c3.4,0,6.8-0.5,9.9-1.1v19.6C55.8,58.2,52.4,57.8,49,57.8z" />
    <path d="M73.8,66.5c-0.7,0-1.2-0.6-1.2-1.2V33c0-0.7,0.6-1.2,1.2-1.2S75,32.3,75,33v32.2C75,65.9,74.5,66.5,73.8,66.5z" />
    <path d="M84.9,57.2c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.6,0-0.9l5.8-5.8l-5.8-5.8c-0.2-0.2-0.2-0.6,0-0.9c0.2-0.2,0.6-0.2,0.9,0
    	l6.6,6.6L85.4,57C85.2,57.1,85.1,57.2,84.9,57.2z" />
    <path d="M13.1,57.2c-0.2,0-0.3-0.1-0.4-0.2L6,50.4l6.6-6.6c0.2-0.2,0.6-0.2,0.9,0c0.2,0.2,0.2,0.6,0,0.9l-5.8,5.8l5.8,5.8
    	c0.2,0.2,0.2,0.6,0,0.9C13.4,57.1,13.2,57.2,13.1,57.2z" />
  </svg>;
