import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function exitDialogue() {
  store.dispatch(stepsActions.set(['step4', 'choices', 'getMilo'], true));
  store.dispatch(stepsActions.setRoom('hospitalRoom'));
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'dialogues', 'accident', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentDialogue'], 'accident'));
}

export default {
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/office/milo-portrait.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Milo, kannst du mal bitte kurz kommen. Herr Teller ist gestürzt.',
      audio: '214-Sturz-Milo_ID_2.mp3'
    },
    1: {
      from: 'ai',
      text: 'Komme. Ist er jetzt ganz alleine?',
      audio: '214-Sturz-Milo_ID_3.mp3'
    },
    2: {
      from: 'player',
      text: 'Ja.',
      audio: '214-Sturz-Milo_ID_4.mp3'
    },
    3: {
      from: 'ai',
      text: 'Oh, das geht aber nicht. Du darfst ihn auf keinen Fall alleine lassen. Klingel bitte beim nächsten Mal.',
      audio: '214-Sturz-Milo_ID_5.mp3'
    },
    4: {
      from: 'player',
      text: 'Du hast Recht. Ich bin ein bisschen aufgeregt. Ich gehe jetzt sofort zu ihm zurück.',
      audio: '214-Sturz-Milo_ID_6.mp3'
    },
    5: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
