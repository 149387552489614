import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function finishDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'corridor', 'dialogues', 'goingHome', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'corridor', 'dialogues', 'gameOver', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentDialogue'], 'gameOver'));
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/office/milo-portrait.png')
    }
  },
  flow: {
    0: {
      from: 'ai',
      text: 'Gehen wir zusammen raus?',
      audio: '217-Abspann_ID_1.mp3',
      nextSnippet: '1'
    },
    1: {
      from: 'player',
      text: 'Ja, gerne.',
      audio: '217-Abspann_ID_2.mp3',
      nextSnippet: '2'
    },
    2: {
      from: 'player',
      text: 'Puh. Was für ein Tag: Zuerst der traurige Herr Teller, dann die Diskussion mit Dr. Thewes und zum Schluss noch der Sturz.',
      audio: '217-Abspann_ID_3.mp3',
      nextSnippet: '3'
    },
    3: {
      from: 'ai',
      text: `Du, ganz ehrlich, ${store.getState().user
        .name}. Das war echt super, wie du das heute alles gemacht hast.`,
      audio: '217-Abspann_ID_4.mp3',
      nextSnippet: '4'
    },
    4: {
      from: 'player',
      text: 'Findest du?',
      audio: '217-Abspann_ID_5.mp3',
      nextSnippet: '5'
    },
    5: {
      from: 'player',
      text: 'Danke. Aber ihr unterstützt mich auch wirklich sehr.',
      audio: '217-Abspann_ID_6.mp3',
      nextSnippet: '6'
    },
    6: {
      from: 'ai',
      text: 'Jetzt hast du dir erst mal einen ruhigen Abend verdient. Was machst du denn heute noch?',
      audio: '217-Abspann_ID_7.mp3',
      nextSnippet: '7'
    },
    7: {
      from: 'player',
      text: 'Ich werde mich erst mal ausruhen und noch ein bisschen über den Tag nachdenken. Und dann freue ich mich auf meinen nächsten Fall.',
      audio: '217-Abspann_ID_8.mp3',
      nextSnippet: 'fin'
    },
    fin: {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: '8',
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },
    8: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        finishDialogue();
      },
      correct: true
    }
  }
};
