import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { VelocityTransitionGroup } from 'velocity-react';
import { PageTransitions } from '../lib/velocityTransitions';

import pages from '../pages';
import { actions as appActions } from '../reducers/app';

import styles from './pageRouter.less';

const mapStateToProps = ({ app }) => ({
  page: app.get('page')
});

const defaultPageConfiguration = {};

class PageRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageConfiguration: defaultPageConfiguration
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const page = this.props.page;

    this.loadPage(page);

    window.addEventListener('popstate', e => {
      const { state } = e;

      dispatch(appActions.setPage(state || 'home', { ignoreHistory: true }));
    });
  }

  componentDidUpdate(prevProps) {
    const { page } = prevProps;
    if (page !== this.props.page) {
      this.loadPage(this.props.page);
    }
  }

  render() {
    const { page } = this.props;
    const { PageComponent } = this.state;

    if (PageComponent) {
      const pageSpecificProps = this.props.config[page] || {};

      const pageProps = {
        ...this.props,
        ...pageSpecificProps
      };

      const children = PageComponent.$$typeof
        ? PageComponent
        : React.createElement(PageComponent, pageProps);

      return (
        <VelocityTransitionGroup
          enter={{ animation: PageTransitions.fadeIn }}
          leave={{ animation: PageTransitions.fadeOut }}
        >
          <div key={page} className={`${styles.page}`}>
            {children}
          </div>
        </VelocityTransitionGroup>
      );
    }

    return null;
  }

  loadPage = page => {
    if (typeof pages[page] === 'undefined') {
      throw new Error(`Tried to open page "${page}" which does not exist!`);
    }

    if (typeof pages[page].load === 'function') {
      pages[page].load(this.loadPageCallback.bind(this, page));
    } else {
      this.loadPageCallback(page, pages[page].load);
    }
  };

  loadPageCallback = (page, module) => {
    let component = null;

    if (module && module.default) {
      component = module.default;
    } else if (typeof module === 'string') {
      component = () =>
        <div>
          {module}
        </div>;
    } else if (typeof module === 'function') {
      return this.loadPageCallback(page, module());
    } else if (typeof module === 'object') {
      if (module.$$typeof && module.$$typeof) {
        component = module;
      }
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.error(module); //eslint-disable-line
      }

      throw new Error(`Invalid type of page module for page "${page}".`);
    }

    return this.setState({
      PageComponent: component,
      pageConfiguration: pages[page]
    });
  };
}

PageRouter.defaultProps = {
  config: {}
};

PageRouter.propTypes = {
  page: PropTypes.string.isRequired, // the config object may contain page specific configuration
  config: PropTypes.object, // eslint-disable-line
  dispatch: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(PageRouter);
