import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function finishStep() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'step1Score', 'overlay'],
      false
    )
  );

  store.dispatch(stepsActions.set(['stepFinished'], true));
  store.dispatch(stepsActions.set(['buttonHintStepFinished'], true));
}

export default {
  flow: {
    7: {
      type: 'score',
      image: () => require('../../../static/images/badges/look-after.png'),
      text: 'Schritt 1 von 4',
      onClick: () => {
        finishStep();
      }
    }
  }
};
