import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'corridor', 'exercises', 'knockingExercise2', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.knockingExercise2 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['knocking', 'knockingExercise2'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Auf beunruhigte Patienten eingehen',
      text: 'Welche Äußerungen klingen empathisch?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Hören Sie die Sätze.',
          question: 'Wählen Sie die vier empathischen aus.',
          correct: 'Gute Antwort, weiter so!',
          partiallyCorrect: 'Das stimmt noch nicht ganz.',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: true,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u36/u36_1.mp3')
                },
                'Machen Sie sich keine Sorgen. Wir sind ja auch noch da.'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u36/u36_2.mp3')
                },
                'Das ist doch Quatsch!'
              ]
            },
            {
              id: 3,
              meta: { correct: 3 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u36/u36_3.mp3')
                },
                'Sie können jederzeit klingeln, wenn Sie Hilfe benötigen.'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u36/u36_4.mp3')
                },
                'Gehen Sie mal schnell wieder auf Ihr Zimmer.'
              ]
            },
            {
              id: 5,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u36/u36_5.mp3')
                },
                'Das ist ja nicht Ihr Problem.'
              ]
            },
            {
              id: 6,
              meta: { correct: 6 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u36/u36_6.mp3')
                },
                'Beruhigen Sie sich. Es ist alles in Ordnung. Wir kümmern uns darum.'
              ]
            },
            {
              id: 7,
              meta: { correct: 7 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u36/u36_7.mp3')
                },
                'Alles in Ordnung bei Ihnen? Kommen Sie zurecht?'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
