import React from 'react';

export const Pronunciation = ({ size, className, fill }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    style={{ width: size, height: size }}
  >
    <title>EinTagDeutsch_Icons-Übungstypen</title>
    <g id="Layer_2" data-name="Layer 2">
      <path
        d="M36.64,35A17.92,17.92,0,0,0,31,31.4c-.47-.19-1-.35-1.43-.5v-.13a12.51,12.51,0,0,0,4.14-7.34,3.5,3.5,0,0,0,1.24-1.53c.64-1.44.38-2.81-.63-3.26a1.48,1.48,0,0,0-.5-.12v-.08C35.12,13.12,36.21,6.88,29,6.71A5,5,0,0,0,23.89,3c-4,0-13.51,3-9.95,15.65h0a1.74,1.74,0,0,0-1,1.56,3.93,3.93,0,0,0,1.34,3.06A12.82,12.82,0,0,0,18,30.4v.67c-.32.11-.64.21-1,.33A17.92,17.92,0,0,0,11.4,35c-1.65,1.56-5.28,6.28-5.28,8.59A1.35,1.35,0,0,0,7.47,45h33.1a1.35,1.35,0,0,0,1.35-1.35C41.92,41.32,38.29,36.6,36.64,35ZM28.55,30.3V31a4.21,4.21,0,0,1-4.2,4.2H23.21A4.21,4.21,0,0,1,19,31V30a9.48,9.48,0,0,1-.71-.66,10.5,10.5,0,0,1-1.25-1.57,12.62,12.62,0,0,1-1.87-5,2.39,2.39,0,0,1-1-1.18,3.14,3.14,0,0,1-.19-.58v0a2.33,2.33,0,0,1-.05-.53s0,0,0,0A1.33,1.33,0,0,1,14,19.9h0a.65.65,0,0,1,.22-.3h0l.08-.06a.89.89,0,0,1,.92.3c.22-2,.72-3.18,2.16-4,2.68-1.44,5.43-1.16,6.49-1.55,2.58-.93,3.84-2.21,4.39-3.58h0a13,13,0,0,1,4.64,9.11,1.14,1.14,0,0,1,.74-.37h0a.52.52,0,0,1,.21,0,.58.58,0,0,1,.27.27,1.91,1.91,0,0,1,0,1.38,3.29,3.29,0,0,1-.13.33,2.08,2.08,0,0,1-1.22,1.25,13.06,13.06,0,0,1-1,3.37,10.89,10.89,0,0,1-2.47,3.51A9.13,9.13,0,0,1,28.55,30.3Z"
        fill="#79ada3"
      />
      <circle cx="36.51" cy="28" r="8.8" fill="#afd0ca" />
      <path
        d="M36.51,19.2A8.8,8.8,0,1,1,27.7,28a8.8,8.8,0,0,1,8.8-8.8m0-1.09A9.89,9.89,0,1,0,46.4,28a9.9,9.9,0,0,0-9.89-9.89Z"
        fill="#fff"
      />
      <path
        d="M34,28a3.49,3.49,0,0,1-.75,2.16.48.48,0,0,0,0,.64h0a.48.48,0,0,0,.71,0,4.46,4.46,0,0,0,0-5.54.48.48,0,0,0-.71,0h0a.48.48,0,0,0,0,.64A3.49,3.49,0,0,1,34,28Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.43"
      />
      <path
        d="M36.78,28a6.23,6.23,0,0,1-1.54,4.1.47.47,0,0,0,0,.65h0a.48.48,0,0,0,.71,0,7.21,7.21,0,0,0,0-9.44.48.48,0,0,0-.71,0h0a.47.47,0,0,0,0,.65A6.23,6.23,0,0,1,36.78,28Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.43"
      />
      <path
        d="M39.39,28A8.83,8.83,0,0,1,37.1,34a.48.48,0,0,0,0,.65h0a.48.48,0,0,0,.7,0,9.81,9.81,0,0,0,0-13.15.48.48,0,0,0-.7,0h0a.48.48,0,0,0,0,.65A8.83,8.83,0,0,1,39.39,28Z"
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.43"
      />
    </g>
  </svg>;
