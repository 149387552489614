import React from 'react';

export const RestartIcon = ({ size, className, fill }) =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
    <title>1_</title>
    <g id="Layer_1" data-name="Layer 1">
      <path
        d="M128.72,97.11a55.42,55.42,0,1,1-13.56-64.52"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="20"
      />
      <polygon
        points="137.58 63.79 130.02 11.92 86.78 50.85 137.58 63.79"
        fill="#fff"
      />
    </g>
  </svg>;
