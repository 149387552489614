import React from 'react';

import { Audio } from '../../../components/audio';
import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'coffeemaschineExercise1', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.coffeemaschineExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['coffee', 'coffeemaschineExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Höflich um Hilfe bitten',
      text:
        'Freundlich oder gestresst? Achten Sie auf die kleinen Unterschiede.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Welche zwei Varianten sind besonders freundlich, wenn …',
          question: 'Sie etwas nicht verstanden haben und Ihre Kollegen fragen.',
          correct: 'Gute Antwort, weiter so!',
          partiallyCorrect: 'Das stimmt noch nicht ganz.',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: true,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              style: { 'flex': 1 },
              insert: () =>
                <Audio src={require('../../../static/audio/u13/u13_1.mp3')} />,
              data: ['Dürfte ich dir/Ihnen vielleicht kurz eine Frage stellen?']
            },
            {
              id: 2,
              style: { 'flex': 1 },
              insert: () =>
                <Audio src={require('../../../static/audio/u13/u13_2.mp3')} />,
              data: ['Ich muss etwas fragen. Geht das?']
            },
            {
              id: 3,
              meta: { correct: 3 },
              style: { 'flex': 1 },
              insert: () =>
                <Audio src={require('../../../static/audio/u13/u13_3.mp3')} />,
              data: ['Darf ich dich/Sie kurz was fragen?']
            }
          ]
        },
        {
          title: 'Welche zwei Varianten sind besonders freundlich, wenn …',
          question: 'Sie Hilfe brauchen.',
          correct: 'Gute Antwort, weiter so!',
          partiallyCorrect: 'Das stimmt noch nicht ganz.',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: true,
          answers: [
            {
              id: 4,
              style: { 'flex': 1 },
              insert: () =>
                <Audio src={require('../../../static/audio/u13/u13_4.mp3')} />,
              data: ['Bitte hilf mir / Bitte helfen Sie mir.']
            },
            {
              id: 5,
              meta: { correct: 5 },
              style: { 'flex': 1 },
              insert: () =>
                <Audio src={require('../../../static/audio/u13/u13_5.mp3')} />,
              data: ['Entschuldigung, wärst du / wären Sie vielleicht so nett, mir kurz zu helfen?']
            },
            {
              id: 6,
              meta: { correct: 6 },
              style: { 'flex': 1 },
              insert: () =>
                <Audio src={require('../../../static/audio/u13/u13_6.mp3')} />,
              data: ['Könntest du / Könnten Sie mir vielleicht kurz helfen? Das wäre sehr nett.']
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
