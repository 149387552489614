import React, { Component } from 'react';
import { Cloze } from '@ovos-media/widget-cloze';

class CustomCloze extends Component {
  componentDidMount() {
    setTimeout(this.setDropdownPosition);
  }

  componentDidUpdate() {
    setTimeout(this.setDropdownPosition);
  }

  setDropdownPosition = () => {
    const containers = document.querySelectorAll('[data-dropdownwrapper]');
    const center =
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0) /
      2;

    const length = containers.length;
    for (let i = 0; i < length; i += 1) {
      const container = containers[i];
      const containerBox = container.getBoundingClientRect();
      container.setAttribute(
        'data-dropdownwrapper',
        containerBox.left < center ? 'left' : 'right'
      );
    }

    /* containers.forEach(container => {
      const containerBox = container.getBoundingClientRect();
      container.setAttribute(
        'data-dropdownwrapper',
        containerBox.left < center ? 'left' : 'right'
      );
    }); */
  };

  render() {
    const { content, items, multiple, showResult, onChange } = this.props;
    return (
      <Cloze
        {...this.props}
        content={content}
        items={items}
        multiple={multiple}
        showResult={showResult}
        onChange={onChange}
        addStyleFiles={cb => cb(require('./styles.less'))}
      />
    );
  }
}

export default CustomCloze;
