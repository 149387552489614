import React from 'react';

function finishDialogue() {
  // this is the complete list of currently supported params you can pass to the plugin (all optional)
  const options = {
    message: 'Schau dir mal diese App an:',
    subject: 'Ein Tag Deutsch',
    url: 'https://www.ein-tag-deutsch.de/',
    chooserTitle: 'Wähle eine App aus'
  }; // not supported on some apps (Facebook, Instagram) // fi. for email // Android only, you can override the default share sheet title

  if (window.plugins) {
    window.plugins.socialsharing.shareWithOptions(options);
  } else {
    window.location.href =
      'mailto:?subject=Ein%20Tag%20Deutsch%20&body=Schau%20dir%20mal%20diese%20App%20an:%20https://www.ein-tag-deutsch.de/';
  }
}

export default {
  overlay: false,
  flow: {
    0: {
      type: 'feedback',
      text: () =>
        <span>
          Wir wünschen Ihnen weiterhin viel Spaß beim Deutschlernen. Besuchen
          Sie uns auf unserer Webseite:{' '}
          <a
            ref="noopener noreferrer"
            href="http://www.deutsch-am-arbeitsplatz.de/app"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.deutsch-am-arbeitsplatz.de/app
          </a>
        </span>,
      onClick: () => {
        finishDialogue();
      },
      correct: true,
      endScreen: true
    }
  }
};
