import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as infoboxActions } from '../../../reducers/infobox';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as glossaryActions } from '../../../reducers/glossary';
import styles from '../styles.less';

function finishDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'food', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'entities', 'tablet', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'food', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'openWindow', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'family', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'disinfection', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'checkWound', 'overlay'],
      true
    )
  );
  store.dispatch(exerciseSetActions.setCurrentExerciseSet('food'));
}

function resetChoices() {
  store.dispatch(stepsActions.set(['step1', 'choices', 'removeTray'], false));
  store.dispatch(stepsActions.set(['step1', 'choices', 'foodNotTasty'], false));
  store.dispatch(stepsActions.set(['step1', 'choices', 'foodFeedback'], true));
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question: 'Herr Teller hat heute wieder nichts gegessen. Das passiert oft. <br /> <br />Ihm schmeckt das Essen<br>einfach nicht. Ob ich ihn wohl motivieren kann, etwas zu essen?',
      answers: () => {
        const removeTray = store.getState().steps.step1.choices.removeTray;
        return [
          {
            id: 'a',
            goTo: '2-1',
            data: 'ein Gespräch beginnen',
            renderQuestion: false
          },
          {
            id: 'b',
            goTo: '0-2-1',
            data: 'das Tablett abräumen',
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step1', 'choices', 'removeTray'], true)
              );
            },
            className: removeTray ? styles.disabled : '',
            renderQuestion: false
          }
        ];
      }
    },

    '0-2-1': {
      from: 'player',
      type: 'thought',
      text: 'Hm, so kann ich Herrn Teller wahrscheinlich nicht überzeugen. Ich sollte lieber mit ihm sprechen!',
      audio: '007-Tablett_Abraumen_ID_1.mp3',
      nextSnippet: '0'
    },

    '2-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: () => {
        const foodNotTasty = store.getState().steps.step1.choices.foodNotTasty;

        return [
          {
            id: 'b',
            goTo: '2-2-1',
            data: 'Warum essen Sie denn nichts?',
            audio: '001-Ueberzeugen_ID_2.mp3'
          },
          {
            id: 'a',
            goTo: '2-1-1',
            data: 'Mensch Herr Teller, das Tablett ist ja noch voll. Und die Flasche auch. Was haben Sie denn heute schon gegessen und getrunken?',
            audio: '001-Ueberzeugen_ID_1.mp3'
          },
          {
            id: 'c',
            goTo: '2-3-1',
            data: 'Mir schmeckt das Essen hier auch nicht.',
            audio: '001-Ueberzeugen_ID_3.mp3',
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step1', 'choices', 'foodNotTasty'], true)
              );
            },
            className: foodNotTasty ? styles.disabled : ''
          }
        ];
      }
    },

    '2-1-1': {
      from: 'ai',
      text: 'Heute Morgen zwei Kaffee und vorhin ein Glas Wasser. Das reicht doch.',
      audio: '001-Ueberzeugen_ID_12.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'player',
      text: 'Ich verstehe, Sie sind nicht begeistert von dem Essen. Sie würden sich aber etwas Gutes tun, wenn Sie regelmäßig etwas essen würden.',
      audio: '001-Ueberzeugen_ID_13.mp3',
      nextSnippet: '2-1-3'
    },
    '2-1-3': {
      from: 'player',
      text: 'Wichtig ist, dass Sie wieder zu Kräften kommen. Sie könnten auch mit kleinen Portionen beginnen.',
      audio: '001-Ueberzeugen_ID_14.mp3',
      nextSnippet: '2-1-4'
    },
    '2-1-4': {
      from: 'ai',
      text: 'Na ja, eine kleine Bratwurst würde ich schon essen. Hm, und dazu ein kühles Bier!',
      audio: '001-Ueberzeugen_ID_15.mp3',
      nextSnippet: '3-1'
    },

    '2-2-1': {
      from: 'ai',
      text: 'Ich kann jetzt nichts essen.',
      audio: '001-Ueberzeugen_ID_7.mp3',
      nextSnippet: '2-2-2'
    },
    '2-2-2': {
      from: 'player',
      text: () =>
        <span>
          Was haben Sie denn heute schon zu sich{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('nehmen'));
            }}
          >
            genommen
          </span>
          ?
        </span>,
      audio: '001-Ueberzeugen_ID_8.mp3',
      nextSnippet: '2-1-1'
    },

    '2-3-1': {
      from: 'player',
      type: 'thought',
      text: 'Oh, das hätte ich vielleicht nicht sagen sollen. So motiviere ich ihn bestimmt nicht.',
      audio: '001-Ueberzeugen_ID_5.mp3',
      nextSnippet: '2-1'
    },

    '3-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'c',
          goTo: '3-3-1',
          data: () =>
            <span>
              Ich muss Ihnen jetzt doch nicht erzählen, was Sie als {' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('diabetiker'));
                }}
              >
                Diabetiker
              </span>{' '}
              essen dürfen.
            </span>,
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step1', 'choices', 'foodFeedback'], false)
            );
          },
          audio: '001-Ueberzeugen_ID_18.mp3'
        },
        {
          id: 'a',
          goTo: '3-1-1',
          data: 'Hm, Bratwurst lecker. Aber Kartoffeln mit Spiegelei und Spinat – das ist doch auch etwas Gutes.',
          audio: '001-Ueberzeugen_ID_16.mp3'
        },
        {
          id: 'b',
          goTo: '3-2-1',
          data: () =>
            <span>
              Würstchen und ein Bier? Das ist lecker, aber für Sie wäre eine
              spezielle{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('diätkost'));
                }}
              >
                Diätkost
              </span>{' '}
              besser.
            </span>,
          audio: '001-Ueberzeugen_ID_17.mp3'
        }
      ]
    },

    '3-1-1': {
      from: 'ai',
      text: 'Na ja, das kann man jetzt nicht vergleichen.',
      audio: '001-Ueberzeugen_ID_20.mp3',
      type: 'thought',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'player',
      text: () =>
        <span>
          Die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('kohlenhydrate'));
            }}
          >
            Kohlenhydrate
          </span>{' '}
          aus den Kartoffeln sind gut für Ihre
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('insulineinstellung')
              );
            }}
          >
            {' '}Insulineinstellung
          </span>. Und aus dem Spinat und dem Ei bekommen Sie wertvolle
          Mineralstoffe und{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('eiweiß'));
            }}
          >
            Eiweiße
          </span>{' '}
          für die Wundheilung.
        </span>,
      audio: '001-Ueberzeugen_ID_24.mp3',
      nextSnippet: '3-1-3'
    },
    '3-1-3': {
      from: 'ai',
      text: 'Ja, ja. Ich weiß.',
      audio: '001-Ueberzeugen_ID_25.mp3',
      nextSnippet: '3-1-4'
    },
    '3-1-4': {
      from: 'player',
      text: 'Schauen Sie doch mal in die Menükarte. Da finden Sie vielleicht etwas anderes, was Ihnen schmecken würde.',
      audio: '001-Ueberzeugen_ID_26.mp3',
      nextSnippet: '3-1-5'
    },
    '3-1-5': {
      from: 'ai',
      text: 'Na gut, dann fange ich mal mit einem Joghurt an.',
      audio: '001-Ueberzeugen_ID_27.mp3',
      nextSnippet: '3-1-6'
    },
    '3-1-6': {
      from: 'player',
      type: 'thought',
      text: 'Toll, es klappt.',
      audio: '001-Ueberzeugen_ID_28.mp3',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['compliance']));
        }
      },
      nextSnippet: 'fin'
    },

    '3-2-1': {
      from: 'player',
      text: 'Ich sollte ihm erklären, warum.',
      audio: '001-Ueberzeugen_ID_22.mp3',
      type: 'thought',
      nextSnippet: '3-2-2'
    },
    '3-2-2': {
      from: 'player',
      text: 'Das Mittagessen von heute ist perfekt für Sie.',
      audio: '001-Ueberzeugen_ID_23.mp3',
      nextSnippet: '3-1-2'
    },

    '3-3-1': {
      from: 'ai',
      type: 'thought',
      text: 'Also bitte, ich weiß selber, was ich essen darf und was nicht.',
      audio: '001-Ueberzeugen_ID_30.mp3',
      nextSnippet: '3-3-2'
    },
    '3-3-2': {
      from: 'ai',
      text: 'Ich hätte jetzt gerne meine Ruhe.',
      audio: '001-Ueberzeugen_ID_31.mp3',
      nextSnippet: '4-1'
    },

    '4-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'b',
          goTo: '4-2-1',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step1', 'choices', 'foodFeedback'], false)
            );
          },
          data: 'Sie müssen schon ein bisschen mitmachen. Sonst werden Sie ja nie gesund.',
          audio: '001-Ueberzeugen_ID_32.mp3'
        },
        {
          id: 'a',
          goTo: '4-1-1',
          data: 'Warten Sie mal, ich habe da noch eine Idee für Sie.',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step1', 'choices', 'foodFeedback'], true)
            );
          },
          audio: '001-Ueberzeugen_ID_33.mp3'
        }
      ]
    },

    '4-1-1': {
      from: 'player',
      text: 'Wenn Sie die Kartoffeln von heute nicht mögen, dann schauen Sie doch nochmal in die Menükarte. Da finden Sie vielleicht etwas anderes, was Ihnen schmeckt.',
      audio: '001-Ueberzeugen_ID_35.mp3',
      nextSnippet: '4-1-2'
    },
    '4-1-2': {
      from: 'ai',
      text: 'Na, wenn Sie meinen! Ich könnte ja einen Joghurt nehmen.',
      audio: '001-Ueberzeugen_ID_36.mp3',
      nextSnippet: '3-1-6'
    },

    '4-2-1': {
      from: 'ai',
      text: 'Bitte lassen Sie mich jetzt alleine!',
      audio: '001-Ueberzeugen_ID_39.mp3',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['compliance']));
        }
      },
      nextSnippet: 'fin'
    },
    fin: {
      type: 'choice',
      answers: () => {
        const foodFeedback = store.getState().steps.step1.choices.foodFeedback;
        const feedback = foodFeedback ? '5-1' : '5-2';

        return [
          {
            id: 'a',
            goTo: feedback,
            data: 'Weiter',
            onClick: () => {
              resetChoices();
            },
            renderQuestion: false
          }
        ];
      }
    },

    '5-1': {
      type: 'feedback',
      text: 'Sehr gut. Sie haben Herrn Teller überzeugt!',
      onClick: () => {
        finishDialogue();
      },
      unlockedEntries: ['compliance'],
      correct: true
    },
    '5-2': {
      type: 'feedback',
      text:
        'Herr Teller ist nicht überzeugt. Sprechen Sie noch einmal mit ihm!',
      unlockedEntries: ['compliance'],
      correct: false
    }
  }
};
