import React from 'react';

import store from '../../../store';
import { VERSIONS } from '../../../constants';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'email', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(['step2', 'office', 'hotSpots', 'email', 'overlay'], false)
  );

  if (store.getState().app.get('version') === VERSIONS.STUDENT) {
    store.dispatch(stepsActions.setRoom('corridor'));
    store.dispatch(
      stepsActions.set(
        ['step2', 'corridor', 'entities', 'mrsTeller', 'overlay'],
        true
      )
    );
    store.dispatch(
      stepsActions.set(
        ['step2', 'corridor', 'dialogues', 'firstThoughtsCorridor', 'overlay'],
        true
      )
    );
    store.dispatch(
      stepsActions.set(['currentDialogue'], 'firstThoughtsCorridor')
    );
  }

  if (store.getState().exerciseSet.special.email === false) {
    const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    // special case of exercise triggert by hotspot, not by dialog
    store.dispatch(exerciseSetActions.closeExercise(['special', 'email']));
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Eine E-Mail schreiben',
      text: 'Auf Ihrer Liste steht noch: E-Mail an Wundmanagerin wegen Terminabsprache',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          type: 'CLOZE',
          title: 'Ergänzen Sie die fehlenden Wörter.',
          text: () => {
            const name = store.getState().user.name;
            const sex = store.getState().user.sex;

            return `
            {} Frau Schuster, <br/><br/>
            mein Name ist ${name}, Pfleger${sex !== 'male' ? 'in' : ''}, auf der Chirurgie 2. {}, Torben Teller, 56, Diabetiker hat
            eine problematische Wundheilung. Ihm wurde vor sieben Tagen der Unterschenkel abgenommen.
            {} heilt nicht richtig ab. Sie ist stark gerötet und nässt immer noch (siehe  Wunddokumentation mit den Fotos {}).
            Wann {} und sich den Stumpf ansehen?
            <br /><br />Mit {}
            <br />${name}
            `;
          },
          items: [
            [
              { data: 'im Anhang', id: 'a' },
              { data: 'Unser Pat.', id: 'b' },
              { data: 'könnten Sie vorbeikommen', id: 'c' },
              { data: 'Liebe', meta: { correct: 1 }, id: 'd' },
              { data: 'freundlichen Grüßen', id: 'e' },
              { data: 'Die Wunde am Stumpf', id: 'f' }
            ],
            [
              { data: 'im Anhang', id: 'a' },
              { data: 'Unser Pat.', meta: { correct: 1 }, id: 'b' },
              { data: 'könnten Sie vorbeikommen', id: 'c' },
              { data: 'Liebe', id: 'd' },
              { data: 'freundlichen Grüßen', id: 'e' },
              { data: 'Die Wunde am Stumpf', id: 'f' }
            ],
            [
              { data: 'im Anhang', id: 'a' },
              { data: 'Unser Pat.', id: 'b' },
              { data: 'könnten Sie vorbeikommen', id: 'c' },
              { data: 'Liebe', id: 'd' },
              { data: 'freundlichen Grüßen', id: 'e' },
              { data: 'Die Wunde am Stumpf', meta: { correct: 1 }, id: 'f' }
            ],
            [
              { data: 'im Anhang', meta: { correct: 1 }, id: 'a' },
              { data: 'Unser Pat.', id: 'b' },
              { data: 'könnten Sie vorbeikommen', id: 'c' },
              { data: 'Liebe', id: 'd' },
              { data: 'freundlichen Grüßen', id: 'e' },
              { data: 'Die Wunde am Stumpf', id: 'f' }
            ],
            [
              { data: 'im Anhang', id: 'a' },
              { data: 'Unser Pat.', id: 'b' },
              {
                data: 'könnten Sie vorbeikommen',
                meta: { correct: 1 },
                id: 'c'
              },
              { data: 'Liebe', id: 'd' },
              { data: 'freundlichen Grüßen', id: 'e' },
              { data: 'Die Wunde am Stumpf', id: 'f' }
            ],
            [
              { data: 'im Anhang', id: 'a' },
              { data: 'Unser Pat.', id: 'b' },
              { data: 'könnten Sie vorbeikommen', id: 'c' },
              { data: 'Liebe', id: 'd' },
              { data: 'freundlichen Grüßen', meta: { correct: 1 }, id: 'e' },
              { data: 'Die Wunde am Stumpf', id: 'f' }
            ]
          ],
          feedback: () => {
            const name = store.getState().user.name;
            const sex = store.getState().user.sex;
            
            return (
              <span>
                <strong>Liebe</strong> Frau Schuster, <br />
                <br /> mein Name ist {name}, Pfleger{sex !== 'male' ? 'in' : ''},
              auf der Chirurgie 2. <strong>Unser Patient</strong>, Torben
              Teller, 56, Diabetiker hat eine problematische Wundheilung. Ihm
              wurde vor sieben Tagen der Unterschenkel abgenommen.{' '}
                <strong>Die Wunde am Stumpf</strong> heilt nicht richtig ab. Sie
              ist stark gerötet und nässt immer noch (siehe Wunddokumentation
              mit den Fotos <strong>im Anhang</strong>). Wann{' '}
                <strong>könnten Sie vorbeikommen</strong> und sich den Stumpf
              ansehen?<br />
                <br /> Mit <strong>freundlichen Grüßen</strong>
                <br /> {name}
              </span>
            );
          },
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
