import React, { Component } from 'react';

import MenuPoint from './menuPoint';

import styles from './styles.less';

class Menu extends Component {
  static defaultProps = {
    data: require('./data').data
  };

  render() {
    const { style } = this.props;
    return (
      <div className={styles.menu} style={style}>
        {this.renderMenuPoints()}
      </div>
    );
  }

  renderMenuPoints = () => {
    const { data } = this.props;

    return data.map((entry, i) =>
      <MenuPoint
        key={i}
        image={entry.image}
        headline={entry.headline}
        subline={entry.subline}
        description={entry.description}
        scenes={entry.scenes}
      />
    );
  };
}

export default Menu;
