import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'dialogues', 'dietaryAssistant', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'hotSpots', 'telephone', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(['step2', 'office', 'hotSpots', 'email', 'overlay'], true)
  );

  store.dispatch(exerciseSetActions.setCurrentExerciseSet('dietaryAssistant'));
}

export default {
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () =>
        require('../../../static/images/office/dietary-assistant.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Mist! Dieses Telefonieren immer. Ich kenne den Diätassistenten auch noch gar nicht. Wird schon nicht so schlimm sein. Jetzt ganz ruhig bleiben. Wenn ich etwas nicht verstehe, kann ich ja nachfragen.',
      audio: '206-Telefonat_ID_1.mp3',
      type: 'thought',
      nextSnippet: '0-0-1'
    },

    '0-0-1': {
      from: 'player',
      text: 'Ah, es klingelt schon!',
      audio: '206-Telefonat_ID_2.mp3',
      type: 'thought',
      nextSnippet: '0-0-2'
    },
    '0-0-2': {
      from: 'ai',
      text: 'Marzapane.',
      audio: '206-Telefonat_ID_3.mp3',
      nextSnippet: '1-1'
    },

    '1-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          data: `Guten Tag mein Name ist ${store.getState().user
            .name}, Chirurgie 2. Ich bin Pflegekraft hier auf der Station und ich bin seit zwei Wochen hier im Krankenhaus. Und wissen Sie, ich telefoniere nicht so gerne, weil ich ja noch nicht so gut Deutsch spreche. Bitte entschuldigen Sie.`,
          audio: '206-Telefonat_ID_5.mp3'
        },
        {
          id: 'b',
          goTo: '1-2-1',
          data: () =>
            <span>
              Guten Tag mein Name ist {store.getState().user.name}, von der{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('chirurgie'));
                }}
              >
                Chirurgie
              </span>{' '}
              2, bin ich da richtig bei der{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(
                    glossaryActions.setCurrentInfo('diätassistenz')
                  );
                }}
              >
                Diätassistenz
              </span>
              ?
            </span>,
          audio: '206-Telefonat_ID_4.mp3'
        },
        {
          id: 'c',
          goTo: '1-3-1',
          data: 'Hallo, ein Patient hier hat Probleme mit dem Essen. Können Sie kommen?',
          audio: '206-Telefonat_ID_6.mp3'
        }
      ]
    },

    '1-1-1': {
      from: 'ai',
      text: `${store.getState().user
        .name}, ich verstehe Sie ganz gut. Bitte worum geht's denn?`,
      audio: '206-Telefonat_ID_11.mp3',
      nextSnippet: '7-1'
    },

    '7-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-3-8',
          data: 'Ich rufe Sie an, weil wir einen Patienten haben, der nichts essen will.',
          audio: '206-Telefonat_ID_19.mp3'
        },
        {
          id: 'b',
          goTo: '7-2-1',
          data: 'Ja, die Aussprache finde ich einfach so schwierig. Viele sprechen hier ja auch Dialekt und dann verstehe ich fast nichts. Verstehen Sie?',
          audio: '206-Telefonat_ID_23.mp3'
        }
      ]
    },

    '7-2-1': {
      from: 'ai',
      text: 'Rufen Sie deshalb bei mir an? Ich kenne mich eigentlich nur mit Ernährung gut aus, und ich habe jetzt auch gar nicht so viel Zeit.',
      audio: '206-Telefonat_ID_24.mp3',
      nextSnippet: '7-2-2'
    },
    '7-2-2': {
      from: 'player',
      text: 'Oh je, Entschuldigung. Es geht um Herrn Teller. Er isst fast nichts.',
      audio: '206-Telefonat_ID_25.mp3',
      nextSnippet: '1-3-8'
    },

    '1-2-1': {
      from: 'ai',
      text: 'Ja, da sind Sie richtig.',
      audio: '206-Telefonat_ID_8.mp3',
      nextSnippet: '1-2-2'
    },
    '1-2-2': {
      from: 'player',
      text: () =>
        <span>
          Oh, schön. Ich rufe Sie an wegen eines Patienten, der an{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('appetitlosigkeit')
              );
            }}
          >
            Appetitlosigkeit
          </span>{' '}
          leidet.
        </span>,
      audio: '206-Telefonat_ID_9.mp3',
      nextSnippet: '1-3-8'
    },

    '1-3-1': {
      from: 'ai',
      text: 'Entschuldigen Sie, mit wem spreche ich denn?',
      audio: '206-Telefonat_ID_13.mp3',
      nextSnippet: '1-3-2'
    },
    '1-3-2': {
      from: 'player',
      text: `${store.getState().user.name}`,
      nextSnippet: '1-3-3'
    },
    '1-3-3': {
      from: 'ai',
      text: 'Von welcher Station?',
      audio: '206-Telefonat_ID_15.mp3',
      nextSnippet: '1-3-4'
    },
    '1-3-4': {
      from: 'player',
      text: 'Ach so, Chirurgie 2.',
      audio: '206-Telefonat_ID_16.mp3',
      nextSnippet: '1-3-5'
    },
    '1-3-5': {
      from: 'ai',
      text: `Wieso sagt ${store.getState().user.sex === 'male'
        ? 'er'
        : 'sie'} mir nicht einfach, was ${store.getState().user.sex === 'male'
        ? 'er'
        : 'sie'} will. Ich habe nicht den ganzen Tag Zeit.`,
      audio: `${store.getState().user.sex === 'male'
        ? '206-Telefonat_ID_17m.mp3'
        : '206-Telefonat_ID_17w.mp3'}`,
      type: 'thought',
      nextSnippet: '1-3-6'
    },
    '1-3-6': {
      from: 'ai',
      text: 'Und was kann ich für Sie tun?',
      audio: '206-Telefonat_ID_18.mp3',
      nextSnippet: '1-3-7'
    },
    '1-3-7': {
      from: 'player',
      text: 'Ich rufe Sie an, weil wir einen Patienten haben, der nichts essen will.',
      audio: '206-Telefonat_ID_19.mp3',
      nextSnippet: '1-3-8'
    },
    '1-3-8': {
      from: 'ai',
      text: 'Können Sie das noch genauer beschreiben?',
      audio: '206-Telefonat_ID_26.mp3',
      nextSnippet: '2-1'
    },

    '2-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '3-1-4',
          data: () =>
            <span>
              Er ist 56 Jahre alt, Diabetiker, Wundheilungs&shy;störung nach
              Beinamputation.
            </span>,
          audio: '206-Telefonat_ID_27.mp3'
        },
        {
          id: 'b',
          goTo: '3-1-4',
          data: () =>
            <span>
              Ihm wurde der{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(
                    glossaryActions.setCurrentInfo('unterschenkel')
                  );
                }}
              >
                Unterschenkel
              </span>{' '}
              abgenommen. Das war vor 7 Tagen. Und er ist Diabetiker.
            </span>,
          audio: '206-Telefonat_ID_28.mp3'
        },
        {
          id: 'c',
          goTo: '2-3-1',
          data: 'Er ist nett und sieht ein bisschen dick aus.',
          audio: '206-Telefonat_ID_29.mp3'
        }
      ]
    },

    '2-3-1': {
      from: 'ai',
      text: 'Nein, das meinte ich nicht. Haben Sie noch mehr Informationen zu seinem Krankheitsbild?',
      audio: '206-Telefonat_ID_33.mp3',
      nextSnippet: '3-1'
    },

    '3-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '3-1-1',
          data: 'Ja, ich bin ja noch nicht fertig.',
          audio: '206-Telefonat_ID_35.mp3'
        },
        {
          id: 'b',
          goTo: '3-2-1',
          data: 'Ah, entschuldigen Sie. Ich verstehe am Telefon oft noch nicht gut.',
          audio: '206-Telefonat_ID_34.mp3'
        }
      ]
    },

    '3-1-1': {
      from: 'ai',
      text: 'Oh! So bitte nicht.',
      audio: '206-Telefonat_ID_40.mp3',
      type: 'thought',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'ai',
      text: 'Jetzt aber zu Ihrem Patienten. Was hat er genau?',
      audio: '206-Telefonat_ID_41.mp3',
      nextSnippet: '3-1-3'
    },
    '3-1-3': {
      from: 'player',
      text: () =>
        <span>
          Er ist 56 Jahre alt, Diabetiker, Wundheilungs&shy;störung nach
          Beinamputation
        </span>,
      audio: '206-Telefonat_ID_27.mp3',
      nextSnippet: '3-1-4'
    },
    '3-1-4': {
      from: 'player',
      text: () =>
        <span>
          Seine Ernährung muss optimal auf seinen{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('blutzucker'));
            }}
          >
            Blutzucker
          </span>{' '}
          eingestellt werden.
        </span>,
      audio: '206-Telefonat_ID_43.mp3',
      nextSnippet: '3-1-5'
    },
    '3-1-5': {
      from: 'player',
      text: 'Er hat wenig Bewegung und das treibt den Blutzucker nach oben.',
      audio: '206-Telefonat_ID_44.mp3',
      nextSnippet: '3-1-6'
    },
    '3-1-6': {
      from: 'player',
      text: 'Und er hat wenig Appetit, nur auf Süßes. Er sagt, ihm schmeckt das Essen hier nicht.',
      audio: '206-Telefonat_ID_45.mp3',
      nextSnippet: '3-1-7'
    },
    '3-1-7': {
      from: 'ai',
      text: 'Aha, und wie schätzen Sie das ein?',
      audio: '206-Telefonat_ID_46.mp3',
      nextSnippet: '4-1'
    },

    '3-2-1': {
      from: 'ai',
      text: 'Das macht gar nichts. Was hat Ihr Patient genau?',
      audio: '206-Telefonat_ID_37.mp3',
      nextSnippet: '3-1-3'
    },

    '4-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '4-1-1',
          data: 'Ich weiß es nicht. Vielleicht isst er heimlich Süßes.',
          audio: '206-Telefonat_ID_48.mp3'
        },
        {
          id: 'b',
          goTo: '4-2-1',
          data: 'Ich denke, es liegt an der neuen Situation. Er sieht momentan keine Perspektiven für sich. Deshalb isst er nichts.',
          audio: '206-Telefonat_ID_47.mp3'
        }
      ]
    },

    '4-1-1': {
      from: 'ai',
      text: () =>
        <span>
          Na, ja. Ob es daran liegt? Die Zeit nach einer Amputation ist immer
          schwierig, vielleicht ist es eher die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('psychisch'));
            }}
          >
            psychische
          </span>{' '}
          Belastung.
        </span>,
      audio: '206-Telefonat_ID_53.mp3',
      nextSnippet: '4-1-2'
    },
    '4-1-2': {
      from: 'ai',
      text: 'Ich komme vorbei und schau\' mir den Ernährungsplan an.',
      audio: '206-Telefonat_ID_54.mp3',
      nextSnippet: '5-1'
    },

    '4-2-1': {
      from: 'player',
      text: 'Auf die Wundheilung wirkt sich das natürlich negativ aus.',
      audio: '206-Telefonat_ID_50.mp3',
      nextSnippet: '4-2-2'
    },
    '4-2-2': {
      from: 'ai',
      text: () =>
        <span>
          Ja, da haben Sie recht. Ich komme vorbei und schau` mir den{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('ernährungsplan'));
            }}
          >
            Ernährungsplan
          </span>{' '}
          an.
        </span>,
      audio: '206-Telefonat_ID_51.mp3',
      nextSnippet: '5-1'
    },

    '5-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '5-1-1',
          data: 'Wunderbar! Schaffen Sie das heute noch?',
          audio: '206-Telefonat_ID_55.mp3'
        },
        {
          id: 'b',
          goTo: '5-2-1',
          data: 'Super, tschüs!',
          audio: '206-Telefonat_ID_56.mp3'
        }
      ]
    },

    '5-1-1': {
      from: 'ai',
      text: 'Ja, im Laufe des Nachmittags. Ich kann es noch nicht genau sagen.',
      audio: '206-Telefonat_ID_58.mp3',
      nextSnippet: '5-1-2'
    },
    '5-1-2': {
      from: 'ai',
      text: 'Also, das war Station 2. Und der Name?',
      audio: '206-Telefonat_ID_61.mp3',
      nextSnippet: '6-1'
    },

    '5-2-1': {
      from: 'ai',
      text: 'Moment, nicht so schnell.',
      audio: '206-Telefonat_ID_60.mp3',
      nextSnippet: '5-1-2'
    },

    '6-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '6-1-1',
          data: 'Tähler.',
          audio: '206-Telefonat_ID_63.mp3'
        },
        {
          id: 'b',
          goTo: '6-1-1',
          data: 'Tiela.',
          audio: '206-Telefonat_ID_64.mp3'
        },
        {
          id: 'c',
          goTo: '6-1-3',
          data: 'Teller.',
          audio: '206-Telefonat_ID_62.mp3'
        }
      ]
    },

    '6-1-1': {
      from: 'player',
      text: 'Ich glaube, es war doch T-E-L-L-E-R.',
      audio: '206-Telefonat_ID_69.mp3',
      nextSnippet: '6-1-2'
    },
    '6-1-2': {
      from: 'player',
      text: 'Teller',
      audio: '206-Telefonat_ID_62.mp3',
      nextSnippet: '6-1-3'
    },
    '6-1-3': {
      from: 'ai',
      text: 'Gut, ich hab\'s notiert. Dankeschön.',
      audio: '206-Telefonat_ID_66.mp3',
      nextSnippet: '6-1-4'
    },
    '6-1-4': {
      from: 'player',
      text: 'Ich sag\' auch danke, auf Wiederhören.',
      audio: '206-Telefonat_ID_67.mp3',
      nextSnippet: 'fin'
    },

    fin: {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: 7,
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },

    7: {
      type: 'feedback',
      text: 'Super, das wäre geschafft. Mit jedem Telefonat wird’s besser!',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
