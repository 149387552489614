import isArray from 'lodash/isArray';
import shortid from 'shortid';

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer ? reducer(state, action) : state;
  };
}

export function createActionTypes(actions = [], namespace = shortid()) {
  if (!isArray(actions))
    throw new Error('actions parameter must be an array of strings');

  return actions.reduce(
    (acc, action) => {
      acc[action] = `${namespace}${action}`; // eslint-disable-line
      return acc;
    },
    { __namespace: namespace }
  );
}
