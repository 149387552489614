import React from 'react';

import { Hotspot } from '../../../../components/hotspot';
import { ScrollIndicator } from '../../../../static/icons/ScrollIndicator';
import { InfoIcon } from '../../../../static/icons/InfoIcon';
import { Button } from '../../../../components/button';

import store from '../../../../store';
import { VERSIONS } from '../../../../constants';

import styles from '../../styles/slide.less';


let slides = [
  {
    heading: 'Spielwelt erkunden',
    paragraph: 'Sie können sich umsehen, indem Sie auf dem Bild bei gedrückter Maustaste nach links und rechts ziehen.',
    content: (
      <div className={styles.scrollIndicatorContainer}>
        <ScrollIndicator
          size="30%"
          fill="#fff"
          className={styles.scrollIndicator}
        />
      </div>
    )
  },
  {
    heading: 'Hotspots entdecken',
    paragraph: store.getState().app.get('version') === VERSIONS.STUDENT ? 
      'In jeder Szene befinden sich Objekte, hinter denen ein Gespräch auf Sie wartet. Diese sind durch pulsierende Ringe markiert.' :
      'In jeder Szene befinden sich Objekte, hinter denen ein Gespräch auf Sie wartet. Diese sind durch pulsierende Ringe markiert. In der Version für Lehrkräfte können Sie jederzeit auf alle Hotspots aus allen Schritten zugreifen. In der Version für Lernende dagegen sind diese schrittweise aufeinanderfolgend sichtbar.',
    content: <Hotspot className={styles.hotspot} />
  },
  {
    heading: 'Für Ihren Unterricht',
    paragraph: 'In den Zusatzmaterialien finden Sie konkrete Ideen dafür, wie Sie mit den Inhalten aus der App im Unterricht arbeiten können. Laden Sie sich jetzt die Materialien kostenlos herunter.',
    content: (
      <div className={styles.center}>
        <Button
          theme='noFixed'
          className={`${styles.button}`}
        >
          <InfoIcon />
        </Button>
      </div>
    )
  }
];

if(store.getState().app.get('version') === VERSIONS.STUDENT) {
  slides = slides.splice(0, 2);
} 

export { slides };
