import { createReducer, createActionTypes } from '../lib/redux';

const defaultState = {
  tutorialFinished: false,
  buttonHintActive: true,
  dialogues: { greeting: { overlay: false } },
  currentDialogue: 'greeting'
};

const types = createActionTypes(
  [
    'SET_TUTORIAL_STATUS',
    'SET_BUTTON_HINT_STATUS',
    'SET_OVERLAY',
    'SET_CURRENT_DIALOGUE'
  ],
  ''
);

const actions = {
  setTutorialStatus: tutorialFinished => ({
    type: types.SET_TUTORIAL_STATUS,
    tutorialFinished
  }),
  setButtonHintStatus: buttonHintActive => ({
    type: types.SET_BUTTON_HINT_STATUS,
    buttonHintActive
  }),
  setOverlay: (name, value) => ({
    type: types.SET_OVERLAY,
    name,
    value
  }),
  setCurrentDialogue: name => ({
    type: types.SET_CURRENT_DIALOGUE,
    name
  })
};

const reducer = createReducer(defaultState, {
  [types.SET_TUTORIAL_STATUS]: (
    state = defaultState,
    { tutorialFinished }
  ) => ({
    ...state,
    tutorialFinished
  }),
  [types.SET_BUTTON_HINT_STATUS]: (
    state = defaultState,
    { buttonHintActive }
  ) => ({
    ...state,
    buttonHintActive
  }),
  [types.SET_OVERLAY]: (state = defaultState, { name, value }) => ({
    ...state,
    dialogues: { ...state.dialogues, [name]: { overlay: value } }
  }),
  [types.SET_CURRENT_DIALOGUE]: (state = defaultState, { name }) => ({
    ...state,
    currentDialogue: name
  })
});

export { actions, reducer };
