import set from 'lodash/set';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { createReducer, createActionTypes } from '../lib/redux';

const defaultState = {
  max: {
    step1: 41,
    step2: 69,
    step3: 34,
    step4: 39
  },
  step1: 0,
  step2: 0,
  step3: 0,
  step4: 0,
  currentExercisePoints: 0
};

const types = createActionTypes(['INCREMENT', 'RESET_CURRENT_POINTS'], '');

const actions = {
  increment: (path, value) => ({
    type: types.INCREMENT,
    path,
    value
  }),
  resetCurrentPoints: () => ({
    type: types.RESET_CURRENT_POINTS
  })
};

const reducer = createReducer(defaultState, {
  [types.INCREMENT]: (state = defaultState, { path, value }) => {
    const newValue = set(cloneDeep(state), path, get(cloneDeep(state), path) + value);
    console.log('currentPoints: ' + newValue.currentExercisePoints, 'step1: ' + newValue.step1, 'step2: ' + newValue.step2, 'step3: ' + newValue.step3, 'step4: ' + newValue.step4);
    return newValue;
  },
    
  [types.RESET_CURRENT_POINTS]: (state = defaultState) =>
    set(cloneDeep(state), 'currentExercisePoints', 0)
});

export { actions, reducer };
