import React, { Component } from 'react';
import { connect } from 'react-redux';

import store from '../../store';
import { actions as appActions } from '../../reducers/app';
import { actions as stepsActions } from '../../reducers/steps';
import { actions as exerciseSetActions } from '../../reducers/exerciseSet';

import {
  Button,
  ButtonHint,
  Dialogue,
  Exercise,
  ExerciseOverview,
  Hotspot,
  LoadingScreen,
  Menu,
  ScalingContainer
} from '../../components';

import { Tutorial } from '../../modules/corridor';
import { ScrollIndicator } from '../../static/icons/ScrollIndicator';
import { GlossaryIcon } from '../../static/icons/GlossaryIcon';
import { InfoBoxIcon } from '../../static/icons/InfoBoxIcon';
import { InfoIcon } from '../../static/icons/InfoIcon';
import { RestartIcon } from '../../static/icons/RestartIcon';
import { DragScroll } from '../../components/dragScroll';

import { stepTitles } from './stepTitles';

import DIALOGUES from '../../config/dialogues';
import EXERCISES from '../../config/exercises';
import ROOMS from './rooms';
import { VERSIONS } from '../../constants';

import styles from './styles.less';

const mapStateToProps = ({ steps, exerciseSet, infobox, app }) => ({
  steps,
  version: app.get('version'),
  currentScene: steps.currentScene,
  currentStep: steps.currentStep,
  currentRoom: steps.currentRoom,
  currentDialogue: steps.currentDialogue,
  currentExercise: steps.currentExercise,
  renderButtonHint: steps.buttonHintActive,
  tutorialFinished: steps.tutorialFinished,
  stepFinished: steps.stepFinished,
  buttonHintActive: steps.buttonHintActive,
  buttonHintStepFinished: steps.buttonHintStepFinished,
  isInfoboxNotificationVisible: steps.isInfoboxNotificationVisible,
  stepIntro: steps.step1.stepIntro.text,
  stepIntroTitle: steps.step1.stepIntro.title,
  stepIntroStatus: steps.stepIntroStatus,
  exerciseSet,
  currentExerciseSet: exerciseSet.currentExerciseSet,
  nextActivity: exerciseSet.nextActivity,
  unlockedEntries: infobox.unlockedEntries,
  seenEntries: infobox.seenEntries,
  gameRestartPopUp: steps.gameRestartPopUp
});

const mapDispatchToProps = {
  setPage: appActions.setPage,
  setRoom: stepsActions.setRoom,
  setButtonHintStatus: stepsActions.setButtonHintStatus,
  setTutorialStatus: stepsActions.setTutorialStatus,
  setDialogue: stepsActions.setDialogue,
  setExercise: stepsActions.setExercise,
  setStepFinished: stepsActions.setStepFinished,
  setButtonHintStepFinished: stepsActions.setButtonHintStepFinished,
  set: stepsActions.set,
  showInfoboxNotification: stepsActions.showInfoboxNotification,
  setCurrentExerciseSet: exerciseSetActions.setCurrentExerciseSet,
  setNextActivity: exerciseSetActions.setNextActivity
};

class Room extends Component {
  constructor(props) {
    super(props);

    const scaledWidth = (1920 * document.body.offsetHeight) / 1080;
    this.state = {
      loading: true,
      scaledWidth,
      difference: window.innerWidth > scaledWidth ? window.innerWidth - scaledWidth : 0
    };

    this.resizeTimeout = false;

    this.throttledResize = () => {
      clearTimeout(this.resizeTimeout);

      this.resizeTimeout = setTimeout(this.handleResize, 100);
    };
  }

  render() {
    const {
      currentRoom,
      steps,
      currentStep,
      currentDialogue,
      tutorialFinished,
      currentExercise,
      gameRestartPopUp
    } = this.props;

    const { loading, difference } = this.state;
    const dialogues = steps[currentStep][currentRoom].dialogues;
    const dialogue = dialogues ? dialogues[currentDialogue] : null;

    const exercises = steps[currentStep][currentRoom].exercises;
    const exercise = exercises ? exercises[currentExercise] : null;

    const disableScrolling = (dialogue && dialogue.overlay) || (exercise && exercise.overlay) || !tutorialFinished || gameRestartPopUp;
    return [
      <LoadingScreen key="loadingScreen" loading={loading} />,
      <div
        key="room"
        id="room"
        className={`${styles.room} ${dialogue && dialogue.overlay
          ? styles.fixed
          : ''}`}
        style={{ opacity: +!loading }}
      >
        <DragScroll
          key={'dragscroll'}
          style={{ height: '100%', width: `calc(100% - ${difference}px)`, left: difference / 2 }}
          onlyX
          disabled={disableScrolling}
          className={styles.scrollArea}
        >
          <div>
            {this.renderMenu()}
            {this.renderInfoButtons()}
            {this.renderRestartButton()}
            {this.renderScrollIndicator()}

            {this.renderDialogue()}
            {this.renderExerciseOverview()}
            {this.renderExercise()}

            {this.renderButtonHint()}
            {this.renderIntroHint()}
            {this.renderTutorial()}
            {this.renderBackButton()}
            {this.renderRestartPopUp()}

            {this.renderNextButtonHint()}
            {this.renderNextButton()}

            <ScalingContainer>
              <img
                onLoad={this.hideLoadingScreen}
                alt="room"
                className={styles.backgroundImage}
                src={ROOMS[currentRoom]}
                draggable={false}
              />
              {this.renderEntities()}
              {this.renderHotspots()}
            </ScalingContainer>
          </div>
        </DragScroll>
      </div>
    ];
  }

  componentDidMount() {
    this.updateScaleVariables();

    window.addEventListener('resize', this.throttledResize);
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResize);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentRoom !== this.props.currentRoom) {
      this.setState({ loading: true });
    }

    const scrollContainer = document.getElementById('room');
    const scrollTarget = document.querySelector('[data-scrolltarget="true"]');

    if (scrollTarget) {
      scrollContainer.scrollLeft = 0;
      const viewCenter = this.getCenter();
      const targetOffset = this.getTargetOffset(scrollTarget);

      scrollContainer.scrollLeft = targetOffset - viewCenter;
    }
  }

  handleResize = () => {
    this.updateScaleVariables();
  };

  handleKeyDown = e => {
    const { currentDialogue, steps, currentStep, currentRoom, gameRestartPopUp } = this.props;
    // fix for edge scrolling issue
    if(currentDialogue && (e.key === 'ArrowLeft' || e.key === 'ArrowRight')) {
      const dialogues = steps[currentStep][currentRoom].dialogues;
      const dialogue = dialogues ? dialogues[currentDialogue] : null;
      if (dialogue && dialogue.overlay) {
        e.preventDefault();
      }
    }

    if(gameRestartPopUp && (e.key === 'ArrowLeft' || e.key === 'ArrowRight')) {
      e.preventDefault();
    }
  }

  updateScaleVariables = () => {
    const { offsetHeight } = document.body;
    const scaledWidth = (1920 * offsetHeight) / 1080;

    this.setState({
      scaledWidth,
      difference: window.innerWidth > scaledWidth ? window.innerWidth - scaledWidth : 0
    });
  };

  getCenter = () =>
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0) / 2;

  getTargetOffset = el => {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;

    return rect.left + scrollLeft;
  };

  renderMenu = () => {
    const { version } = this.props;
    const { difference } = this.state;
    if (version === VERSIONS.TEACHER) {
      return <Menu
        style={{
          left: `calc(120px + ${difference / 2}px)`,
          right: `calc(120px + ${difference / 2}px)`
        }}
      />;
    }
    return null;
  };

  renderExerciseOverview = () => {
    const {
      currentExerciseSet,
      currentStep,
      currentRoom,
      nextActivity,
      exerciseSet,
      setCurrentExerciseSet,
      set,
      setDialogue,
      setRoom,
      setNextActivity,
      version
    } = this.props;

    if (currentExerciseSet !== '') {
      return (
        <div className={styles.exerciseSetContainer}>
          <p>Übungen</p>
          <h2>
            {stepTitles[currentStep]}
          </h2>
          <Button
            theme="next invert"
            onClick={() => {
              setCurrentExerciseSet('');
              if (nextActivity !== '' && version === VERSIONS.STUDENT) {
                if (nextActivity === 'firstThoughtsCorridor') {
                  setRoom('corridor');
                }
                if (nextActivity === 'accident') {
                  setRoom('hospitalRoom');
                  return;
                }
                if (nextActivity === 'handingOver') {
                  setRoom('office');
                  return;
                }

                set(
                  [
                    currentStep,
                    currentRoom,
                    'dialogues',
                    nextActivity,
                    'overlay'
                  ],
                  true
                );
                setDialogue(nextActivity);
                setNextActivity('');
              }
            }}
          />
          <ExerciseOverview exercises={exerciseSet[currentExerciseSet]} />
        </div>
      );
    }
    return null;
  };

  hideLoadingScreen = () => {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 300);
  };

  renderInfoButtons = () => {
    const { seenEntries, unlockedEntries, version } = this.props;
    const { difference } = this.state;
    let className = '';
    if (seenEntries < unlockedEntries.length && version === VERSIONS.STUDENT) {
      className = styles.pulse;
    }
    
    
    return [
      <Button
        key="glossary"
        className={`${styles.button} ${styles.glossaryButton}`}
        style={{ left: `calc(20px + ${difference / 2}px)` }}
        onClick={this.openGlossary}
      >
        <GlossaryIcon />
      </Button>,
      <Button
        key="infoBox"
        className={`${styles.button} ${styles.infoBoxButton} ${className}`}
        style={{ left: `calc(20px + ${difference / 2}px)` }}
        onClick={this.openInfoBox}
      >
        <InfoBoxIcon />
      </Button>,
      version === VERSIONS.STUDENT ? null : <Button
        key="info"
        className={`${styles.button} ${styles.infoButton}`}
        style={{ left: `calc(20px + ${difference / 2}px)` }}
        onClick={this.openInfo}
      >
        <InfoIcon />
      </Button>
    ];
  };

  renderRestartButton = () => {
    const { difference } = this.state;

    return (
      <Button
        key="restart"
        className={`${styles.button} ${styles.restartButton}`}
        style={{ right: `calc(20px + ${difference / 2}px)` }}
        onClick={this.restart}
      >
        <RestartIcon />
      </Button>
    );
  };

  restart = () => {
    const { set } = this.props;
    set(['gameRestartPopUp'], true);
  };

  renderRestartPopUp = () => {
    const { set, setPage, gameRestartPopUp } = this.props;

    if (gameRestartPopUp) {
      return (
        <div className={styles.restartContainer}>
          <div className={styles.innerContainer}>
            <p>Wollen Sie das Spiel wirklich neu starten?</p>
            <div className={styles.restartButtonsHolder}>
              <div
                className={styles.restartButtons}
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.setState({ loading: true });
                  window.localForage.clear().then(() => {
                    setPage('home');
                    window.location = window.initialHref;
                  });
                }}
              >
                Neu starten
              </div>
              <div
                className={styles.restartButtons}
                role="button"
                tabIndex={0}
                onClick={() => {
                  set(['gameRestartPopUp'], false);
                }}
              >
                Weiterspielen
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  openGlossary = () => {
    const { setPage, set, stepIntroStatus, currentRoom } = this.props;
    if (stepIntroStatus && currentRoom === 'hospitalRoom') {
      set(['stepIntroStatus'], false);
    }
    setPage('glossary');
  };

  openInfoBox = () => {
    const { setPage, set, stepIntroStatus, currentRoom } = this.props;
    if (stepIntroStatus && currentRoom === 'hospitalRoom') {
      set(['stepIntroStatus'], false);
    }
    setPage('infobox');
  };

  openInfo = () => {
    const { setPage } = this.props;
    setPage('info');
  };

  renderScrollIndicator = () => {
    const { difference } = this.state;

    return (
      <div
        className={styles.scrollIndicatorContainer}
        style={{
          left: `${difference / 2}px`,
          right: `${difference / 2}px`
        }}
      >
        <ScrollIndicator
          size="70%"
          fill="#fff"
          className={styles.scrollIndicator}
        />
      </div>
    );
  }
   

  renderEntities = () => {
    const {
      steps,
      currentScene,
      currentStep,
      currentRoom,
      version
    } = this.props;

    let entities = steps[currentStep][currentRoom].entities;
    if (entities) {
      entities = Object.entries(entities);
      return entities.map(entity => {
        const key = entity[0];
        const data = entity[1];

        if (data.overlay && version === VERSIONS.STUDENT) {
          return (
            <img
              key={key}
              alt={key}
              className={`${styles.sceneElement}`}
              src={data.src}
              data-scrolltarget={data.scrollTarget === true}
              style={{ left: data.x, top: data.y, height: data.height }}
              draggable={false}
            />
          );
        } else if (version === VERSIONS.TEACHER && !currentScene.exclude[key]) {
          return (
            <img
              key={key}
              alt={key}
              className={`${styles.sceneElement}`}
              src={data.src}
              data-scrolltarget={data.scrollTarget === true}
              style={{ left: data.x, top: data.y, height: data.height }}
              draggable={false}
            />
          );
        }
        return null;
      });
    }
    return null;
  };

  renderHotspots = () => {
    const {
      steps,
      currentScene,
      currentStep,
      currentRoom,
      version,
      exerciseSet
    } = this.props;
    let hotSpots = steps[currentStep][currentRoom].hotSpots;
    if (hotSpots) {
      hotSpots = Object.entries(hotSpots);

      return hotSpots.map(hotSpot => {
        const key = hotSpot[0];
        const data = hotSpot[1];

        if (version === VERSIONS.STUDENT && data.overlay) {
          return (
            <Hotspot
              key={key}
              style={{ left: data.x, top: data.y }}
              handleHotSpot={() => {
                this.handleHotSpots(data.type, data.goTo, data.onClick);
              }}
              audio={data.audio}
              scrollTarget={data.scrollTarget === true}
            />
          );
        } else if (version === VERSIONS.TEACHER && !currentScene.exclude[key]) {
          let exercises = null;
          if (data.goTo && exerciseSet[data.goTo]) {
            exercises = data.goTo;
          }

          return (
            <Hotspot
              key={key}
              style={{ left: data.x, top: data.y }}
              audio={data.audio}
              handleHotSpot={() => {
                this.handleHotSpots(data.type, data.goTo, data.onClick);
              }}
              handleExercise={() => {
                this.handleHotSpots('exerciseSet', exercises);
              }}
              exercises={exercises}
              scrollTarget={data.scrollTarget === true}
            />
          );
        }
        return null;
      });
    }
    return null;
  };

  handleHotSpots = (type, goTo, onClick) => {
    const {
      set,
      setRoom,
      currentStep,
      currentRoom,
      setDialogue,
      setExercise,
      setCurrentExerciseSet
    } = this.props;
    if (onClick) {
      onClick();
    }
    if (type === 'dialogue') {
      set([currentStep, currentRoom, 'dialogues', goTo, 'overlay'], true);
      setDialogue(goTo);
    } else if (type === 'scene') {
      setRoom(goTo);
    } else if (type === 'exercise') {
      set([currentStep, currentRoom, 'exercises', goTo, 'overlay'], true);
      setExercise(goTo);
    } else if (type === 'exerciseSet') {
      setCurrentExerciseSet(goTo);
    }
  };

  renderDialogue = () => {
    const { steps, currentStep, currentRoom, currentDialogue } = this.props;
    const { difference } = this.state;

    if (currentDialogue) {
      const dialogues = steps[currentStep][currentRoom].dialogues;
      const dialogue = dialogues ? dialogues[currentDialogue] : null;
      if (dialogue && dialogue.overlay) {
        return (
          <Dialogue
            key={currentDialogue}
            difference={difference}
            {...DIALOGUES[currentStep][currentRoom][currentDialogue]}
          />
        );
      }
    }
    return null;
  };

  renderExercise = () => {
    const { steps, currentStep, currentRoom, currentExercise } = this.props;

    if (currentExercise) {
      const exercises = steps[currentStep][currentRoom].exercises;
      const exercise = exercises ? exercises[currentExercise] : null;

      if (exercise && exercise.overlay) {
        return (
          <Exercise
            key={currentExercise}
            {...EXERCISES[currentStep][currentRoom][currentExercise]}
          />
        );
      }
    }
    return null;
  };

  renderButtonHint = () => {
    const { renderButtonHint, currentRoom, currentStep, version } = this.props;
    if (
      renderButtonHint &&
      currentRoom !== 'corridor' &&
      currentStep === 'step1' &&
      version === VERSIONS.STUDENT
    ) {
      return (
        <ButtonHint
          theme="left"
          onClick={this.hideHint}
          title="Ausgang"
          description="Sie können einen Raum jederzeit wieder verlassen, indem Sie auf den
        Zurück-Knopf tippen."
        />
      );
    }
    return null;
  };

  hideHint = () => {
    const { setButtonHintStatus } = this.props;
    setButtonHintStatus(false);
  };

  renderTutorial = () => {
    const { tutorialFinished, currentRoom } = this.props;
    const { difference } = this.state;
    if (!tutorialFinished && currentRoom === 'corridor') {
      return <Tutorial tutorialStatus={this.tutorialStatus} difference={difference} />;
    }
    return null;
  };

  tutorialStatus = tutorialFinished => {
    const { setTutorialStatus } = this.props;
    setTutorialStatus({ tutorialFinished });
  };

  renderBackButton = () => {
    const { currentRoom, stepFinished, stepIntroStatus, version } = this.props;
    const { difference } = this.state;
    
    if (
      currentRoom !== 'corridor' &&
      !stepFinished &&
      !stepIntroStatus &&
      version === VERSIONS.STUDENT
    ) {
      return <Button theme="back" onClick={this.goBack} style={{ left: `calc(20px + ${difference / 2}px)` }} />;
    }
    return null;
  };

  renderNextButton = () => {
    const { stepFinished, version } = this.props;
    const { difference } = this.state;

    if (stepFinished && version === VERSIONS.STUDENT) {
      return <Button theme="next" onClick={this.handleNextClick} style={{ right: `calc(20px + ${difference / 2}px)` }} />;
    }
    return null;
  };

  handleNextClick = () => {
    const {
      set,
      setPage,
      currentStep,
      currentRoom,
      setDialogue,
      setRoom
    } = this.props;
    this.hideNextButtonHint();
    set(['stepFinished'], false);
    if (currentStep === 'step4') {
      setRoom('corridor');
      set(['currentDialogue'], 'goingHome');
    } else if (currentStep === 'step3') {
      set(['currentStep'], 'step4');
      setPage('steps');
      setRoom('office');
    } else if (currentStep === 'step2') {
      set(['currentStep'], 'step3');
      setPage('steps');
      setRoom('office');
    } else {
      set([currentStep, currentRoom, 'dialogues', 'farewell', 'overlay'], true);
      setDialogue('farewell');
    }
  };

  renderNextButtonHint = () => {
    const { buttonHintStepFinished, currentStep } = this.props;
    const { difference } = this.state;
    
    const hintTitle = store.getState().steps[currentStep].nextStepButton.title;
    const hintDescription = store.getState().steps[currentStep].nextStepButton
      .description;

    if (buttonHintStepFinished) {
      return (
        <ButtonHint
          theme="right"
          onClick={this.hideNextButtonHint}
          title={typeof hintTitle === 'function' ? hintTitle() : hintTitle}
          description={hintDescription}
          style={{
            left: `calc(${difference / 2}px  + 20px)`,
            right: `calc(${difference / 2}px + 20px)`
          }}
        />
      );
    }
    return null;
  };

  renderIntroHint = () => {
    const {
      stepIntro,
      currentRoom,
      stepIntroTitle,
      stepIntroStatus,
      setButtonHintStatus,
      set,
      currentStep
    } = this.props;
    const { difference } = this.state;

    if (stepIntroStatus && currentRoom === 'hospitalRoom' && currentStep === 'step1') {
      return (
        <ButtonHint
          title={stepIntroTitle}
          description={stepIntro}
          style={{
            left: `calc(${difference / 2}px  + 20px)`,
            right: `calc(${difference / 2}px + 20px)`
          }}
          onClick={() => {
            set(['stepIntroStatus'], false);
            setButtonHintStatus(true);
          }}
        />
      );
    }
    return null;
  };

  hideNextButtonHint = () => {
    const { setButtonHintStepFinished } = this.props;
    setButtonHintStepFinished(false);
  };

  goBack = () => {
    const { setRoom, set, stepIntroStatus } = this.props;
    if (stepIntroStatus) {
      set(['stepIntroStatus'], false);
    }
    this.hideHint();
    setRoom('corridor');
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Room);
