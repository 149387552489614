import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'exercises', 'handingOverExercise1', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'exercises', 'handingOverExercise2', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'handingOverExercise2'));
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.handingOverExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Übergabegespräch',
      text:
        'Am Ende der Schicht geben Sie die wichtigsten Informationen des Tages an Ihre Kollegen weiter.',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Was passt zusammen?',
          question: 'Warum wurde der Patient eingewiesen?',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'der Einweisungsgrund'
            },
            {
              id: 2,
              data: 'die Verlaufsbeschreibung'
            },
            {
              id: 3,
              data: 'der Untersuchungsbefund'
            },
            {
              id: 4,
              data: 'der Allgemeinzustand'
            },
            {
              id: 5,
              data: 'das Aufnahmedatum'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Wie geht es dem Patienten insgesamt?',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'der Einweisungsgrund'
            },
            {
              id: 2,
              data: 'der Untersuchungsbefund'
            },
            {
              id: 3,
              data: 'die Verlaufsbeschreibung'
            },
            {
              id: 4,
              meta: { correct: 1 },
              data: 'der Allgemeinzustand'
            },
            {
              id: 5,
              data: 'das Aufnahmedatum'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Was haben Untersuchungen ergeben?',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'der Einweisungsgrund'
            },
            {
              id: 2,
              data: 'die Verlaufsbeschreibung'
            },
            {
              id: 3,
              meta: { correct: 1 },
              data: 'der Untersuchungsbefund'
            },
            {
              id: 4,
              data: 'der Allgemeinzustand'
            },
            {
              id: 5,
              data: 'das Aufnahmedatum'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Wie hat sich der Zustand des Patienten verändert?',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'die Verlaufsbeschreibung'
            },
            {
              id: 2,
              data: 'der Untersuchungsbefund'
            },
            {
              id: 3,
              data: 'der Einweisungsgrund'
            },
            {
              id: 4,
              data: 'das Aufnahmedatum'
            },
            {
              id: 5,
              data: 'der Allgemeinzustand'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Wann wurde der Patient eingeliefert?',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'der Einweisungsgrund'
            },
            {
              id: 2,
              data: 'die Verlaufsbeschreibung'
            },
            {
              id: 3,
              meta: { correct: 1 },
              data: 'das Aufnahmedatum'
            },
            {
              id: 4,
              data: 'der Untersuchungsbefund'
            },
            {
              id: 5,
              data: 'der Allgemeinzustand'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
