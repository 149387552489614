import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'openWindow', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set([
      'step1',
      'hospitalRoom',
      'dialogues',
      'openWindow',
      'overlay'
    ]),
    false
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'closeWindow', 'overlay'],
      true
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'closeWindow', 'overlay'],
      true
    )
  );

  store.dispatch(stepsActions.set(['step1', 'choices', 'windowClosed'], false));

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'entities', 'window', 'overlay'],
      true
    )
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      type: 'thought',
      text: () =>
        <span>
          Hier ist es aber{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('stickig'));
            }}
          >
            stickig
          </span>
          .
        </span>,
      audio: '008-Fenster_ID_Start.mp3'
    },
    1: {
      from: 'player',
      text: 'Ein bisschen frische Luft wäre ganz gut. Darf ich kurz das Fenster aufmachen?',
      audio: '008-Fenster_ID_1.mp3'
    },
    2: {
      from: 'ai',
      text: () =>
        <span>
          Ja,{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('lüften'));
            }}
          >
            lüften
          </span>{' '}
          Sie gern. Frische Luft würde mir gut tun.
        </span>,
      audio: '008-Fenster_ID_2.mp3'
    },
    3: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
