import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as infoboxActions } from '../../../reducers/infobox';

import styles from '../styles.less';

function goToOffice() {
  store.dispatch(stepsActions.setRoom('office'));
  store.dispatch(
    stepsActions.set(['step4', 'office', 'entities', 'milo', 'overlay'], true)
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'dialogues', 'getHelp', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentDialogue'], 'getHelp'));
}

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'dialogue', 'accident', 'overlay'],
      false
    )
  );
  store.dispatch(stepsActions.setDialogue(''));
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'entities', 'firstPatientDown', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'hotSpots', 'accident', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'entities', 'firstPatient', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(['step4', 'office', 'entities', 'milo', 'overlay'], false)
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'entities', 'miloAndLisa', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'hotSpots', 'miloAndLisa', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'corridor', 'hotSpots', 'hospitalRoom', 'overlay'],
      false
    )
  );
  store.dispatch(exerciseSetActions.setNextActivity('handingOver'));
  store.dispatch(exerciseSetActions.setCurrentExerciseSet('accident'));
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step4', 'choices', 'patientStatusKnown'], false)
  );
  store.dispatch(
    stepsActions.set(['step4', 'choices', 'liftUpThePatient'], false)
  );
  store.dispatch(stepsActions.set(['step4', 'choices', 'getMilo'], false));
  store.dispatch(stepsActions.set(['step4', 'choices', 'askAboutPain'], false));
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question: 'Oh, nein. Herr Teller! <br>Hoffentlich hat er sich nicht verletzt. <br>Jetzt nur die Ruhe bewahren! <br>Eins nach dem anderen.',
      answers: () => {
        const liftUpThePatient = store.getState().steps.step4.choices
          .liftUpThePatient;

        const getMilo = store.getState().steps.step4.choices.getMilo;
        const patientStatusKnown = store.getState().steps.step4.choices
          .patientStatusKnown;

        // - try to lift the patient
        let choiceOne = 'Herrn Teller hochheben';
        let choiceOneIndex = '0-1-1';
        if (getMilo && patientStatusKnown) {
          choiceOne = 'Herrn Teller jetzt hochheben';
          choiceOneIndex = '0-4-1';
        }

        let choiceThree = 'Milo holen';
        let choiceThreeIndex = '0-3-1';
        if (!getMilo && patientStatusKnown) {
          choiceOne = 'Herrn Teller jetzt hochheben';
          choiceOneIndex = '0-6-1';
          choiceThree = 'nach Milo klingeln';
          choiceThreeIndex = '0-5-1';
        }

        return [
          {
            id: 'a',
            goTo: choiceOneIndex,
            data: choiceOne,
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step4', 'choices', 'liftUpThePatient'], true)
              );
            },
            renderQuestion: false,
            className: liftUpThePatient ? styles.disabled : ''
          },
          {
            id: 'b',
            goTo: '1-1',
            onClick: () => {
              store.dispatch(
                stepsActions.set(
                  ['step4', 'choices', 'patientStatusKnown'],
                  true
                )
              );
              store.dispatch(
                stepsActions.set(
                  ['step4', 'choices', 'liftUpThePatient'],
                  false
                )
              );
            },
            data: 'Herrn Teller ansprechen',
            renderQuestion: false,
            className: patientStatusKnown ? styles.disabled : ''
          },
          {
            id: 'c',
            goTo: choiceThreeIndex,
            data: choiceThree,
            renderQuestion: false,
            className: getMilo ? styles.disabled : ''
          }
        ];
      }
    },

    '0-1-1': {
      from: 'ai',
      text: 'He, was machen Sie denn da? Lassen Sie mich los.',
      audio: '215-Hochheben-ohne-Info_ID_1.mp3',
      nextSnippet: '0-1-2'
    },
    '0-1-2': {
      from: 'player',
      text: () =>
        <span>
          Oh, das wollte ich nicht. Jetzt ist er{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('panisch'));
            }}
          >
            panisch
          </span>
          .
        </span>,
      audio: '215-Hochheben-ohne-Info_ID_2.mp3',
      type: 'thought',
      nextSnippet: '0-1-3'
    },
    '0-1-3': {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: '0',
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },

    '0-3-1': {
      from: 'player',
      text: 'Herr Teller, ich bin gleich wieder da.',
      audio: '214-Sturz-Milo_ID_1.mp3',
      nextSnippet: '0-3-2'
    },
    '0-3-2': {
      type: 'choice',
      answers: [
        {
          id: 'a',
          onClick: () => {
            goToOffice();
          },
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },

    '0-4-1': {
      from: 'player',
      text: () =>
        <span>
          Herr Teller, Milo kommt jetzt an Ihre rechte Seite. Wir unterstützen
          Sie unter den Armen und am{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('gesäß'));
            }}
          >
            Gesäß
          </span>{' '}
          und bewegen Sie{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('vorsichtig'));
            }}
          >
            vorsichtig
          </span>{' '}
          hoch.
        </span>,
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_2.mp3',
      nextSnippet: '0-4-2'
    },
    '0-4-2': {
      from: 'ai',
      text: 'Und was mache ich?',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_3.mp3',
      nextSnippet: '0-4-3'
    },
    '0-4-3': {
      from: 'player',
      text: () =>
        <span>
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('stützen'));
            }}
          >
            Stützen
          </span>{' '}
          Sie sich auf Ihr linkes Bein, damit Sie Ihre Wunde nicht belasten.
        </span>,
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_4.mp3',
      nextSnippet: '0-4-4'
    },
    '0-4-4': {
      from: 'player',
      text: 'Wir drehen Sie dann zur linken Seite und ... Milo, holst du bitte den Rollstuhl von da drüben.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_5.mp3',
      nextSnippet: '0-4-5'
    },
    '0-4-5': {
      from: 'ai',
      text: 'Das ist so anstrengend. Ich kann mich nicht mehr lange halten.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_6.mp3',
      nextSnippet: '0-4-6'
    },
    '0-4-6': {
      from: 'player',
      text: 'Aber wir halten Sie sicher, Herr Teller.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_7.mp3',
      nextSnippet: '0-4-7'
    },
    '0-4-7': {
      from: 'player',
      text: 'Auf 3 bewegen wir Sie gemeinsam in den Rollstuhl. Sind Sie bereit? 1-2-3 ...',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_8.mp3',
      nextSnippet: '0-4-8'
    },
    '0-4-8': {
      from: 'ai',
      text: 'Oh, Gott sei Dank. Das ist ja nochmal gut gegangen. Aber was für ein Schreck!',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_9.mp3',
      nextSnippet: '3-1'
    },

    '0-5-1': {
      from: 'player',
      text: 'Herr Teller, passen Sie auf: Ich hole meinen Kollegen dazu und dann helfen wir Ihnen gemeinsam in den Rollstuhl.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_1.mp3',
      nextSnippet: '0-4-1'
    },

    '0-6-1': {
      from: 'player',
      text: 'Er ist doch viel zu schwer für mich! Ich sollte doch Milo holen, auch wegen der Wunde.',
      audio: '213-Sturz-Nicht-Milo_ID_1.mp3',
      type: 'thought',
      nextSnippet: '0'
    },

    '1-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          data: 'Was ist denn hier los?',
          audio: '211-Sturz-Ansprechen-Teil1_ID_2.mp3'
        },
        {
          id: 'b',
          goTo: '1-2-1',
          data: 'Was machen Sie da?',
          audio: '211-Sturz-Ansprechen-Teil1_ID_3.mp3'
        },
        {
          id: 'c',
          goTo: '1-1-3',
          data: 'Herr Teller, was ist denn passiert? Tut Ihnen irgendetwas weh?',
          audio: '211-Sturz-Ansprechen-Teil1_ID_1.mp3'
        }
      ]
    },

    '1-1-1': {
      from: 'ai',
      text: 'Helfen Sie mir doch bitte!',
      audio: '211-Sturz-Ansprechen-Teil1_ID_6.mp3',
      nextSnippet: '1-1-2'
    },
    '1-1-2': {
      from: 'player',
      text: 'Ja, natürlich, Herr Teller. Tut Ihnen irgendetwas weh?',
      audio: '211-Sturz-Ansprechen-Teil1_ID_7.mp3',
      nextSnippet: '1-1-3'
    },
    '1-1-3': {
      from: 'ai',
      text: () =>
        <span>
          Nein, mir tut nichts weh. Ich wollte mich nur ein bisschen{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('aufsetzen'));
            }}
          >
            aufsetzen
          </span>
          . Dann ist mir schlecht geworden.
        </span>,
      audio: '211-Sturz-Ansprechen-Teil1_ID_13.mp3',
      nextSnippet: '1-1-4'
    },
    '1-1-4': {
      from: 'player',
      text: 'Geht es Ihnen jetzt wieder besser?',
      audio: '211-Sturz-Ansprechen-Teil1_ID_14.mp3',
      nextSnippet: '1-1-5'
    },
    '1-1-5': {
      from: 'ai',
      text: 'Ja, aber jetzt liege ich hier wie ein Käfer!',
      audio: '211-Sturz-Ansprechen-Teil1_ID_15.mp3',
      nextSnippet: '2-1'
    },

    '1-2-1': {
      from: 'ai',
      text: 'Was glauben Sie denn? Ich putze den Boden!',
      audio: '211-Sturz-Ansprechen-Teil1_ID_9.mp3',
      nextSnippet: '1-2-2'
    },
    '1-2-2': {
      from: 'player',
      text: 'Ah, so meinte ich das nicht Herr Teller, tut mir leid.',
      audio: '211-Sturz-Ansprechen-Teil1_ID_10.mp3',
      nextSnippet: '1-1-1'
    },

    '2-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: () => {
        const askAboutPain = store.getState().steps.step4.choices.askAboutPain;
        return [
          {
            id: 'a',
            goTo: '2-1-1',
            data: 'Und? Schmerzen?',
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step4', 'choices', 'askAboutPain'], true)
              );
            },
            audio: '211-Sturz-Ansprechen-Teil1_ID_18.mp3',
            className: askAboutPain ? styles.disabled : ''
          },
          {
            id: 'b',
            goTo: '2-2-1',
            data: 'Haben Sie sich irgendwo verletzt? Haben Sie irgendwo Schmerzen? Sind Sie auf die Wunde gefallen?',
            audio: '211-Sturz-Ansprechen-Teil1_ID_17.mp3'
          },
          {
            id: 'c',
            goTo: '2-2-3',
            data: 'Sind Sie auf die Wunde gefallen?',
            audio: '211-Sturz-Ansprechen-Teil1_ID_16.mp3'
          }
        ];
      }
    },

    '2-1-1': {
      from: 'ai',
      text: 'Wer? Ich?',
      audio: '211-Sturz-Ansprechen-Teil1_ID_26.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'player',
      text: 'Ah, Mist. Gerade in Stress-Situationen sollte man ganze Sätze formulieren.',
      audio: '211-Sturz-Ansprechen-Teil1_ID_27.mp3',
      type: 'thought',
      nextSnippet: '2-1-3'
    },
    '2-1-3': {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: '2-1',
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },

    '2-2-1': {
      from: 'ai',
      text: 'Nicht so viele Fragen auf einmal. Mir wird wieder ganz schlecht.',
      audio: '211-Sturz-Ansprechen-Teil1_ID_22.mp3',
      nextSnippet: '2-2-2'
    },
    '2-2-2': {
      from: 'player',
      text: 'Ja, Herr Teller, da haben Sie recht. Sind Sie auf die Wunde gefallen?',
      audio: '211-Sturz-Ansprechen-Teil1_ID_23.mp3',
      nextSnippet: '2-2-3'
    },
    '2-2-3': {
      from: 'ai',
      text: () =>
        <span>
          Nein, ich glaube nicht. Ich bin ja einfach nur{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('rutschen'));
            }}
          >
            rausgerutscht
          </span>{' '}
          und auf der Seite gelandet.
        </span>,
      audio: '211-Sturz-Ansprechen-Teil1_ID_20.mp3',
      nextSnippet: '2-2-4'
    },
    '2-2-4': {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: '0',
          data: 'Weiter',
          renderQuestion: false
        }
      ]
    },

    '3-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '3-1-1',
          data: 'Sie hätten besser aufpassen müssen!',
          audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_12.mp3'
        },
        {
          id: 'b',
          goTo: '3-2-1',
          data: 'Das können Sie laut sagen.',
          audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_10.mp3'
        },
        {
          id: 'c',
          goTo: '3-3-1',
          data: 'Dieses Mal ist alles gut gegangen, aber ...',
          audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_11.mp3'
        }
      ]
    },

    '3-1-1': {
      from: 'ai',
      text: 'Mit Vorwürfen erreicht man bei mir nichts.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_19.mp3',
      type: 'thought',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'ai',
      text: 'Jetzt sitze ich im Rollstuhl, das war doch Ihr Ziel.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_20.mp3',
      nextSnippet: '3-1-3'
    },
    '3-1-3': {
      from: 'player',
      text: () =>
        <span>
          Wir legen Sie jetzt erst mal ins Bett zurück, ich messe Ihren{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('blutdruck'));
            }}
          >
            Blutdruck
          </span>{' '}
          und sehe mir den Verband an.
        </span>,
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_21.mp3',
      nextSnippet: '3-1-4'
    },
    '3-1-4': {
      from: 'player',
      text: 'Dann beobachten wir, wie es Ihnen geht. Wenn alles in Ordnung ist und Sie noch mögen, drehen Sie gegen Abend noch eine Runde.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_22.mp3',
      nextSnippet: '3-1-5'
    },
    '3-1-5': {
      from: 'ai',
      text: 'Gut, vielen Dank.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_23.mp3',
      nextSnippet: '3-1-6'
    },
    '3-1-6': {
      from: 'ai',
      text: 'Na dann mache ich ja heute doch noch den Ausflug in den Aufenthaltsraum.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_24.mp3',
      nextSnippet: '4-1'
    },

    '3-2-1': {
      from: 'player',
      text: () =>
        <span>
          Wir legen Sie jetzt erst mal ins Bett zurück, ich messe Ihren{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('blutdruck'));
            }}
          >
            Blutdruck
          </span>{' '}
          und sehe mir den Verband an.
        </span>,
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_14.mp3',
      nextSnippet: '3-1-4'
    },

    '3-3-1': {
      from: 'ai',
      text: 'Beim nächsten Mal sage ich Bescheid, versprochen.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_16.mp3',
      nextSnippet: '3-1-3'
    },

    '4-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '4-1-1',
          data: () =>
            <span>
              Ja, genau Herr Teller, mit Ina von der{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('spätschicht'));
                }}
              >
                Spätschicht
              </span>
              . Und wir zwei machen dann morgen weiter.
            </span>,
          audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_25.mp3'
        },
        {
          id: 'b',
          goTo: '4-2-1',
          data: () =>
            <span>
              Herr Teller, den Ausflug machen wir morgen. Ich habe jetzt nämlich{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('feierabend'));
                }}
              >
                Feierabend
              </span>
              .
            </span>,
          audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_30.mp3'
        }
      ]
    },

    '4-1-1': {
      from: 'player',
      text: 'Jetzt erholen Sie sich erst mal.',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_28.mp3',
      nextSnippet: '4-1-2'
    },
    '4-1-2': {
      from: 'ai',
      text: 'Ach so, stimmt. Sie haben ja gleich Feierabend. Danke für Ihre Hilfe und denken Sie an mich, wenn Sie die Torte essen!',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_29.mp3',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['notfall']));
        }
      },
      nextSnippet: 'fin'
    },

    '4-2-1': {
      from: 'ai',
      text: 'Ach so, schade. Dann eben morgen. Danke für Ihre Hilfe und denken Sie an mich, wenn Sie die Torte essen!',
      audio: '212-Sturz-Ansprechen-Teil2-Milo_ID_31.mp3',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['notfall']));
        }
      },
      nextSnippet: 'fin'
    },

    fin: {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: 'feedback',
          data: 'Weiter',
          onClick: () => {
            resetChoices();
          },
          renderQuestion: false
        }
      ]
    },

    feedback: {
      type: 'feedback',
      text:
        'Fast geschafft. Den Feierabend haben Sie sich wirklich verdient. Nur eins fehlt noch ...',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['notfall'],
      correct: true
    }
  }
};
