import React from 'react';
import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      [
        'step1',
        'hospitalRoom',
        'exercises',
        'disinfectionExercise2',
        'overlay'
      ],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.disinfectionExercise2 === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['checkWound', 'disinfectionExercise2'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Wörter mit Wund- und Pflege-',
      text: 'Wie viele Komposita kennen Sie schon mit Wund- und Pflege-?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          type: 'CLOZE',
          title: 'Was passt?',
          text: `<strong>Wählen Sie aus.</strong><br><br>
                die {}maßnahme<br>
                der {}rand<br>
                der {}dienst
                `,
          items: [
            [
              { data: 'Pflege', meta: { correct: 1 }, id: 'a' },
              { data: 'Wund', id: 'b' }
            ],
            [
              { data: 'Pflege', id: 'a' },
              { data: 'Wund', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: 'Pflege', meta: { correct: 1 }, id: 'a' },
              { data: 'Wund', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              die <strong>Pflege</strong>maßnahme <br /> der{' '}
              <strong>Wund</strong>rand
              <br /> der <strong>Pflege</strong>dienst
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Was passt?',
          text: `<strong>Wählen Sie aus.</strong><br><br>
                 die {}kraft<br>
                 die {}heilung<br>
                 der {}abstrich
                `,
          items: [
            [
              { data: 'Pflege', meta: { correct: 1 }, id: 'a' },
              { data: 'Wund', id: 'b' }
            ],
            [
              { data: 'Pflege', id: 'a' },
              { data: 'Wund', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: 'Pflege', id: 'a' },
              { data: 'Wund', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              die <strong>Pflege</strong>kraft <br /> die <strong>Wund</strong>heilung
              <br /> der <strong>Wund</strong>abstrich
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Was passt?',
          text: `<strong>Wählen Sie aus.</strong><br><br>
                 das {}personal<br>
                 der {}bericht<br>
                 der {}verband
                `,
          items: [
            [
              { data: 'Pflege', meta: { correct: 1 }, id: 'a' },
              { data: 'Wund', id: 'b' }
            ],
            [
              { data: 'Pflege', meta: { correct: 1 }, id: 'a' },
              { data: 'Wund', id: 'b' }
            ],
            [
              { data: 'Pflege', id: 'a' },
              { data: 'Wund', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              das <strong>Pflege</strong>personal <br /> der{' '}
              <strong>Pflege</strong>bericht
              <br /> der <strong>Wund</strong>verband
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Was passt?',
          text: `<strong>Wählen Sie aus.</strong><br><br>
                 die {}einrichtung<br>
                 das {}heim<br>
                 die {}stufe
                `,
          items: [
            [
              { data: 'Pflege', meta: { correct: 1 }, id: 'a' },
              { data: 'Wund', id: 'b' }
            ],
            [
              { data: 'Pflege', meta: { correct: 1 }, id: 'a' },
              { data: 'Wund', id: 'b' }
            ],
            [
              { data: 'Pflege', meta: { correct: 1 }, id: 'a' },
              { data: 'Wund', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              die <strong>Pflege</strong>einrichtung <br /> das{' '}
              <strong>Pflege</strong>heim <br /> die <strong>Pflege</strong>stufe
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
