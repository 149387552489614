import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { themeit } from 'react-themeit';

const themeOptions = {
  base: cb => cb(require('./styles.less')),
  themes: {
    lineThrough: cb => cb(require('./themes/lineThrough.less')),
    small: cb => cb(require('./themes/small.less'))
  }
};

class NavDots extends Component {
  render() {
    const { styles, clickable } = this.props;

    const className = `${styles.container} ${clickable
      ? styles.clickable
      : ''} ${this.props.className}`;

    return (
      <div className={className}>
        {this.createDots()}
      </div>
    );
  }

  createDots = () => {
    const { amount, current, styles } = this.props;

    const dots = [];

    for (let i = 0; i < amount; ++i) {
      dots.push(
        <div
          className={`${styles.dot} ${current === i ? styles.active : ''}`}
          key={i}
          data-index-number={i}
          onClick={this.activate}
        />
      );
    }

    return dots;
  };

  activate = e => {
    const { clickable } = this.props;
    if (clickable) {
      this.props.onChange(Number(e.target.dataset.indexNumber));
    }
  };
}

NavDots.defaultProps = {
  current: 0,
  amount: 4,
  clickable: true,
  className: ''
};

NavDots.propTypes = {
  current: PropTypes.number,
  amount: PropTypes.number,
  clickable: PropTypes.bool,
  className: PropTypes.string
};

export default themeit(themeOptions)(NavDots);
