import React, { Component } from 'react';
import { connect } from 'react-redux';

import { DefaultButton } from '../../../components';
import { DownloadIcon } from '../../../static/icons/DownloadIcon';
import { actions as stepsActions } from '../../../reducers/steps';

import styles from './styles.less';

const mapDispatchToProps = {
  setScene: stepsActions.setScene
};

class MenuPoint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMore: false
    };
  }

  render() {
    const { image } = this.props;
    const { showMore } = this.state;

    return (
      <div
        className={styles.menuPoint}
        style={{ backgroundImage: `url(${image})` }}
        onMouseEnter={this.showMore}
        onMouseLeave={this.showLess}
      >
        {!showMore && this.renderHeadlines()}
        {this.renderChildren()}
      </div>
    );
  }

  showMore = () => {
    this.setState({ showMore: true });
  };

  showLess = () => {
    this.setState({ showMore: false });
  };

  renderHeadlines = () => {
    const { headline, subline } = this.props;

    return [
      <h2 key="headline" className={styles.headline}>
        {headline}
      </h2>,
      <h3 key="subline" className={styles.subline}>
        {subline}
      </h3>
    ];
  };

  renderChildren = () => {
    const { description } = this.props;
    const { showMore } = this.state;
    if (showMore) {
      return (
        <div className={styles.moreContainer}>
          <div className={styles.informationContainer}>
            {this.renderHeadlines()}
            <div className={styles.description}>
              {description}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {this.renderButtons()}
          </div>
        </div>
      );
    }
    return null;
  };

  renderButtons = () => {
    const { scenes, setScene } = this.props;

    return scenes.map((scene, i) =>
      <div className={styles.entry} key={i}>
        <a className={styles.pdfDownload}
          href={scene.pdf}
          target="_blank" rel="noopener noreferrer"
        >
          <DownloadIcon />
        </a>
        <DefaultButton
          onClick={() => {
            setScene(scene);
          }}
          className={styles.button}
          label={scene.name}
          tooltipText={scene.tooltip}
        />
      </div>
    );
  };
}

export default connect(null, mapDispatchToProps)(MenuPoint);
