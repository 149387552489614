import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as infoboxActions } from '../../../reducers/infobox';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'entities', 'mrsTeller', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'dialogues', 'firstThoughtsCorridor', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(['step2', 'corridor', 'hotSpots', 'cake', 'overlay'], true)
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Wer geht denn da gerade am Gang vorbei? Das ist ja Frau Teller und in ihrer Hand: eine riesige Torte. Sie wird doch nicht ...',
      audio: '207-Kuchen_ID_1.mp3',
      type: 'thought'
    },
    1: {
      from: 'player',
      text: 'Ich muss sie unbedingt aufhalten, bevor sie mit der Torte zu ihrem Mann geht. Auch sie muss mitmachen, den Ernährungsplan einzuhalten.',
      audio: '207-Kuchen_ID_2.mp3',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['patientenedukation']));
        }
      },
      type: 'thought'
    },
    2: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['patientenedukation'],
      correct: true
    }
  }
};
