import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'headboard', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'activitySecondPart', 'overlay'],
      false
    )
  );

  store.dispatch(exerciseSetActions.setNextActivity('step1Score'));
  store.dispatch(
    exerciseSetActions.setCurrentExerciseSet('activitySecondPart')
  );
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step1', 'choices', 'gettingTheNewspaper'], false)
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Liegen Sie denn bequem?',
      audio: '019-Kissen_ID_1.mp3',
      nextSnippet: '0-1-1'
    },

    '0-1-1': {
      from: 'ai',
      text: 'Ja, ja.',
      audio: '019-Kissen_ID_2.mp3',
      nextSnippet: '0-1-2'
    },
    '0-1-2': {
      from: 'player',
      text: 'Was würden Sie denn heute gerne noch machen?',
      audio: '019-Kissen_ID_3.mp3',
      nextSnippet: '0-1-3'
    },
    '0-1-3': {
      from: 'ai',
      text: 'Hauptsache im Bett bleiben, vielleicht was lesen. Aber ich habe leider nichts da.',
      audio: '019-Kissen_ID_4.mp3',
      nextSnippet: '0-1-4'
    },
    '0-1-4': {
      from: 'player',
      text: () =>
        <span>
          Wir haben da ein paar Zeitschriften im {' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('aufenthaltsraum'));
            }}
          >
            Aufenthaltsraum
          </span>
          .
        </span>,
      audio: '019-Kissen_ID_5.mp3',
      nextSnippet: '0-1-5'
    },
    '0-1-5': {
      from: 'ai',
      text: 'Können Sie mir welche holen?',
      audio: '019-Kissen_ID_6.mp3',
      nextSnippet: '1-1'
    },
    '1-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          data: 'Das gehört wirklich nicht zu meinen Aufgaben.',
          audio: '019-Kissen_ID_9.mp3'
        },
        {
          id: 'b',
          goTo: '1-2-1',
          data: 'Ich habe eine andere Idee: Wir könnten sie ja gemeinsam holen.',
          audio: '019-Kissen_ID_7.mp3'
        },
        {
          id: 'c',
          goTo: '1-2-1',
          data: 'Ich könnte das schon machen, aber es wäre viel besser, wenn Sie mitkommen würden.',
          audio: '019-Kissen_ID_8.mp3'
        }
      ]
    },

    '1-1-1': {
      from: 'ai',
      text: 'Ja, und wie bekomme ich dann meine Zeitschriften?',
      audio: '019-Kissen_ID_17.mp3',
      nextSnippet: '1-1-2'
    },
    '1-1-2': {
      from: 'player',
      text: 'Indem Sie ein bisschen mehr mitmachen.',
      audio: '019-Kissen_ID_18.mp3',
      nextSnippet: '1-1-3'
    },
    '1-1-3': {
      from: 'ai',
      text: 'Ich warte jetzt erst mal auf meine Frau. Die bringt mir bestimmt die Zeitschriften, und vielleicht auch ein Törtchen!',
      audio: '019-Kissen_ID_19.mp3',
      nextSnippet: '2-1-8'
    },

    '1-2-1': {
      from: 'ai',
      text: 'Oh nein, ich habe doch gesagt, dass ich nicht möchte.',
      audio: '019-Kissen_ID_11.mp3',
      nextSnippet: '1-2-2'
    },
    '1-2-2': {
      from: 'ai',
      type: 'thought',
      text: `Warum lässt ${store.getState().user.sex === 'male'
        ? 'er'
        : 'sie'} mich nicht einfach in Ruhe?`,
      audio:
        store.getState().user.sex === 'male'
          ? '019-Kissen_ID_12.mp3'
          : '019-Kissen_ID_Neutral1.mp3',
      nextSnippet: '2-1'
    },

    '2-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: () => [
        {
          id: 'a',
          goTo: '2-1-1',
          audio: '019-Kissen_ID_21.mp3',
          data: 'Aber Sie möchten doch bald wieder nach Hause.'
        },
        {
          id: 'b',
          goTo: '2-2-1',
          audio: '019-Kissen_ID_22.mp3',
          onClick: () => {
            store.dispatch(
              stepsActions.set(
                ['step1', 'choices', 'gettingTheNewspaper'],
                true
              )
            );
          },
          data: 'Na gut, ich hole Ihnen jetzt mal ein paar Zeitschriften.'
        },
        {
          id: 'c',
          goTo: '2-3-1',
          audio: '019-Kissen_ID_20.mp3',
          data: 'Herr Teller, warum möchten Sie denn nicht? '
        }
      ]
    },

    '2-1-1': {
      from: 'player',
      type: 'thought',
      text: 'Kein leichter Fall! Vielleicht sollte ich ihn fragen, warum er nicht möchte.',
      audio: '019-Kissen_ID_26.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'player',
      text: 'Herr Teller, warum möchten Sie denn nicht?',
      audio: '019-Kissen_ID_20.mp3',
      nextSnippet: '2-3-1'
    },

    '2-1-3': {
      from: 'player',
      text: 'Na gut, ausnahmsweise hole ich Ihnen die Zeitschrift. Aber Sie versprechen mir, dass Sie morgen mitmachen, ja?',
      audio: '019-Kissen_ID_31.mp3',
      nextSnippet: '2-1-4'
    },
    '2-1-4': {
      from: 'player',
      text: () =>
        <span>
          Und bei der{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(
                glossaryActions.setCurrentInfo('krankengymnastin')
              );
            }}
          >
            Krankengymnastin
          </span>{' '}
          melde ich Sie jetzt zweimal täglich an.
        </span>,
      audio: '019-Kissen_ID_32.mp3',
      nextSnippet: '2-1-5'
    },
    '2-1-5': {
      from: 'ai',
      text: 'Einverstanden. Dann bekomme ich ein bisschen mehr Sicherheit.',
      audio: '019-Kissen_ID_33.mp3',
      nextSnippet: '2-1-6'
    },
    '2-1-6': {
      from: 'player',
      text: 'Ja, und es wäre doch schön, wenn Sie sich die Zeitschriften bald selbst holen könnten.',
      audio: '019-Kissen_ID_34.mp3',
      nextSnippet: '2-1-7'
    },
    '2-1-7': {
      from: 'ai',
      text: 'Ja, Sie haben Recht. Und wissen Sie was? Ich hätte so Lust auf ein kleines Törtchen. Wo bleibt denn eigentlich meine Frau?',
      audio: '019-Kissen_ID_35.mp3',
      nextSnippet: '2-1-8'
    },
    '2-1-8': {
      from: 'player',
      text: 'Ah wie schön. Ihre Frau kommt heute noch.',
      audio: '019-Kissen_ID_36.mp3',
      nextSnippet: '2-1-9'
    },
    '2-1-9': {
      from: 'player',
      type: 'thought',
      text: 'Aber hoffentlich ohne Torte!',
      audio: '019-Kissen_ID_37.mp3',
      nextSnippet: 'fin'
    },

    '2-2-1': {
      from: 'player',
      text: 'Aber Sie versprechen mir, dass Sie morgen mitmachen.',
      audio: '019-Kissen_ID_28.mp3',
      nextSnippet: '2-1-4'
    },

    '2-3-1': {
      from: 'ai',
      text: () =>
        <span>
          Ich habe Angst, zu{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('stürzen'));
            }}
          >
            stürzen
          </span>
          . Ich möchte jetzt wirklich nicht.
        </span>,
      audio: '019-Kissen_ID_24.mp3',
      nextSnippet: '2-1-3'
    },

    fin: {
      type: 'choice',
      answers: () => {
        const gettingTheNewspaper = store.getState().steps.step1.choices
          .gettingTheNewspaper;
        const feedback = gettingTheNewspaper ? '3-2' : '3-3';

        return [
          {
            id: 'a',
            goTo: feedback,
            onClick: () => {
              resetChoices();
            },
            data: 'Weiter',
            renderQuestion: false
          }
        ];
      }
    },

    '3-1': {
      type: 'feedback',
      text:
        'Gehen Sie besser auf Herrn Teller ein! Sprechen Sie noch einmal mit ihm!',
      correct: false
    },
    '3-2': {
      type: 'feedback',
      text:
        'Lassen Sie nicht locker! Herr Teller muss jetzt unbedingt mobilisiert werden. ',
      onClick: () => {
        exitDialogue();
      },
      correct: false
    },
    '3-3': {
      type: 'feedback',
      text:
        'Gut gemacht! Sie sind gut auf Herrn Teller eingegangen. Bleiben Sie dran!',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
