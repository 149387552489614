import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import store from '../store';

import { createReducer, createActionTypes } from '../lib/redux';

const defaultState = {
  step1: {
    corridor: {
      hotSpots: {
        hospitalRoom: {
          x: '525px',
          y: '425px',
          overlay: true,
          goTo: 'hospitalRoom',
          audio: require('../static/audio/door.mp3'),
          type: 'scene',
          scrollTarget: true
        },
        office: {
          x: '1500px',
          y: '485px',
          overlay: false,
          goTo: 'office',
          audio: require('../static/audio/door.mp3'),
          type: 'scene'
        }
      }
    },
    hospitalRoom: {
      hotSpots: {
        greeting: {
          x: '715px',
          y: '550px',
          overlay: true,
          goTo: 'greeting',
          type: 'dialogue',
          scrollTarget: true
        },
        openWindow: {
          x: '1230px',
          y: '420px',
          overlay: false,
          goTo: 'openWindow',
          type: 'dialogue'
        },
        closeWindow: {
          x: '1330px',
          y: '420px',
          overlay: false,
          goTo: 'closeWindow',
          type: 'dialogue'
        },
        disinfection: {
          x: '1190px',
          y: '580px',
          overlay: false,
          goTo: 'disinfection',
          audio: require('../static/audio/disinfection.mp3'),
          type: 'dialogue'
        },
        checkWound: {
          x: '860px',
          y: '640px',
          overlay: false,
          goTo: 'checkWound',
          type: 'dialogue',
          scrollTarget: true
        },
        family: {
          x: '330px',
          y: '640px',
          overlay: false,
          goTo: 'family',
          type: 'dialogue'
        },
        food: {
          x: '500px',
          y: '730px',
          overlay: false,
          goTo: 'food',
          type: 'dialogue',
          scrollTarget: true
        },
        activity: {
          x: '730px',
          y: '870px',
          overlay: false,
          goTo: 'activity',
          type: 'dialogue',
          scrollTarget: true
        },
        headboard: {
          x: '530px',
          y: '550px',
          overlay: false,
          goTo: 'activitySecondPart',
          type: 'dialogue',
          scrollTarget: true
        },
        farewell: {
          x: '1500px',
          y: '420px',
          overlay: false,
          goTo: 'farewell',
          type: 'dialogue'
        },
      },
      entities: {
        firstPatient: {
          x: '607px',
          y: '420px',
          height: '281px',
          overlay: true,
          src: require('../static/images/hospitalRoom/first-patient.png')
        },
        secondPatient: {
          x: '910px',
          y: '418px',
          height: '155px',
          overlay: true,
          src: require('../static/images/hospitalRoom/second-patient.png')
        },
        tablet: {
          x: '378px',
          y: '662px',
          height: '137px',
          overlay: true,
          src: require('../static/images/hospitalRoom/tablet.png')
        },
        slipper: {
          x: '700px',
          y: '855px',
          height: '50px',
          overlay: true,
          src: require('../static/images/hospitalRoom/slipper.png')
        },
        window: {
          x: '1020px',
          y: '114px',
          height: '702px',
          overlay: false,
          src: require('../static/images/hospitalRoom/window-opened.jpg')
        },
        wheelchair: {
          x: '943px',
          y: '670px',
          height: '385px',
          overlay: true,
          src: require('../static/images/hospitalRoom/wheelchair.png')
        }
      },
      dialogues: {
        checkWound: { overlay: false },
        activitySecondPart: { overlay: true },
        farewell: { overlay: false },
        food: { overlay: false },
        disinfection: { overlay: false },
        activity: { overlay: false },
        step1Score: { overlay: false }
      },
      exercises: {
        calm: { overlay: false },
        empathicConvincing: { overlay: false },
        trayExercise1: { overlay: false },
        trayExercise2: { overlay: false },
        trayExercise3: { overlay: false },
        woundTreatment: { overlay: false },
        woundAndCare: { overlay: false },
        activityExercise1: { overlay: false },
        activityExercise2: { overlay: false },
        activityExercise3: { overlay: false },
        checkWoundExercise1: { overlay: false },
        checkWoundExercise2: { overlay: false },
        checkWoundExercise3: { overlay: false },
        disinfectionExercise1: { overlay: false },
        disinfectionExercise2: { overlay: false },
        disinfectionExercise3: { overlay: false }
      }
    },
    office: {
      entities: {
        coffeeMachine: {
          x: '1430px',
          y: '390px',
          height: '100px',
          overlay: true,
          src: require('../static/images/office/coffee-machine.png')
        }
      }
    },
    choices: {
      disinfected: false,
      windowClosed: true,
      informedPatient: false,
      foodNotTasty: false,
      removeTray: false,
      getWheelchair: false,
      enoughRest: false,
      patientMoves: false,
      greetingFeedback: 'init',
      informedFeedback: 'init',
      foodFeedback: true,
      gettingTheNewspaper: false
    },
    nextStepButton: {
      title: 'Herzlichen Glückwunsch!',
      description: 'Sie haben den ersten Schritt erfolgreich gespielt. Klicken Sie auf den Weiter-Knopf, um sich von Herrn Teller zu verabschieden und das Krankenzimmer zu verlassen.'
    },
    stepIntro: {
      title: 'Der Patient',
      text: 'Torben Teller ist 56 Jahre alt und wurde vor sieben Tagen ins Krankenhaus eingeliefert. Ihm musste als Folge der Grunderkrankung Diabetes mellitus das rechte Bein amputiert werden. Er liegt in einem Doppelzimmer zusammen mit Herrn Friesen.'
    }
  },

  step2: {
    corridor: {
      hotSpots: {
        hospitalRoom: {
          x: '525px',
          y: '425px',
          overlay: true,
          goTo: 'hospitalRoom',
          audio: require('../static/audio/door.mp3'),
          type: 'scene'
        },
        cake: {
          x: '800px',
          y: '380px',
          overlay: false,
          goTo: 'mrsTeller',
          type: 'dialogue',
          scrollTarget: true
        },
        office: {
          x: '1500px',
          y: '485px',
          overlay: true,
          goTo: 'office',
          onClick: () => {
            store.dispatch(actions.setDialogue('firstThoughts'));
          },
          audio: require('../static/audio/door.mp3'),
          type: 'scene',
          scrollTarget: true
        }
      },
      entities: {
        mrsTeller: {
          x: '700px',
          y: '240px',
          height: '750px',
          overlay: false,
          src: require('../static/images/corridor/mrs-teller.png')
        }
      },
      dialogues: {
        firstThoughtsCorridor: { overlay: true },
        mrsTeller: { overlay: false }
      },
      exercises: {
        mrsTellerExercise1: { overlay: false },
        mrsTellerExercise2: { overlay: false }
      }
    },
    hospitalRoom: {
      hotSpots: {},
      entities: {
        firstPatient: {
          x: '607px',
          y: '420px',
          height: '281px',
          overlay: true,
          src: require('../static/images/hospitalRoom/first-patient.png')
        },
        secondPatient: {
          x: '910px',
          y: '418px',
          height: '155px',
          overlay: true,
          src: require('../static/images/hospitalRoom/second-patient.png')
        },
        slipper: {
          x: '700px',
          y: '855px',
          height: '50px',
          overlay: true,
          src: require('../static/images/hospitalRoom/slipper.png')
        },
        wheelchair: {
          x: '943px',
          y: '670px',
          height: '385px',
          overlay: true,
          src: require('../static/images/hospitalRoom/wheelchair.png')
        }
      },
      dialogues: {},
      exercises: {}
    },
    office: {
      hotSpots: {
        coffee: {
          x: '1460px',
          y: '420px',
          overlay: true,
          goTo: 'coffee',
          type: 'dialogue',
          scrollTarget: true
        },
        advice: {
          x: '1320px',
          y: '330px',
          overlay: false,
          goTo: 'advice',
          type: 'dialogue',
          scrollTarget: true
        },
        telephone: {
          x: '1225px',
          y: '930px',
          overlay: false,
          goTo: 'dietaryAssistant',
          audio: require('../static/audio/telephone.mp3'),
          type: 'dialogue',
          scrollTarget: true
        },
        email: {
          x: '300px',
          y: '700px',
          overlay: false,
          goTo: 'email',
          type: 'exercise',
          scrollTarget: true
        }
      },
      entities: {
        coffeeMachine: {
          x: '1430px',
          y: '390px',
          height: '100px',
          overlay: true,
          src: require('../static/images/office/coffee-machine.png')
        },
        lisaBack: {
          x: '1133px',
          y: '175px',
          height: '700px',
          overlay: true,
          src: require('../static/images/office/lisa-back.png')
        },
        lisaFront: {
          x: '1220px',
          y: '185px',
          height: '670px',
          overlay: false,
          src: require('../static/images/office/lisa-front.png')
        }
      },
      dialogues: {
        dietaryAssistant: { overlay: false }
      },
      exercises: {
        dietaryAssistantExercise1: { overlay: false },
        dietaryAssistantExercise2: { overlay: false },
        dietaryAssistantExercise3: { overlay: false },
        dietaryAssistantExercise4: { overlay: false },
        dietaryAssistantExercise5: { overlay: false },
        coffeemaschineExercise1: { overlay: false },
        coffeemaschineExercise2: { overlay: false },
        coffeemaschineExercise3: { overlay: false },
        coffeemaschineExercise4: { overlay: false },
        coffeemaschineExercise5: { overlay: false },
        adviceExercise1: { overlay: false },
        adviceExercise2: { overlay: false },
        adviceExercise3: { overlay: false },
        adviceExercise4: { overlay: false },
        adviceExercise5: { overlay: false },
        email: { overlay: false }
      }
    },
    choices: {
      leaveOffice: false,
      waitForColleague: false,
      adviceFeedback: true,
      coffeeFeedback: true,
      wifeCooperative: true
    },
    nextStepButton: {
      title: () => `Sehr gut, ${store.getState().user.name}!`,
      description:
        'Auch den zweiten Schritt haben Sie mit Erfolg beendet. Bleiben Sie im Dienstzimmer, es gibt noch einiges zu tun!'
    }
  },
  step3: {
    corridor: {
      hotSpots: {
        hospitalRoom: {
          x: '525px',
          y: '425px',
          overlay: false,
          goTo: 'hospitalRoom',
          audio: require('../static/audio/door.mp3'),
          type: 'scene'
        },
        office: {
          x: '1500px',
          y: '485px',
          overlay: true,
          goTo: 'office',
          onClick: () => {
            store.dispatch(actions.setDialogue('talkingWasNice'));
          },
          audio: require('../static/audio/door.mp3'),
          type: 'scene',
          scrollTarget: true
        }
      }
    },
    office: {
      hotSpots: {
        doctor: {
          x: '900px',
          y: '600px',
          overlay: true,
          goTo: 'doctor',
          type: 'dialogue',
          scrollTarget: true
        },
        records: {
          x: '1360px',
          y: '680px',
          overlay: false,
          goTo: 'records',
          type: 'dialogue',
          scrollTarget: true
        }
      },
      entities: {
        coffeeMachine: {
          x: '1430px',
          y: '390px',
          height: '100px',
          overlay: true,
          src: require('../static/images/office/coffee-machine.png')
        },
        doctor: {
          x: '532px',
          y: '260px',
          height: '700px',
          overlay: true,
          src: require('../static/images/office/doctor.png')
        },
        records: {
          x: '1300px',
          y: '600px',
          height: '300px',
          overlay: true,
          src: require('../static/images/office/records.png')
        }
      },
      dialogues: {
        talkingWasNice: { overlay: true },
        doctor: { overlay: false },
        records: { overlay: false },
        step3Score: { overlay: true }
      },
      exercises: {
        doctorExercise1: { overlay: false },
        doctorExercise2: { overlay: false },
        recordsExercise1: { overlay: false },
        recordsExercise2: { overlay: false },
        recordsExercise3: { overlay: false },
        recordsExercise4: { overlay: false }
      }
    },
    choices: {},
    nextStepButton: {
      title: () => `Keine Sorge, ${store.getState().user.name}!`,
      description: 'Das Gespräch mit dem Arzt war vielleicht nicht ganz leicht, aber auch diesen Schritt haben Sie erfolgreich gemeistert.'
    }
  },
  step4: {
    corridor: {
      hotSpots: {
        hospitalRoom: {
          x: '525px',
          y: '425px',
          overlay: false,
          goTo: 'hospitalRoom',
          type: 'scene',
          audio: require('../static/audio/door.mp3'),
          scrollTarget: true
        },
        office: {
          x: '1500px',
          y: '485px',
          overlay: true,
          goTo: 'office',
          audio: require('../static/audio/door.mp3'),
          type: 'scene'
        },
        friesen: {
          x: '300px',
          y: '425px',
          overlay: false,
          goTo: 'knocking',
          type: 'dialogue'
        },
        goingHome: {
          x: '1630px',
          y: '450px',
          overlay: false,
          goTo: 'goingHome',
          type: 'dialogue'
        }
      },
      entities: {
        secondPatient: {
          x: '130px',
          y: '245px',
          height: '835px',
          overlay: false,
          src: require('../static/images/corridor/friesen.png'),
          scrollTarget: true
        }
      },
      dialogues: {
        goingHome: { overlay: true },
        gameOver: { overlay: false },
        knocking: { overlay: true }
      },
      exercises: {
        knockingExercise1: { overlay: false },
        knockingExercise2: { overlay: false }
      }
    },
    hospitalRoom: {
      hotSpots: {
        accident: {
          x: '650px',
          y: '750px',
          overlay: false,
          goTo: 'accident',
          type: 'dialogue',
          scrollTarget: true
        }
      },
      entities: {
        firstPatient: {
          x: '607px',
          y: '420px',
          height: '281px',
          overlay: true,
          src: require('../static/images/hospitalRoom/first-patient.png')
        },
        firstPatientDown: {
          x: '500px',
          y: '600px',
          height: '400px',
          overlay: false,
          src: require('../static/images/hospitalRoom/first-patient-down.png')
        },
        secondPatient: {
          x: '910px',
          y: '418px',
          height: '155px',
          overlay: false,
          src: require('../static/images/hospitalRoom/second-patient.png')
        }
      },
      dialogues: {
        accident: { overlay: false }
      },
      exercises: {
        accidentExercise1: { overlay: false },
        accidentExercise2: { overlay: false },
        accidentExercise3: { overlay: false }
      }
    },
    office: {
      hotSpots: {
        milo: {
          x: '725px',
          y: '390px',
          overlay: false,
          goTo: 'askingMiloForHelp',
          type: 'dialogue',
          scrollTarget: true
        },
        miloAndLisa: {
          x: '1630px',
          y: '450px',
          overlay: false,
          goTo: 'handingOver',
          type: 'dialogue',
          scrollTarget: true
        }
      },
      entities: {
        milo: {
          x: '580px',
          y: '228px',
          height: '631px',
          overlay: false,
          src: require('../static/images/office/milo.png')
        },
        miloAndLisa: {
          x: '1428px',
          y: '247px',
          height: '727px',
          overlay: false,
          src: require('../static/images/office/milo-and-lisa.jpg')
        }
      },
      dialogues: {
        canMiloHelpMe: { overlay: true },
        askingMiloForHelp: { overlay: false },
        handingOver: { overlay: false },
        getHelp: { overlay: false }
      },
      exercises: {
        handingOverExercise1: { overlay: false },
        handingOverExercise2: { overlay: false },
        handingOverExercise3: { overlay: false },
        handingOverExercise4: { overlay: false },
        handingOverExercise5: { overlay: false }
      }
    },
    choices: {
      waitedForMilo: false,
      placedDocumentsOnMilosDesk: false,
      liftUpThePatient: false,
      getMilo: false,
      askAboutPain: false,
      patientStatusKnown: false,
      listenToSecondPatient: true
    },
    nextStepButton: {
      title: () => `Super, ${store.getState().user.name}!`,
      description: 'Sie haben diesen ereignisreichen Tag in der Pflege erfolgreich abgeschlossen und sich jetzt einen ruhigen Feierabend verdient.'
    }
  },
  currentScene: {
    exclude: { hospitalRoom: true, office: true }
  },
  currentStep: 'step1',
  currentRoom: 'corridor',
  currentDialogue: '',
  currentExercise: '',
  buttonHintActive: false,
  tutorialFinished: false,
  stepFinished: false,
  buttonHintStepFinished: false,
  isInfoboxNotificationVisible: false,
  stepIntroStatus: true,
  gameRestartPopUp: false
};

const types = createActionTypes(
  [
    'SET_SCENE',
    'SET_STEP',
    'SET_ROOM',
    'SET_DIALOGUE',
    'SET_EXERCISE',
    'SET_BUTTON_HINT_STATUS',
    'SET_TUTORIAL_STATUS',
    'SET_STEP_FINISHED',
    'SET_BUTTON_HINT_STEP_FINISHED',
    'SET',
    'SHOW_INFOBOX_NOTIFICATION'
  ],
  ''
);

const actions = {
  setScene: scene => ({ type: types.SET_SCENE, scene }),
  setStep: step => ({ type: types.SET_STEP, step }),
  setRoom: room => ({ type: types.SET_ROOM, room }),
  setDialogue: dialogue => ({ type: types.SET_DIALOGUE, dialogue }),
  setExercise: exercise => ({ type: types.SET_EXERCISE, exercise }),
  setButtonHintStatus: value => ({ type: types.SET_BUTTON_HINT_STATUS, value }),
  setStepFinished: value => ({ type: types.SET_STEP_FINISHED, value }),
  setButtonHintStepFinished: value => ({
    type: types.SET_BUTTON_HINT_STEP_FINISHED,
    value
  }),
  setTutorialStatus: value => ({
    type: types.SET_TUTORIAL_STATUS,
    value
  }),
  set: (path, value) => ({
    type: types.SET,
    path,
    value
  }),
  showInfoboxNotification: value => ({
    type: types.SHOW_INFOBOX_NOTIFICATION,
    value
  })
};

const reducer = createReducer(defaultState, {
  [types.SET_SCENE]: (state = defaultState, { scene }) => ({
    ...state,
    currentScene: scene,
    currentRoom: scene.room,
    currentStep: scene.step
  }),
  [types.SET_STEP]: (state = defaultState, { step }) => ({
    ...state,
    currentStep: step
  }),
  [types.SET_ROOM]: (state = defaultState, { room }) => ({
    ...state,
    currentRoom: room
  }),
  [types.SET_DIALOGUE]: (state = defaultState, { dialogue }) => ({
    ...state,
    currentDialogue: dialogue
  }),
  [types.SET_EXERCISE]: (state = defaultState, { exercise }) => ({
    ...state,
    currentExercise: exercise
  }),
  [types.SET_BUTTON_HINT_STATUS]: (state = defaultState, { value }) => ({
    ...state,
    buttonHintActive: value
  }),
  [types.SET_TUTORIAL_STATUS]: (state = defaultState, { value }) => ({
    ...state,
    tutorialFinished: value
  }),
  [types.SET_STEP_FINISHED]: (state = defaultState, { value }) => ({
    ...state,
    stepFinished: value
  }),
  [types.SET_BUTTON_HINT_STEP_FINISHED]: (state = defaultState, { value }) => ({
    ...state,
    buttonHintStepFinished: value
  }),
  [types.SET]: (state = defaultState, { path, value }) =>
    set(cloneDeep(state), path, value),
  [types.SHOW_INFOBOX_NOTIFICATION]: (state = defaultState, { value }) => ({
    ...state,
    isInfoboxNotificationVisible: value
  })
});

export { actions, reducer };
