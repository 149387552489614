import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'trayExercise1', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'trayExercise2', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'trayExercise2'));
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.trayExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(exerciseSetActions.closeExercise(['food', 'trayExercise1']));
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Lebensmittel',
      text:
        'Trainieren Sie in dieser Übung die passenden Begriffe für Nährstoffe!',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Was ist richtig?',
          question: 'Spinat enthält …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: 'viele Kalorien.' },
            { id: 2, data: 'viele Kohlenhydrate.' },
            { id: 3, meta: { correct: 3 }, data: 'viele Mineralstoffe.' }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'Vollkornnudeln enthalten …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: 'viel Eiweiß.' },
            { id: 2, data: 'viele Vitamine.' },
            { id: 3, meta: { correct: 3 }, data: 'viele Kohlenhydrate.' }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'Orangensaft enthält …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: 'viel Fett.' },
            { id: 2, meta: { correct: 2 }, data: 'viel Vitamin C.' },
            { id: 3, data: 'viel Eiweiß.' }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'Weißer Reis enthält …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: 'viele Ballaststoffe.' },
            { id: 2, meta: { correct: 2 }, data: 'viele Kohlenhydrate.' },
            { id: 3, data: 'viele Mineralstoffe.' }
          ]
        },
        {
          title: 'Was ist richtig?',
          question: 'Eier enthalten …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, meta: { correct: 1 }, data: 'viel Eiweiß.' },
            { id: 2, data: 'viele Ballaststoffe.' },
            { id: 3, data: 'viel Fett.' }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
