import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'exercises', 'handingOverExercise3', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.handingOverExercise3 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['handingOver', 'handingOverExercise3'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Pflegeberichte schriftlich formulieren',
      text: 'Beim Schreiben eines Pflegeberichts helfen Satzbausteine. Lernen Sie bei dieser Übung hilfreiche Sätze kennen.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Was passt zusammen?',
          question: 'In Zimmer 15 rechts am Fenster ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: '… Verdacht auf ...' },
            { id: 2, data: '… hat sich sein Zustand verschlechtert.' },
            { id: 3, data: '… 11 Uhr aufgenommen.' },
            { id: 4, data: '… waschen und braucht Hilfe beim Aufstehen.' },
            {
              id: 5,
              meta: { correct: 5 },
              data: '… liegt Herr Khavari, 67 Jahre alt.'
            },
            { id: 6, data: '… Unterstützung beim Spritzen.' }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Er wurde heute um ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: '… Verdacht auf ...' },
            { id: 2, data: '… hat sich sein Zustand verschlechtert.' },
            { id: 3, meta: { correct: 5 }, data: '… 11 Uhr aufgenommen.' },
            { id: 4, data: '… waschen und braucht Hilfe beim Aufstehen.' },
            {
              id: 5,
              data: '… liegt Herr Khavari, 67 Jahre alt.'
            },
            { id: 6, data: '… Unterstützung beim Spritzen.' }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Wegen starker Beschwerden besteht ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: '… Verdacht auf ...'
            },
            { id: 2, data: '… hat sich sein Zustand verschlechtert.' },
            { id: 3, data: '… 11 Uhr aufgenommen.' },
            { id: 4, data: '… waschen und braucht Hilfe beim Aufstehen.' },
            {
              id: 5,
              data: '… liegt Herr Khavari, 67 Jahre alt.'
            },
            { id: 6, data: '… Unterstützung beim Spritzen.' }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question:
            'Herr Khavari bekommt deshalb das Mittel intravenös. Seitdem …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: '… Verdacht auf ...' },
            {
              id: 2,
              meta: { correct: 2 },
              data: '… hat sich sein Zustand verschlechtert.'
            },
            { id: 3, data: '… 11 Uhr aufgenommen.' },
            { id: 4, data: '… waschen und braucht Hilfe beim Aufstehen.' },
            {
              id: 5,
              data: '… liegt Herr Khavari, 67 Jahre alt.'
            },
            { id: 6, data: '… Unterstützung beim Spritzen.' }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Herr Khavari ist Typ 1-Diabetiker und benötigt …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: '… Verdacht auf ...' },
            { id: 2, data: '… hat sich sein Zustand verschlechtert.' },
            { id: 3, data: '… 11 Uhr aufgenommen.' },
            { id: 4, data: '… waschen und braucht Hilfe beim Aufstehen.' },
            {
              id: 5,
              data: '… liegt Herr Khavari, 67 Jahre alt.'
            },
            {
              id: 6,
              meta: { correct: 6 },
              data: '… Unterstützung beim Spritzen.'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Er kann sich nicht allein …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: '… Verdacht auf ...' },
            { id: 2, data: '… hat sich sein Zustand verschlechtert.' },
            { id: 3, data: '… 11 Uhr aufgenommen.' },
            {
              id: 4,
              meta: { correct: 5 },
              data: '… waschen und braucht Hilfe beim Aufstehen.'
            },
            {
              id: 5,
              data: '… liegt Herr Khavari, 67 Jahre alt.'
            },
            { id: 6, data: '… Unterstützung beim Spritzen.' }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
