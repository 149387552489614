import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'family', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'family', 'overlay'],
      false
    )
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: 'Das ist ja ein hübsches Foto. Wer sind denn die zwei?',
      audio: '009-Foto_ID_1.mp3'
    },
    1: {
      from: 'ai',
      text: 'Meine Frau und meine Tochter.',
      audio: '009-Foto_ID_2.mp3'
    },
    2: {
      from: 'player',
      text: 'Ihre Tochter sieht Ihnen ähnlich. Bekommen Sie manchmal Besuch?',
      audio: '009-Foto_ID_3.mp3'
    },
    3: {
      from: 'ai',
      text: 'Ja, meine Frau kommt eigentlich täglich. Aber meine Tochter wohnt nicht hier. Sie ist nach Köln gezogen. Deshalb kann sie mich nicht besuchen.',
      audio: '009-Foto_ID_4.mp3'
    },
    4: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
