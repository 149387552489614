import { velocityHelpers } from 'velocity-react';

export const SLIDE_IN_DURATION = 500;
const SLIDE_OUT_DURATION = 500;

export const PageTransitions = {
  fadeIn: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_IN_DURATION,
    calls: [[{ opacity: [1, 0] }]]
  }),
  fadeOut: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_IN_DURATION,
    calls: [[{ opacity: [0, 1] }]]
  }),
  slideInFromRight: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_IN_DURATION,
    calls: [[{ position: 'absolute', zIndex: 2, translateX: [0, '100%'] }]]
  }),
  slideOutToLeft: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_OUT_DURATION,
    calls: [[{ position: 'absolute', zIndex: 1, translateX: ['-110%', 0] }]]
  }),
  slideInFromLeft: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_IN_DURATION,
    calls: [[{ position: 'absolute', zIndex: 2, translateX: [0, '-100%'] }]]
  }),
  slideOutToRight: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_OUT_DURATION,
    calls: [[{ position: 'absolute', zIndex: 1, translateX: ['100%', 0] }]]
  }),
  slideInFromTop: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_IN_DURATION,
    calls: [[{ position: 'absolute', zIndex: 2, scaleY: [1, 0] }]]
  }),
  slideOutToTop: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_OUT_DURATION,
    calls: [[{ position: 'absolute', zIndex: 1, scaleY: [0, 1] }]]
  })
};

export const NotificationTransitions = {
  slideInFromTop: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_IN_DURATION,
    calls: [
      [
        {
          zIndex: 2,
          bottom: ['0%', '100%'],
          paddingTop: [15, 0],
          paddingBottom: [15, 0]
        }
      ]
    ]
  }),
  slideOutToTop: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_OUT_DURATION,
    calls: [
      [
        {
          zIndex: 1,
          bottom: ['100%', '0%'],
          paddingTop: [0, 15],
          paddingBottom: [0, 15]
        }
      ]
    ]
  })
};
