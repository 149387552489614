import React from 'react';

import store from '../../store';
import { VERSIONS } from '../../constants';

const content = {
  step1: {
    heading: 'Die Situation',
    paragraph: () =>
      <span>
        {store.getState().app.get('version') === VERSIONS.STUDENT ?
          <span>
            Sie arbeiten seit zwei Wochen als Pflegekraft im Rosalienkrankenhaus in Hamburg. Heute sind Sie in der Frühschicht eingeteilt. Nach dem Mittagessen sollen Sie Herrn Torben Teller <b>betreuen</b> und seinen Verband wechseln.
          </span> :
          <span>
            Die Pflegefachkraft, in deren Rolle die Lernenden schlüpfen, arbeitet seit zwei Wochen als Pflegekraft im Rosalienkrankenhaus in Hamburg. Heute ist sie in der Frühschicht eingeteilt. Nach dem Mittagessen <b>betreut</b> sie den Patienten Torben Teller: Sie lernt ihn kennen, wechselt seinen Verband und versucht, ihn zum Mitmachen zu motivieren.
          </span>}
      </span>,
    duration: '30 Minuten'
  },
  step2: {
    heading: 'Im Dienstzimmer',
    paragraph: () =>
      <span>
        {store.getState().app.get('version') === VERSIONS.STUDENT ?
          <span>
            Herr Teller war der letzte Patient auf diesem Rundgang. Wie wäre es mit einer Tasse Kaffee im Dienstzimmer? Da können Sie auch Ihre Kollegin Lisa Blank über Herrn Tellers Zustand <b>informieren</b>.
          </span> :
          <span>
            Herr Teller war der letzte Patient auf diesem Rundgang. Im Dienstzimmer trifft die Pflegefachkraft auf die Kollegin Lisa Blank und <b>informiert</b> sie über dessen Zustand. Daraufhin ruft sie den Diätassistenten an und bittet die Wundmanagerin per E-Mail um einen Termin. Kurz darauf begegnet sie der Ehefrau Ramona Teller und ermutigt sie, ihren Mann bei dem neuen Diätplan zu unterstützen.
          </span>}
      </span>,
    duration: '30 Minuten'
  },
  step3: {
    heading: 'Gespräch mit dem Arzt',
    paragraph: () =>
    
      <span>
        {store.getState().app.get('version') === VERSIONS.STUDENT ?
          <span>
            Als nächstes kommt die Dokumentation. Und Dr. Roland Thewes möchte auch noch etwas mit Ihnen <b>besprechen</b>.
          </span> :
          <span>
            Nach dem freundlichen Gespräch mit der Kollegin möchte jetzt auch der diensthabende Arzt Dr. Thewes den Zustand des Patienten mit der Pflegefachkraft <b>besprechen</b>. Er ist sehr besorgt Teller und möchte genau über die bisherigen und geplanten Pflegemaßnahmen informiert werden. Dieses Gespräch ist eine ziemliche Herausforderung!
          </span>}
      </span>,
    duration: '10 Minuten'
  },
  step4: {
    heading: 'Was für ein Tag!',
    paragraph: () =>
      <span>
        {store.getState().app.get('version') === VERSIONS.STUDENT ?
          <span>
            Einen großen Teil Ihrer Arbeiten haben Sie schon erledigt. Jetzt können Sie sich auf die Übergabe vorbereiten, um Ihrer Kollegin Ina das Wichtigste zu <b>berichten</b>. Doch der Pflegealltag steckt immer voller Überraschungen ...
          </span> :
          <span>
            Was für ein Tag! Ein Großteil der Arbeit ist bereits erledigt. Die Pflegefachkraft möchte sich jetzt mit Milo auf das Übergabegespräch vorbereiten, als der Zimmernachbar von Herrn Teller einen Zwischenfall meldet: Herr Teller ist aus dem Bett gerutscht. Die Pflegefachkraft versorgt ihn und <b>berichtet</b> anschließend der Kollegin Ina.
          </span>}
       
      </span>,
    duration: '20 Minuten'
  }
};

export { content };
