import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'exercises', 'doctorExercise1', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.doctorExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step3', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['doctor', 'doctorExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Notizen im Pflegebericht',
      text:
        'Ein wichtiger Teil der Pflegearbeit ist die Dokumentation. Hier trainieren Sie, wie Sie Ihre Beobachtungen korrekt formulieren können.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Was passt zusammen?',
          question: 'Herr Helms reagierte …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: '… das Essen beschwert.'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: '… ablehnend auf den Vorschlag aufzustehen und ein paar Schritte zu gehen.'
            },
            {
              id: 3,
              data: '… nur von einer weiblichen Pflegekraft versorgt zu werden.'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Frau Ihrig hat sich über …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: '… bereits um 20 Uhr ins Bett gebracht.' },
            { id: 2, data: '… Schmerzen im Unterbauch.' },
            { id: 3, meta: { correct: 3 }, data: '… das Essen beschwert.' }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Herr Martens gab an, …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: '… ablehnend auf den Vorschlag aufzustehen und ein paar Schritte zu gehen.'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: '… kein Schlafmittel zu brauchen.'
            },
            {
              id: 3,
              data: '… nur von einer weiblichen Pflegekraft versorgt zu werden.'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Frau Frieder äußerte den Wunsch, …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: '… bereits um 20 Uhr ins Bett gebracht.' },
            {
              id: 2,
              meta: { correct: 2 },
              data: '… nur von einer weiblichen Pflegekraft versorgt zu werden.'
            },
            {
              id: 3,
              data: '… ablehnend auf den Vorschlag aufzustehen und ein paar Schritte zu gehen.'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Frau Loibl klagte über …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: '… das Essen beschwert.'
            },
            {
              id: 2,
              data: '… ablehnend auf den Vorschlag aufzustehen und ein paar Schritte zu gehen.'
            },
            {
              id: 3,
              meta: { correct: 2 },
              data: '… Schmerzen im Unterbauch.'
            }
          ]
        },
        {
          title: 'Was passt zusammen?',
          question: 'Herr Miller wurde auf eigenen Wunsch …',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: '… bereits um 20 Uhr ins Bett gebracht.'
            },
            {
              id: 2,
              data: '… Schmerzen im Unterbauch.'
            },
            {
              id: 3,
              data: '… das Essen beschwert.'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
