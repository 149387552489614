import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'exercises', 'handingOverExercise2', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.handingOverExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['handingOver', 'handingOverExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Übergabegespräch',
      text:
        'Informieren Sie Ihre Kollegen über einen Neuzugang auf der Station.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question:
            'Heute Nachmittag wurde eine Patientin aus der ... zu uns verlegt.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'Einweisungsgrund'
            },
            {
              id: 2,
              data: 'Zimmer'
            },
            {
              id: 3,
              meta: { correct: 1 },
              data: 'Notaufnahme'
            },
            {
              id: 4,
              data: 'Station'
            },
            {
              id: 5,
              data: 'Rettungsdienst'
            }
          ]
        },
        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question:
            'Sie heißt Marlene Berger, ist 76 Jahre alt und liegt jetzt in ... 21.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'Rettungsdienst'
            },
            {
              id: 2,
              meta: { correct: 1 },
              data: 'Zimmer'
            },
            {
              id: 3,
              data: 'Station'
            },
            {
              id: 4,
              data: 'Notaufnahme'
            },
            {
              id: 5,
              data: 'Einweisungsgrund'
            }
          ]
        },
        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question: 'Frau Berger wurde gegen 9:30 auf ... gebracht.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'Rettungsdienst'
            },
            {
              id: 2,
              meta: { correct: 1 },
              data: 'Station'
            },
            {
              id: 3,
              data: 'Zimmer'
            },
            {
              id: 4,
              data: 'Notaufnahme'
            },
            {
              id: 5,
              data: 'Einweisungsgrund'
            }
          ]
        },
        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question: 'Der ... ist ein Sturz.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'Notaufnahme'
            },
            {
              id: 2,
              data: 'Station'
            },
            {
              id: 3,
              meta: { correct: 1 },
              data: 'Einweisungsgrund'
            },
            {
              id: 4,
              data: 'Rettungsdienst'
            },
            {
              id: 5,
              data: 'Zimmer'
            }
          ]
        },
        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question:
            'Ihre Nachbarin hat den ... gerufen, nachdem sie Frau Berger in ihrem Schlafzimmer auf dem Boden liegend vorgefunden hatte.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Rettungsdienst'
            },
            {
              id: 2,
              data: 'Station'
            },
            {
              id: 3,
              data: 'Zimmer'
            },
            {
              id: 4,
              data: 'Einweisungsgrund'
            },
            {
              id: 5,
              data: 'Notaufnahme'
            }
          ]
        },

        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question: 'Die Patientin wollte in der Nacht aufstehen und ist ... .',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'Becken-OP'
            },
            {
              id: 2,
              data: 'Unterstützung'
            },
            {
              id: 3,
              data: 'Untersuchungsbefund'
            },
            {
              id: 4,
              meta: { correct: 1 },
              data: 'gestürzt'
            }
          ]
        },
        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question:
            'Laut ... liegen eine Becken- und eine Schulterfraktur vor.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'Becken-OP'
            },
            {
              id: 2,
              data: 'Unterstützung'
            },
            {
              id: 3,
              meta: { correct: 1 },
              data: 'Untersuchungsbefund'
            },
            {
              id: 4,
              data: 'gestürzt'
            }
          ]
        },
        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question:
            'Frau Berger benötigt ... beim Essen, beim Gang auf die Toilette und beim Anziehen.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'Becken-OP'
            },
            {
              id: 2,
              meta: { correct: 1 },
              data: 'Unterstützung'
            },
            {
              id: 3,
              data: 'Untersuchungsbefund'
            },
            {
              id: 4,
              data: 'gestürzt'
            }
          ]
        },
        {
          title: 'Welches Wort passt? Wählen Sie aus.',
          question:
            'Sie bekommt intravenös Metamizol und die ... ist für morgen früh 8:00 Uhr geplant.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Becken-OP'
            },
            {
              id: 2,
              data: 'Unterstützung'
            },
            {
              id: 3,
              data: 'Untersuchungsbefund'
            },
            {
              id: 4,
              data: 'gestürzt'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
