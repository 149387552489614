import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'calm', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'food', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.setExercise('woundAndCare'));
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'woundAndCare', 'overlay'],
      true
    )
  );
  // !!!this exercise is probably not part of the game anymore
  if (store.getState().exerciseSet.special.calm === false) {
    const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(exerciseSetActions.closeExercise(['special', 'calm']));
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Beruhigen',
      text:
        'In manchen Situationen ist es schwierig, Patienten über Ihren Zustand aufzuklären, ohne Ihnen Angst zu machen. Welche Sätze halten Sie für beruhigender? Wählen Sie aus!',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Welcher Satz ist beruhigender?',
          question: 'Wählen Sie aus.',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Ich sehe mir die Wunde genauer an'
            },
            { id: 2, data: 'Ihre Wunde gefällt mir gar nicht.' }
          ]
        },
        {
          title: 'Welcher Satz ist beruhigender?',
          question: 'Wählen Sie aus.',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            { id: 1, data: 'Ich muss noch schnell den Verband wechseln.' },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'Ich werde jetzt Ihren Verband wechseln'
            }
          ]
        },
        {
          title: 'Welcher Satz ist beruhigender?',
          question: 'Wählen Sie aus.',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Die Wundmanagerin sollte sich das nochmal ansehen.'
            },
            {
              id: 2,
              data: 'Oh, das ist ein Problem für die Wundmanagerin.'
            }
          ]
        },
        {
          title: 'Welcher Satz ist beruhigender?',
          question: 'Wählen Sie aus.',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              data: 'Die Wunde sieht komisch aus.'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'Die Wunde ist vermutlich entzündet.'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
