import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { CustomMultipleChoice } from '../../../components/customMultipleChoice';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'exercises', 'recordsExercise4', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.recordsExercise4 === false) {
    store.dispatch(rewardSystemActions.increment('step3', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['records', 'recordsExercise4'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Freundliche und unfreundliche Äußerungen unterscheiden',
      text: 'Der Ton macht die Musik: Nicht nur "was" Sie sagen, sondern "wie" Sie es sagen, ist wichtig für die Beziehung zu den Patienten.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <CustomMultipleChoice
              key="1"
              answers={[
                {
                  id: 1,
                  meta: { correct: 1 },
                  data: [
                    {
                      type: 'audio',
                      src: require('../../../static/audio/u32/u32_1.mp3')
                    },
                    'Hören Sie die Sätze und wählen Sie aus.'
                  ]
                }
              ]}
            />
          ),
          question:
            'Es wäre sehr nett, wenn Sie mich über die Ergebnisse informieren würden.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'freundlich'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'unfreundlich'
            }
          ]
        },
        {
          title: (
            <CustomMultipleChoice
              key="2"
              answers={[
                {
                  id: 1,
                  meta: { correct: 1 },
                  data: [
                    {
                      type: 'audio',
                      src: require('../../../static/audio/u32/u32_2.mp3')
                    },
                    'Hören Sie die Sätze und wählen Sie aus.'
                  ]
                }
              ]}
            />
          ),
          question:
            'Es wäre sehr nett, wenn Sie mich über die Ergebnisse informieren würden.',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'freundlich'
            },
            {
              id: 2,
              data: 'unfreundlich'
            }
          ]
        },
        {
          title: (
            <CustomMultipleChoice
              key="3"
              answers={[
                {
                  id: 1,
                  meta: { correct: 1 },
                  data: [
                    {
                      type: 'audio',
                      src: require('../../../static/audio/u32/u32_3.mp3')
                    },
                    'Hören Sie die Sätze und wählen Sie aus.'
                  ]
                }
              ]}
            />
          ),
          question:
            'Haben Sie denn nicht bemerkt, dass die Leukozyten und das CRP erhöht sind?',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'freundlich'
            },
            {
              id: 2,
              data: 'unfreundlich'
            }
          ]
        },
        {
          title: (
            <CustomMultipleChoice
              key="4"
              answers={[
                {
                  id: 1,
                  meta: { correct: 1 },
                  data: [
                    {
                      type: 'audio',
                      src: require('../../../static/audio/u32/u32_4.mp3')
                    },
                    'Hören Sie die Sätze und wählen Sie aus.'
                  ]
                }
              ]}
            />
          ),
          question:
            'Haben Sie denn nicht bemerkt, dass die Leukozyten und das CRP erhöht sind?',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            {
              id: 1,
              data: 'freundlich'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'unfreundlich'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
