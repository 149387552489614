import React from 'react';

export const GlossaryIcon = () =>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
  >
    <title>glossar</title>
    <path
      d="M104.11,31.6h-67V28.95c0-6,4.59-11,10.23-11h55a9.84,9.84,0,0,1,7.34,3.35A2.48,2.48,0,1,0,113.42,18a14.93,14.93,0,0,0-11-5h-55C39,13,32.17,20.15,32.17,28.95V31.6h0v88.05a13.26,13.26,0,0,0,13.22,13.22h58.71a13.26,13.26,0,0,0,13.22-13.22V44.83A13.26,13.26,0,0,0,104.11,31.6Z"
      fill="#1e9ec1"
    />
    <path
      d="M101.22,27.19H46a2.49,2.49,0,0,1,0-5h55.24a2.49,2.49,0,0,1,0,5Z"
      fill="#1e9ec1"
    />
    <path
      d="M92,30.53V56.91a.35.35,0,0,0,.56.29l6.15-4.34a.35.35,0,0,1,.41,0l6.15,4.34a.35.35,0,0,0,.56-.29V30.66Z"
      fill="#fff"
    />
    <path
      d="M51.55,67.51,40,97.19h6.36l2.45-6.74H60.66l2.59,6.74h6.52L57.89,67.51Zm-.91,17.94,4-11,4.09,11Z"
      fill="#fff"
      stroke="#fff"
      strokeMiterlimit="10"
    />
    <rect
      x="69.94"
      y="78.93"
      width="11.17"
      height="5.69"
      fill="#fff"
      stroke="#fff"
      strokeMiterlimit="10"
    />
    <polygon
      points="91.53 92.19 107.79 72.17 107.79 67.51 86.07 67.51 86.07 72.53 99.89 72.53 84.31 91.78 84.31 97.19 108.42 97.19 108.42 92.19 91.53 92.19"
      fill="#fff"
      stroke="#fff"
      strokeMiterlimit="10"
    />
  </svg>;
