import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as appActions } from '../../reducers/app';
import { Button } from '../../components/button';

import styles from './styles.less';

const mapDispatchToProps = {
  setPage: appActions.setPage
};

class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entryID: null
    };
  }

  render() {
    return (
      <div className={styles.info}>
        <h1>Für Ihren Unterricht </h1>
        <p>
          In den Zusatzmaterialien finden Sie konkrete Ideen dafür, wie Sie mit den Inhalten aus der App im Unterricht arbeiten können. 
          <br />
          <a
            href={require('../../static/pdfs/Ein_Tag_Deutsch_Zusatzmaterialien_gesamt.pdf')}
            target="_blank" rel="noopener noreferrer"
          >
            Laden Sie sich das Gesamtdokument kostenlos herunter.
          </a>
        </p>
        <Button theme="back" onClick={this.changePage} />
      </div>
    );
  }

  changePage = () => {
    const { setPage } = this.props;
    setPage('room');
  };
}

export default connect(undefined, mapDispatchToProps)(Info);
