import { velocityHelpers } from 'velocity-react';

const SLIDE_IN_DURATION = 500;
const SLIDE_OUT_DURATION = 500;

export default {
  slideInFromRight: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_IN_DURATION,
    calls: [[{ position: 'absolute', zIndex: 2, translateX: [0, '100%'] }]]
  }),
  slideOutToLeft: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_OUT_DURATION,
    calls: [[{ position: 'absolute', zIndex: 1, translateX: ['-110%', 0] }]]
  }),
  slideInFromLeft: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_IN_DURATION,
    calls: [[{ position: 'absolute', zIndex: 2, translateX: [0, '-100%'] }]]
  }),
  slideOutToRight: velocityHelpers.registerEffect({
    defaultDuration: SLIDE_OUT_DURATION,
    calls: [[{ position: 'absolute', zIndex: 1, translateX: ['100%', 0] }]]
  })
};
