import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'coffeemaschineExercise6', 'overlay'],
      false
    )
  );
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Vorschläge machen',
      text: 'Jetzt sind Sie dran. Formulieren Sie selbst Vorschläge und achten Sie dabei auf grammatische Korrektheit. (Artikel, Verbposition, Kasus)',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Wie endet der Satz? Sprechen Sie.',
          question: () =>
            <span>
              eine Pause: <i>Wie wär‘s mit …?</i>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u17/u17_01.mp3')
                },
                'Hören Sie dann und korrigieren Sie sich selbst.'
              ]
            }
          ]
        },
        {
          title: 'Wie endet der Satz? Sprechen Sie.',
          question: () =>
            <span>
              Stationsarzt - zuerst - sprechen: <i>Lass uns doch …</i>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u17/u17_02.mp3')
                },
                'Hören Sie dann und korrigieren Sie sich selbst.'
              ]
            }
          ]
        },
        {
          title: 'Wie endet der Satz? Sprechen Sie.',
          question: () =>
            <span>
              informieren - Hauspsychologen:
              <i> Ich schlage vor, …</i>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u17/u17_03.mp3')
                },
                'Hören Sie dann und korrigieren Sie sich selbst.'
              ]
            }
          ]
        },
        {
          title: 'Wie endet der Satz? Sprechen Sie.',
          question: () =>
            <span>
              tauschen - Schicht: <i>Hättest du Lust, …?</i>
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u17/u17_04.mp3')
                },
                'Hören Sie dann und korrigieren Sie sich selbst.'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
