import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import Bugsnag from 'bugsnag-js';

import './index.less';
import App from './components/App';
import { unregister } from './registerServiceWorker';

Bugsnag.apiKey = 'b6e116ff83fea044355b7ef467e22793';

window.bugsnag = Bugsnag;
window.initialHref = window.location.href;

window.onload = () => {
  document.addEventListener('deviceready', () => {
    document.addEventListener('backbutton', onBackKeyDown, false);
  });
};

function onBackKeyDown(event) {
  event.preventDefault();
}

document.addEventListener('keyup', closeMobileKeyboardOnEnter);

function closeMobileKeyboardOnEnter(event) {
  if (event.code === 'Enter' || event.keyCode === 13) {
    if (window.cordova) {
      window.cordova.plugins.Keyboard.close();
    }
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
