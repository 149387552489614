import React from 'react';
import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';

import { Speaker } from '../../../static/icons/Speaker';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'trayExercise2', 'overlay'],
      false
    )
  );
}

const style = { display: 'flex', alignItems: 'center' };

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Wortakzent',
      text:
        'Lange Wörter sind oft schwierig auszusprechen. Achten Sie bei dieser Übung auf die Betonung der Fremdwörter und Komposita. Korrigieren Sie sich selbst!',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_1.mp3')
                },
                'Kalorien'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_2.mp3')
                },
                'Mineralstoffe'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_3.mp3')
                },
                'Kohlenhydrate'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_4.mp3')
                },
                'Vitamine'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_5.mp3')
                },
                'Eiweiß'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_6.mp3')
                },
                'Ballaststoffe'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_7.mp3')
                },
                'Magnesium'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_8.mp3')
                },
                'Cholesterin'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_9.mp3')
                },
                'Insulin'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_10.mp3')
                },
                'Allergie'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_11.mp3')
                },
                () => <span>Nahrungsmittel&shy;unverträglichkeit</span>
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_12.mp3')
                },
                'Lebensmittelallergie'
              ]
            },
            {
              id: 5,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u1/u1b_13.mp3')
                },
                'Blutzuckerspiegel'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
