import React from 'react';
import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

import styles from '../styles.less';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'activity', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'dialogues', 'activity', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'hotSpots', 'headboard', 'overlay'],
      true
    )
  );
  store.dispatch(exerciseSetActions.setCurrentExerciseSet('activity'));
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step1', 'choices', 'getWheelchair'], false)
  );
  store.dispatch(stepsActions.set(['step1', 'choices', 'enoughRest'], false));
  store.dispatch(stepsActions.set(['step1', 'choices', 'patientMoves'], false));
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/patient.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question:
        'Aus der Krankenakte wissen Sie, dass Herr Teller seit der OP am liebsten im Bett bleibt. <br><br>Er tut sich schwer, sich an die neue Situation zu gewöhnen. Auch heute sieht er resigniert aus und hat das Bett noch nicht verlassen. <br><br>Wie gehen Sie vor?',
      answers: () => {
        const getWheelchair = store.getState().steps.step1.choices
          .getWheelchair;

        return [
          {
            id: 'a',
            goTo: '0-1-1',
            data: 'einen Vorschlag zur Aktivierung machen',
            renderQuestion: false
          },
          {
            id: 'b',
            goTo: '0-2-1',
            data: 'den Rollstuhl holen',
            renderQuestion: false,
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step1', 'choices', 'getWheelchair'], true)
              );
            },
            className: getWheelchair ? styles.disabled : ''
          }
        ];
      }
    },

    '0-1-1': {
      from: 'player',
      type: 'thought',
      text: 'Wie könnte ich Herrn Teller motivieren, sich ein bisschen zu bewegen? Ich will ihn aber nicht unter Druck setzen.',
      audio: '015-Aktivierung_ID_1.mp3',
      nextSnippet: '1-1'
    },

    '0-2-1': {
      from: 'player',
      type: 'thought',
      text: 'Stopp! Auch wenn heute viel zu tun ist, muss ich mir schon ein bisschen Zeit nehmen.',
      audio: '016-Rollstuhl_ID_1.mp3',
      nextSnippet: '0-2-2'
    },
    '0-2-2': {
      from: 'player',
      type: 'thought',
      text: 'Ich sollte Herrn Teller fragen, ob er sich bereit fühlt.',
      audio: '016-Rollstuhl_ID_2.mp3',
      nextSnippet: '0'
    },

    '1-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          data: 'Herr Teller, Sie müssen heute mal aufstehen.',
          audio: '015-Aktivierung_ID_4.mp3'
        },
        {
          id: 'b',
          goTo: '1-2-1',
          data: 'Herr Teller, wie wäre es mit ein bisschen Bewegung?',
          audio: '015-Aktivierung_ID_3.mp3'
        },
        {
          id: 'c',
          goTo: '1-2-1',
          data: () =>
            <span>
              Herr Teller, möchten Sie denn heute mal einen kleinen Ausflug
              machen? Vielleicht in den{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(
                    glossaryActions.setCurrentInfo('aufenthaltsraum')
                  );
                }}
              >
                Aufenthaltsraum
              </span>?
            </span>,
          audio: '015-Aktivierung_ID_2.mp3'
        }
      ]
    },

    '1-1-1': {
      from: 'ai',
      text: 'Ich muss gar nichts.',
      audio: '015-Aktivierung_ID_10.mp3',
      nextSnippet: '1-1-2'
    },
    '1-1-2': {
      from: 'player',
      text: 'Da haben Sie natürlich recht. Sie müssen nicht, aber ein bisschen Bewegung würde Ihnen gut tun.',
      audio: '015-Aktivierung_ID_11.mp3',
      nextSnippet: '1-2-1'
    },

    '1-2-1': {
      from: 'ai',
      text: 'Nein, bloß nicht. Ich möchte lieber meine Ruhe haben.',
      audio: '015-Aktivierung_ID_6.mp3',
      nextSnippet: '2-1'
    },

    '2-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: () => {
        const enoughRest = store.getState().steps.step1.choices.enoughRest;

        return [
          {
            id: 'a',
            goTo: '2-1-1',
            data: 'Ach, Ruhe hatten Sie doch schon genug.',
            audio: '015-Aktivierung_ID_20.mp3',
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step1', 'choices', 'enoughRest'], true)
              );
            },
            className: enoughRest ? styles.disabled : ''
          },
          {
            id: 'b',
            goTo: '2-2-1',
            data: 'Waren Sie heute schon im Bad?',
            audio: '015-Aktivierung_ID_13.mp3'
          },
          {
            id: 'c',
            goTo: '2-3-1',
            data: 'Fühlen Sie sich denn so müde?',
            audio: '015-Aktivierung_ID_12.mp3'
          }
        ];
      }
    },

    '2-1-1': {
      from: 'ai',
      text: 'Sie sind gut. Unter Ruhe verstehe ich was anderes.',
      audio: '015-Aktivierung_ID_21.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'player',
      type: 'thought',
      text: 'Oh, da habe ich wohl was Falsches gesagt. Ich versuch\' es nochmal.',
      audio: '015-Aktivierung_ID_22.mp3',
      nextSnippet: '2-1'
    },

    '2-2-1': {
      from: 'ai',
      text: 'Nee, heute noch nicht.',
      audio: '015-Aktivierung_ID_18.mp3',
      nextSnippet: '2-2-2'
    },
    '2-2-2': {
      from: 'ai',
      text: () =>
        <span>
          Dafür fühle ich mich zu{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('schlapp'));
            }}
          >
            schlapp
          </span>{' '}
          und zu müde.
        </span>,
      audio: '015-Aktivierung_ID_19.mp3',
      nextSnippet: '3-1'
    },

    '2-3-1': {
      from: 'ai',
      text: () =>
        <span>
          Hm, weiß nicht. Irgendwie bin ich{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('schlapp'));
            }}
          >
            schlapp
          </span>{' '}
          und sehr müde, ja?
        </span>,
      audio: '015-Aktivierung_ID_16.mp3',
      nextSnippet: '3-1'
    },

    '3-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '3-1-1',
          data: () =>
            <span>
              Dann wäre ein bisschen Bewegung doch genau das Richtige für Sie.
              Auch für Ihren{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('kreislauf'));
                }}
              >
                Kreislauf
              </span>
              . Wir versuchen das jetzt zusammen. Machen Sie mit?
            </span>,
          audio: '015-Aktivierung_ID_25.mp3'
        },
        {
          id: 'b',
          goTo: '3-2-1',
          data: 'Dann müssen Sie sich mal ein bisschen bewegen.',
          audio: '015-Aktivierung_ID_27.mp3'
        },
        {
          id: 'c',
          goTo: '3-1-1',
          data: 'Bewegung tut Ihnen gut. Wir probieren es zusammen.',
          audio: '015-Aktivierung_ID_26.mp3'
        }
      ]
    },

    '3-1-1': {
      from: 'ai',
      text: 'Hm.',
      audio: '015-Aktivierung_ID_29.mp3',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'player',
      text: () =>
        <span>
          Ich stelle zuerst das{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('kopfteil'));
            }}
          >
            Kopfteil
          </span>{' '}
          hoch und lege Ihnen ein langes Kissen in den Rücken. Darauf können Sie
          sich{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('abstützen'));
            }}
          >
            abstützen
          </span>
          . Sie{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('greifen'));
            }}
          >
            greifen
          </span>{' '}
          dann vorne meinen Arm und ich unterstütze Sie auf dem Weg zur{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('bettkante'));
            }}
          >
            Bettkante
          </span>
          .
        </span>,
      audio: '015-Aktivierung_ID_34.mp3',
      nextSnippet: '3-1-3'
    },
    '3-1-3': {
      from: 'ai',
      text: 'Ich kann nicht, ich bin noch nicht so weit.',
      audio: '015-Aktivierung_ID_35.mp3',
      nextSnippet: '4-1'
    },

    '3-2-1': {
      from: 'ai',
      text: 'Was fällt Ihnen ein? Sie haben ja keine Ahnung, wie es mir geht.',
      audio: '015-Aktivierung_ID_33.mp3',
      nextSnippet: '3-2-2'
    },
    '3-2-2': {
      from: 'player',
      type: 'thought',
      text: 'Oh, das war jetzt nicht so gut. „Müssen“ setzt ihn nur unter Druck.',
      audio: '015-Aktivierung_ID_End.mp3',
      nextSnippet: '3-1-3'
    },

    '4-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '4-1-1',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step1', 'choices', 'patientMoves'], true)
            );
          },
          data: 'Ok, lassen Sie sich Zeit. Vielleicht reicht es schon, wenn Sie einfach nur an die Bettkante kommen.',
          audio: '015-Aktivierung_ID_37.mp3'
        },
        {
          id: 'b',
          goTo: '4-2-1',
          data: 'Ich verstehe. Ich komme nachher wieder, dann probieren wir es nochmal, wenn es Ihnen recht ist.',
          audio: '015-Aktivierung_ID_38.mp3'
        },
        {
          id: 'c',
          goTo: '4-3-1',
          data: 'Das finde ich schade.',
          audio: '015-Aktivierung_ID_39.mp3'
        }
      ]
    },

    '4-1-1': {
      from: 'player',
      text: 'Drehen Sie sich ein Stück in die Richtung Bettkante und lassen Sie das Bein raushängen.',
      audio: '015-Aktivierung_ID_40.mp3',
      nextSnippet: '4-1-2'
    },
    '4-1-2': {
      from: 'ai',
      text: 'Ja, gut, aber langsam bitte.',
      audio: '015-Aktivierung_ID_41.mp3',
      nextSnippet: '5-1'
    },

    '4-2-1': {
      from: 'ai',
      text: 'Vielleicht.',
      audio: '015-Aktivierung_ID_42.mp3',
      nextSnippet: '5-2'
    },

    '4-3-1': {
      from: 'ai',
      type: 'thought',
      text: 'Ja, ich auch.',
      audio: '015-Aktivierung_ID_43.mp3',
      nextSnippet: '5-2'
    },

    '5-1': {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: '6-1',
          data: 'Weiter',
          onClick: () => {
            resetChoices();
          },
          renderQuestion: false
        }
      ]
    },

    '5-2': {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: '6-2',
          data: 'Weiter',
          onClick: () => {
            resetChoices();
          },
          renderQuestion: false
        }
      ]
    },

    '6-1': {
      type: 'feedback',
      text:
        'Torben Teller ist kein leichter Fall. Aber Sie sind auf dem richtigen Weg.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    },

    '6-2': {
      type: 'feedback',
      text:
        'Torben Teller ist kein leichter Fall. Aber geben Sie nicht so schnell auf!',
      onClick: () => {
        exitDialogue();
      },
      correct: false
    }
  }
};
