import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'dialogues', 'handingOver', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'hotSpots', 'miloAndLisa', 'overlay'],
      false
    )
  );
  store.dispatch(exerciseSetActions.setNextActivity('step4Score'));
  store.dispatch(exerciseSetActions.setCurrentExerciseSet('handingOver'));
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/office/lisa-portrait.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: () =>
        <span>
          So, jetzt nur noch die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('übergabe'));
            }}
          >
            Übergabe
          </span>{' '}
          und dann kann ich endlich nach Hause. Zum Glück hat mir Milo bei der
          Vorbereitung geholfen.
        </span>,
      audio: '216-Uebergabe_ID_1.mp3',
      type: 'thought'
    },
    1: {
      from: 'player',
      text: 'Hoffentlich vergesse ich nichts. Ich sage aber auch nur das Wichtigste. Es haben ja alle immer wenig Zeit.',
      audio: '216-Uebergabe_ID_2.mp3',
      type: 'thought'
    },
    2: {
      from: 'player',
      text: 'So, dann fange ich mal an mit Herrn Teller. Ihr kennt ihn ja schon.',
      audio: '216-Uebergabe_ID_3.mp3'
    },
    3: {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '3-1-1',
          data: 'Er ist aus dem Bett gefallen und wurde von Milo und mir versorgt.',
          audio: '216-Uebergabe_ID_5.mp3'
        },
        {
          id: 'b',
          goTo: '3-2-1',
          data: 'Er ist vor ca. einer Stunde beim selbstständigen Aufsetzen an die Bettkante aus dem Bett gerutscht.',
          audio: '216-Uebergabe_ID_4.mp3'
        },
        {
          id: 'c',
          goTo: '3-3-1',
          data: 'Seine Wunde heilt nicht. Er hat keinen Appetit und er hat sich nicht verletzt.',
          audio: '216-Uebergabe_ID_6.mp3'
        }
      ]
    },

    '3-1-1': {
      from: 'ai',
      text: 'Hat er sich verletzt?',
      audio: '216-Uebergabe_ID_11.mp3',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'player',
      text: 'Nein, zum Glück nicht. Er ist nicht auf seinen Stumpf gefallen.',
      audio: '216-Uebergabe_ID_12.mp3',
      nextSnippet: '3-1-3'
    },
    '3-1-3': {
      from: 'ai',
      text: () =>
        <span>
          Wie ist der{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('sturz'));
            }}
          >
            Sturz
          </span>{' '}
          passiert?
        </span>,
      audio: '216-Uebergabe_ID_18.mp3',
      nextSnippet: '3-1-4'
    },
    '3-1-4': {
      from: 'player',
      text: () =>
        <span>
          Du weißt ja, dass er bei der Mobilisation nicht besonderes{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('compliant'));
            }}
          >
            compliant
          </span>{' '}
          ist. Aber nach dem Mittagessen hat er versucht, sich alleine
          aufzusetzen.
        </span>,
      audio: '216-Uebergabe_ID_19.mp3',
      nextSnippet: '3-1-5'
    },
    '3-1-5': {
      from: 'player',
      text: 'Der Kreislauf ist jetzt in Ordnung. Bitte schau dir gleich nochmal den Stumpf an und setz ihn später in den Rollstuhl.',
      audio: '216-Uebergabe_ID_20.mp3',
      nextSnippet: '3-1-6'
    },
    '3-1-6': {
      from: 'ai',
      text: 'Natürlich. Das ist dann ja Glück im Unglück. Muss ich sonst noch etwas wissen?',
      audio: '216-Uebergabe_ID_21.mp3',
      nextSnippet: '3-1-7'
    },
    '3-1-7': {
      from: 'player',
      text: () =>
        <span>
          Ja, die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('entzündungswert'));
            }}
          >
            Entzündungswerte
          </span>{' '}
          sind wirklich hoch. Marzapane von der Diät und die Wundmanagerin
          kommen vorbei. Sie soll einen Wundabstrich machen und Herrn Dr. Thewes
          danach informieren.
        </span>,
      audio: '216-Uebergabe_ID_22.mp3',
      nextSnippet: '3-1-8'
    },
    '3-1-8': {
      from: 'ai',
      text: 'Ja, das geht klar.',
      audio: '216-Uebergabe_ID_23.mp3',
      nextSnippet: '3-1-9'
    },
    '3-1-9': {
      from: 'player',
      text: () =>
        <span>
          Ich habe außerdem Krankengymnastik zweimal täglich{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('anordnen'));
            }}
          >
            angeordnet
          </span>
          .
        </span>,
      audio: '216-Uebergabe_ID_24.mp3',
      nextSnippet: '3-1-10'
    },
    '3-1-10': {
      from: 'ai',
      text: 'Sehr gut. Hat er genug getrunken und gegessen?',
      audio: '216-Uebergabe_ID_25.mp3',
      nextSnippet: '3-1-11'
    },
    '3-1-11': {
      from: 'player',
      text: 'Weiterhin zu wenig. Ich habe auch mit seiner Frau gesprochen und ihr nochmal erklärt, dass sie jetzt beide besonders auf seine Ernährung achten müssen.',
      audio: '216-Uebergabe_ID_26.mp3',
      nextSnippet: '3-1-12'
    },
    '3-1-12': {
      from: 'player',
      text: 'Und Frau Tellers Buttercremetorte - die steht jetzt bei uns im Kühlschrank. Hm, köstlich.',
      audio: '216-Uebergabe_ID_27.mp3',
      nextSnippet: 'fin'
    },

    '3-2-1': {
      from: 'ai',
      text: 'Oh nein, und wie geht es ihm?',
      audio: '216-Uebergabe_ID_8.mp3',
      nextSnippet: '3-2-2'
    },
    '3-2-2': {
      from: 'player',
      text: 'Jetzt ganz gut. Er ist nicht verletzt und ist auch nicht auf seinen Stumpf gefallen.',
      audio: '216-Uebergabe_ID_9.mp3',
      nextSnippet: '3-1-3'
    },

    '3-3-1': {
      from: 'ai',
      text: 'Moment mal, nicht so schnell. Was ist passiert?',
      audio: '216-Uebergabe_ID_14.mp3',
      nextSnippet: '3-3-2'
    },
    '3-3-2': {
      from: 'player',
      text: 'Er wollte sich aufsetzen und ist aus dem Bett gefallen.',
      audio: '216-Uebergabe_ID_15.mp3',
      nextSnippet: '3-3-3'
    },
    '3-3-3': {
      from: 'ai',
      text: 'Er hat sich also nicht verletzt?',
      audio: '216-Uebergabe_ID_16.mp3',
      nextSnippet: '3-1-2'
    },

    fin: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
