import React from 'react';

export const Vocabulary = ({ size, className, fill }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    style={{ width: size, height: size }}
  >
    <title>EinTagDeutsch_Icons-Übungstypen</title>
    <g id="Layer_2" data-name="Layer 2">
      <path
        d="M36.26,10.13h-24v-1a3.81,3.81,0,0,1,3.67-3.93H35.65a3.53,3.53,0,0,1,2.63,1.2.89.89,0,1,0,1.31-1.2,5.35,5.35,0,0,0-3.95-1.79H15.94A5.59,5.59,0,0,0,10.5,9.18v1h0V41.67a4.75,4.75,0,0,0,4.74,4.74h21A4.75,4.75,0,0,0,41,41.67V14.87A4.75,4.75,0,0,0,36.26,10.13Z"
        fill="#79ada3"
      />
      <path
        d="M35.23,8.55H15.45a.89.89,0,0,1,0-1.78H35.23a.89.89,0,0,1,0,1.78Z"
        fill="#afd0ca"
      />
      <polygon
        points="31.93 9.75 31.93 15.86 34.41 14.11 36.88 15.86 36.88 9.8 31.93 9.75"
        fill="#afd0ca"
      />
      <path
        d="M36.88,16.33a.47.47,0,0,1-.27-.09l-2.2-1.55-2.2,1.55a.47.47,0,0,1-.74-.38V9.75a.47.47,0,0,1,.14-.33.43.43,0,0,1,.34-.14l4.95,0a.47.47,0,0,1,.47.47v6.06a.47.47,0,0,1-.47.47ZM34.4,13.64a.47.47,0,0,1,.27.09L36.41,15V10.26l-4,0V15l1.73-1.22A.47.47,0,0,1,34.4,13.64Z"
        fill="#fff"
      />
      <path
        d="M29.56,34.3H22.69l-1,3.23H15.56L22.92,18h6.6l7.36,19.58H30.54ZM28.3,30.07l-2.16-7-2.14,7Z"
        fill="#fff"
      />
    </g>
  </svg>;
