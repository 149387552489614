import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as infoboxActions } from '../../../reducers/infobox';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'hotSpots', 'advice', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'dialogues', 'advice', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'hotSpots', 'telephone', 'overlay'],
      true
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'entities', 'lisaBack', 'overlay'],
      false
    )
  );

  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'entities', 'lisaFront', 'overlay'],
      false
    )
  );

  store.dispatch(exerciseSetActions.setCurrentExerciseSet('advice'));
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step2', 'choices', 'adviceFeedback'], true)
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/office/lisa-portrait.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: () =>
        <span>
          Ich möchte mit Lisa über die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={e => {
              e.nativeEvent.cancel = true;
              store.dispatch(glossaryActions.setCurrentInfo('pflegemaßnahme'));
            }}
          >
            Pflegemaßnahmen
          </span>{' '}
          sprechen, weil ich mir{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={e => {
              e.nativeEvent.cancel = true;
              store.dispatch(glossaryActions.setCurrentInfo('sorge'));
            }}
          >
            Sorgen
          </span>{' '}
          um Herrn Teller mache. Und ich kenne ihn noch nicht so gut. Ist jetzt
          ein guter Moment dafür?
        </span>,
      audio: '205-Pflegemassnahmen_ID_1.mp3',
      type: 'thought'
    },
    1: {
      type: 'choice',
      question: 'Wie beginnen Sie das Gespräch?',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          data: 'Hilf mir, bitte.',
          audio: '205-Pflegemassnahmen_ID_4.mp3'
        },
        {
          id: 'b',
          goTo: '1-2-1',
          data: 'Darf ich dich mal was fragen?',
          audio: '205-Pflegemassnahmen_ID_2.mp3'
        },
        {
          id: 'c',
          goTo: '1-3-1',
          data: 'Du bist bestimmt sehr beschäftigt und hast keine Zeit, oder?',
          audio: '205-Pflegemassnahmen_ID_3.mp3'
        }
      ]
    },

    '1-1-1': {
      from: 'ai',
      text: () =>
        <span>
          Gibt es einen{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('notfall'));
            }}
          >
            Notfall
          </span>
          ?
        </span>,
      audio: '205-Pflegemassnahmen_ID_10.mp3',
      nextSnippet: '1-1-2'
    },
    '1-1-2': {
      from: 'player',
      text: 'Nein, ich wollte nur etwas mit dir besprechen.',
      audio: '205-Pflegemassnahmen_ID_11.mp3',
      nextSnippet: '1-1-3'
    },
    '1-1-3': {
      from: 'ai',
      text: 'Ach so. "Hilf mir, bitte" klingt wirklich sehr dramatisch.',
      audio: '205-Pflegemassnahmen_ID_12.mp3',
      nextSnippet: '1-1-4'
    },
    '1-1-4': {
      from: 'player',
      text: 'Entschuldigung, ich wollte dich nicht erschrecken.',
      audio: '205-Pflegemassnahmen_ID_13.mp3',
      nextSnippet: '1-1-5'
    },
    '1-1-5': {
      from: 'ai',
      text: 'Schon gut. Was ist denn los?',
      audio: '205-Pflegemassnahmen_ID_14.mp3',
      nextSnippet: '1-1-6'
    },
    '1-1-6': {
      from: 'player',
      text: () =>
        <span>
          Ich mache mir Sorgen um Herrn Teller. Er macht keine{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('fortschritt'));
            }}
          >
            Fortschritte
          </span>{' '}
          für den 7. Tag{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('postoperativ'));
            }}
          >
            postoperativ
          </span>.
        </span>,
      audio: '205-Pflegemassnahmen_ID_15.mp3',
      nextSnippet: '1-1-7'
    },
    '1-1-7': {
      from: 'ai',
      text: 'Ja, das hat Milo gestern schon berichtet. Er konnte ihn nicht mobilisieren. Hat das bei dir heute geklappt?',
      audio: '205-Pflegemassnahmen_ID_16.mp3',
      nextSnippet: '1-1-8'
    },
    '1-1-8': {
      from: 'player',
      text: 'Leider nicht.',
      audio: '205-Pflegemassnahmen_ID_17.mp3',
      nextSnippet: store.getState().steps.step1.choices.patientMoves
        ? '1-1-9'
        : '1-1-12'
    },
    '1-1-9': {
      from: 'ai',
      text: 'Noch nicht mal auf die Bettkante?',
      audio: '205-Pflegemassnahmen_ID_18.mp3',
      nextSnippet: '1-1-10'
    },
    '1-1-10': {
      from: 'player',
      text: 'Er hat nur das Bein raushängen lassen.',
      audio: '205-Pflegemassnahmen_ID_19.mp3',
      nextSnippet: '1-1-11'
    },
    '1-1-11': {
      from: 'player',
      text: 'Das reicht aber in seiner Situation leider nicht.',
      audio: '205-Pflegemassnahmen_ID_20.mp3',
      nextSnippet: '1-1-12'
    },
    '1-1-12': {
      from: 'ai',
      text: 'Wir müssen intensiver daran arbeiten, dass er besser mitmacht.',
      audio: '205-Pflegemassnahmen_ID_21.mp3',
      nextSnippet: '1-1-13'
    },
    '1-1-13': {
      from: 'ai',
      text: 'Und was kannst du über die Wunde sagen?',
      audio: '205-Pflegemassnahmen_ID_22.mp3',
      nextSnippet: '2-1'
    },

    '1-2-1': {
      from: 'ai',
      text: 'Ja, klar. Was ist denn los?',
      audio: '205-Pflegemassnahmen_ID_9.mp3',
      nextSnippet: '1-1-6'
    },

    '1-3-1': {
      from: 'ai',
      text: 'Wenn es wichtig ist, habe ich immer Zeit. Was ist denn los?',
      audio: '205-Pflegemassnahmen_ID_8.mp3',
      nextSnippet: '1-1-6'
    },

    '2-1': {
      type: 'choice',
      question: 'Wie beschreiben Sie die Wunde?',
      answers: [
        {
          id: 'a',
          goTo: '2-1-1',
          data: 'Also, ich habe sie versorgt und Fotos gemacht, aber die Wunde heilt nicht richtig ab.',
          audio: '205-Pflegemassnahmen_ID_23.mp3'
        },
        {
          id: 'b',
          goTo: '2-2-1',
          data: () =>
            <span>
              Das steht alles in der Wunddoku&shy;mentation. Soll ich sie kurz
              holen und du schaust mal rein?
            </span>,
          audio: '205-Pflegemassnahmen_ID_25.mp3'
        },
        {
          id: 'c',
          goTo: '2-3-1',
          data: 'Die sieht nicht so gut aus.',
          audio: '205-Pflegemassnahmen_ID_24.mp3'
        }
      ]
    },

    '2-1-1': {
      from: 'ai',
      text: 'Beschreib mal!',
      audio: '205-Pflegemassnahmen_ID_27.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'player',
      text: () =>
        <span>
          Die Stelle am Stumpf ist stark{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('gerötet'));
            }}
          >
            gerötet
          </span>{' '}
          und{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('nässen'));
            }}
          >
            nässt
          </span>{' '}
          immer noch. Klar, Herr Teller ist Diabetiker. Da kann das mit der
          Wundheilung schon mal länger dauern, aber ...
        </span>,
      audio: '205-Pflegemassnahmen_ID_34.mp3',
      nextSnippet: '2-1-3'
    },
    '2-1-3': {
      from: 'ai',
      text: () =>
        <span>
          Ja, bitte schick doch die Wunddoku&shy;mentation an die Wundmanagerin,
          Frau Schuster, und mach einen Termin mit ihr aus, damit sie sich
          seinen Stumpf nochmal ansieht.
        </span>,
      audio: '205-Pflegemassnahmen_ID_35.mp3',
      nextSnippet: '3-1'
    },

    '2-2-1': {
      from: 'ai',
      type: 'thought',
      text: `Ich will ${store.getState().user.sex === 'male'
        ? 'ihn'
        : 'sie'} ja nicht prüfen.`,
      audio: `${store.getState().user.sex === 'male'
        ? '205-Pflegemassnahmen_ID_31m.mp3'
        : '205-Pflegemassnahmen_ID_31w.mp3'}`,
      nextSnippet: '2-2-2'
    },
    '2-2-2': {
      from: 'ai',
      text: 'Eigentlich wollte ich deine Einschätzung hören.',
      audio: '205-Pflegemassnahmen_ID_32.mp3',
      nextSnippet: '2-2-3'
    },
    '2-2-3': {
      from: 'ai',
      text: 'Beschreib doch die Wunde mal!',
      audio: '205-Pflegemassnahmen_ID_33.mp3',
      nextSnippet: '2-1-2'
    },

    '2-3-1': {
      from: 'ai',
      text: 'Beschreib doch bitte mal genauer.',
      audio: '205-Pflegemassnahmen_ID_29.mp3',
      nextSnippet: '2-1-2'
    },

    '3-1': {
      type: 'choice',
      question: 'Was antworten Sie?',
      answers: [
        {
          id: 'a',
          goTo: '3-1-1',
          data: 'Kannst du das vielleicht machen?',
          audio: '205-Pflegemassnahmen_ID_37.mp3'
        },
        {
          id: 'b',
          goTo: '3-2-1',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step2', 'choices', 'adviceFeedback'], false)
            );
          },
          data: 'Aber ich kenne die Wundmanagerin noch gar nicht. Außerdem weiß ich nicht, was ich schreiben soll.',
          audio: '205-Pflegemassnahmen_ID_38.mp3'
        },
        {
          id: 'c',
          goTo: '3-3-1',
          data: 'Gut, das mache ich. Ich darf dich ja fragen, wenn ich nicht weiß, wie man etwas auf Deutsch schreibt, oder?',
          audio: '205-Pflegemassnahmen_ID_36.mp3'
        }
      ]
    },

    '3-1-1': {
      from: 'ai',
      text: 'Ich möchte, dass du das probierst. Du kannst mich aber gerne fragen.',
      audio: '205-Pflegemassnahmen_ID_42.mp3',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'ai',
      text: 'Was hast du sonst noch über Herrn Teller?',
      audio: '205-Pflegemassnahmen_ID_43.mp3',
      nextSnippet: '3-1-3'
    },
    '3-1-3': {
      from: 'player',
      text: 'Ich habe außerdem im Ernährungsprotokoll gesehen, dass er schon die ganze Zeit zu wenig isst und trinkt. Das war heute auch nicht anders.',
      audio: '205-Pflegemassnahmen_ID_47.mp3',
      nextSnippet: '3-1-4'
    },
    '3-1-4': {
      from: 'ai',
      text: () =>
        <span>
          Dann muss der{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('diätassistent'));
            }}
          >
            Diätassistent
          </span>
          , der nette Fabio Marzapane, benachrichtigt werden.
        </span>,
      audio: '205-Pflegemassnahmen_ID_48.mp3',
      nextSnippet: '4-1'
    },

    '3-2-1': {
      from: 'ai',
      text: 'Na, dann musst du es eben lernen.',
      audio: '205-Pflegemassnahmen_ID_45.mp3',
      nextSnippet: '3-1-2'
    },

    '3-3-1': {
      from: 'ai',
      text: 'Ja, natürlich.',
      audio: '205-Pflegemassnahmen_ID_40.mp3',
      nextSnippet: '3-1-3'
    },

    '4-1': {
      type: 'choice',
      question: 'Was antworten Sie?',
      answers: [
        {
          id: 'a',
          goTo: '4-1-1',
          data: 'Ok, ich kann ihn ja gleich anrufen.',
          audio: '205-Pflegemassnahmen_ID_49.mp3'
        },
        {
          id: 'b',
          goTo: '4-2-1',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step2', 'choices', 'adviceFeedback'], false)
            );
          },
          data: 'Muss ich das machen?',
          audio: '205-Pflegemassnahmen_ID_51.mp3'
        },
        {
          id: 'c',
          goTo: '4-3-1',
          data: 'Und wer macht das?',
          audio: '205-Pflegemassnahmen_ID_50.mp3'
        }
      ]
    },

    '4-1-1': {
      from: 'player',
      text: 'Mir macht aber auch große Sorgen, dass er so ruhig ist.',
      audio: '205-Pflegemassnahmen_ID_53.mp3',
      nextSnippet: '4-1-2'
    },
    '4-1-2': {
      from: 'ai',
      text: 'Was meinst du mit ruhig?',
      audio: '205-Pflegemassnahmen_ID_63.mp3',
      nextSnippet: '5-1'
    },

    '4-2-1': {
      from: 'ai',
      text: 'Ja, das ist doch nur ein Anruf.',
      audio: '205-Pflegemassnahmen_ID_60.mp3',
      nextSnippet: '4-2-2'
    },
    '4-2-2': {
      from: 'player',
      type: 'thought',
      text: 'Nur ein Anruf? Wenn sie wüsste, wie schwer Telefonieren für mich ist!',
      audio: '205-Pflegemassnahmen_ID_61.mp3',
      nextSnippet: '4-2-3'
    },
    '4-2-3': {
      from: 'player',
      text: 'Und noch etwas: Mir macht auch große Sorgen, dass Herr Teller so ruhig ist.',
      audio: '205-Pflegemassnahmen_ID_58.mp3',
      nextSnippet: '4-1-2'
    },

    '4-3-1': {
      from: 'ai',
      text: 'Am besten du. Ruf ihn einfach an!',
      audio: '205-Pflegemassnahmen_ID_55.mp3',
      nextSnippet: '4-3-2'
    },
    '4-3-2': {
      from: 'player',
      text: 'Ok.',
      audio: '205-Pflegemassnahmen_ID_56.mp3',
      nextSnippet: '4-3-3'
    },
    '4-3-3': {
      from: 'player',
      type: 'thought',
      text: 'Wenn sie wüsste, wie schwer Telefonieren für mich ist!',
      audio: '205-Pflegemassnahmen_ID_57.mp3',
      nextSnippet: '4-2-3'
    },

    '5-1': {
      type: 'choice',
      question: 'Wie beschreiben Sie sein Verhalten?',
      answers: [
        {
          id: 'a',
          goTo: '5-1-1',
          data: () =>
            <span>
              Er antwortet auf Fragen, aber er macht einen{' '}
              <span
                role="button"
                tabIndex={0}
                onClick={e => {
                  e.nativeEvent.cancel = true;
                  store.dispatch(glossaryActions.setCurrentInfo('deprimiert'));
                }}
              >
                deprimierten
              </span>{' '}
              Eindruck.
            </span>,
          audio: '205-Pflegemassnahmen_ID_64.mp3'
        },
        {
          id: 'b',
          goTo: '5-2-1',
          data: 'Er sagt so wenig und das nervt.',
          audio: '205-Pflegemassnahmen_ID_66.mp3'
        },
        {
          id: 'c',
          goTo: '5-1-1',
          data: 'Ich weiß nicht genau, wie er sich fühlt. Er spricht zu wenig.',
          audio: '205-Pflegemassnahmen_ID_65.mp3'
        }
      ]
    },

    '5-1-1': {
      from: 'ai',
      text: () =>
        <span>
          Ja, eine {' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('amputation'));
            }}
          >
            Amputation
          </span>{' '}
          ist natürlich auch eine große{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('belastung'));
            }}
          >
            Belastung
          </span>{' '}
          für die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('psyche'));
            }}
          >
            Psyche
          </span>.
        </span>,
      audio: '205-Pflegemassnahmen_ID_68.mp3',
      nextSnippet: '5-1-2'
    },
    '5-1-2': {
      from: 'ai',
      text: () =>
        <span>
          Wir sehen mal nach, ob da schon eine psychologische Unterstützung
          läuft. Das{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('abklären'));
            }}
          >
            kläre
          </span>{' '}
          ich ansonsten noch in der Visite mit Herrn Dr. Thewes ab.
        </span>,
      audio: '205-Pflegemassnahmen_ID_74.mp3',
      nextSnippet: '5-1-3'
    },
    '5-1-3': {
      from: 'player',
      text: 'Gut, vielen Dank für deine Hilfe.',
      audio: '205-Pflegemassnahmen_ID_75.mp3',
      after: {
        cb: () => {
          store.dispatch(
            infoboxActions.unlock(['pflegefachkraft', 'pflegedokumentation'])
          );
        }
      },
      nextSnippet: 'fin'
    },

    '5-2-1': {
      from: 'ai',
      text: `Oh ${store.getState().user
        .name}, das ist aber keine professionelle Antwort.`,
      audio: '205-Pflegemassnahmen_ID_72.mp3',
      nextSnippet: '5-2-2'
    },
    '5-2-2': {
      from: 'ai',
      text: 'Eine Amputation ist eine große Belastung für die Psyche. Es ist verständlich, dass Herr Teller nicht die beste Laune hat.',
      audio: '205-Pflegemassnahmen_ID_73.mp3',
      nextSnippet: '5-1-2'
    },

    fin: {
      type: 'choice',
      answers: () => {
        const adviceFeedback = store.getState().steps.step2.choices
          .adviceFeedback;
        const feedback = adviceFeedback ? '6-1' : '6-2';
        return [
          {
            id: 'a',
            goTo: feedback,
            data: 'Weiter',
            onClick: () => {
              resetChoices();
            },
            renderQuestion: false
          }
        ];
      }
    },

    '6-1': {
      type: 'feedback',
      text:
        'Toll, dass Sie Ihre Patientenbeobachtung so engagiert geäußert haben und kommunikative Herausforderungen annehmen!',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['pflegefachkraft', 'pflegedokumentation'],
      correct: true
    },
    '6-2': {
      type: 'feedback',
      text:
        'Verstecken Sie sich nicht hinter Ihrer sprachlichen Unsicherheit! Ihre Arbeit lebt vom fachlichen Austausch.',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['pflegefachkraft', 'pflegedokumentation'],
      correct: false
    }
  }
};
