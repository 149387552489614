import React from 'react';

export const NewEntryIcon = ({ size }) =>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    style={{ width: size, height: size, flexShrink: 0 }}
  >
    <title>infobox_new-entry</title>
    <path
      d="M37.54,44.2h70.1a8.73,8.73,0,0,0-8.7-8H44l-5.39-9.48H24.73A8.73,8.73,0,0,0,16,35.45v64.72a8.72,8.72,0,0,0,4,7.31l8.1-53.8C28.61,48.89,31.48,44.11,37.54,44.2Z"
      fill="#79ada3"
    />
    <path
      d="M72.71,106.28a34.65,34.65,0,0,1,46.83-32.44L122,57.3a8.3,8.3,0,0,0-8.3-8.3H41.28c-5.3-.08-7.81,4.1-8.3,8.3l-7.68,51a8.3,8.3,0,0,0,8.3,8.3H74.26A34.63,34.63,0,0,1,72.71,106.28Z"
      fill="#79ada3"
    />
    <path
      d="M107.35,135a28.7,28.7,0,1,1,28.7-28.7A28.73,28.73,0,0,1,107.35,135Zm0-50.41a21.7,21.7,0,1,0,21.7,21.7A21.73,21.73,0,0,0,107.35,84.58Z"
      fill="#79ada3"
    />
    <path
      d="M123,109.15H110.11V122h-4.23V109.15H93v-4.23h12.89V92h4.23v12.89H123Z"
      fill="#79ada3"
    />
  </svg>;
