import React from 'react';
import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'woundAndCare', 'overlay'],
      false
    )
  );

  // !!!this exercise is probably not part of the game anymore
  if (store.getState().exerciseSet.special.woundAndCare === false) {
    const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['special', 'woundAndCare'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Grammatik',
      headline: 'Komposita mit Wund- und Pflege-',
      text:
        'Bilden Sie zusammengesetzte Wörter mit Pflege- und Wund- und ergänzen Sie den richtigen Artikel.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'grammar',
      questions: [
        {
          type: 'CLOZE',
          title: 'die Maßnahme',
          text: '{} {}maßnahme',
          items: [
            [
              { data: 'der', id: 'a' },
              { data: 'die', meta: { correct: 1 }, id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', id: 'a' },
              { data: 'Pflege', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>die Pflege</strong>maßnahme
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'der Rand',
          text: '{} {}rand',
          items: [
            [
              { data: 'der', meta: { correct: 1 }, id: 'a' },
              { data: 'die', id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', meta: { correct: 1 }, id: 'a' },
              { data: 'Pflege', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>der Wund</strong>rand
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'der Dienst',
          text: '{} {}dienst',
          items: [
            [
              { data: 'der', meta: { correct: 1 }, id: 'a' },
              { data: 'die', id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', id: 'a' },
              { data: 'Pflege', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>der Pflege</strong>dienst
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'die Kraft',
          text: '{} {}kraft',
          items: [
            [
              { data: 'der', id: 'a' },
              { data: 'die', meta: { correct: 1 }, id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', id: 'a' },
              { data: 'Pflege', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>die Pflege</strong>kraft
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'die Heilung',
          text: '{} {}heilung',
          items: [
            [
              { data: 'der', id: 'a' },
              { data: 'die', meta: { correct: 1 }, id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', meta: { correct: 1 }, id: 'a' },
              { data: 'Pflege', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>die Wund</strong>heilung
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'der Abstrich',
          text: '{} {}abstrich',
          items: [
            [
              { data: 'der', meta: { correct: 1 }, id: 'a' },
              { data: 'die', id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', meta: { correct: 1 }, id: 'a' },
              { data: 'Pflege', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>der Wund</strong>abstrich
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'das Personal',
          text: '{} {}personal',
          items: [
            [
              { data: 'der', id: 'a' },
              { data: 'die', id: 'b' },
              { data: 'das', meta: { correct: 1 }, id: 'c' }
            ],
            [
              { data: 'Wund', id: 'a' },
              { data: 'Pflege', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>das Pflege</strong>personal
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'der Bericht',
          text: '{} {}bericht',
          items: [
            [
              { data: 'der', meta: { correct: 1 }, id: 'a' },
              { data: 'die', id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', id: 'a' },
              { data: 'Pflege', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>der Pflege</strong>bericht
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'der Verband',
          text: '{} {}verband',
          items: [
            [
              { data: 'der', meta: { correct: 1 }, id: 'a' },
              { data: 'die', id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', meta: { correct: 1 }, id: 'a' },
              { data: 'Pflege', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>der Wund</strong>verband
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'die Einrichtung',
          text: '{} {}einrichtung',
          items: [
            [
              { data: 'der', id: 'a' },
              { data: 'die', meta: { correct: 1 }, id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', id: 'a' },
              { data: 'Pflege', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>die Pflege</strong>einrichtung
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'das Heim',
          text: '{} {}heim',
          items: [
            [
              { data: 'der', id: 'a' },
              { data: 'die', id: 'b' },
              { data: 'das', meta: { correct: 1 }, id: 'c' }
            ],
            [
              { data: 'Wund', id: 'a' },
              { data: 'Pflege', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>das Pflege</strong>heim
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'die Stufe',
          text: '{} {}stufe',
          items: [
            [
              { data: 'der', id: 'a' },
              { data: 'die', meta: { correct: 1 }, id: 'b' },
              { data: 'das', id: 'c' }
            ],
            [
              { data: 'Wund', id: 'a' },
              { data: 'Pflege', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>die Pflege</strong>stufe
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
