import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'activityExercise3', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.activityExercise3 === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['activity', 'activityExercise3'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Emotionaler Zustand des Patienten',
      text:
        'Für Fallbesprechungen und Patientendokumentationen brauchen Sie Wörter, um den emotionalen Zustand des Patienten zu beschreiben.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          type: 'CLOZE',
          title: 'Bringen Sie die Silben in die richtige Reihenfolge.',
          text: `Der Patient sieht traurig aus und hat keinen Appetit. Er wirkt {}. (pri – miert – de) <br><br>
Die Patientin liegt schon seit vier Wochen im Krankenhaus. Sie hat keine Lust nach draußen zu gehen und wirkt lustlos und {}. (sig – niert – re) `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'deprimiert' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'resigniert' }, id: 'b' }]
          ],
          feedback: (
            <span>
              Der Patient sieht traurig aus und hat keinen Appetit. Er wirkt{' '}
              <strong>deprimiert</strong>.<br />
              <br />
              Die Patientin liegt schon seit vier Wochen im Krankenhaus. Sie hat
              keine Lust nach draußen zu gehen und wirkt lustlos und{' '}
              <strong>resigniert</strong>.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Bringen Sie die Silben in die richtige Reihenfolge.',
          text: `Der Patient liegt seit Stunden im Bett und starrt an die Decke. Er wirkt {}. (thisch – pa – a)<br><br>
Der Patient hat Angst vor Spritzen. Er reagiert {} (nisch – pa), weil der Arzt ihm Blut abnehmen möchte.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'apathisch' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'panisch' }, id: 'b' }]
          ],
          feedback: (
            <span>
              Der Patient liegt seit Stunden im Bett und starrt an die Decke. Er
              wirkt <strong>apathisch</strong>. <br />
              <br />
              Der Patient hat Angst vor Spritzen. Er reagiert
              <strong> panisch</strong>, weil der Arzt ihm Blut abnehmen möchte.
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Bringen Sie die Silben in die richtige Reihenfolge.',
          text: `Die Patientin lässt sich nicht beruhigen. Sie reagiert {} (siv – gres – ag), wenn man sie anspricht.<br><br>
          Der Patient hat schlechte Laune. Er reagiert {} (reizt – ge), wenn man ihm eine Frage stellt.`,
          items: [
            [{ type: 'INPUT', meta: { correct: 'aggressiv' }, id: 'a' }],
            [{ type: 'INPUT', meta: { correct: 'gereizt' }, id: 'b' }]
          ],
          feedback: (
            <span>
              Die Patientin lässt sich nicht beruhigen. Sie reagiert{' '}
              <strong>aggressiv</strong>, wenn man sie anspricht.<br />
              <br />
              Der Patient hat schlechte Laune. Er reagiert
              <strong> gereizt</strong>, wenn man ihm eine Frage stellt.
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
