import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

const renderDot = (index, isCurrent = false) =>
  <div
    key={index}
    className={`${styles.dot} ${isCurrent ? styles.current : ''}`}
  />;

const renderBar = (current, total, showDivider) =>
  <div key="progress-bar" className={styles.bar}>
    <div
      key="fill"
      className={styles.fill}
      style={{
        width: `${current * (100 / total)}%`
      }}
    />
    {showDivider
      ? <div key="count" className={styles.count}>
        {current + 1} / {total + 1}
      </div>
      : null}
  </div>;

const Progress = ({ theme, current, total, showDivider, stripOnBottom }) => {
  const indicator = [];

  switch (theme) {
    case 'dots':
      for (let i = 1; i <= total; ++i) {
        indicator.push(renderDot(i, current === i));
      }
      break;
    case 'bar':
    default:
      indicator.push(renderBar(current, total, showDivider));
      break;
  }

  return (
    <div
      className={
        styles.container + (stripOnBottom ? ` ${styles.stripOnBottom}` : '')
      }
    >
      {indicator}
    </div>
  );
};

Progress.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  theme: PropTypes.string,
  showDivider: PropTypes.bool
};

Progress.defaultProps = {
  current: 1,
  total: 3,
  theme: 'dots',
  showDivider: false
};

export default Progress;
