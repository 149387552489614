import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

function exitDialogue() {
  console.log('exit');
  
  store.dispatch(
    stepsActions.set(
      ['step4', 'corridor', 'dialogues', 'knocking', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'corridor', 'entities', 'secondPatient', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'hotSpots', 'accident', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'entities', 'firstPatientDown', 'overlay'],
      true
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'entities', 'firstPatient', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step4', 'corridor', 'hotSpots', 'hospitalRoom', 'overlay'],
      true
    )
  );
  store.dispatch(exerciseSetActions.setCurrentExerciseSet('knocking'));
  store.dispatch(exerciseSetActions.setNextActivity('accident'));
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step4', 'choices', 'listenToSecondPatient'], true)
  );
}

export default {
  overlay: false,
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () =>
        require('../../../static/images/corridor/friesen-avatar.png')
    }
  },
  flow: {
    0: {
      from: 'player',
      text: () =>
        <span>
          Jetzt klopft es auch noch. Ah, das ist ja - wie war nochmal sein Name?
          - Herr Friesen, der Zimmernachbar von Herrn Teller. <br /> Warum ist
          der denn so{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('blass'));
            }}
          >
            blass
          </span>
          ?
        </span>,
      audio: '210-Mitpatient_ID_1.mp3',
      type: 'thought'
    },
    1: {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-1',
          data: 'Herr Friesen, geht\'s Ihnen gut? Sie können doch klingeln, wenn Sie etwas brauchen.',
          audio: '210-Mitpatient_ID_2.mp3'
        },
        {
          id: 'b',
          goTo: '1-2-1',
          data: 'Was gibt\'s denn?',
          audio: '210-Mitpatient_ID_4.mp3'
        },
        {
          id: 'c',
          goTo: '1-3-1',
          data: 'Herr Friesen, was kann ich für Sie tun?',
          audio: '210-Mitpatient_ID_3.mp3'
        }
      ]
    },

    '1-1-1': {
      from: 'ai',
      text: 'Na ja, das wollte ich ja. Aber die Sache ist ein bisschen kompliziert.',
      audio: '210-Mitpatient_ID_6.mp3',
      nextSnippet: '1-1-2'
    },
    '1-1-2': {
      from: 'player',
      text: 'Worum geht es denn?',
      audio: '210-Mitpatient_ID_7.mp3',
      nextSnippet: '1-1-3'
    },
    '1-1-3': {
      from: 'ai',
      text: 'Hm, ich mache mir Sorgen um Herrn Teller.',
      audio: '210-Mitpatient_ID_22.mp3',
      nextSnippet: '2-1'
    },

    '1-2-1': {
      from: 'ai',
      text: 'Ach, nichts Bestimmtes. Ich wollte nur ... hm.',
      audio: '210-Mitpatient_ID_12.mp3',
      nextSnippet: '3-1'
    },

    '1-3-1': {
      from: 'ai',
      text: 'Na ja, die Sache ist ein bisschen kompliziert.',
      audio: '210-Mitpatient_ID_9.mp3',
      nextSnippet: '1-1-2'
    },

    '2-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '2-1-1',
          data: 'Wieso? Ist etwas passiert?',
          audio: '210-Mitpatient_ID_23.mp3'
        },
        {
          id: 'b',
          goTo: '2-2-1',
          data: 'Das brauchen Sie nicht. Wenn etwas ist, dann wird er sich schon selber melden.',
          onClick: () => {
            store.dispatch(
              stepsActions.set(
                ['step4', 'choices', 'listenToSecondPatient'],
                false
              )
            );
          },
          audio: '210-Mitpatient_ID_24.mp3'
        }
      ]
    },

    '2-1-1': {
      from: 'ai',
      text: 'Ich glaube, er versucht, alleine aufzustehen.',
      audio: '210-Mitpatient_ID_26.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'ai',
      text: 'Ich wollte eigentlich klingeln, aber Herr Teller hat es mir verboten.',
      audio: '210-Mitpatient_ID_27.mp3',
      nextSnippet: '2-1-3'
    },
    '2-1-3': {
      from: 'ai',
      text: 'Deshalb bin ich jetzt selber gekommen.',
      audio: '210-Mitpatient_ID_28.mp3',
      nextSnippet: '2-1-4'
    },
    '2-1-4': {
      from: 'player',
      text: 'Da gehe ich gleich nachsehen. Kommen Sie zurecht?',
      audio: '210-Mitpatient_ID_29.mp3',
      nextSnippet: '2-1-5'
    },
    '2-1-5': {
      from: 'ai',
      text: 'Ja, ja. Aber bitte sagen Sie ihm nicht, dass ich Sie geholt habe.',
      audio: '210-Mitpatient_ID_30.mp3',
      nextSnippet: '2-1-6'
    },
    '2-1-6': {
      from: 'player',
      text: 'Ich sage nichts, Herr Friesen, versprochen. Vielen Dank, dass Sie Bescheid gegeben haben.',
      audio: '210-Mitpatient_ID_31.mp3',
      nextSnippet: 'fin'
    },

    '2-2-1': {
      from: 'ai',
      text: 'Oh, entschuldigen Sie. Ich wollte mich nicht einmischen.',
      audio: '210-Mitpatient_ID_33.mp3',
      nextSnippet: 'fin'
    },

    '3-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '1-1-3',
          data: 'Herr Friesen, was kann ich denn für Sie tun?',
          audio: '210-Mitpatient_ID_14.mp3'
        },
        {
          id: 'b',
          goTo: '3-2-1',
          data: 'Herr Friesen, ist alles in Ordnung?',
          audio: '210-Mitpatient_ID_13.mp3'
        },
        {
          id: 'c',
          goTo: '3-3-1',
          data: 'Dann gehen Sie mal wieder auf Ihr Zimmer. Ich komme nachher nochmal.',
          onClick: () => {
            store.dispatch(
              stepsActions.set(
                ['step4', 'choices', 'listenToSecondPatient'],
                false
              )
            );
          },
          audio: '210-Mitpatient_ID_15.mp3'
        }
      ]
    },

    '3-2-1': {
      from: 'ai',
      text: 'Mit mir schon!',
      audio: '210-Mitpatient_ID_17.mp3',
      nextSnippet: '1-1-3'
    },
    '3-3-1': {
      from: 'player',
      text: 'Das kann ja dann nicht so wichtig sein.',
      audio: '210-Mitpatient_ID_20.mp3',
      type: 'thought',
      nextSnippet: '3-3-2'
    },
    '3-3-2': {
      from: 'ai',
      text: 'Oh, entschuldigen Sie. Ich wollte nicht stören.',
      audio: '210-Mitpatient_ID_21_v2.mp3',
      nextSnippet: 'fin'
    },

    fin: {
      type: 'choice',
      answers: () => {
        const listenToSecondPatient = store.getState().steps.step4.choices
          .listenToSecondPatient;
        const feedback = listenToSecondPatient ? '4-1' : '4-2';

        return [
          {
            id: 'a',
            goTo: feedback,
            onClick: () => {
              resetChoices();
            },
            data: 'Weiter',
            renderQuestion: false
          }
        ];
      }
    },

    '4-1': {
      type: 'feedback',
      text: 'Gut so. Herr Friesen fühlt sich ernst genommen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    },
    '4-2': {
      type: 'feedback',
      text:
        'Herr Friesen ist extra zu Ihnen gekommen. Er hatte bestimmt ein wichtiges Anliegen. Sprechen Sie noch einmal mit ihm!',
      correct: false
    }
  }
};
