import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      [
        'step1',
        'hospitalRoom',
        'exercises',
        'disinfectionExercise1',
        'overlay'
      ],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.disinfectionExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['checkWound', 'disinfectionExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Wundbehandlung',
      text:
        'Kennen Sie die richtigen Nomen-Verb-Verbindungen, wenn Sie Ihren Patienten etwas erklären möchten?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          title: 'Ein Verb passt nicht. Wählen Sie aus!',
          question: 'den Verband ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: 'wechseln' },
            { id: 2, data: 'abnehmen' },
            { id: 3, data: 'lösen' },
            { id: 4, meta: { correct: 4 }, data: 'unternehmen' }
          ]
        },
        {
          title: 'Ein Verb passt nicht. Wählen Sie aus!',
          question: 'die Wunde ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: 'versorgen' },
            { id: 2, data: 'desinfizieren' },
            { id: 3, meta: { correct: 3 }, data: 'abnehmen' },
            { id: 4, data: 'reinigen' }
          ]
        },
        {
          title: 'Ein Verb passt nicht. Wählen Sie aus!',
          question: 'den Patienten ...',
          correct: 'Gute Antwort, weiter so!',
          incorrect: 'Leider keine optimale Antwort.',
          multiple: false,
          answers: [
            { id: 1, data: 'aufklären' },
            { id: 2, data: 'versorgen' },
            { id: 3, meta: { correct: 3 }, data: 'lösen' },
            { id: 4, data: 'informieren' }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
