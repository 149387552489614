import React from 'react';
import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'exercises', 'accidentExercise3', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.accidentExercise3 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['accident', 'accidentExercise3'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'im Notfall angemessen reagieren',
      text:
        'Frau Berger ist gestürzt. Sie finden sie auf dem Boden liegend vor ihrem Bett.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          type: 'CLOZE',
          title: 'Zu welcher Situation passen die Äußerungen?',
          text: `
          Situation 1: <i>Sie sprechen die Patientin vorsichtig an.</i><br>
          Situation 2: <i>Sie erklären der Patientin, was Sie jetzt machen.</i>
          <br><br>
          {} Wir drehen Sie jetzt vorsichtig auf die linke Seite.<br>
          {} Frau Berger, tut Ihnen etwas weh?<br>
          {} Auf 3 bewegen wir Sie gemeinsam in den Rollstuhl. 1, 2, 3 …<br>
          {} Haben Sie sich irgendwo verletzt?<br>
          {} Haben Sie Schmerzen, Frau Berger?<br>
          `,
          items: [
            [
              { data: '1', id: 'a' },
              { data: '2', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: '1', meta: { correct: 1 }, id: 'a' },
              { data: '2', id: 'b' }
            ],
            [
              { data: '1', id: 'a' },
              { data: '2', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: '1', meta: { correct: 1 }, id: 'a' },
              { data: '2', id: 'b' }
            ],
            [
              { data: '1', meta: { correct: 1 }, id: 'a' },
              { data: '2', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>2</strong> Wir drehen Sie jetzt vorsichtig auf die linke
              Seite.<br />
              <strong>1</strong> Frau Berger, tut Ihnen etwas weh?<br />
              <strong>2</strong> Auf 3 bewegen wir Sie gemeinsam in den
              Rollstuhl. 1, 2, 3 …<br />
              <strong>1</strong> Haben Sie sich irgendwo verletzt?<br />
              <strong>1</strong> Haben Sie Schmerzen, Frau Berger?<br />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Zu welcher Situation passen die Äußerungen?',
          text: `
          Situation 3: <i>Sie erklären der Patientin, dass Sie Hilfe holen.</i><br />
          Situation 4: <i>Sie geben der Patienten Anweisungen.</i>
          <br><br>
          {} Ich hole meinen Kollegen dazu und wir helfen Ihnen gemeinsam aufs Bett.<br>
          {} Wenn möglich, helfen Sie ein bisschen mit, ja?<br>
          {} Es ist alles gut, ich hole jetzt Hilfe.<br>
          {} Drehen Sie Ihren Kopf mal nach links. So, ich entnehme etwas Blut aus Ihrem Ohrläppchen.<br>
          {} Bitte stützen Sie sich auf Ihren rechten Arm.
          `,
          items: [
            [
              { data: '3', meta: { correct: 1 }, id: 'a' },
              { data: '4', id: 'b' }
            ],
            [
              { data: '3', id: 'a' },
              { data: '4', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: '3', meta: { correct: 1 }, id: 'a' },
              { data: '4', id: 'b' }
            ],
            [
              { data: '3', id: 'a' },
              { data: '4', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: '3', id: 'a' },
              { data: '4', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              <strong>3</strong> Ich hole meinen Kollegen dazu und wir helfen
              Ihnen gemeinsam aufs Bett.<br />
              <strong>4</strong> Wenn möglich, helfen Sie ein bisschen mit, ja?<br />
              <strong>3</strong> Es ist alles gut, ich hole jetzt Hilfe.<br />
              <strong>4</strong> Drehen Sie Ihren Kopf mal nach links. So, ich
              entnehme etwas Blut aus Ihrem Ohrläppchen.<br />
              <strong>4</strong> Bitte stützen Sie sich auf Ihren rechten Arm.
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
