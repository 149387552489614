import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'hospitalRoom', 'exercises', 'accidentExercise1', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.accidentExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step4', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['accident', 'accidentExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Grammatik',
      headline: 'Was ist passiert?',
      text:
        'Bei Übergaben, Patientenvorstellungen und Fallbesprechungen berichtet man auch über Ereignisse in der Vergangenheit. Kennen Sie die Hilfsverben für die Formen des Partizip Perfekt noch?',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'grammar',
      questions: [
        {
          type: 'CLOZE',
          title: 'Lesen Sie die Sätze.',
          text: `
            <strong>Welches Hilfsverb passt?</strong><br/><br/>
            1. stürzen:<br>
            Der Patient {} gestürzt.<br><br>
            2. sich hinsetzen:<br>
            Die Patientin {} sich hingesetzt.<br><br>
            `,
          items: [
            [
              { data: 'hat', id: 'a' },
              { data: 'ist', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: 'hat', meta: { correct: 1 }, id: 'a' },
              { data: 'ist', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              1. stürzen:<br />
              Der Patient <strong>ist</strong> gestürzt.<br />
              <br />
              2. sich hinsetzen:<br />
              Die Patientin <strong>hat</strong> sich hingesetzt.<br />
              <br />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Lesen Sie die Sätze.',
          text: `
            <strong>Welches Hilfsverb passt?</strong><br/><br/>
            3. ausrutschen:<br>
            Der Patient {} ausgerutscht.<br><br>
            4. stolpern:<br>
            Die Patientin {} gestolpert.<br><br>
            `,
          items: [
            [
              { data: 'hat', id: 'a' },
              { data: 'ist', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: 'hat', id: 'a' },
              { data: 'ist', meta: { correct: 1 }, id: 'b' }
            ]
          ],
          feedback: (
            <span>
              3. ausrutschen:<br />
              Der Patient <strong>ist</strong> ausgerutscht.<br />
              <br />
              4. stolpern:<br />
              Die Patientin <strong>ist</strong> gestolpert.<br />
              <br />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Lesen Sie die Sätze.',
          text: `
            <strong>Welches Hilfsverb passt?</strong><br/><br/>
            5. aufstehen:<br>
            Der Patient {} aufgestanden.<br><br>
            6. klingeln:<br>
            Die Patientin {} geklingelt.<br><br>
            `,
          items: [
            [
              { data: 'hat', id: 'a' },
              { data: 'ist', meta: { correct: 1 }, id: 'b' }
            ],
            [
              { data: 'hat', meta: { correct: 1 }, id: 'a' },
              { data: 'ist', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              5. aufstehen:<br />
              Der Patient <strong>ist</strong> aufgestanden.<br />
              <br />
              6. klingeln:<br />
              Die Patientin <strong>hat</strong> geklingelt.<br />
              <br />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Lesen Sie die Sätze.',
          text: `
            <strong>Welches Hilfsverb passt?</strong><br/><br/>
            7. schwitzen:<br>
            Der Patient {} geschwitzt.<br><br>
            8. sich übergeben:<br>
            Die Patientin {} sich übergeben.<br><br>
            `,
          items: [
            [
              { data: 'hat', meta: { correct: 1 }, id: 'a' },
              { data: 'ist', id: 'b' }
            ],
            [
              { data: 'hat', meta: { correct: 1 }, id: 'a' },
              { data: 'ist', id: 'b' }
            ]
          ],
          feedback: (
            <span>
              7. schwitzen:<br />
              Der Patient <strong>hat</strong> geschwitzt.<br />
              <br />
              8. sich übergeben:<br />
              Die Patientin <strong>hat</strong> sich übergeben.<br />
              <br />
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
