import React, { Component } from 'react';
import styles from './styles.less';

class ScalingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1
    };

    this.resizeTimeout = false;

    this.throttledResize = () => {
      clearTimeout(this.resizeTimeout);

      this.resizeTimeout = setTimeout(this.handleResize, 250);
    };
  }

  componentDidMount() {
    this.updateScale();

    window.addEventListener('resize', this.throttledResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResize);
  }

  render() {
    const { children } = this.props;
    const { scale } = this.state;
    return (
      <div
        className={styles.scalingContainer}
        style={{
          transform: `scale(${scale})`
        }}
      >
        {children}
      </div>
    );
  }

  handleResize = () => {
    this.updateScale();
  };

  updateScale = () => {
    const { offsetHeight } = document.body;

    const scale = offsetHeight / 1080;

    this.setState({
      scale
    });
  };
}

export default ScalingContainer;
