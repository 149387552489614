import React from 'react';
import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { CustomMultipleChoice } from '../../../components/customMultipleChoice';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'trayExercise3', 'overlay'],
      false
    )
  );
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;

  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.trayExercise3 === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(exerciseSetActions.closeExercise(['food', 'trayExercise3']));
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Wortschatz',
      headline: 'Wortbildung Adjektive',
      text:
        'Die Endungen -haltig, -reich und -arm geben wichtige Informationen zur Bedeutung eines Adjektivs.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'vocabulary',
      questions: [
        {
          type: 'CLOZE',
          title: 'Schreiben Sie das Adjektiv.',
          text: `Beispiel: <br>
                 <i>Linsen <b>enthalten</b> Eiweiß, sie sind eiweiß<b>haltig</b>. <br>
                 Linsen enthalten <b>viel</b> Eiweiß, sie sind eiweiß<b>reich</b>. <br>
                 Linsen enthalten <b>wenig</b> Eiweiß, sie sind eiweiß<b>arm</b>.</i> <br><br>
                 Fleisch enthält Eiweiß, es ist {}.
                 `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'eiweißhaltig' }, id: 'a' }]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u2/u2_2.mp3')
                      },
                      'Fleisch enthält Eiweiß, es ist <strong>eiweißhaltig</strong>.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie das Adjektiv.',
          text: `Beispiel: <br>
                 <i>Linsen <b>enthalten</b> Eiweiß, sie sind eiweiß<b>haltig</b>. <br>
                 Linsen enthalten <b>viel</b> Eiweiß, sie sind eiweiß<b>reich</b>. <br>
                 Linsen enthalten <b>wenig</b> Eiweiß, sie sind eiweiß<b>arm</b>.</i> <br><br>
                 Gemüse enthält viele Ballaststoffe, es ist {}.
                 `,
          items: [
            [
              {
                type: 'INPUT',
                meta: { correct: 'ballaststoffreich' },
                id: 'a'
              }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u2/u2_3.mp3')
                      },
                      'Gemüse enthält viele Ballaststoffe, es ist <strong>ballaststoffreich</strong>.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie das Adjektiv.',
          text: `Beispiel: <br>
                 <i>Linsen <b>enthalten</b> Eiweiß, sie sind eiweiß<b>haltig</b>. <br>
                 Linsen enthalten <b>viel</b> Eiweiß, sie sind eiweiß<b>reich</b>. <br>
                 Linsen enthalten <b>wenig</b> Eiweiß, sie sind eiweiß<b>arm</b>.</i> <br><br>
                 Salat hat wenig Kalorien, er ist {}.
                 `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'kalorienarm' }, id: 'a' }]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u2/u2_4.mp3')
                      },
                      'Salat hat wenig Kalorien, er ist <strong>kalorienarm</strong>.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie das Adjektiv.',
          text: `Beispiel: <br>
                 <i>Linsen <b>enthalten</b> Eiweiß, sie sind eiweiß<b>haltig</b>. <br>
                 Linsen enthalten <b>viel</b> Eiweiß, sie sind eiweiß<b>reich</b>. <br>
                 Linsen enthalten <b>wenig</b> Eiweiß, sie sind eiweiß<b>arm</b>.</i> <br><br>
                 Kiwis enthalten viele Vitamine, sie sind {}.
                 `,
          items: [
            [{ type: 'INPUT', meta: { correct: 'vitaminreich' }, id: 'a' }]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u2/u2_5.mp3')
                      },
                      'Kiwis enthalten viele Vitamine, sie sind <strong>vitaminreich</strong>.'
                    ]
                  }
                ]}
              />
            </span>
          )
        },
        {
          type: 'CLOZE',
          title: 'Schreiben Sie das Adjektiv.',
          text: `Beispiel: <br>
                 <i>Linsen <b>enthalten</b> Eiweiß, sie sind eiweiß<b>haltig</b>. <br>
                 Linsen enthalten <b>viel</b> Eiweiß, sie sind eiweiß<b>reich</b>. <br>
                 Linsen enthalten <b>wenig</b> Eiweiß, sie sind eiweiß<b>arm</b>.</i> <br><br>
                 Bananen enthalten Mineralstoffe, sie sind {}.
                 `,
          items: [
            [
              {
                type: 'INPUT',
                meta: { correct: 'mineralstoffhaltig' },
                id: 'a'
              }
            ]
          ],
          feedback: (
            <span>
              <CustomMultipleChoice
                answers={[
                  {
                    id: 1,
                    meta: { correct: 1 },
                    data: [
                      {
                        type: 'audio',
                        src: require('../../../static/audio/u2/u2_6.mp3')
                      },
                      'Bananen enthalten Mineralstoffe, sie sind <strong>mineralstoffhaltig</strong>.'
                    ]
                  }
                ]}
              />
            </span>
          ),
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
