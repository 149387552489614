import React from 'react';

export const InfoBoxIcon = () =>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
  >
    <title>infobox</title>
    <path
      d="M41,47h81.34a10.13,10.13,0,0,0-10.09-9.28H48.5l-6.25-11H26.13A10.13,10.13,0,0,0,16,36.85v75.09a10.12,10.12,0,0,0,4.59,8.48L30,58C30.63,52.44,34,46.89,41,47Z"
      fill="#1e9ec1"
    />
    <path
      d="M129.4,52.57H45.33c-6.15-.09-9.07,4.76-9.63,9.63l-8.91,59.13A9.63,9.63,0,0,0,36.42,131h84.07a9.63,9.63,0,0,0,9.63-9.63L139,62.2A9.63,9.63,0,0,0,129.4,52.57Z"
      fill="#1e9ec1"
    />
  </svg>;
