import uniq from 'lodash/uniq';
import { createReducer, createActionTypes } from '../lib/redux';

const defaultState = {
  unlockedEntries: [],
  seenEntries: 0
};

const types = createActionTypes(['UNLOCK', 'SET_SEEN_ENTRIES'], '');

const actions = {
  unlock: entry => ({
    type: types.UNLOCK,
    entry
  }),
  setSeenEntries: value => ({
    type: types.SET_SEEN_ENTRIES,
    value
  })
};

const reducer = createReducer(defaultState, {
  [types.UNLOCK]: (state = defaultState, { entry }) => ({
    ...state,
    unlockedEntries: uniq([...state.unlockedEntries, ...entry]),
  }),
  [types.SET_SEEN_ENTRIES]: (state = defaultState, { value }) => ({
    ...state,
    seenEntries: value
  })
});

export { actions, reducer };
