import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'dietaryAssistantExercise3', 'overlay'],
      false
    )
  );
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Wurde schon gemacht!',
      text: 'Wie können Sie kurz darüber informieren, was schon erledigt wurde? In dieser Übung trainieren Sie Passiv Präteritum.',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Formulieren Sie den Satz um. Hören Sie dann zur Kontrolle.',
          question: () =>
            <span>
              <small>
                Beispiel:<br />
                <i>Die Chirurgie muss noch informiert werden.<br />{' '}
                  Die Chirurgie <strong>wurde bereits informiert.</strong></i>
              </small>
              <br />
              <br />
              Die Diätassistentin muss noch informiert werden.
              <br />
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u24/u24b_1.mp3')
                },
                'Hören Sie zu und vergleichen Sie.'
              ]
            }
          ]
        },
        {
          title: 'Formulieren Sie den Satz um. Hören Sie dann zur Kontrolle.',
          question: () =>
            <span>
              <small>
                Beispiel:<br />
                <i>Die Chirurgie muss noch informiert werden.<br />{' '}
                  Die Chirurgie <strong>wurde bereits informiert.</strong></i>
              </small>
              <br />
              <br />
              Der Ernährungsplan für Frau Becker muss noch erstellt werden.
              <br />
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u24/u24b_2.mp3')
                },
                'Hören Sie zu und vergleichen Sie.'
              ]
            }
          ]
        },
        {
          title: 'Formulieren Sie den Satz um. Hören Sie dann zur Kontrolle.',
          question: () =>
            <span>
              <small>
                Beispiel:<br />
                <i>Die Chirurgie muss noch informiert werden.<br />{' '}
                  Die Chirurgie <strong>wurde bereits informiert.</strong></i>
              </small>
              <br />
              <br />
              Bei Herrn Gegg sollte der Blutzucker neu eingestellt werden.
              <br />
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u24/u24b_3.mp3')
                },
                'Hören Sie zu und vergleichen Sie.'
              ]
            }
          ]
        },
        {
          title: 'Formulieren Sie den Satz um. Hören Sie dann zur Kontrolle.',
          question: () =>
            <span>
              <small>
                Beispiel:<br />
                <i>Die Chirurgie muss noch informiert werden.<br />{' '}
                  Die Chirurgie <strong>wurde bereits informiert.</strong></i>
              </small>
              <br />
              <br />
              Die Ernährung von Frau Wagner muss umgestellt werden.
              <br />
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u24/u24b_4.mp3')
                },
                'Hören Sie zu und vergleichen Sie.'
              ]
            }
          ]
        },
        {
          title: 'Formulieren Sie den Satz um. Hören Sie dann zur Kontrolle.',
          question: () =>
            <span>
              <small>
                Beispiel:<br />
                <i>Die Chirurgie muss noch informiert werden.<br />{' '}
                  Die Chirurgie <strong>wurde bereits informiert.</strong></i>
              </small>
              <br />
              <br />
              Das Krankheitsbild sollte in dem Bericht genau beschrieben werden.
              <br />
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u24/u24b_5.mp3')
                },
                'Hören Sie zu und vergleichen Sie.'
              ]
            }
          ]
        },
        {
          title: 'Formulieren Sie den Satz um. Hören Sie dann zur Kontrolle.',
          question: () =>
            <span>
              <small>
                Beispiel:<br />
                <i>Die Chirurgie muss noch informiert werden.<br />{' '}
                  Die Chirurgie <strong>wurde bereits informiert.</strong></i>
              </small>
              <br />
              <br />
              Herr Wegele muss dringend operiert werden.
              <br />
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u24/u24b_6.mp3')
                },
                'Hören Sie zu und vergleichen Sie.'
              ]
            }
          ]
        },
        {
          title: 'Formulieren Sie den Satz um. Hören Sie dann zur Kontrolle.',
          question: () =>
            <span>
              <small>
                Beispiel:<br />
                <i>Die Chirurgie muss noch informiert werden.<br />{' '}
                  Die Chirurgie <strong>wurde bereits informiert.</strong></i>
              </small>
              <br />
              <br />
              Mit Frau Weiß sollte so schnell wie möglich ein Termin vereinbart
              werden.
              <br />
            </span>,
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u24/u24b_7.mp3')
                },
                'Hören Sie zu und vergleichen Sie.'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
