import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step4', 'office', 'dialogues', 'canMiloHelpMe', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(['step4', 'office', 'hotSpots', 'milo', 'overlay'], true)
  );
  store.dispatch(
    stepsActions.set(['step4', 'office', 'entities', 'milo', 'overlay'], true)
  );
}

export default {
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    }
  },
  flow: {
    0: {
      from: 'player',
      text: () =>
        <span>
          Fast{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('dienstschluss'));
            }}
          >
            Dienstschluss
          </span>{' '}
          und ich muss noch die ganze Übergabe vorbereiten. Vielleicht{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('unterstützen'));
            }}
          >
            unterstützt
          </span>{' '}
          mich Milo, der ist immer so hilfsbereit.
        </span>,
      audio: '221-Milo-Monolog_ID_1.mp3',
      type: 'thought'
    },
    1: {
      from: 'player',
      text: 'Wie schaffe ich es, dass Milo mir hilft?',
      audio: '221-Milo-Monolog_ID_2.mp3',
      type: 'thought'
    },
    2: {
      type: 'feedback',
      text: 'Sie können den Dialog noch einmal wiederholen oder weiterspielen.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    }
  }
};
