import React from 'react';

import store from '../../../store';
import { VERSIONS } from '../../../constants';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as infoboxActions } from '../../../reducers/infobox';

import styles from '../styles.less';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(['step4', 'office', 'hotSpots', 'milo', 'overlay'], false)
  );
  store.dispatch(
    stepsActions.set(['step4', 'office', 'dialogues', 'askingMiloForHelp', 'overlay'], false)
  );

  if (store.getState().app.get('version') === VERSIONS.STUDENT) {
    store.dispatch(stepsActions.setRoom('corridor'));
    store.dispatch(
      stepsActions.set(
        ['step4', 'corridor', 'entities', 'secondPatient', 'overlay'],
        true
      )
    );
    store.dispatch(
      stepsActions.set(['step4', 'corridor', 'dialogues', 'knocking', 'overlay'], true)
    );
    store.dispatch(stepsActions.setDialogue('knocking'));
  }
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step4', 'choices', 'placedDocumentsOnMilosDesk'], false)
  );
  store.dispatch(stepsActions.set(['step4', 'choices', 'waitedForMilo'], false));
}

export default {
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () => require('../../../static/images/office/milo-portrait.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question: 'Wie schaffen Sie es, dass Milo Ihnen bei der Übergabe hilft?',
      answers: () => {
        const waitedForMilo = store.getState().steps.step4.choices.waitedForMilo;
        const placedDocumentsOnMilosDesk = store.getState().steps.step4.choices
          .placedDocumentsOnMilosDesk;
        return [
          {
            id: 'a',
            goTo: '0-1-1',
            renderQuestion: false,
            data: 'die Pflegedokumentation auf Milos Platz legen',
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step4', 'choices', 'placedDocumentsOnMilosDesk'], true)
              );
            },
            className: placedDocumentsOnMilosDesk ? styles.disabled : ''
          },
          {
            id: 'b',
            goTo: '0-2-1',
            renderQuestion: false,
            data: 'Milo direkt fragen'
          },
          {
            id: 'c',
            goTo: '0-3-1',
            renderQuestion: false,
            data: 'warten, dass Milo Hilfe anbietet',
            onClick: () => {
              store.dispatch(
                stepsActions.set(['step4', 'choices', 'waitedForMilo'], true)
              );
            },
            className: waitedForMilo ? styles.disabled : ''
          }
        ];
      }
    },
    '0-1-1': {
      from: 'ai',
      text: `Was soll denn das jetzt? Warum hat ${store.getState().user.sex === 'male'
        ? 'er'
        : 'sie'} mich denn nicht direkt angesprochen?`,
      audio:
        store.getState().user.sex === 'male'
          ? '219-Milo-Platz_ID_1m.mp3'
          : '219-Milo-Platz_ID_1w.mp3',
      type: 'thought',
      nextSnippet: '0'
    },
    '0-2-1': {
      from: 'player',
      text: 'Ah, Milo, da bist du ja. Und? Bei dir alles klar?',
      audio: '218-Milo-Fragen_ID_1.mp3',
      nextSnippet: '0-2-2'
    },
    '0-2-2': {
      from: 'ai',
      text: () =>
        <span>
          Ja, heute drei Neuaufnahmen, viel{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('papierkram'));
            }}
          >
            Papierkram
          </span>
          , aber alles im grünen Bereich.
        </span>,
      audio: '218-Milo-Fragen_ID_2.mp3',
      nextSnippet: '0-2-3'
    },
    '0-2-3': {
      from: 'player',
      text: 'Grün?',
      audio: '218-Milo-Fragen_ID_3.mp3',
      nextSnippet: '0-2-4'
    },
    '0-2-4': {
      from: 'ai',
      text: 'Grüner Bereich, das bedeutet: Es ist alles in Ordnung.',
      audio: '218-Milo-Fragen_ID_4.mp3',
      nextSnippet: '0-2-5'
    },
    '0-2-5': {
      from: 'player',
      text: 'Aha, du, weil wir gerade von Papierkram sprechen. Lisa wird heute nicht bei der Übergabe dabei sein. Können wir die zusammen vorbereiten?',
      audio: '218-Milo-Fragen_ID_5.mp3',
      nextSnippet: '0-2-6'
    },
    '0-2-6': {
      from: 'ai',
      text: 'Ja, warte. Ich muss nochmal kurz in den Spülraum und dann können wir das gerne machen.',
      audio: '218-Milo-Fragen_ID_6.mp3',
      after: {
        cb: () => {
          store.dispatch(infoboxActions.unlock(['pflegebericht']));
        }
      },
      nextSnippet: 'fin'
    },
    '0-3-1': {
      from: 'player',
      text: 'Hm, schon fünf Minuten vorbei.',
      audio: '220-Auf-Milo-Warten_ID_1.mp3',
      type: 'thought',
      nextSnippet: '0-3-2'
    },
    '0-3-2': {
      from: 'player',
      text: 'Ich habe ja jetzt nur noch 30 Minuten Zeit. Bisher hat er nichts gesagt. Vielleicht sollte ich ihn doch lieber direkt fragen.',
      audio: '220-Auf-Milo-Warten_ID_2.mp3',
      type: 'thought',
      nextSnippet: '0'
    },
    fin: {
      type: 'choice',
      answers: [
        {
          id: 'a',
          goTo: 'feedback',
          data: 'Weiter',
          onClick: () => {
            resetChoices();
          },
          renderQuestion: false
        }
      ]
    },
    feedback: {
      type: 'feedback',
      text: 'Schön! Milo steht Ihnen hilfsbereit zur Seite.',
      onClick: () => {
        exitDialogue();
      },
      unlockedEntries: ['pflegebericht'],
      correct: true
    }
  }
};
