import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MultipleChoice } from '@ovos-media/widget-multiple-choice';

class Choice extends Component {
  render() {
    const { question, answers, ...props } = this.props;
    return (
      <MultipleChoice
        {...props}
        question={question}
        answers={answers}
        onChange={this.handleAnswer}
        multiple={false}
        addStyleFiles={cb => require(['./styles.less'], cb)}
      />
    );
  }

  handleAnswer = id => {
    const answer = this.props.answers.find(a => a.id === id[0]);
    if (answer.onClick) answer.onClick();
  };
}

Choice.propTypes = {
  question: PropTypes.string,
  answers: PropTypes.array, // eslint-disable-line
};

Choice.defaultProps = {
  answers: []
};

export default Choice;
