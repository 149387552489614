import React, { Component } from 'react';

import styles from './styles.less';

class LoadingScreen extends Component {
  render() {
    const { loading } = this.props;
    return (
      <div
        key="loadingScreen"
        className={styles.loadingScreen}
        style={{
          backgroundImage: `url('${require('../../static/images/hospital-room.jpg')}')`,
          display: loading ? 'block' : 'none'
        }}
      >
        <div className={styles.loading}>
          <div className={styles.spinner}>
            <div className={styles.bounce1} />
            <div className={styles.bounce2} />
            <div className={styles.bounce3} />
          </div>
        </div>
      </div>
    );
  }
}

LoadingScreen.defaultProps = {
  loading: true
};

export default LoadingScreen;
