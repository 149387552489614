import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'checkWoundExercise1', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step1', 'hospitalRoom', 'exercises', 'checkWoundExercise2', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'checkWoundExercise2'));
  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.checkWoundExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step1', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise(['checkWound', 'checkWoundExercise1'])
    );
  }
}

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Kommunikation',
      headline: 'Auf Patienten beruhigend eingehen',
      text:
        'Pflegebedürftige wissen oft nicht, welcher Schritt folgt und ob für sie Schmerz, Wartezeiten oder Unsicherheit entstehen. Eine beruhigende Ausdrucksweise kann helfen, unangenehme Gefühle zu verringern. Lesen Sie auch die Tipps in der Infobox.',
      duration: 'DAUER: 2 MIN',
      goTo: '1',
      chainedExercises: 'oneChained'
    },
    1: {
      type: 'questionSet',
      category: 'communication',
      questions: [
        {
          title: 'Wählen Sie aus.',
          question: 'Welcher Satz ist beruhigender?',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'Frau Meier, ich würde jetzt gern Ihren Verband wechseln, damit ich Ihre Wunde versorgen kann. Sie werden sehen, das geht ganz schnell. Einverstanden?'
            },
            {
              id: 2,
              data: 'Frau Meier, wir müssen dringend Ihren Verband wechseln, die Wunde muss versorgt werden. Achtung, das tut manchmal ein bisschen weh.'
            }
          ]
        },
        {
          title: 'Wählen Sie aus.',
          question: 'Welcher Satz ist beruhigender?',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: 'So, Herr Filkamp, ich werde Ihnen jetzt ein Schmerzmittel spritzen, damit es Ihnen schnell besser geht. Sie werden sehen: ein kleiner Pieks, und schon ist alles vorbei.'
            },
            {
              id: 2,
              data: 'Herr Filkamp, Sie Armer, Sie haben sicher schlimme Schmerzen! Das tut mir ja so leid. Ich spritze Ihnen jetzt ein Schmerzmittel in den Oberschenkel. Das ist doch kein Problem für Sie, oder?'
            }
          ]
        },
        {
          title: 'Wählen Sie aus.',
          question: 'Welcher Satz ist beruhigender?',
          correct: 'Super, das stimmt!',
          incorrect: 'Leider keine optimale Antwort.',
          answers: [
            {
              id: 1,
              data: 'Oh weh, die Wunde sieht ja gar nicht gut aus. Die Wundränder sind ja total entzündet, da müssen wir was machen, das geht so nicht. Ich mache jetzt mal Fotos, damit die Wundmanagerin Bescheid weiß.'
            },
            {
              id: 2,
              meta: { correct: 2 },
              data: 'Na ja, die Entzündung ist noch nicht ganz abgeklungen. Aber das kriegen wir schon hin, da machen Sie sich mal keine Sorgen. Ich spreche mit der Wundmanagerin, das ist unsere Expertin, und dann besprechen wir in Ruhe, wie‘s weitergeht, in Ordnung?'
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
