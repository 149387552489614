import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

import { Speaker } from '../../../static/icons/Speaker';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise4', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'adviceExercise5', 'overlay'],
      true
    )
  );
  store.dispatch(stepsActions.set(['currentExercise'], 'adviceExercise5'));
}

const style = { display: 'flex', alignItems: 'center' };

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Wortakzent: Hilfsmittel',
      text: 'Achten Sie auf die Betonung der Silben. Korrigieren Sie sich selbst!',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_01_v2.mp3')
                },
                'Gehhilfe'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 2,
              meta: { correct: 2 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_02_v2.mp3')
                },
                'Hörgerät'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 3,
              meta: { correct: 3 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_03_v2.mp3')
                },
                'Zahnprothese'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 4,
              meta: { correct: 4 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_04_v2.mp3')
                },
                'Gehstock'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 4,
              meta: { correct: 4 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_05_v2.mp3')
                },
                'Kontaktlinsen'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 4,
              meta: { correct: 4 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_06_v2.mp3')
                },
                'Rollator'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 4,
              meta: { correct: 4 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u21/u21a-c_07_v2.mp3')
                },
                'Lupe'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
