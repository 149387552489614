import { Component } from 'react';
// import { connect } from 'react-redux';
import ReactPiwik from 'react-piwik';

// initialize matomo
new ReactPiwik({
  url: 'piwik.ovos.at',
  siteId: window.location.hostname === 'spiel.ein-tag-deutsch.de' ? 13 : 12,
  trackErrors: true,
});

export function matomo (content) {
  ReactPiwik.push(content);
};

class MatomoProvider extends Component {
  constructor(props) {
    super(props);
    // const magazines = props.magazines;

    matomo(['enableHeartBeatTimer']);
    // if(magazines.currentMagazineId && magazines.pageId) {
    //   matomo(['setCustomUrl', `/magazine/${magazines.currentMagazineId}/page/${magazines.pageId}`]);
    // }
    matomo(['trackPageView']);
  }

  render() {
    const { children } = this.props;
    return children;
  }
};

// const mapStateToProps = ({ magazines }) => ({
//   magazines
// });
  
// export default connect(mapStateToProps)(MatomoProvider);
export default MatomoProvider;