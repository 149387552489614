import React, { Component } from 'react';

import styles from './styles.less';
import { InfoIcon } from '../../static/icons/InfoIcon';

class DefaultButton extends Component {
  static defaultProps = {
    className: '',
    label: 'Button'
  };

  render() {
    const { className, label, onClick } = this.props;
    return (
      <div
        className={`${styles.button} ${className}`}
        onClick={onClick}
        role="button"
        tabIndex={-1}
      >
        {label} <i className={styles.buttonArrow} />
        {this.renderToolTip()}
      </div>
    );
  }

  renderToolTip = () => {
    const { tooltipText } = this.props;

    if (tooltipText) {
      // todo: add info icon
      return <span className={styles.tooltipText}>
        <span className={styles.icon}>
          <InfoIcon />
        </span>
        {tooltipText}
      </span>
      ;
    }
    return null;
  };
}

export default DefaultButton;
