import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';

import { Speaker } from '../../../static/icons/Speaker';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step3', 'office', 'exercises', 'recordsExercise3', 'overlay'],
      false
    )
  );
}

const style = { display: 'flex', alignItems: 'center' };

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Wortakzent: Partizip I',
      text:
        'Wie geht es dem Patienten? Trainieren Sie bei dieser Übung den Wortakzent der Partizipien. Korrigieren Sie sich selbst!',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u31/u31_1.mp3')
                },
                'Sein Zustand ist alarmierend.'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u31/u31_2.mp3')
                },
                'Ihr Zustand ist besorgniserregend.'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u31/u31_3.mp3')
                },
                'Sein Zustand ist zufriedenstellend.'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u31/u31_4.mp3')
                },
                'Die Nahrungsaufnahme ist nicht ausreichend.'
              ]
            },
            {
              id: 5,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u31/u31_5.mp3')
                },
                'Er reagiert ablehnend.'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
