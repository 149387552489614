import React from 'react';
import store from '../../../store';

import { actions as stepsActions } from '../../../reducers/steps';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';
import { actions as rewardSystemActions } from '../../../reducers/rewardSystem';

import { Speaker } from '../../../static/icons/Speaker';

function finishExercise() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'office', 'exercises', 'dietaryAssistantExercise1', 'overlay'],
      false
    )
  );

  const exercisePoints = store.getState().rewardSystem.currentExercisePoints;
  // check if arleady answered
  const currentExercise = store.getState().exerciseSet.currentExerciseSet;
  const currentExerciseSet = store.getState().exerciseSet[currentExercise];

  if (currentExerciseSet.dietaryAssistantExercise1 === false) {
    store.dispatch(rewardSystemActions.increment('step2', exercisePoints));
    store.dispatch(
      exerciseSetActions.closeExercise([
        'dietaryAssistant',
        'dietaryAssistantExercise1'
      ])
    );
  }
}

const style = { display: 'flex', alignItems: 'center' };

export default {
  flow: {
    0: {
      type: 'info',
      subline: 'Aussprache',
      headline: 'Substantive und Adjektive',
      text:
        'Hier lernen Sie, Fachbegriffe richtig auszusprechen. Korrigieren Sie sich selbst!',
      duration: 'DAUER: 2 MIN',
      goTo: '1'
    },
    1: {
      type: 'questionSet',
      category: 'pronunciation',
      questions: [
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_1.mp3')
                },
                'Entzündung – Entzündungswert'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_2.mp3')
                },
                'Ernährung – Ernährungsplan'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_3.mp3')
                },
                'Aufenthalt – Aufenthaltsraum'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_4.mp3')
                },
                'Belastung – Belastungsstörung'
              ]
            },
            {
              id: 5,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_5.mp3')
                },
                'Krankheit – Krankheitsbild'
              ]
            },
            {
              id: 6,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_6.mp3')
                },
                'Station – Stationsleitung'
              ]
            },
            {
              id: 7,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_7.mp3')
                },
                'Verband – Verbandswechsel'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_8.mp3')
                },
                'Bein | amputation'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_9.mp3')
                },
                'Diät | assistent'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_10.mp3')
                },
                'post | operativ'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_11.mp3')
                },
                'Wund | abstrich'
              ]
            },
            {
              id: 5,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_12.mp3')
                },
                'Wund | heilung'
              ]
            },
            {
              id: 6,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_13.mp3')
                },
                'fett | haltig'
              ]
            },
            {
              id: 7,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_14.mp3')
                },
                'Dienst | schluss'
              ]
            }
          ]
        },
        {
          title: (
            <span style={style}>
              Tippen Sie auf &nbsp;
              <Speaker size="35px" fill="#717171" />
            </span>
          ),
          question: 'Hören Sie die Wörter und sprechen Sie nach.',
          multiple: false,
          type: 'listen',
          answers: [
            {
              id: 1,
              meta: { correct: 1 },
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_15.mp3')
                },
                'die Chirurgie – chirurgisch'
              ]
            },
            {
              id: 2,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_16.mp3')
                },
                'das Antibiotikum – antibiotisch'
              ]
            },
            {
              id: 3,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_17.mp3')
                },
                'die Psyche – psychisch'
              ]
            },
            {
              id: 4,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_18.mp3')
                },
                'die Diagnose – diagnostisch'
              ]
            },
            {
              id: 5,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_19.mp3')
                },
                'die Hygiene – hygienisch'
              ]
            },
            {
              id: 6,
              data: [
                {
                  type: 'audio',
                  src: require('../../../static/audio/u23/u23a-c_20.mp3')
                },
                'die Diät – diätisch'
              ]
            }
          ],
          after: () => {
            finishExercise();
          }
        }
      ]
    }
  }
};
