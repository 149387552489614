import React from 'react';

import store from '../../../store';
import { actions as stepsActions } from '../../../reducers/steps';
import { actions as glossaryActions } from '../../../reducers/glossary';
import { actions as exerciseSetActions } from '../../../reducers/exerciseSet';

function exitDialogue() {
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'dialogues', 'mrsTeller', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'entities', 'mrsTeller', 'overlay'],
      false
    )
  );
  store.dispatch(
    stepsActions.set(
      ['step2', 'corridor', 'hotSpots', 'cake', 'overlay'],
      false
    )
  );

  store.dispatch(exerciseSetActions.setCurrentExerciseSet('mrsTeller'));
  store.dispatch(exerciseSetActions.setNextActivity('step2Score'));
}

function resetChoices() {
  store.dispatch(
    stepsActions.set(['step2', 'choices', 'wifeCooperative'], true)
  );
}

export default {
  speakers: {
    player: {
      theme: 'green right',
      avatar: () => {
        const portrait = store.getState().user.sex || 'female';
        return require(`../../../static/images/${portrait}.png`);
      }
    },
    ai: {
      theme: '',
      avatar: () =>
        require('../../../static/images/corridor/mrs-teller-avatar.png')
    }
  },
  flow: {
    0: {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '0-1-1',
          data: 'Hallo, wo wollen Sie denn hin?',
          onClick: () => {
            store.dispatch(
              stepsActions.set(['step2', 'choices', 'wifeCooperative'], false)
            );
          },
          audio: '207-Kuchen_ID_5.mp3'
        },
        {
          id: 'b',
          goTo: '0-2-1',
          data: 'Hm, das sieht ja gut aus. Ist die Torte für uns?',
          audio: '207-Kuchen_ID_4.mp3'
        },
        {
          id: 'c',
          goTo: '0-2-3',
          data: `Sie müssen Frau Teller sein. Ich erkenne Sie vom Foto. Übrigens mein Name ist ${store.getState()
            .user.name}.`,
          audio: '207-Kuchen_ID_3.mp3'
        }
      ]
    },

    '0-1-1': {
      from: 'ai',
      type: 'thought',
      text: `Spricht ${store.getState().user.sex === 'male'
        ? 'er'
        : 'sie'} mit mir? Was ist denn das für ein Ton! Ich besuche doch nur meinen Mann.`,
      audio: `${store.getState().user.sex === 'male'
        ? '207-Kuchen_ID_11m.mp3'
        : '207-Kuchen_ID_11w.mp3'}`,
      nextSnippet: '0-1-2'
    },
    '0-1-2': {
      from: 'player',
      text: 'Also wenn Sie Herrn Teller besuchen möchten, kann ich Ihnen gleich sagen, dass er diese Torte nicht essen darf.',
      audio: '207-Kuchen_ID_12.mp3',
      nextSnippet: '0-1-3'
    },
    '0-1-3': {
      from: 'ai',
      text: 'Und wer sagt das?',
      audio: '207-Kuchen_ID_13.mp3',
      nextSnippet: '0-1-4'
    },
    '0-1-4': {
      from: 'player',
      text: 'Ich sage das, ich bin heute für Ihren Mann in der Frühschicht zuständig.',
      audio: '207-Kuchen_ID_14.mp3',
      nextSnippet: '0-1-5'
    },
    '0-1-5': {
      from: 'player',
      text: `Mein Name ist ${store.getState().user
        .name}, neu auf der Station. Wir müssten uns mal kurz über Ihren Mann unterhalten.`,
      audio: '207-Kuchen_ID_15.mp3',
      nextSnippet: '0-1-6'
    },
    '0-1-6': {
      from: 'ai',
      text: 'Ich würde aber lieber mit Ihrer Kollegin sprechen.',
      audio: '207-Kuchen_ID_16.mp3',
      nextSnippet: 'fin'
    },

    '0-2-1': {
      from: 'ai',
      text: 'Das hätten Sie wohl gern.',
      audio: '207-Kuchen_ID_8.mp3',
      nextSnippet: '0-2-2'
    },
    '0-2-2': {
      from: 'player',
      text: `Sie sind doch Frau Teller, richtig? Haben Sie einen Moment Zeit? Ich bin ${store.getState()
        .user.name}, neu auf der Station.`,
      audio: '207-Kuchen_ID_9.mp3',
      nextSnippet: '0-2-3'
    },
    '0-2-3': {
      from: 'ai',
      text: 'Ah ja, stimmt. Ein neues Gesicht. Ramona Teller, freut mich.',
      audio: '207-Kuchen_ID_17.mp3',
      nextSnippet: '2-1'
    },

    '2-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '2-1-1',
          data: 'Frau Teller duzen',
          renderQuestion: false
        },
        {
          id: 'b',
          goTo: '2-1-3',
          data: 'Frau Teller siezen',
          renderQuestion: false
        }
      ]
    },

    '2-1-1': {
      from: 'player',
      text: 'Ramona, du hast einen Kuchen ...',
      audio: '207-Kuchen_ID_22.mp3',
      nextSnippet: '2-1-2'
    },
    '2-1-2': {
      from: 'player',
      text: () =>
        <span>
          Stopp, das ist keine so gute Idee. Lisa hat mir am ersten Tag gesagt,
          dass wir die{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('angehörige'));
            }}
          >
            Angehörigen
          </span>{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('siezen'));
            }}
          >
            siezen
          </span>{' '}
          sollen.
        </span>,
      audio: '207-Kuchen_ID_23.mp3',
      type: 'thought',
      nextSnippet: '2-1-3'
    },
    '2-1-3': {
      from: 'player',
      text: 'Frau Teller, ich sehe, Sie haben etwas für Ihren Mann gebacken. Lecker, aber leider nicht gut für ihn.',
      audio: '207-Kuchen_ID_20.mp3',
      nextSnippet: '2-1-4'
    },
    '2-1-4': {
      from: 'ai',
      text: 'Natürlich weiß ich, dass mein Torben jetzt keine Torte essen sollte. Aber ich wollte ihm eine Freude machen.',
      audio: '207-Kuchen_ID_21.mp3',
      nextSnippet: '3-1'
    },

    '3-1': {
      type: 'choice',
      question: 'Bitte wählen Sie eine dieser Optionen:',
      answers: [
        {
          id: 'a',
          goTo: '3-1-1',
          data: 'So machen Sie ihm aber keine.',
          onClick: () => {
            store.dispatch(
              stepsActions.set(
                ['step2', 'choices', 'wifeCooperative'],
                'neutral'
              )
            );
          },
          audio: '207-Kuchen_ID_25.mp3'
        },
        {
          id: 'b',
          goTo: '3-2-1',
          data: 'Ja, das glaube ich Ihnen. ',
          audio: '207-Kuchen_ID_24.mp3'
        }
      ]
    },

    '3-1-1': {
      from: 'ai',
      text: 'Woher wollen Sie das bitte wissen?',
      audio: '207-Kuchen_ID_30.mp3',
      nextSnippet: '3-1-2'
    },
    '3-1-2': {
      from: 'player',
      text: 'Frau Teller, ich meine nur, dass Sie Ihrem Mann viel mehr helfen würden, wenn Sie sich an seinen Diätplan halten würden.',
      audio: '207-Kuchen_ID_31.mp3',
      nextSnippet: '3-1-3'
    },
    '3-1-3': {
      from: 'ai',
      text: 'Ja, aber ich dachte ... Er muss doch etwas essen.',
      audio: '207-Kuchen_ID_32.mp3',
      nextSnippet: '3-1-4'
    },
    '3-1-4': {
      from: 'player',
      text: 'Da haben Sie recht. Vielleicht könnten Sie morgen einen Apfel oder etwas Gemüse mitbringen. Wie wär\' das?',
      audio: '207-Kuchen_ID_33.mp3',
      nextSnippet: '3-1-5'
    },
    '3-1-5': {
      from: 'ai',
      text: 'Ja, das mache ich gern. Und was machen wir mit der Torte? Möchten Sie und Ihre Kollegen vielleicht ein Stück?',
      audio: '207-Kuchen_ID_34.mp3',
      nextSnippet: '3-1-6'
    },
    '3-1-6': {
      from: 'player',
      text: 'Ah, das ist nett. Gerne, vielen Dank.',
      audio: '207-Kuchen_ID_35.mp3',
      nextSnippet: '3-1-7'
    },
    '3-1-7': {
      from: 'ai',
      text: 'Ein Familienrezept. Lassen Sie es sich schmecken!',
      audio: '207-Kuchen_ID_36.mp3',
      nextSnippet: 'fin'
    },

    '3-2-1': {
      from: 'player',
      text: () =>
        <span>
          Sehen Sie, wir versuchen gerade, einen neuen{' '}
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              store.dispatch(glossaryActions.setCurrentInfo('diätplan'));
            }}
          >
            Diätplan
          </span>{' '}
          für Ihren Mann zu erstellen.
        </span>,
      audio: '207-Kuchen_ID_27.mp3',
      nextSnippet: '3-2-2'
    },
    '3-2-2': {
      from: 'player',
      text: 'Sie würden ihn sehr unterstützen, wenn Sie sich auch daran halten würden.',
      audio: '207-Kuchen_ID_28.mp3',
      nextSnippet: '3-1-3'
    },
    fin: {
      type: 'choice',
      answers: () => {
        const wifeCooperative = store.getState().steps.step2.choices
          .wifeCooperative;

        let feedback = '4-1';
        if (wifeCooperative === false) {
          feedback = '4-2';
        } else if (wifeCooperative === 'neutral') {
          feedback = '4-3';
        }

        return [
          {
            id: 'a',
            goTo: feedback,
            data: 'Weiter',
            onClick: () => {
              resetChoices();
            },
            renderQuestion: false
          }
        ];
      }
    },
    '4-1': {
      type: 'feedback',
      text: 'Toll, dass Sie Frau Teller auf Ihre Seite gezogen haben.',
      onClick: () => {
        exitDialogue();
      },
      correct: true
    },
    '4-2': {
      type: 'feedback',
      text:
        'Ein freundlicher Ton motiviert auch die Angehörigen zu mehr Mitarbeit. Sprechen Sie noch einmal mit Frau Teller!',
      correct: false
    },
    '4-3': {
      type: 'feedback',
      text:
        'Ein freundlicher Ton motiviert auch die Angehörigen zu mehr Mitarbeit. Sprechen Sie noch einmal mit Frau Teller!',
      onClick: () => {
        exitDialogue();
      },
      correct: false
    }
  }
};
