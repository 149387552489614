import localForage from 'localforage/dist/localforage.min';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { Iterable } from 'immutable';
import immutableTransform from 'redux-persist-transform-immutable';

import rootReducer from './reducers';
import { actions as appActions } from './reducers/app';

let middlewareApplier;

if (
  process.env.NODE_ENV === 'development' &&
  process.env.TARGET !== 'staging'
) {
  const COLLAPSE_ACTIONS = ['SET_PAGE', 'persist/REHYDRATE'];
  const logger = require('redux-logger').createLogger({
    diff: true,
    collapsed: (getState, action) =>
      !window.verboseActions || COLLAPSE_ACTIONS.indexOf(action.type) > -1,
    stateTransformer: state => {
      const newState = {};

      Object.keys(state).forEach(s => {
        if (Iterable.isIterable(state[s])) {
          newState[s] = state[s].toJS();
        } else {
          newState[s] = state[s];
        }
      });

      return newState;
    }
  });
  middlewareApplier = applyMiddleware(logger);
} else {
  middlewareApplier = applyMiddleware();
}

const store = createStore(
  rootReducer,
  undefined,
  compose(middlewareApplier, autoRehydrate())
);

const persistor = persistStore(
  store,
  {
    storage: localForage,
    transforms: [immutableTransform()]
  },
  () => {
    store.dispatch(appActions.setReady());
  }
);

window.store = store;
window.localForage = localForage;

export default store;

export { persistor };
