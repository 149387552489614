import React, { Component } from 'react';

import { themeit } from 'react-themeit';

const themeOptions = {
  base: cb => cb(require('./styles.less')),
  themes: {
    right: cb => cb(require('./themes/right.less')),
    left: cb => cb(require('./themes/left.less'))
  }
};

class ButtonHint extends Component {
  render() {
    const { onClick, styles, title, description, style } = this.props;

    return (
      <div
        className={styles.buttonHintWrapper}
        role="button"
        tabIndex={0}
        onClick={onClick}
      >
        <div className={styles.buttonHintBackground} />
        <div className={styles.buttonHintContent} 
          style={style}>
          <h2 className={styles.buttonHintTitle}>
            {title}
          </h2>
          <p className={styles.buttonHintdescription}>
            {description}
          </p>
        </div>
      </div>
    );
  }
}

export default themeit(themeOptions)(ButtonHint);
