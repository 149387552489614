export const data = [
  {
    headline: 'Schritt 1',
    subline: 'Betreuen',
    image: require('../../static/images/menu/step1.jpg'),
    description: require('../../pages/steps/content').content.step1.paragraph(),
    scenes: [{
      name: 'Gespräch mit Torben Teller',
      room: 'hospitalRoom',
      step: 'step1',
      exclude: {},
      pdf: require('../../static/pdfs/Schritt_1_Gespraech_mit_Torben_Teller.pdf')
    }]
  },
  {
    headline: 'Schritt 2',
    subline: 'Informieren',
    image: require('../../static/images/menu/step2.jpg'),
    description: require('../../pages/steps/content').content.step2.paragraph(),
    scenes: [
      {
        name: 'Austausch mit der Kollegin',
        room: 'office',
        step: 'step2',
        exclude: { lisaBack: true },
        pdf: require('../../static/pdfs/Schritt_2_Austausch_mit_der_Kollegin.pdf')
      },
      {
        name: 'Gespräch mit der Ehefrau',
        room: 'corridor',
        step: 'step2',
        exclude: { hospitalRoom: true, office: true },
        pdf: require('../../static/pdfs/Schritt_2_Gespraech_mit_der_Ehefrau.pdf')
      }
    ]
  },
  {
    headline: 'Schritt 3',
    subline: 'Besprechen',
    image: require('../../static/images/menu/step3.jpg'),
    description: require('../../pages/steps/content').content.step3.paragraph(),
    scenes: [{
      name: 'Gespräch mit dem Arzt',
      room: 'office',
      step: 'step3',
      exclude: { milo: true },
      pdf: require('../../static/pdfs/Schritt_3_Gespraech_mit_dem_Arzt.pdf')
    }]
  },
  {
    headline: 'Schritt 4',
    subline: 'Berichten',
    image: require('../../static/images/menu/step4.jpg'),
    description: require('../../pages/steps/content').content.step4.paragraph(),
    scenes: [
      {
        name: 'Bitte an den Kollegen Milo',
        room: 'office',
        step: 'step4',
        exclude: { miloAndLisa: true },
        pdf: require('../../static/pdfs/Schritt_4_Bitte_an_den_Kollegen_Milo.pdf')
      },
      {
        name: 'Meldung des Zimmer\xADnachbarn',
        room: 'corridor',
        step: 'step4',
        exclude: { hospitalRoom: true, office: true, goingHome: true },
        pdf: require('../../static/pdfs/Schritt_4_Gespraech_mit_dem_Zimmernachbarn.pdf')
      },
      {
        name: 'Notfall\xADsituation Torben Teller',
        room: 'hospitalRoom',
        step: 'step4',
        exclude: { firstPatient: true, secondPatient: true },
        pdf: require('../../static/pdfs/Schritt_4_Notfallsituation_Torben_Teller.pdf')
      },
      {
        name:
        'Übergabe\xADgespräch mit Ina',
        room: 'office',
        step: 'step4',
        exclude: { milo: true },
        pdf: require('../../static/pdfs/Schritt_4_Uebergabegespraech.pdf')
      },
      {
        name: 'Endlich Feierabend',
        room: 'corridor',
        step: 'step4',
        exclude: { hospitalRoom: true, office: true, friesen: true, secondPatient: true },
        pdf: require('../../static/pdfs/Schritt_4_Endlich_Feierabend.pdf')
      }
    ]
  }
];
